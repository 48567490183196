import React, { useEffect, useState } from 'react';

import { Table } from '@belrald_hq/belrald-ui';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ChevronGrey } from '../../../assets/icons/arrow-down2.svg';
import { ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
import { GoBack } from '../../../components/go-back';
import { PageTitle } from '../../../containers/MesssageContainers';
import { FlexDiv, RelativeContainer } from '../../../containers/ScreenContainers';
import { getActiveSemester, getActiveSession, getAdmissionSessions } from '../../../redux/admissions/actions';
import { getStudentLevels } from '../../../redux/exam-magt/actions';


const FilterContainer = tw(FlexDiv)`
  p-[16px]
`;

const ExamLevels = ({ title, inDepartment }) => {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.examMagt);
    const { sessions: admissionsSessions, activeSession, activeSemester } = useSelector((state) => state.admission);
    const { currentDepartment } = useSelector((state) => state.department);
    const { currentProgramme } = useSelector((state) => state.programmes);

    const { _id: departmentId, academicStructure, academicStructureId } = currentDepartment || {};
    const { _id: programId } = currentProgramme || {};
    const splitStruct = academicStructure?.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const [data, setData] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [session, setSession] = useState(activeSession || '');
    const [semester, setSemester] = useState(activeSemester?.split(' ')?.[0] || 'First');

    const fields = [
        {
            header: 'Level',
            accessorKey: 'level',
            cell: (props) => (
                <Link
                    to={props.getValue()}
                    state={{ item: props.getValue(), session, semester }}
                    style={{ color: '#2563EB' }}
                >
                    {props.getValue()}
                </Link>
            ),
        },
    ];

    const actionItemSemester = ['First', 'Second'].map((item) => ({
        name: item,
        click: () => {
            setSemester(item);
            setOpenDropdown(null);
        },
    }));

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    useEffect(() => {
        if (semester && session) {
            const getData = async () => {
                const params = {
                    academicStructureId,
                    lastDegreeId,
                    session,
                    semester: semester.toLowerCase(),
                };

                const res = await dispatch(getStudentLevels(params));
                if (res) return setData(res);
                setData([]);
            };
            getData();
        }
    }, [academicStructureId, dispatch, lastDegreeId, semester, session]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
        dispatch(getActiveSession());
        dispatch(getActiveSemester());
    }, [dispatch]);

    return (
        <div className="h-[100vh]">
            {inDepartment ? (
                <div className="grid gap-10">
                    <GoBack title="Programmes" />
                    <PageTitle align="start">{currentDepartment?.departmentName}</PageTitle>
                </div>
            ) : (
                <PageTitle align="left">{title}</PageTitle>
            )}
            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer className="gap-5">
                    <RelativeContainer>
                        <Button
                            bgColor="#FFF"
                            color="#6B7280"
                            border="0.5px solid #9CA3AF"
                            onClick={() => handleOpenDropdown('sessions')}
                        >
                            {session || 'Select Session'}
                            <ChevronGrey />
                        </Button>
                        <div className="absolute left-[200px] top-0 ">
                            <div className="relative">
                                <ActionsPopup
                                    open={openDropdown === 'sessions'}
                                    close={() => setOpenDropdown(null)}
                                    items={admissionsSessions?.map((item) => ({
                                        name: item.admissionYear,
                                        click: () => {
                                            setSession(item.admissionYear);
                                            setOpenDropdown(null);
                                        },
                                    }))}
                                />
                            </div>
                        </div>
                    </RelativeContainer>

                    <RelativeContainer>
                        <Button
                            color="#6B7280"
                            border="1px solid #9CA3AF"
                            onClick={() => handleOpenDropdown('semester')}
                        >
                            {`${semester} Semester` || 'Semester'}
                            <ChevronGrey />
                        </Button>
                        <ActionsPopup
                            open={openDropdown === 'semester'}
                            close={() => setOpenDropdown(null)}
                            items={actionItemSemester}
                        />
                    </RelativeContainer>
                </FilterContainer>
                <div className="w-full">
                    <Table width={'100%'} data={data || []} columns={fields} loading={isLoading} />
                </div>
            </div>
        </div>
    );
};

export default ExamLevels;
