import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as AttachmentAsh } from '../../../../../assets/icons/attachment-ash.svg';
import { ReactComponent as BackArrow } from '../../../../../assets/icons/back-arrow.svg';
import { ReactComponent as Close } from '../../../../../assets/icons/close.svg';
import { ReactComponent as Download } from '../../../../../assets/icons/download-new.svg';
import { Button } from '../../../../../components/buttons';
import Acknowledge from '../../../../../components/forms/acknowledge';
import { Loader } from '../../../../../components/loader';
import PDFPreview from '../../../../../components/pdf-preview';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowEnd,
    FlexRowSpaceBetween,
} from '../../../../../containers/ScreenContainers';
import { getMemoById } from '../../../../../redux/staff-arena/actions';

const MainContainer = tw.div`
h-auto
bg-white
mx-auto
py-[2.4rem]
px-[3.2rem]
rounded-lg
`;

const MemoPreview = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const { staffData } = useSelector((store) => store.user);
    const { isLoading } = useSelector((store) => store.staffArena);

    const [memo, setMemo] = useState({});
    const [showAcknowledge, setShowAcknowledge] = useState(false);

    useEffect(() => {
        const getMemo = async () => {
            const res = await dispatch(getMemoById(id));
            setMemo(res);
        };

        getMemo();
    }, [dispatch, id]);

    if (isLoading) return <Loader />;

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.2rem]">
                <BackArrow className="mr-[8px] " />
                <PageTitle size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Received - Communications
                </PageTitle>
            </FlexCentredRow>

            <MainContainer>
                <FlexRowSpaceBetween>
                    <PageTitle>Memo</PageTitle>

                    <FlexCentredRow className="gap-x-[1.6rem]">
                        <Button className="shadow-[0px_1px_2px_rgba(6,25,56,0.05)]">
                            <Download />
                        </Button>

                        <Close onClick={() => navigate('/office/inbox')} />
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
                <FlexRowSpaceBetween className="mt-[2.8rem]">
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            TYPE:
                        </Text>
                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                            Memo
                        </Text>
                    </FlexCentredRow>
                    <FlexCentredRow>
                        <AttachmentAsh />
                        <Text color="#9CA3AF">
                            {staffData?._id === memo?.senderId ? 'Sent' : 'Received'}.{' '}
                            {new Date(memo?.createdAt).toLocaleString()}
                        </Text>
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
                <FlexCentredCol>
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            FROM (OFFICE):
                        </Text>
                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                            {memo?.senderOffice}
                        </Text>
                    </FlexCentredRow>
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            THROUGH:{' '}
                        </Text>
                        {memo?.through?.map((appointment, i) => (
                            <Text key={i} weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                                {appointment?.appointmentType}
                            </Text>
                        ))}
                    </FlexCentredRow>
                    <FlexCentredRow>
                        <Text align="left" weight="600" size="1.4rem" lineHeight="2rem" color="#9CA3AF">
                            TO:{' '}
                        </Text>
                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" left="2.4rem">
                            {memo?.recipientOffice}
                        </Text>
                    </FlexCentredRow>
                </FlexCentredCol>
                <FlexCentredCol className=" gap-y-[3.2rem] my-[3.2rem]">
                    <Text align="left">{memo?.title}</Text>
                    <Text align="left">{memo?.content}</Text>
                    <Text align="left">Best Regards, {memo?.senderOffice}</Text>
                </FlexCentredCol>
                <div className="flex flex-wrap gap-[1rem] items-center">
                    {memo?.attachment?.length > 0 ? (
                        <>
                            {memo?.attachment?.map((file) => (
                                <PDFPreview url={file.url} pageNumber={1} />
                            ))}
                        </>
                    ) : null}
                    {memo?.image?.length > 0 ? (
                        <>
                            {memo?.image?.map((img, i) => (
                                <img key={i} src={img.url} alt={img.fileName} className="h-[100px] w-[200px]" />
                            ))}
                        </>
                    ) : null}
                </div>
                <FlexRowEnd>
                    <Button
                        border="1px solid #6366F1"
                        bgColor="#6366F1"
                        color="#FFF"
                        onClick={() => setShowAcknowledge(true)}
                    >
                        Acknowledge
                    </Button>
                </FlexRowEnd>
            </MainContainer>

            <Acknowledge show={showAcknowledge} onClose={() => setShowAcknowledge(false)}></Acknowledge>
        </div>
    );
};

export default MemoPreview;
