import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ImportIcon } from '../../../../assets/icons/download.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit3.svg';
import { ReactComponent as StackIcon } from '../../../../assets/icons/stack.svg';
import AllocateCourse from '../../../../components/forms/allocate-course';
import EditCourseAllocation from '../../../../components/forms/edit-course-allocation';
import ImportAllotedLecturers from '../../../../components/forms/import-course-allocation';
import ListEmptyContent from '../../../../components/list-empty';
import CourseAllocationHistory from '../../../../components/popups/course-allocation-history';
import { CenteredContainer } from '../../../../containers/ScreenContainers';
import useFetchCoursesForAllocation from '../../../../hooks/useFetchCoursesForAllocation';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { getActiveSession, getAdmissionSessions } from '../../../../redux/admissions/actions';
import { getDepartmentStaff } from '../../../../redux/departments/actions';
import { getFacultyStaff } from '../../../../redux/faculty/actions';

const fields = [
    {
        cell: (props) => <p>{props.getValue()}</p>,
        header: 'Course',
        accessorKey: 'courseTitle',
    },
    {
        header: 'Course Code',
        accessorKey: 'courseCode',
    },
    {
        cell: (props) => <p>{props.getValue()?.join(', ')}</p>,
        header: 'Lecturers',
        accessorKey: 'lecturers',
    },
    {
        header: 'Level',
        accessorKey: 'courseLevel',
    },
    {
        cell: (props) => <p className="capitalize">{props.getValue()?.split(' ')[0]?.toLowerCase()}</p>,
        header: 'Semester',
        accessorKey: 'courseSemester',
    },
];

const CourseAllocation = ({ isFirstDegreeAllocation, pageTitle = '' }) => {
    const dispatch = useDispatch();

    const { sessions: admissionsSessions, activeSession } = useSelector((state) => state.admission);
    const { isLoading, currentDepartment } = useSelector((state) => state.department);
    const { isLoading: firstDegreeLoading, currentFaculty } = useSelector((state) => state.faculty);

    const { academicStructureId, _id: firstDegreeId } = currentFaculty;
    const { _id: secondDegreeId } = currentDepartment || {};

    const [searchValue, setSearchValue] = useState('');
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [courseToGetHistory, setCourseToGetHistory] = useState(null);
    const [openModal, setOpenModal] = useState(null);
    const [session, setSession] = useState(activeSession || '');
    const [showDropdown, setShowDropdown] = useState(false);
    const [refetchCourses, setRefetchCourses] = useState(false);

    const courses = useFetchCoursesForAllocation({
        academicStructureId,
        firstDegreeId,
        secondDegreeId,
        isFirstDegreeAllocation,
        session,
        refetchCourses,
    });

    const handleOpenModal = (modal) => {
        if (openModal === modal) {
            setOpenModal(null);
        } else {
            setOpenModal(modal);
        }
    };

    const handleFinish = () => {
        setSelectedCourse(null);
        setRefetchCourses((prev) => !prev);
    };

    useEffect(() => {
        if (isFirstDegreeAllocation) return;
        if (secondDegreeId) {
            dispatch(getDepartmentStaff(academicStructureId, secondDegreeId));
        }
    }, [dispatch, secondDegreeId, academicStructureId, firstDegreeId, isFirstDegreeAllocation]);

    useEffect(() => {
        if (!isFirstDegreeAllocation) return;
        if (firstDegreeId) {
            dispatch(getFacultyStaff(academicStructureId, firstDegreeId));
        }
    }, [firstDegreeId, academicStructureId, dispatch, isFirstDegreeAllocation]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
        dispatch(getActiveSession());
    }, [dispatch]);

    return (
        <>
            <PageLayout
                pageTitle={pageTitle || currentDepartment?.departmentName}
                secondaryButtonText="Allocate Course"
                onSecondaryButtonClick={() => setOpenModal('allocate')}
                data={courses || []}
                fields={fields}
                isActionable
                actionType="radio"
                onSelectedItemsChange={(items) => setSelectedCourse(items[0])}
                onActionBtnClick={() => handleOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <EditIcon />,
                        name: 'Edit Course Allocation',
                        click: () => {
                            setOpenModal('edit');
                        },
                    },
                    {
                        icon: <StackIcon />,
                        name: 'Allocation History',
                        click: () => {
                            setCourseToGetHistory(selectedCourse);
                            setOpenModal('history');
                        },
                        disabled: !selectedCourse,
                    },
                    {
                        icon: <ImportIcon />,
                        name: 'Import Allocation',
                        click: () => {
                            setOpenModal('import');
                        },
                    },
                ]}
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Course(s) yet"></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading || firstDegreeLoading}
                sessionItems={admissionsSessions?.map((item) => ({
                    name: item.admissionYear,
                    click: () => {
                        setSession(item.admissionYear);
                        setShowDropdown(false);
                    },
                }))}
                onSessionFilterClick={() => setShowDropdown(!showDropdown)}
                sessionPlaceHolder={session || 'Select Session'}
                openSessionFilter={showDropdown}
                closeSessionFilter={() => setShowDropdown(false)}
            />

            <AllocateCourse
                show={openModal === 'allocate'}
                setShow={setOpenModal}
                selectedCourse={selectedCourse}
                courses={courses || []}
                onFinish={handleFinish}
                isFirstDegreeAllocation={isFirstDegreeAllocation}
            />
            <EditCourseAllocation
                show={openModal === 'edit'}
                setShow={setOpenModal}
                selectedCourse={selectedCourse}
                courses={courses || []}
                onFinish={handleFinish}
            />
            <ImportAllotedLecturers
                show={openModal === 'import'}
                setShow={setOpenModal}
                onFinish={handleFinish}
                isFirstDegreeAllocation={isFirstDegreeAllocation}
            />

            <CourseAllocationHistory
                show={openModal === 'history'}
                setShow={setOpenModal}
                selectedCourse={courseToGetHistory}
            />
        </>
    );
};

export default CourseAllocation;
