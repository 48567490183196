import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as ProfileIcon } from '../../../assets/icons/people2.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow } from '../../../containers/ScreenContainers';

const ProfileLinks = ({ userType }) => {
    const navigate = useNavigate();

    const profileLinks = [
        ...(userType === 'staff'
            ? [{ icon: <ProfileIcon fill="#1F2937" />, title: 'Profile', link: '/staff-arena/profile' }]
            : []),
    ];

    return (
        <FlexCentredCol className="gap-[1.6rem] pt-[3.6rem]">
            {profileLinks?.map((item, index) => (
                <FlexCentredRow
                    onClick={() => navigate(item?.link)}
                    key={item?.title}
                    className={`${
                        profileLinks?.length - 1 === index ? 'border-y-[0.5px] border-y-[#6B7280] py-[1.6rem]' : ''
                    } gap-[10px]  px-[2.4rem]`}
                >
                    {item.icon}
                    <Text align="left" weight="400" size="1.6rem" color="#1F2937">
                        {item.title}
                    </Text>
                </FlexCentredRow>
            ))}
        </FlexCentredCol>
    );
};

export default ProfileLinks;
