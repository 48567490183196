import React, { useState } from 'react';


import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Text } from '../../../containers/MesssageContainers';
import { FlexColStart, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { loginUser } from '../../../redux/auth/actions';
import { LoadingButton } from '../../buttons';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';

const AdminSignInForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector((state) => state.auth.isLoading);

    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    userType: 'admin',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                    const payload = { ...values, email: values.email.toLowerCase() };

                    dispatch(loginUser(payload, navigate));
                }}
            >
                {({ errors, handleChange }) => (
                    <Form>
                        <div className="w-[50rem] mt-[32px] md:w-[60rem] max-h-[80vh] h-auto bg-white mx-auto p-[4rem] md:p-[5rem] rounded-3xl shadow-lg">
                            <h3 className="font-semibold text-[#1F2937] text-[19px] my-4">
                                Organisation Moderator Login
                            </h3>
                            <p className="font- text-[#9CA3AF] text-[12px] mb-16">
                                Enter your email and password to sign in.
                            </p>

                            <div className="grid gap-10">
                                <TextInput
                                    name="email"
                                    type="text"
                                    placeholder="Enter Email Address"
                                    errors={errors}
                                    onChange={handleChange}
                                />
                                <TextInput
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    inputType="password"
                                    togglePassword={() => setShowPassword((prevState) => !prevState)}
                                    placeholder="Enter Password"
                                    errors={errors}
                                    onChange={handleChange}
                                />
                            </div>

                            <FlexRowSpaceBetween className="mt-12">
                                <FlexColStart>
                                    <Link to="/sign-in-staff">
                                        <Text color="#3B82F6" align="left">
                                            Sign in as Staff
                                        </Text>
                                    </Link>
                                    <Link to="/forgot-password-admin">
                                        <Text color="#3B82F6" align="left">
                                            Forgot Password?
                                        </Text>
                                    </Link>
                                </FlexColStart>
                                <LoadingButton
                                    loading={isLoading}
                                    disabled={isLoading}
                                    bgColor="#6366F1"
                                    color="#fff"
                                    type="submit"
                                >
                                    Sign In
                                </LoadingButton>
                            </FlexRowSpaceBetween>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AdminSignInForm;
