import React, { useEffect, useState } from 'react';

import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// import { ReactComponent as Dropdown2 } from '../../../assets/icons/arrow-down2.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-sm.svg';
import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
// import { ActionsPopup } from '../../../components';
// import { Button } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowSpaceBetween,
    // RelativeContainer,
} from '../../../containers/ScreenContainers';
import { useTableContext } from '../../../context/tableContext';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getAgentDetails } from '../../../redux/bursary/actions';

const ViewAgent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { state } = useLocation();
    const { agentDetails: agent } = state || {};

    // const { sessions, activeSession } = useSelector((store) => store.admission);
    const {
        firstDegreeOptionsStr,
        secondDegreeOptionsStr,
        //  firstDegreeOptionsArray = [],
    } = useSelector((state) => state.academicStructure);
    const { isLoading } = useSelector((state) => state.bursary);

    const { tableSettings } = useTableContext();
    const numOfPages = tableSettings?.numOfPages;

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    // const [sessionValue, setSessionValue] = useState(activeSession || '');
    // const [openDropdown, setOpenDropdown] = useState(null);
    // const [openPaymentDropdown, setOpenPaymentDropdown] = useState(null);
    const [agentDetail, setAgentDetail] = useState(null);
    const [currentPage, setCurrentPage] = useState('1');
    const [showAccountInformation, setShowAccountInformation] = useState(false);

    const { students, pagination } = agentDetail || {};

    const { total, totalPages } = pagination || {};

    const {
        companyName,
        companyAddress,
        companyEmail,
        companyPhoneNumber,
        companyRegistrationNumber,
        createdBy,
        createdAt,
        agentAccountName,
        agentAccountNumber,
        agentAccountType,
        agentBankName,
    } = agent || {};

    // const sessionItems = sessions?.map((item) => ({
    //     name: item?.admissionYear,
    //     click: () => {
    //         setSessionValue(item?.admissionYear);
    //         setOpenDropdown(null);
    //     },
    // }));

    useEffect(() => {
        // if (!sessionValue) return;
        const query = {
            // session: sessionValue,
            search: debouncedSearchValue,
            page: currentPage,
            limit: numOfPages,
        };

        const fetchAgentDetails = async (query) => {
            const response = await dispatch(getAgentDetails(id, query));
            setAgentDetail(response);
        };
        fetchAgentDetails(query);
    }, [currentPage, debouncedSearchValue, dispatch, id, numOfPages]);

    const agentDetails = [
        {
            key: 'Company Name',
            value: companyName || '',
        },
        {
            key: 'Company Address',
            value: companyAddress || '',
        },
        {
            key: 'Company Email',
            value: companyEmail || '',
        },
        {
            key: "Company's Phone Number",
            value: companyPhoneNumber || '',
        },
        {
            key: 'Company Registration Number',
            value: companyRegistrationNumber || '',
        },
        {
            key: 'Created At',
            value: `${new Date(createdAt).toLocaleDateString()}, ${new Date(createdAt).toLocaleTimeString()}` || '',
        },
        {
            key: 'Created By',
            value: createdBy || '',
        },
    ];

    const accountInformation = [
        {
            key: 'Account Name',
            value: agentAccountName || '',
        },
        {
            key: 'Account Number',
            value: agentAccountNumber || '',
        },
        {
            key: 'Bank',
            value: agentBankName || '',
        },
        {
            key: 'Account Type',
            value: agentAccountType || '',
        },
    ];

    // const feeDetailsData = [
    //     { key: 'Fee Name', value: feeDetails?.feeName || '' },
    //     { key: 'Amount', value: feeDetails?.amountPayable || '' },
    //     { key: 'Payment Schedule', value: feeDetails?.paymentSchedule || '' },
    // ];

    // const paymentDetailsData = [
    //     {
    //         key: 'Total Amount Due to Agent (NGN)',
    //         value: paymentDetails?.totalAmount || '',
    //     },
    //     {
    //         key: 'Total Number of Students Referred',
    //         value: paymentDetails?.totalNumberOfStudentReferred || '',
    //     },
    //     {
    //         key: 'Total Amount Paid',
    //         value: paymentDetails?.totalAmountPaid || '',
    //     },
    //     {
    //         key: 'Total Amount Owed',
    //         value: paymentDetails?.remainingBalance || '',
    //     },
    //     {
    //         key: 'Total Number of Students Paid',
    //         value: paymentDetails?.totalNumberofStudentPaid || '',
    //     },
    //     {
    //         key: 'Total Number of Students Not Paid',
    //         value: paymentDetails?.totalNumberOfUnpaid || '',
    //     },
    // ];

    const fields = [
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Full Name',
            accessorKey: 'fullName',
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Matric No',
            accessorKey: 'matricNumber',
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: firstDegreeOptionsStr,
            accessorKey: 'faculty',
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: secondDegreeOptionsStr,
            accessorKey: 'program',
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Admission Type',
            accessorKey: 'admissionType',
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Admission Level',
            accessorKey: 'admittedLevel',
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Agent Fee',
            accessorKey: 'agentFeeAmount',
        },
        {
            cell: (props) => <p>{props.getValue()}</p>,
            header: 'Payment Status',
            accessorKey: 'paymentStatus',
        },
        {
            cell: (props) => <p>{new Date(props.getValue()?.[0]).toLocaleDateString()}</p>,
            header: 'Date Added',
            accessorKey: 'dateAdded',
        },
    ];

    const modifiedStudentsData = students?.map((item) => {
        return {
            ...item,
            faculty: item?.college || item?.faculty || item?.school,
            program: item?.subProgram || item?.department,
        };
    });

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.3rem] gap-[0.5rem]">
                <BackArrow className="" />

                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <PageTitle
                    as="span"
                    size="1.6rem"
                    weight="400"
                    color="#6366F1"
                    lineHeight="2.8rem"
                    className="mx-[1.4rem]"
                >
                    /
                </PageTitle>
                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Agents Management
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                    /
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                    {companyName}
                </PageTitle>
            </FlexCentredRow>

            <FlexCentredCol>
                <PageTitle align="left">{companyName}</PageTitle>
            </FlexCentredCol>

            <div className="bg-white p-[2.4rem] rounded-[8px] my-[2.4rem]">
                <PageTitle align="left" bottom="2.4rem">
                    Overview
                </PageTitle>
                <div className="grid grid-cols-4 gap-[2.4rem]">
                    {agentDetails?.map((item, index) => (
                        <div className="">
                            <Text align={index !== 3 ? 'left' : 'right'} size="1.2rem" weight="500" color="#9CA3AF">
                                {item?.key}
                            </Text>
                            <Text align={index !== 3 ? 'left' : 'right'} size="1.4rem" weight="500" color="#4B5563">
                                {item?.value}
                            </Text>
                        </div>
                    ))}
                </div>
                <div className="">
                    <FlexCentredRow
                        className="pt-[3.2rem] gap-[1rem]"
                        onClick={() => setShowAccountInformation(!showAccountInformation)}
                    >
                        <Text size="1.6rem" weight="600" align="left">
                            Account Information
                        </Text>

                        <div className="">
                            <ArrowDown
                                onClick={() => {
                                    setShowAccountInformation(!showAccountInformation);
                                }}
                                className={`${
                                    showAccountInformation ? 'rotate-90' : 'rotate-0'
                                } cursor-pointer transform duration-100`}
                            />
                        </div>
                    </FlexCentredRow>

                    {showAccountInformation && (
                        <div className="grid grid-cols-4 gap-[2.4rem]">
                            {accountInformation?.map((item, index) => (
                                <div className="">
                                    <Text
                                        align={index !== 3 ? 'left' : 'right'}
                                        size="1.2rem"
                                        weight="500"
                                        color="#9CA3AF"
                                    >
                                        {item?.key}
                                    </Text>
                                    <Text
                                        align={index !== 3 ? 'left' : 'right'}
                                        size="1.4rem"
                                        weight="500"
                                        color="#4B5563"
                                    >
                                        {item?.value}
                                    </Text>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="bg-white py-[2rem] rounded-[8px] my-[2.4rem]">
                {/* <FlexCentredRow className="py-[8px] px-[3.2rem]">
                    <RelativeContainer>
                        <Button color="#6B7280" border="1px solid #9CA3AF" onClick={() => setOpenDropdown('session')}>
                            {sessionValue || 'Session'}
                            <Dropdown2 />
                        </Button>
                        <ActionsPopup
                            open={openDropdown === 'session'}
                            close={() => setOpenDropdown(null)}
                            items={sessionItems}
                        />
                    </RelativeContainer>
                </FlexCentredRow> */}
                {/* <div className="border-b border-b-[#e5e7eb] py-[8px] px-[3.2rem]">
                    <FlexCentredRow className="gap-x-[1.6rem]">
                        <Text weight="600" size="1.4rem" color="#000">
                            Fee Details
                        </Text>
                        <ArrowDown
                            onClick={() => {
                                if (openDropdown === 'fee-details') {
                                    setOpenDropdown(null);
                                } else {
                                    setOpenDropdown('fee-details');
                                }
                            }}
                            className={`${
                                openDropdown === 'fee-details' ? 'rotate-90' : 'rotate-0'
                            } cursor-pointer transform duration-100`}
                        />
                    </FlexCentredRow>
                    {openDropdown === 'fee-details' && (
                        <div className="flex justify-between w-full pt-[2rem]">
                            {feeDetailsData?.map((item, idx) => (
                                <div className="">
                                    <Text
                                        align={idx === 2 ? 'right' : 'left'}
                                        size="1.2rem"
                                        weight="500"
                                        color="#9CA3AF"
                                    >
                                        {item?.key}
                                    </Text>
                                    <Text
                                        align={idx === 2 ? 'right' : 'left'}
                                        size="1.4rem"
                                        weight="500"
                                        color="#4B5563"
                                    >
                                        {item?.value}
                                    </Text>
                                </div>
                            ))}
                        </div>
                    )}
                </div> */}
                {/* <div className="border-b border-b-[#e5e7eb] py-[8px] px-[3.2rem]">
                    <FlexCentredRow className="gap-x-[1.6rem]">
                        <Text weight="600" size="1.4rem" color="#000">
                            Payment Details
                        </Text>
                        <ArrowDown
                            onClick={() => {
                                if (openPaymentDropdown === 'payment-details') {
                                    setOpenPaymentDropdown(null);
                                } else {
                                    setOpenPaymentDropdown('payment-details');
                                }
                            }}
                            className={`${
                                openPaymentDropdown === 'payment-details' ? 'rotate-90' : 'rotate-0'
                            } cursor-pointer transform duration-100`}
                        />
                    </FlexCentredRow>
                    {openPaymentDropdown === 'payment-details' && (
                        <div className="grid grid-cols-3 justify-between w-full pt-[2rem]">
                            {paymentDetailsData?.map((item, idx) => (
                                <div className="">
                                    <Text
                                        align={idx === 2 || idx === 5 ? 'right' : 'left'}
                                        size="1.2rem"
                                        weight="500"
                                        color="#9CA3AF"
                                    >
                                        {item?.key}
                                    </Text>
                                    <Text
                                        align={idx === 2 || idx === 5 ? 'right' : 'left'}
                                        size="1.4rem"
                                        weight="500"
                                        color="#4B5563"
                                    >
                                        {item?.value}
                                    </Text>
                                </div>
                            ))}
                        </div>
                    )}
                </div> */}
                <div className="">
                    <PageLayout
                        tableTitle="Referred Students"
                        fields={fields}
                        data={modifiedStudentsData || []}
                        searchValue={searchValue}
                        onSearchChange={(e) => setSearchValue(e.target.value)}
                        onSearchClose={() => setSearchValue('')}
                        isPaginated={true}
                        loading={isLoading}
                        paginationComponent={
                            <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem] text-black">
                                <PaginationStatus
                                    currentPage={currentPage}
                                    itemsPerPage={tableSettings?.numOfPages}
                                    totalItems={total}
                                />
                                <Pagination
                                    pageRangeDisplayed={3}
                                    totalPages={totalPages}
                                    onPageChange={(nextPage) => {
                                        setCurrentPage(nextPage);
                                    }}
                                    currentPage={currentPage}
                                />
                            </FlexRowSpaceBetween>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default ViewAgent;
