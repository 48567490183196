import React, { useState, useEffect, useMemo, useRef } from 'react';

import { format, isValid } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import ReactDatePicker from 'react-datepicker';
import tw from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-bg-ash.svg';
import { ReactComponent as DateIcon } from '../../../assets/icons/date.svg';

import './DatePicker.css';

const Label = tw.label`
    text-[#1F2937]
    font-medium
    text-[1.4rem]
`;

const DatePicker = ({
    initialValue, // Date string from calling new Date(date) e.g  Mon Oct 23 2023 01:00:00 GMT+0100 (West Africa Standard Time)
    name,
    minDate, // date string like above
    maxDate, // date string like above
    label,
    placeholder,
    touched,
    useYear, // show year only vs full date
    isClearable = true,
    disabled,
}) => {
    const ref = useRef(null);

    const { setFieldValue, errors } = useFormikContext();
    const [field] = useField(name);

    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState('');

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        // Add both mouse and touch event listeners
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            // Remove the event listeners
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, []);

    const formatedInitialValue = useMemo(() => {
        if (!initialValue && !isValid(initialValue)) return null;

        if (useYear) {
            return format(initialValue, 'yyyy');
        } else {
            return format(initialValue, 'dd/MM/yyyy');
        }
    }, [initialValue, useYear]);

    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (value) setIsOpen(false);
    }, [value]);

    useEffect(() => {
        if (formatedInitialValue) {
            setValue(formatedInitialValue);
        } else {
            setValue('');
        }
    }, [formatedInitialValue]);

    return (
        <div>
            {/* {isOpen && <div className="fixed z-10 inset-0" onClick={handleClick}></div>} */}
            {label && <Label htmlFor={name}>{label}</Label>}
            <div className="date-picker-wrapper text-[#1F2937] relative h-[4rem]">
                <button
                    type="button"
                    className="date-select-field flex items-center"
                    disabled={disabled}
                    onClick={handleClick}
                >
                    {value ? (
                        <p className="whitespace-nowrap font-normal mt-1 text-[1.4rem]">{value}</p>
                    ) : (
                        <p className="whitespace-nowrap font-normal mt-1 text-[1.4rem]">
                            {placeholder || `Select ${useYear ? 'year' : 'date'}`}
                        </p>
                    )}
                    {!isClearable || !value ? (
                        <DateIcon className="absolute right-3 top-3" />
                    ) : (
                        <CloseIcon
                            onClick={(e) => {
                                e.stopPropagation();
                                setFieldValue(field.name, '');
                                setValue('');
                            }}
                            className="absolute right-3 top-5"
                        />
                    )}
                </button>
                {isOpen && (
                    <div className="date__wrapper" ref={ref}>
                        <ReactDatePicker
                            {...field}
                            selected={(field.value && new Date(field.value)) || initialValue || null}
                            onChange={(val) => {
                                if (useYear) {
                                    setValue(format(val, 'yyyy'));
                                } else {
                                    setValue(format(val, 'dd/MM/yyyy'));
                                }
                                setFieldValue(field.name, val);
                            }}
                            minDate={minDate}
                            maxDate={maxDate}
                            showYearDropdown={!useYear}
                            showYearPicker={useYear}
                            dropdownMode="select"
                            inline
                            isClearable={isClearable}
                        />
                    </div>
                )}
            </div>
            {errors[name] && <span className="text-[#EF4444] text-[1.2rem]">{errors[name]}</span>}
        </div>
    );
};

export default DatePicker;
