import React, { useRef, useState } from 'react';

import { FieldArray, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PlusCircle } from '../../../assets/icons/circularplus-blue.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-icon.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { addNewHostel } from '../../../redux/hostel/action';
import { Button, LoadingButton } from '../../buttons';
import { GoBack } from '../../go-back';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input';
import FormikTextInput from '../../inputs/text-input-with-formik';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
    lg:p-[5.5rem]
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[79.3rem]
    mt-[3.6rem]
`;

const Details = tw.div`
    flex
    flex-row
    border
    border-dashed
    border-[#D1D5DB]
    rounded-[8px]
    items-center
    px-[.5rem]
    py-[.5rem]
    mb-[1rem]
    gap-[1rem]
`;
const Label = tw.label`
    text-[#1F2937]
    font-medium
    text-[1.4rem]
`;

const gender = ['Male', 'Female', 'Mixed'];
const partitionName = ['Block', 'Hall', 'Dormitory'];

const AddNewHostel = () => {
    const navigate = useNavigate();
    const hiddenFileInput = useRef(null);
    const hiddenImagesInput = useRef(null);
    const dispatch = useDispatch();
    const [logoName, setLogoName] = useState('');

    const { isLoading } = useSelector((store) => store.hostel);

    const handleClickImages = () => {
        hiddenImagesInput.current.click();
    };
    const handleClickLogo = () => {
        hiddenFileInput.current.click();
    };

    return (
        <div className="max-w-[79.3rem]">
            <div className="mb-12">
                <GoBack title="Accomodation Management" subTitle="Add New Hostel" />
            </div>

            <Text align="left" size="2.3rem" weight="600" bottom="3.2rem" top="3.2rem">
                Add New Hostel
            </Text>
            <Formik
                initialValues={{
                    hostelName: '',
                    hostelAddress: '',
                    gender: '',
                    aboutHostel: '',
                    hostelPartitionName: '',
                    hostelPhoneNumber: '',
                    hostelEmailAddress: '',
                    hostelLogo: {
                        url: '',
                    },
                    hostelImages: [],
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                    const formData = new FormData();
                    Object.keys(values).forEach((value) => {
                        if (value === 'hostelLogo') {
                            formData.append('hostelLogo', values.hostelLogo.url);
                        }
                        formData.append(value, values[value]);
                    });
                    values.hostelImages.forEach((image) => {
                        formData.append('hostelImages', image);
                    });

                    const res = await dispatch(addNewHostel(formData));
                    if (res) {
                        navigate(-1);
                    }
                }}
            >
                {({ handleChange, values, errors, setFieldValue, isValid }) => (
                    <Form>
                        <Container>
                            <div className="flex flex-col gap-[3.2rem]">
                                <TextInput
                                    label="Hostel Name"
                                    name="hostelName"
                                    type="text"
                                    placeholder="Hostel Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.hostelName}
                                />
                                <div>
                                    <Label>Hostel Logo (Optional)</Label>
                                    <Details>
                                        <Button
                                            type="button"
                                            onClick={handleClickLogo}
                                            className="px-[1.6rem] py-[0.8rem] border-dotted  border border-[#E5E7EB] shadow-[0_1px_2px_rgba(6,25,56,0.05)] "
                                        >
                                            Upload Files
                                        </Button>
                                        <Text size="1.6rem">
                                            {values?.hostelLogo?.url ? logoName : 'Click to upload files'}
                                        </Text>
                                        <input
                                            name="hostelLogo"
                                            type="file"
                                            accept=".jpg,.jpeg,.png"
                                            style={{ display: 'none' }}
                                            ref={hiddenFileInput}
                                            onChange={({ target: { files } }) => {
                                                if (files) {
                                                    setFieldValue('hostelLogo.url', files[0]);
                                                    setLogoName(files[0]?.name);
                                                }
                                            }}
                                        />
                                    </Details>
                                </div>

                                <FormikTextInput
                                    label="Hostel Address"
                                    name="hostelAddress"
                                    type="text"
                                    placeholder="Input Hostel Address"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.hostelAddress}
                                />

                                <Select
                                    name="gender"
                                    objProp="gender"
                                    label="Gender"
                                    data={gender.map((item) => ({ gender: item }))}
                                    placeholder="Select Gender"
                                    onChange={(selected) => {
                                        setFieldValue('gender', String(selected[0].gender));
                                    }}
                                    error={errors.gender}
                                    passedSelectedItems={values.gender}
                                />
                                <Select
                                    name="hostelPartitionName"
                                    label="Hostel Partition Name (Nomenclature)"
                                    objProp="hostelPartitionName"
                                    data={partitionName.map((item) => ({ hostelPartitionName: item }))}
                                    placeholder="Hostel Partition Name"
                                    onChange={(selected) => {
                                        setFieldValue('hostelPartitionName', String(selected[0].hostelPartitionName));
                                    }}
                                    error={errors.hostelPartitionName}
                                    passedSelectedItems={values.hostelPartitionName}
                                />
                                <TextInput
                                    name={'hostelPhoneNumber'}
                                    label="Hostel Phone Number"
                                    type="text"
                                    placeholder="Input Phone Number"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.hostelPhoneNumber}
                                />
                                <TextInput
                                    name="hostelEmailAddress"
                                    label="Hostel Email Address"
                                    type="text"
                                    placeholder="Input email address"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.hostelEmailAddress}
                                />
                                <FormikTextInput
                                    label="About Hostel"
                                    as="textarea"
                                    height="132px"
                                    className="py-[1rem]"
                                    name="aboutHostel"
                                    type="text"
                                    placeholder="Write a short information about the hostel."
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.aboutHostel}
                                />
                                <div>
                                    <Label>Hostel Images</Label>
                                    <FieldArray
                                        name="hostelImages"
                                        render={({ remove, push }) => (
                                            <>
                                                {values.hostelImages.length > 0 ? (
                                                    values.hostelImages.map((file, index) => (
                                                        <Details key={index} className="justify-between">
                                                            <FlexCentredRow className="gap-[1rem]">
                                                                <Button
                                                                    type="button"
                                                                    onClick={handleClickImages}
                                                                    className="px-[1.6rem] py-[0.8rem] border-dotted border border-[#E5E7EB] shadow-[0_1px_2px_rgba(6,25,56,0.05)]"
                                                                >
                                                                    <UploadIcon className="mr-[1rem]" />
                                                                    Upload Files
                                                                </Button>
                                                                <Text size="1.6rem">{file.name}</Text>
                                                            </FlexCentredRow>
                                                            <CloseIcon onClick={() => remove(index)} />
                                                        </Details>
                                                    ))
                                                ) : (
                                                    <Details className="justify-between">
                                                        <FlexCentredRow className="gap-[1rem]">
                                                            <Button
                                                                type="button"
                                                                onClick={handleClickImages}
                                                                className="px-[1.6rem] py-[0.8rem] border-dotted border border-[#E5E7EB] shadow-[0_1px_2px_rgba(6,25,56,0.05)]"
                                                            >
                                                                <UploadIcon className="mr-[1rem]" />
                                                                Upload Files
                                                            </Button>
                                                            <Text size="1.6rem">Click to upload files</Text>
                                                        </FlexCentredRow>
                                                    </Details>
                                                )}
                                                <FlexCentredRow className="mt-[3.2rem]">
                                                    <Button
                                                        border="1px solid #3B82F6"
                                                        color="#3B82F6"
                                                        onClick={() => hiddenImagesInput.current?.click()}
                                                    >
                                                        <PlusCircle />
                                                        <Text weight="500" size="1.4rem" color="#3B82F6">
                                                            Add Another Image
                                                        </Text>
                                                    </Button>
                                                </FlexCentredRow>
                                            </>
                                        )}
                                    />
                                    {/* Hidden input placed outside FieldArray */}
                                    <input
                                        name="hostelImages"
                                        type="file"
                                        accept=".pdf,.jpg,.jpeg,.png"
                                        multiple
                                        style={{ display: 'none' }}
                                        ref={hiddenImagesInput}
                                        onChange={(e) => {
                                            const files = Array.from(e.target.files);
                                            setFieldValue('hostelImages', [...values.hostelImages, ...files]);
                                        }}
                                    />
                                </div>
                            </div>
                        </Container>
                        <FlexRowEnd className="gap-[2rem] my-[3.2rem]">
                            <CancelButton type="button" onClick={() => navigate(-1)}>
                                Cancel
                            </CancelButton>
                            <LoadingButton
                                loading={isLoading}
                                disabled={!isValid || isLoading}
                                bgColor="#6366F1"
                                color="#fff"
                                type="submit"
                            >
                                Create Hostel
                            </LoadingButton>
                        </FlexRowEnd>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AddNewHostel;
