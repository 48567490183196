import React, { useEffect, useMemo, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CircularPlus } from '../../../../assets/icons/circularplus.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/edit3.svg';
import { ConfirmActionDialogue } from '../../../../components';
import { Button } from '../../../../components/buttons';
import ListEmptyContent from '../../../../components/list-empty';
import { CenteredContainer } from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { deleteCourse } from '../../../../redux/courses/actions';
import { getCoursesInSecondDegree } from '../../../../redux/departments/actions';

const CONFIRM_TEXT = 'delete';

const fields = [
    {
        header: 'Course',
        accessorKey: 'courseTitle',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Course Code',
        accessorKey: 'courseCode',
    },
    {
        header: 'Semester',
        accessorKey: 'courseSemester',
    },
    {
        header: 'Unit',
        accessorKey: 'courseUnit',
    },
    {
        header: 'Minimum Pass Mark(%)',
        accessorKey: 'minimumPassScore',
    },
    {
        header: 'Level',
        accessorKey: 'courseLevel',
    },
];

const DepartmentCourses = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, courses, currentDepartment } = useSelector((state) => state.department);
    const { currentFaculty } = useSelector((state) => state.faculty);

    const { academicStructureId, _id: facultyId } = currentFaculty;
    const { _id: departmentId } = currentDepartment || {};

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const [selected, setSelected] = useState(null);
    const [confirmDeleteInput, setConfirmDeleteInput] = useState('');
    const [refetch, setRefetch] = useState(false);
    const [loading, setLoading] = useState(false);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.searchParam = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        if (departmentId) {
            dispatch(getCoursesInSecondDegree(academicStructureId, facultyId, departmentId, query));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, departmentId, refetch, query]);

    const handleDelete = async () => {
        setLoading(true);
        setRefetch(false);
        if (CONFIRM_TEXT.toLowerCase() !== confirmDeleteInput.toLowerCase()) {
            toast.error(`Input ${CONFIRM_TEXT}`);
        } else {
            const res = await dispatch(deleteCourse(selected._id));
            if (res) {
                toast.success('Course deleted successfully');
                setSelected({});
                setShowDelete(false);
                setRefetch(true);
            }
        }
        setLoading(false);
    };

    return (
        <>
            <PageLayout
                pageTitle={currentDepartment?.departmentName}
                secondaryButtonText="Add New Course"
                onSecondaryButtonClick={() => navigate('add-new')}
                loading={isLoading}
                fields={fields}
                data={courses || []}
                isActionable
                actionType="radio"
                onSelectedItemsChange={(items) => setSelected(items[0])}
                onActionBtnClick={() => {
                    setDropdownOpen(!dropdownOpen);
                }}
                actionOpen={dropdownOpen}
                actionClose={() => setDropdownOpen(false)}
                actionItems={[
                    {
                        icon: <Edit />,
                        name: 'Edit Course',
                        disabled: !selected,
                        click: () => {
                            navigate(`edit-course/${selected?._id}`, { state: { course: selected } });
                            setDropdownOpen(false);
                        },
                    },
                    {
                        icon: <Delete />,
                        name: 'Delete Course',
                        disabled: !selected,
                        click: () => {
                            setShowDelete(true);
                            setDropdownOpen(false);
                        },
                    },
                ]}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Course(s) Yet " subtitle="Add new Course(s)">
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Button bgColor="#6366F1" color="#fff" onClick={() => navigate('add-new')}>
                                    <CircularPlus className="mr-[1.15rem]" />
                                    Add New Course
                                </Button>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
            />

            <ConfirmActionDialogue
                show={showDelete}
                close={() => setShowDelete(false)}
                title="Delete Course?"
                subtitle="This course will be deleted."
                borderLine
                bodyText="To confirm, enter 'delete' in the text field."
                placeholder="Input delete"
                onChange={(e) => setConfirmDeleteInput(e.target.value)}
                input
                value={confirmDeleteInput}
                btn2Text="Yes, Delete"
                confirmAction={handleDelete}
                isLoading={loading}
                btnDisabled={!confirmDeleteInput}
            />
        </>
    );
};
export default DepartmentCourses;
