import React, { useState, useEffect } from 'react';

import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';

import ListEmptyContent from '../../../components/list-empty';
import UploadFile from '../../../components/upload-file';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getStaffDirectory } from '../../../redux/staff-arena/actions';

const fields = [
    {
        header: '',
        accessorKey: 'action',
        cell: ({ row }) => (
            <div>
                {row.original?.profile ? (
                    <img alt="" src={row.original?.profile?.url} className="rounded-full w-[5rem] h-[5rem]" />
                ) : (
                    <Avatar name={`${row.original?.surname}`} size="50" textSizeRatio={1.75} className="rounded-full" />
                )}
            </div>
        ),
    },
    {
        header: 'Name',
        accessorKey: 'name',
        cell: ({ row }) => (
            <span style={{ fontWeight: '600' }}>{`${row.original?.surname || ''} ${
                row.original?.firstName || ''
            }`}</span>
        ),
    },
    {
        header: 'Unit',
        accessorKey: 'unitName',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Phone Number',
        accessorKey: 'phoneNumber',
    },
    {
        header: 'Email',
        accessorKey: 'staffEmail',
    },
    {
        header: 'Designation',
        accessorKey: 'designation',
    },
    {
        header: 'Appointment',
        accessorKey: 'appointment',
    },
];

const StaffDirectory = () => {
    const dispatch = useDispatch();

    const { isLoading, staffDirectory } = useSelector((state) => state.staffArena);

    const [searchValue, setSearchValue] = useState('');
    const [showUploadFile, setShowUploadFile] = useState(false);

    useEffect(() => {
        dispatch(getStaffDirectory());
    }, [dispatch]);

    return (
        <>
            <PageLayout
                pageTitle="Directory"
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={staffDirectory || []}
                fields={fields}
                loading={isLoading}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Notice yet"></ListEmptyContent>
                    </CenteredContainer>
                }
            />

            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </>
    );
};

export default StaffDirectory;
