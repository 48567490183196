import React, { useEffect, useState } from 'react';

import { SelectInput } from '@belrald_hq/belrald-ui';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ReactComponent as AttachIcon } from '../../../assets/icons/Paperclip.svg';
import { ConfirmActionDialogue } from '../../../components';
import { LoadingButton } from '../../../components/buttons';
import { CancelButton } from '../../../components/forms/sharedStyles';
import TextInput from '../../../components/inputs/text-input-with-formik';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow } from '../../../containers/ScreenContainers';
import { attachFeeToAgent, getFeeDetails } from '../../../redux/bursary/actions';

// import { ValidationSchema } from './validationSchema';

const Container = tw.div`
    bg-white
    h-auto
    max-w-[1000px]
    px-[3.2rem]
    py-[3.6rem]
    rounded-[16px]
`;

const AttachFee = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { agent } = state || {};

    const { sessions } = useSelector((store) => store.admission);
    const { feeDetails, isLoading } = useSelector((store) => store.bursary);

    const [openModal, setOpenModal] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [payload, setPayload] = useState(null);
    const { companyName, _id: companyId } = agent || {};

    const modifiedFeeData = feeDetails?.map((item) => {
        return {
            feeName: item.fee.allFees?.[0]?.feeName,
            feeId: item.fee._id,
            ...item,
        };
    });

    const handleCloseModal = () => {
        setPayload(null);
        setInputValue('');
        setOpenModal(false);
    };

    const handleAttachFee = async () => {
        const attachPayload = { ...payload, agentId: companyId };
        const response = await dispatch(attachFeeToAgent(attachPayload));
        if (response) {
            setOpenModal(false);
            navigate(-1);
        }
    };

    useEffect(() => {
        dispatch(getFeeDetails('Agent-Fee'));
    }, [dispatch]);

    return (
        <>
            <FlexCentredRow onClick={() => navigate(-1)} className="gap-[0.5rem] hover:cursor-pointer mb-[3.3rem]">
                <BackArrow className="mr-[8px]" />

                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                    /
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Attach Fee to Agent
                </PageTitle>
            </FlexCentredRow>

            <Formik
                initialValues={{
                    session: '',
                    agentId: '',
                    feeId: '',
                }}
                // validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    setPayload(values);
                    setOpenModal(true);
                }}
            >
                {({ handleChange, errors, setFieldValue, isSubmitting }) => (
                    <Form>
                        <div className="max-w-[900px]">
                            <Container>
                                <FlexCentredRow className="gap-[7.15px] mb-[1.5rem]">
                                    <AttachIcon />
                                    <Text size="1.6rem" weight="600">
                                        Attach Fee to Agent
                                    </Text>
                                </FlexCentredRow>
                                <div className="gap-y-[2rem] flex flex-col">
                                    <FlexCentredCol>
                                        <SelectInput
                                            name="session"
                                            objProp="admissionYear"
                                            data={sessions}
                                            placeholder="Choose Session"
                                            label="Session"
                                            onChange={(selected) => {
                                                setFieldValue('session', selected[0].admissionYear);
                                            }}
                                            error={errors.session}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <TextInput
                                            name="name"
                                            onChange={handleChange}
                                            label="Agent Name"
                                            type="text"
                                            placeholder="Input Agent Name"
                                            value={companyName}
                                            disabled
                                            errors={errors}
                                        />
                                    </FlexCentredCol>
                                    <FlexCentredCol>
                                        <SelectInput
                                            name="feeId"
                                            objProp="feeName"
                                            data={modifiedFeeData || []}
                                            placeholder="Choose Fee Category"
                                            label="Fee"
                                            onChange={(selected) => {
                                                setFieldValue('feeId', selected?.[0].feeId);
                                            }}
                                            error={errors.fee}
                                        />
                                    </FlexCentredCol>
                                </div>
                            </Container>
                            <div className="flex items-center gap-[1rem] my-[2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)} className="ml-auto">
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isSubmitting} bgColor="#6366F1" color="#fff" type="submit">
                                    Continue
                                </LoadingButton>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <ConfirmActionDialogue
                withIcon={false}
                input
                placeholder="Enter attach fee"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                show={openModal}
                title="Attach Fee"
                close={handleCloseModal}
                subtitle="All students referred by the agent will be required to make this payment"
                bodyText="To confirm, enter attach fee in the text field to confirm."
                btn2Text="Attach Fee"
                bgColor={'#6366f1'}
                confirmAction={handleAttachFee}
                btnDisabled={inputValue?.toLowerCase() !== 'attach fee'}
                isLoading={isLoading}
            />
        </>
    );
};

export default AttachFee;
