import React from 'react';

import CreateNewFacultyCourse from '../../../../components/forms/create-faculty-course';
import { GoBack } from '../../../../components/go-back';

const AddFacultyGeneralCourse = ({ action = 'Add' }) => {
    return (
        <>
            <div className="mb-6">
                <GoBack title="Courses" subtitle={` ${action} General Course`} />\
            </div>

            <CreateNewFacultyCourse action={action} />
        </>
    );
};
export default AddFacultyGeneralCourse;
