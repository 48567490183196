import React from 'react';

import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 56rem;
        max-height: 70vh;
        margin: auto;
    }
`;

const ItemsContainer = tw.ul`
    overflow-y-auto
    border-b
    pb-2
    mt-5
`;

const PromoteStudents = ({
    show,
    close,
    list,
    onSend,
    subtitle,
    title,
    nameField = 'name',
    idField = 'id',
    isLoading,
}) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween>
                <Text as="h3" size="1.9rem" weight="600" align="left">
                    {title || 'Are you sure you want to promote the student(s)?'}
                </Text>
                <CloseIcon className="hover:cursor-pointer" onClick={close} />
            </FlexRowSpaceBetween>

            <Text align="start" size="1.6rem" color="#374151" top="1.6rem" lineHeight="2.4rem" bottom="1rem">
                The selected student(s) will be promoted to a new level. Please check the list below to review the
                selections.
            </Text>

            <ItemsContainer>
                {list?.map((item, index) => (
                    <Text align="left" key={item?.[idField] || index}>{`${item?.lastName} ${item?.firstName} ${
                        item?.middleName || ''
                    }`}</Text>
                    // <Text align="left" key={item?.[idField] || index}>{`${item?.[nameField]}`}</Text>
                ))}
            </ItemsContainer>

            <Text color="#6B7280" size="1.2rem" align="left">
                {list?.length} name(s) selected
            </Text>

            <FlexRowEnd className="gap-x-[16px]">
                <Button type="button" onClick={close} border="0.5px solid #D1D5DB">
                    Cancel
                </Button>
                <LoadingButton loading={isLoading} onClick={onSend}>
                    Promote
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default PromoteStudents;
