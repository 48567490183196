import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/peopleIcon.svg';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexDiv } from '../../../containers/ScreenContainers';
import { getSpecialLetter } from '../../../redux/special-letter/actions';
import { SAVE_ADD } from '../../../redux/special-letter/slice';
import { CancelButton, SubmitButton } from '../../forms/sharedStyles';
import Select from '../../inputs/new-select';
import FormikTextInput from '../../inputs/text-input-with-formik';
import { Loader } from '../../loader';

import { ValidationSchema } from './ValidationSchema';

const LetterTypes = [
    {
        name: 'Letter of Acceptance',
    },
    // {
    //     name: 'Letter of Admission',
    // },
];

const target = [{ name: 'Newly Admitted Student', value: 'Newly-Admitted-Student' }];

const EditSpecialLetterForm = () => {
    const navigate = useNavigate();
    const { sessions } = useSelector((store) => store.admission);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [showContent, setShowContent] = useState(false);
    const { singleSpecialLetter } = useSelector((store) => store.specialLetter);

    useEffect(() => {
        dispatch(getSpecialLetter(id));
    }, [dispatch, id]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowContent(true);
        }, 5000);

        return () => clearTimeout(timeout);
    }, []);

    if (!showContent || !singleSpecialLetter) return <Loader />;

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.6rem] gap-[8px]">
                <BackArrow />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Special Letters
                </PageTitle>
                <Text size="1.6rem">/</Text>
                <Text>Edit Special Letter</Text>
            </FlexCentredRow>

            <FlexCentredRow className="mb-[5.8rem]">
                <PageTitle weight="600" size="2.3rem">
                    Edit Special Letter
                </PageTitle>
            </FlexCentredRow>

            <div className="bg-white rounded-[1.6rem] py-[3.6rem] px-[3.2rem] flex flex-col gap-[3.2rem] mt-[3.2rem]">
                <FlexCentredRow className="gap-[0.5rem]">
                    <PeopleIcon />
                    <Text size="1.6rem" weight="600">
                        Edit Special Letter
                    </Text>
                </FlexCentredRow>
                <Formik
                    initialValues={{
                        letterName: singleSpecialLetter.letterName || '',
                        target: singleSpecialLetter.target || '',
                        type: singleSpecialLetter.type || '',
                        session: singleSpecialLetter.session || '',
                        shortDescription: singleSpecialLetter.shortDescription || '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={(values, actions) => {
                        dispatch(SAVE_ADD(values));
                        navigate(`/registry/edit-special-letters/compose/${id}`);
                    }}
                >
                    {({ errors, values, handleChange, setFieldValue }) => (
                        <Form>
                            <div className="flex flex-col gap-[1.6rem] text-[#000]">
                                <FlexDiv className="gap-[3.2rem]">
                                    <FormikTextInput
                                        label="Name of Letter"
                                        name="letterName"
                                        type="text"
                                        value={values.letterName}
                                        placeholder="Input Name"
                                        errors={errors}
                                        onChange={handleChange}
                                    />
                                    <Select
                                        name="session"
                                        objProp="admissionYear"
                                        placeholder="Choose Session"
                                        passedSelectedItems={singleSpecialLetter.session}
                                        label="Session"
                                        onChange={(selected) => {
                                            setFieldValue('session', selected[0].admissionYear);
                                        }}
                                        containerClass="w-[100%]"
                                        data={sessions}
                                        error={errors.session}
                                    />
                                </FlexDiv>
                                <FlexDiv className="gap-[3.2rem]">
                                    <Select
                                        name="type"
                                        objProp="name"
                                        data={LetterTypes}
                                        passedSelectedItems={values.type}
                                        onChange={(selected) => {
                                            setFieldValue('type', selected[0].name);
                                        }}
                                        label="Type"
                                        containerClass="w-[100%]"
                                        placeholder="Choose Type"
                                        error={errors.type}
                                        disabled
                                    />

                                    <Select
                                        name="target"
                                        objProp="name"
                                        label="Target"
                                        data={target}
                                        onChange={(selected) => {
                                            setFieldValue('target', selected[0].value);
                                        }}
                                        containerClass="w-[100%]"
                                        placeholder="Choose Target"
                                        error={errors.target}
                                        passedSelectedItems={values?.target}
                                    />
                                </FlexDiv>
                                <FlexDiv className="gap-[3.2rem]">
                                    <FormikTextInput
                                        as="textarea"
                                        name="shortDescription"
                                        type="text"
                                        height="7rem"
                                        className="p-[8px]"
                                        placeholder="Input Description"
                                        label="Short Description (optional)"
                                        value={values.shortDescription}
                                        errors={errors}
                                        onChange={handleChange}
                                    />
                                    <div className="w-[100%]"></div>
                                </FlexDiv>
                                <div className="flex items-center gap-[2rem]">
                                    <CancelButton className="ml-[auto]" type="button" onClick={() => navigate(-1)}>
                                        Cancel
                                    </CancelButton>
                                    <SubmitButton type="submit">Next</SubmitButton>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default EditSpecialLetterForm;
