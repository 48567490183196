import React, { useEffect } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as HeaderIcon } from '../../../assets/icons/circularplus-blue.svg';
import { editBlock, getBlockById } from '../../../redux/hostel/action';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end

`;
const gender = ['Male', 'Female', 'Mixed'];
const STEP = 1;

const EditBlock = ({ show, setShow, item, setToogleRefetch }) => {
    const dispatch = useDispatch();
    const onClose = () => {
        setShow(false);
    };
    const { isLoading, blockDetails } = useSelector((store) => store.hostel);

    useEffect(() => {
        if (item?._id) {
            dispatch(getBlockById(item?._id));
        }
    }, [dispatch, item?._id]);

    return (
        <>
            <FormContainer headerIcon={<HeaderIcon />} close={onClose} show={show} title={'Edit Block'}>
                <Formik
                    enableReinitialize
                    initialValues={{
                        blockName: blockDetails?.blockName || '',
                        numberOfFloors: blockDetails?.numberOfFloors || '',
                        gender: blockDetails?.gender || '',
                        blockDescription: blockDetails?.blockDescription || '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const res = await dispatch(editBlock(values, item?._id, item?.hostelId));
                        if (res) {
                            actions.resetForm();
                            onClose();
                            setToogleRefetch(true);
                            toast.success('Block was updated successfully!');
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <div className="gap-10 flex flex-col">
                                <TextInput
                                    name="blockName"
                                    type="text"
                                    label="Block Name"
                                    placeholder="Input Block Name"
                                    value={values?.blockName}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                                <TextInput
                                    name="blockDescription"
                                    type="text"
                                    label="Block Description (Optional)"
                                    placeholder="Input Block Description"
                                    value={values?.blockDescription}
                                    onChange={handleChange}
                                    errors={errors}
                                />

                                <Select
                                    name="gender"
                                    objProp="gender"
                                    label="Gender"
                                    data={gender?.map((item) => ({ gender: item }))}
                                    placeholder="Select Gender"
                                    onChange={(selected) => {
                                        setFieldValue('gender', String(selected[0].gender));
                                    }}
                                    error={errors.gender}
                                    passedSelectedItems={values?.gender ? values?.gender : null}
                                />

                                <TextInput
                                    name="numberOfFloors"
                                    type="number"
                                    label="No of Floors"
                                    placeholder="Select No of Floors"
                                    step={STEP}
                                    min={1}
                                    handleDecrement={() =>
                                        setFieldValue('numberOfFloors', Math.max(0, (values.numberOfFloors || 0) - 1))
                                    }
                                    handleIncrement={() =>
                                        setFieldValue('numberOfFloors', (values?.numberOfFloors || 0) + 1)
                                    }
                                    showSpinBtns={true}
                                    errors={errors}
                                    value={values.numberOfFloors}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value) {
                                            setFieldValue('numberOfFloors', parseInt(value));
                                        }
                                    }}
                                    className=" [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                                />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} disabled={isLoading} type="submit">
                                    Update Block
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default EditBlock;
