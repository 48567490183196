import React, { useEffect } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { Text } from '../../../containers/MesssageContainers';
import {  FlexColStart, FlexRowEnd } from '../../../containers/ScreenContainers';
import { editPaymentGroup, getPaymentGroupsById } from '../../../redux/bursary/actions';
import {  LoadingButton } from '../../buttons';
import { GoBack } from '../../go-back';
import Select from '../../inputs/new-select';
import TextAreaInput from '../../inputs/text-area';
import TextInput from '../../inputs/text-input';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
    lg:p-[5.5rem]
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[79.3rem]
    mt-[3.6rem]
`;


const staffTypes = ['Academics', 'Non-Academics', 'Any'];
const groupTypes = ['Group Only', 'Group And SubGroups' ];

const EditPaymentGroup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams()
    const { isLoading, groupDetails } = useSelector((store) => store.bursary);

useEffect(() => {
    if (id) {
        dispatch(getPaymentGroupsById(id));
    }
}, [dispatch, id]);

    return (
        <div className="max-w-[79.3rem]">
            <div className="mb-12">
                <GoBack title="Payment Group" subTitle="Edit Group" />
            </div>

            <Text align="left" size="2.3rem" weight="600" bottom="3.2rem" top="3.2rem">
                Edit Group
            </Text>
            <Formik
                initialValues={{
                    groupType: groupDetails?.groupType || '',
                    groupName: groupDetails?.groupName || '',
                    staffType:  groupDetails?.staffType || '',
                    description: groupDetails?.description || '',
                    
                }}
                enableReinitialize={true}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                   
                    const res = await dispatch(editPaymentGroup(values, id));
                    if (res) {
                        navigate(-1);
                    }
                }}
            >
                {({ handleChange, values, errors, setFieldValue, isValid }) => (
                    <Form>
                        <Container>
                            <div className="flex flex-col gap-[3.2rem]">
                            <Select
                                    name="groupType"
                                    label="Group Type"
                                    objProp="groupType"
                                    data={groupTypes.map((item) => ({ groupType: item }))}
                                    placeholder="Select Type "
                                    onChange={(selected) => {
                                        setFieldValue('groupType', String(selected[0].groupType));
                                    }}
                                    error={errors.groupType}
                                    passedSelectedItems={ values?.groupType}
                                />
                               
                            <TextInput
                                    label="Group Name"
                                    name="groupName"
                                    type="text"
                                    placeholder="Input Group Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.groupName}
                                />
                               
                            <Select
                                    name="staffType"
                                    objProp="staffType"
                                    label="Staff Type"
                                    data={staffTypes.map((item) => ({ staffType: item }))}
                                    placeholder="Select Type"
                                    onChange={(selected) => {
                                        setFieldValue('staffType', String(selected[0].staffType));
                                    }}
                                    error={errors.staffType}
                                    passedSelectedItems={values?.staffType}
                                />
                            <FlexColStart>
                                <TextAreaInput
                                name="description"
                                label="Description"
                                placeholder="Input Description"
                                errorMessage={errors.description}
                                onChange={(e) => {
                                    setFieldValue('description', e.target.value);
                                }}
                                value={values.description}
                                height="6rem"
                             />
                             <Text size='1.2rem' color='#9CA3AF' lineHeight='1.7rem' align='left' >Maximum of 60 Characters</Text>
                            
                            </FlexColStart>
                            
                            </div>
                        </Container>
                        <FlexRowEnd className="gap-[2rem] my-[3.2rem]">
                            <CancelButton type="button" onClick={() => navigate(-1)}>
                                Cancel
                            </CancelButton>
                            <LoadingButton
                                loading={isLoading}
                                disabled={!isValid || isLoading}
                                bgColor="#6366F1"
                                color="#fff"
                                type="submit"
                            >
                                Update Group
                            </LoadingButton>
                        </FlexRowEnd>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EditPaymentGroup;
