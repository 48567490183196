import React, { useEffect, useMemo, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete2.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit3.svg';
import { Button } from '../../../../components/buttons';
import {
    AddAppointment,
    AddBursar,
    AddDVC,
    AddDean,
    AddDirectors,
    AddHODs,
    AddProvost,
    AddRegistrar,
    AddSubDean,
    NewCategory,
} from '../../../../components/forms/add-appointment';
import {
    EditBursar,
    EditDVC,
    EditDean,
    EditDirectors,
    EditHod,
    EditNewCategory,
    EditProvost,
    EditRegistrar,
    EditSubDean,
    EditVc,
} from '../../../../components/forms/edit-appointment';
import Checkbox from '../../../../components/inputs/checkbox';
import ConfirmAddAdministrator from '../../../../components/popups/confirm-add-administrator';
import DeleteCourse from '../../../../components/popups/delete-course';
import DeleteError from '../../../../components/popups/delete-error';
import ViewHistory from '../../../../components/popups/view-history';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import {
    addBursar,
    addDean,
    addDeputyVc,
    addDirector,
    addHOD,
    addNew,
    addProvost,
    addRegistrar,
    addSubDean,
    addVc,
    getAllBursars,
    getAllDVc,
    getAllDeans,
    getAllDirectors,
    getAllHODs,
    getAllNew,
    getAllProvosts,
    getAllRegistrars,
    getAllSubDeans,
    getAllVc,
    removeBursar,
    removeDVc,
    removeDean,
    removeDirector,
    removeHOD,
    removeNew,
    removeProvost,
    removeRegistrar,
    removeSubdean,
    removeVc,
} from '../../../../redux/appointments/actions';
import { CLEAR_APPOINTMENT_DETAILS } from '../../../../redux/appointments/slice';
import { getFaculties } from '../../../../redux/faculty/actions';
import { getAllStaff } from '../../../../redux/staff/actions';

const fields = [
    {
        accessorKey: 'action',
        cell: (props) => <p>{props?.getValue()}</p>,
        header: <Checkbox />,
        size: 5,
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Name',
        accessorKey: 'fullName',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Staff Number',
        accessorKey: 'staffNo',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Post',
        accessorKey: 'post',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Start Date',
        accessorKey: 'startDate',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'End Date',
        accessorKey: 'endDate',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: '',
        accessorKey: 'viewHistory',
    },
];

const AppointmentDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { allStaff } = useSelector((store) => store.staff);
    const { category, appointmentDetails, isLoading } = useSelector((store) => store.appointment);

    const [showDelete, setShowDelete] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [details, setDetails] = useState({});
    const [appointmentName, setAppointmentName] = useState('');
    const [showNewCategory, setShowNewCategory] = useState(false);
    const [appointment, setAppointment] = useState(null);
    const [name, setName] = useState('');
    const [appointee, setAppointee] = useState({});
    const [editNew, setEditNew] = useState(false);
    const [openModal, setOpenModal] = useState(null);
    const [actions, setActions] = useState(false);
    const [refetchAppointments, setRefetchAppointments] = useState(false);

    const Appointment = appointmentDetails?.map((data) => {
        return {
            id: data._id,
            fullName: data.name,
            post:
                appointmentName === 'Deputy Vice Chancellor'
                    ? data.appointmentType + ' ' + data.post
                    : data.appointmentType,
            startDate: data.startDate?.slice(0, 10),
            endDate: data.endDate?.slice(0, 10),
            viewHistory: (
                <td>
                    <div className="min-w-[180px]">
                        <Button
                            onClick={() => handleOpenDropdown('view-history')}
                            border="2px solid #E5E7EB"
                            color="#6366F1"
                        >
                            View History
                        </Button>
                    </div>
                </td>
            ),
            ...data,
        };
    });

    const handleOpenDropdown = (dropdown) => {
        if (openModal === dropdown) {
            setOpenModal(null);
        } else {
            setOpenModal(dropdown);
        }
    };

    const currentDate = new Date();

    const CurrentAppointment = Appointment?.filter((appointment) => {
        const endDate = new Date(appointment.endDate);

        if (appointment?.endDate) {
            return endDate > currentDate;
        } else {
            return appointment;
        }
    });

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.staffSearch = debouncedSearchValue;
        }
        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        const appointmentCategory = category?.find((item) => item._id === id);
        setAppointmentName(appointmentCategory.appointmentName);
    }, [id, category]);

    useEffect(() => {
        if (appointment) {
            const SingleItem = appointmentDetails?.find((item) => item._id === appointment._id);
            setAppointee(SingleItem);
        }
    }, [appointment, appointmentDetails]);

    useEffect(() => {
        if (details.name) {
            const staff = allStaff?.find((item) => item.fullName === details.name);
            details.staffNo = '';
            details.startDate = details?.startDate ? new Date(details?.startDate).toISOString().slice(0, 10) : '';
            details.endDate = details?.endDate ? new Date(details?.endDate).toISOString().slice(0, 10) : '';
            details.appointmentType = appointmentName;
            details.email = staff.staffEmail;
        }
        if (details.email) delete details.staffNo;
    }, [details, allStaff, appointmentName]);

    useEffect(() => {
        dispatch(getFaculties());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllStaff(query));
    }, [dispatch, query]);

    useEffect(() => {
        const getAppointments = () => {
            if (!appointmentName) return;
            dispatch(CLEAR_APPOINTMENT_DETAILS());
            const dispatchFunction =
                {
                    'Vice Chancellor': getAllVc,
                    'Deputy Vice Chancellors': getAllDVc,
                    Registrar: getAllRegistrars,
                    Bursar: getAllBursars,
                    Provost: getAllProvosts,
                    Directors: getAllDirectors,
                    Deans: getAllDeans,
                    'Sub Deans': getAllSubDeans,
                    'Heads Of Departments': getAllHODs,
                }[appointmentName] || getAllNew;

            dispatch(dispatchFunction(appointmentName));
        };

        getAppointments();
    }, [appointmentName, dispatch, refetchAppointments]);

    const handleAddStaff = () => {
        const categories = {
            'Vice Chancellor': 'Vice Chancellor',
            'Deputy Vice Chancellors': 'Deputy Vice Chancellors',
            Registrar: 'Registrar',
            Bursar: 'Bursar',
            Provost: 'Provost',
            Directors: 'Directors',
            Deans: 'Deans',
            'Sub Deans': 'Sub Dean',
            'Heads of Departments': 'HOD',
        };

        const category = categories[appointmentName];

        if (category) {
            handleOpenDropdown(category);
        } else {
            setShowNewCategory(true);
        }
    };

    const handleConfirm = async () => {
        const addFunctionMap = {
            'Vice Chancellor': addVc,
            'Deputy Vice Chancellors': addDeputyVc,
            Registrar: addRegistrar,
            Bursar: addBursar,
            Provost: addProvost,
            Directors: addDirector,
            Deans: addDean,
            'Sub Deans': addSubDean,
            'Heads of Departments': addHOD,
        };

        const addFunction = addFunctionMap[appointmentName] || addNew;
        const res = await dispatch(addFunction(details));
        if (res) {
            setShowConfirm(false);
            setRefetchAppointments((prev) => !prev);
        }
    };

    const handleRemove = async () => {
        if (!appointment) toast.error('Select an appointee you want to remove using the checkbox');
        if (appointee.name !== name) toast.error('Please input the correct appointee name');

        const removeFunctions = {
            'Vice Chancellor': removeVc,
            'Deputy Vice Chancellors': removeDVc,
            Registrar: removeRegistrar,
            Bursar: removeBursar,
            Provost: removeProvost,
            Directors: removeDirector,
            Deans: removeDean,
            'Sub Deans': removeSubdean,
            'Heads of Departments': removeHOD,
        };

        const removeFunction = removeFunctions[appointmentName] || removeNew;

        const res = await dispatch(removeFunction(appointment._id));
        if (res) {
            setOpenModal(null);
            setConfirmDelete(false);
            setRefetchAppointments((prev) => !prev);
        }
    };

    const handleEdit = () => {
        if (!appointment._id) toast.error('Please select an appointee you want to edit!');

        const EditMap = {
            'Vice Chancellor': 'Edit VC',
            'Deputy Vice Chancellors': 'Edit DVC',
            Registrar: 'Edit Registrar',
            Bursar: 'Edit Bursar',
            Provost: 'Edit Provost',
            Directors: 'Edit Director',
            Deans: 'Edit Dean',
            'Sub Deans': 'Edit Sub Dean',
            'Heads of Departments': 'Edit HOD',
        };

        const editTitle = EditMap[appointmentName];
        if (editTitle) {
            handleOpenDropdown(editTitle);
        } else {
            setEditNew(true);
        }
    };

    return (
        <>
            <PageLayout
                backTitle={`Appoinments / ${appointmentName}`}
                secondaryButtonText="Appoint Staff"
                onSecondaryButtonClick={handleAddStaff}
                isActionable
                actionType="radio"
                onSelectedItemsChange={(items) => setAppointment(items[0])}
                onActionBtnClick={() => {
                    setActions(true);
                }}
                actionClose={() => setActions(false)}
                actionOpen={actions}
                actionItems={[
                    {
                        icon: <EditIcon />,
                        name: 'Edit Appointment',
                        disabled: !appointment,
                        click: handleEdit,
                    },

                    {
                        icon: <DeleteIcon />,
                        name: 'Remove Appointee',
                        disabled: !appointment,
                        click: () => {
                            setShowDelete(true);
                        },
                    },
                ]}
                pageTitle={appointmentName}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={CurrentAppointment || []}
                fields={fields}
                loading={isLoading}
            />

            <ViewHistory show={openModal === 'view-history'} data={Appointment} close={() => setOpenModal(null)} />
            <AddAppointment
                show={openModal === 'Vice Chancellor'}
                setShow={() => setOpenModal(null)}
                btnAction={() => {
                    setOpenModal(null);
                    setShowConfirm(true);
                }}
                setDetails={setDetails}
            />
            <AddDVC
                show={openModal === 'Deputy Vice Chancellors'}
                setShow={() => setOpenModal(null)}
                btnAction={() => {
                    setOpenModal(null);
                    setShowConfirm(true);
                }}
                setDvcDetails={setDetails}
            />
            <AddRegistrar
                show={openModal === 'Registrar'}
                setShow={() => setOpenModal(null)}
                btnAction={() => {
                    setOpenModal(null);
                    setShowConfirm(true);
                }}
                setDetails={setDetails}
            />
            <AddBursar
                show={openModal === 'Bursar'}
                setShow={() => setOpenModal(null)}
                btnAction={() => {
                    setOpenModal(null);
                    setShowConfirm(true);
                }}
                setDetails={setDetails}
            />
            <AddProvost
                show={openModal === 'Provost'}
                setShow={() => setOpenModal(null)}
                btnAction={() => {
                    setOpenModal(null);
                    setShowConfirm(true);
                }}
                setDetails={setDetails}
            />
            <AddDirectors
                show={openModal === 'Directors'}
                setShow={() => setOpenModal(null)}
                btnAction={() => {
                    setOpenModal(null);
                    setShowConfirm(true);
                }}
                setDetails={setDetails}
            />
            <AddDean
                show={openModal === 'Deans'}
                setShow={() => setOpenModal(null)}
                btnAction={() => {
                    setOpenModal(null);
                    setShowConfirm(true);
                }}
                setDetails={setDetails}
            />
            <AddSubDean
                show={openModal === 'Sub Dean'}
                setShow={() => setOpenModal(null)}
                btnAction={() => {
                    setOpenModal(null);
                    setShowConfirm(true);
                }}
                setDetails={setDetails}
            />
            <AddHODs
                show={openModal === 'HOD'}
                setShow={() => setOpenModal(null)}
                btnAction={() => {
                    setOpenModal(null);
                    setShowConfirm(true);
                }}
                setDetails={setDetails}
            />
            <NewCategory
                show={showNewCategory}
                setShow={setShowNewCategory}
                btnAction={() => {
                    setShowNewCategory(false);
                    setShowConfirm(true);
                }}
                setDetails={setDetails}
                appointment={appointmentName}
            />
            <EditVc
                show={openModal === 'Edit VC'}
                setShow={() => setOpenModal(null)}
                id={appointment?._id}
                setDetails={setDetails}
            />
            <EditDVC
                show={openModal === 'Edit DVC'}
                setShow={() => setOpenModal(null)}
                id={appointment?._id}
                setDetails={setDetails}
            />
            <EditRegistrar
                show={openModal === 'Edit Registrar'}
                setShow={() => setOpenModal(null)}
                id={appointment?._id}
                setDetails={setDetails}
            />
            <EditBursar
                show={openModal === 'Edit Bursar'}
                setShow={() => setOpenModal(null)}
                id={appointment?._id}
                setDetails={setDetails}
            />
            <EditDean
                show={openModal === 'Edit Dean'}
                setShow={() => setOpenModal(null)}
                id={appointment?._id}
                setDetails={setDetails}
            />
            <EditDirectors
                show={openModal === 'Edit Director'}
                setShow={() => setOpenModal(null)}
                id={appointment?._id}
                setDetails={setDetails}
            />
            <EditHod
                show={openModal === 'Edit HOD'}
                setShow={() => setOpenModal(null)}
                id={appointment?._id}
                setDetails={setDetails}
            />
            <EditNewCategory
                show={editNew}
                setShow={() => setEditNew(false)}
                id={appointment?._id}
                setDetails={setDetails}
            />
            <EditProvost
                show={openModal === 'Edit Provost'}
                setShow={() => setOpenModal(null)}
                id={appointment?._id}
                setDetails={setDetails}
            />
            <EditSubDean
                show={openModal === 'Edit Sub Dean'}
                setShow={() => setOpenModal(null)}
                id={appointment?._id}
                setDetails={setDetails}
            />
            <ConfirmAddAdministrator
                show={showConfirm}
                close={() => {
                    setShowConfirm(false);
                }}
                role={appointmentName}
                name={details.name}
                confirmAction={handleConfirm}
                btnText="Appoint Staff"
                altLoading={isLoading}
            />
            <DeleteError
                show={showDelete}
                title="Error Deleting Role"
                subtitle="The appointment is currently occupied, kindly detach the staff from the appointment"
                btnText="Close"
                btnAction={() => {
                    setConfirmDelete(true);
                    setShowDelete(false);
                }}
                onClose={() => setShowDelete(false)}
            />
            <DeleteCourse
                show={confirmDelete}
                close={() => setConfirmDelete(false)}
                title="Occupied Appointment"
                subtitle="The selected appoinment is occupied"
                subtitle2="To confirm, enter the name of the appointee in the text field."
                btnText="Yes, Remove"
                onDelete={handleRemove}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Inputt name"
            />
        </>
    );
};

export default AppointmentDetails;
