/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

import { Table } from '@belrald_hq/belrald-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as CashIcon } from '../../../../../assets/icons/cash.svg';
import { ReactComponent as FlutterwaveIcon } from '../../../../../assets/icons/Flutterwave.svg';
import { ReactComponent as MonnifyIcon } from '../../../../../assets/icons/monify.svg';
import { ReactComponent as PaystackIcon } from '../../../../../assets/icons/paystack.svg';
import { ReactComponent as WalletIcon } from '../../../../../assets/icons/wallet.svg';
import { Button } from '../../../../../components/buttons';
import { GoBack } from '../../../../../components/go-back';
import { Loader } from '../../../../../components/loader';
import { Text } from '../../../../../containers/MesssageContainers';
import { FlexColumn, FlexDiv, FlexRowSpaceBetween } from '../../../../../containers/ScreenContainers';
import { getPaymentGatewayById } from '../../../../../redux/bursary/actions';
import currencyFormatter from '../../../../../utils/formatCurrency';

import AccountDetails from './account-details';
import AttachedFees from './attached-fees';

const GatewayDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { isLoading, paymentGateway } = useSelector((store) => store.bursary);
    const { provider, businessName } = paymentGateway || {};

    useEffect(() => {
        if (!id) return;
        dispatch(getPaymentGatewayById(id));
    }, [dispatch, id]);

    if (isLoading) return <Loader />;
    return (
        <div>
            <GoBack title="Payment Gateway" subTitle={businessName} />
            <div className="">
                <FlexDiv className="justify-between items-start">
                    <FlexColumn className="gap-[1rem]">
                        <Text align="left" weight="600" size="32px" lineHeight="38.73px">
                            {businessName}
                        </Text>
                        {provider?.toLowerCase() === 'paystack' ? (
                            <PaystackIcon />
                        ) : provider?.toLowerCase() === 'monnify' ? (
                            <MonnifyIcon />
                        ) : provider?.toLowerCase() === 'flutterwave' ? (
                            <FlutterwaveIcon />
                        ) : null}
                    </FlexColumn>
                    <Button>
                        <WalletIcon />
                        Fund Wallet
                    </Button>
                </FlexDiv>
                <div className="grid grid-cols-2 gap-[2.4rem] pt-[4rem]">
                    <AccountDetails />
                    <AttachedFees />
                </div>
            </div>
        </div>
    );
};

export default GatewayDetails;
