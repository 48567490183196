import React from 'react';

import PaymentGatewayForm from '../../../../components/forms/payment-gateway-form';
import { GoBack } from '../../../../components/go-back';

const EditPaymentGateway = () => {
    return (
        <div>
            <GoBack title="Payment Gateway" subTitle="Edit Gateway" />
            <PaymentGatewayForm action="edit" />
        </div>
    );
};

export default EditPaymentGateway;
