import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    staffName: Yup.string().nullable().required('Required'),
    organizationUnit: Yup.string().nullable().required('Required'),
    academicUnit: Yup.string().when('organizationUnit', {
        is: 'Academics',
        then: Yup.string().nullable().required('Required'),
        otherwise: Yup.string().nullable(),
    }),
    firstDegree: Yup.string().when('organizationUnit', {
        is: 'Academics',
        then: Yup.string().nullable().required('Required'),
        otherwise: Yup.string().nullable(),
    }),
    secondDegree: Yup.string().when(['isAddToSecondDegree', 'staffType'], {
        is: (isAddToSecondDegree, staffType) => isAddToSecondDegree || staffType === 'Teaching',
        then: Yup.string().nullable().required('Required'),
        otherwise: Yup.string().nullable(),
    }),
    // thirdDegree: Yup.string().when(['isAddToSecondDegree', 'staffType', 'hasThirdDegree'], {
    //     is: (isAddToSecondDegree, staffType, hasThirdDegree) =>
    //         (isAddToSecondDegree || staffType === 'Teaching') && hasThirdDegree,
    //     then: Yup.string().nullable().required('Required'),
    //     otherwise: Yup.string().nullable(),
    // }),
    staffType: Yup.string().nullable().required('Required'),
    designation: Yup.string().nullable().required('Required'),
    isAddToSecondDegree: Yup.boolean(),
});
