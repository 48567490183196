import toast from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery, transformThirdDegreeData } from '../../utils';
import { GET_FACULTY_DEPARTMENTS } from '../faculty/slice';
import { GET_DEPARTMENT_PROGRAMMES } from '../programmes/slice';

import { LOADING, STOP_LOADING } from './slice';

export const createSchoolSecondLevel =
    (payload, schoolId, structureType, useLoader = true) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const response = await axiosInstance.post(
                `${currentAPI}structure/:campusId/:unitId/school/create_second_level/${schoolId}?type=${structureType}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getSchoolSecondLevel =
    ({ showLoader = true, academicStructureId, firstDegreeId, structTwo }, query) =>
    async (dispatch, getState) => {
        showLoader && dispatch(LOADING());
        try {
            const queries = encodeQuery({ ...query });

            const response = await axiosInstance.get(
                `${currentAPI}structure/:campusId/:unitId/get_second_degree_school/${academicStructureId}/${firstDegreeId}?${queries}`,
            );
            if (response.status === 200) {
                const { data } = response.data;
                const transformedData = data.map((item) => {
                    let newItem;

                    const structMapping = {
                        department: ['department', 'departmentCode', 'departmentNumberingCode'],
                        program: ['subprogram', 'subProgramCode', 'subProgramNumberingCode'],
                        school: ['schoolName', 'schoolCode', 'schoolNumberingCode'],
                        faculty: ['facultyName', 'facultyCode', 'facultyNumberingCode'],
                        college: ['collegeName', 'collegeCode', 'collegeNumberingCode'],
                    };

                    const fields = structMapping[structTwo?.toLowerCase()];

                    if (fields) {
                        const {
                            [fields[0]]: departmentName,
                            [fields[1]]: departmentCode,
                            [fields[2]]: departmentNumberingCode,
                            ...otherFields
                        } = item;

                        newItem = {
                            departmentName,
                            departmentCode,
                            departmentNumberingCode,
                            ...otherFields,
                        };
                    }

                    return newItem;
                });

                dispatch(GET_FACULTY_DEPARTMENTS(transformedData));
                return transformedData;
            }
        } catch (error) {
            dispatch(GET_FACULTY_DEPARTMENTS([]));
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const createSchoolThirdLevel =
    (payload, useLoader = true) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const {
                department: {
                    currentDepartment: { _id: departmentId },
                },
                faculty: {
                    currentFaculty: { _id: schoolId, academicStructure },
                },
            } = getState();

            const response = await axiosInstance.post(
                `${currentAPI}structure/:campusId/:unitId/school/create_third_degree/${schoolId}/${departmentId}?type=${academicStructure}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getSchoolThirdLevel = (academicStructureId, schoolId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}structure/:campusId/:unitId/get_third_degree_school/${academicStructureId}/${schoolId}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};

            const transformedData = transformThirdDegreeData(data);

            dispatch(GET_DEPARTMENT_PROGRAMMES(transformedData));
        }
    } catch (error) {
        dispatch(GET_DEPARTMENT_PROGRAMMES([]));
        const errorMessage = error?.response?.data?.message || 'An errorr occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
