import React, { Fragment, useEffect, useState } from 'react';

import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

// import { ReactComponent as AwardCup } from '../../../assets/icons/award-cup.svg';
import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import { ReactComponent as People } from '../../../assets/icons/people2.svg';
import { ReactComponent as Send } from '../../../assets/icons/send.svg';
import { Button } from '../../../components/buttons';
import { GoBack } from '../../../components/go-back';
import { Loader } from '../../../components/loader';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import Result from '../../../components/popups/result';
import SuccessPopup from '../../../components/popups/success';
import { StatusContainer, StatusText, getStatusStyles } from '../../../components/shared';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween, FlexDiv, CenteredContainer } from '../../../containers/ScreenContainers';
import { sendSingleAdmissionOffer } from '../../../redux/admissions/actions';
import { getStudentDetails } from '../../../redux/students/actions';

// import DropdownColorChanger from './dropdownColorChanger';

const MainContainer = tw.div`
    grid
    grid-cols-1
    md:grid-cols-2
    lg:grid-cols-3
    grid-flow-row
    gap-6
    mt-8
`;

const BasicContent = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-3
    mt-8
`;

const RowContainer = tw.div`
    grid
    grid-rows-3
    gap-8
`;

const FlexCol = tw.div`
    gap-6
`;

const ColContainer = tw.div`
    mt-[3.2rem]
    pl-[2.4rem]
    pr-[1.6rem]
    mb-[2.9rem]
    rounded-lg
`;

const Details = tw.div`
    lg:col-span-2
    col-span-1
    bg-white
    p-6
    rounded-lg
`;

const SmallCard = tw.div`
    bg-white
    rounded-lg
    p-6
    lg:mb-[7rem]
`;

const SideCard = tw.div`
    lg:col-start-3
    row-span-2
    lg:row-start-1
    row-start-2
    col-start-1
    bg-white
    rounded-lg
    p-6
`;

const TitleText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
`;

const SubText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
    line-height: 2.4rem;
    text-align: ${({ align }) => align || 'left'};
`;

const MainText = styled(Text)`
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 1.6rem;
    color: #6b7280;
    text-align: left;
`;

const ResultCardSubText = styled(MainText)`
    color: #1f2937;
    text-align: right;
`;

const AdmissionCandidateDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const altLoading = useSelector((state) => state.admission.isLoading);
    const { studentDetails, isLoading } = useSelector((store) => store.student);

    const { academicData, basicData, bioData, uploads } = studentDetails || {};
    const { resultInfo, utmeInfo } = academicData || {};

    const { academicStructure } = basicData || {};

    const splitStruct = academicStructure?.split('-');
    const secondDegree = basicData?.[splitStruct?.[1]];

    const [viewMore, setViewMore] = useState(false);
    const [openModal, setOpenModal] = useState('');

    // Function to handle "View More" button click
    const handleViewMore = (info) => {
        setViewMore(true);
    };

    const totalScore = utmeInfo?.[0]?.utmeResults?.reduce((sum, obj) => sum + parseInt(obj.score), 0);

    const averageScore = totalScore;

    const isSendOfferDisabled =
        basicData?.status?.toLowerCase() === 'accepted' || basicData?.status?.toLowerCase() === 'sent';

    useEffect(() => {
        dispatch(getStudentDetails(id));
    }, [dispatch, id]);

    const handleClickSendOffer = () => {
        if (basicData?.status?.toLowerCase() === 'accepted') return;
        setOpenModal('send-offer');
    };

    const handleSendOffer = async () => {
        const res = await dispatch(sendSingleAdmissionOffer(id));
        if (res) {
            dispatch(getStudentDetails(id));
            setOpenModal('success');
        }
    };

    const leftRow = [
        {
            title: 'Full Name',
            subtitle: `${basicData?.lastName} ${basicData?.firstName} ${basicData?.middleName || ''}`,
        },
        {
            title: 'Date of Birth',
            subtitle: bioData?.updateInfo?.dateOfBirth?.slice(0, 10),
        },
        {
            title: 'Email Address',
            subtitle: basicData?.studentEmail,
        },
    ];
    const rightRow = [
        {
            title: 'Gender',
            subtitle: bioData?.updateInfo?.gender,
        },
        {
            title: 'Registration',
            subtitle: basicData?.utmeRegNumber,
        },
        {
            title: 'Admission Mode',
            subtitle: basicData?.admissionType,
        },
    ];
    const otherLeft = [
        {
            title: 'Student Email',
            subtitle: basicData?.studentEmail,
        },
        {
            title: 'Nationality',
            subtitle: bioData?.updateInfo?.nationality,
        },
        {
            title: 'Local Government',
            subtitle: bioData?.updateInfo?.localGovernment,
        },
    ];
    const otherCenter = [
        {
            title: 'Course',
            subtitle: secondDegree || '',
        },
        {
            title: 'State of Origin',
            subtitle: bioData?.updateInfo?.stateOfOrigin,
        },
        {
            title: 'Residential Address',
            subtitle: bioData?.updateInfo?.permanentAddress,
        },
    ];
    const otherRight = [
        {
            title: 'Admission Date',
            subtitle: basicData?.admissionYear?.split(' ')?.[0],
        },
        {
            title: 'Home Town',
            subtitle: bioData?.updateInfo?.homeTown,
        },
        {
            title: 'Phone Number',
            subtitle: bioData?.updateInfo?.phoneNumber,
        },
    ];

    if (isLoading || altLoading) return <Loader />;

    return (
        <div>
            <FlexRowSpaceBetween className="sm:flex-col sm:justify-center">
                <GoBack
                    title="Admissions"
                    subTitle={`${basicData?.lastName} ${basicData?.firstName} ${basicData?.middleName || ''}`}
                />
            </FlexRowSpaceBetween>

            <FlexDiv className="gap-x-3">
                <PageTitle size="3.3rem" lineheight="4rem" top="2.6rem">
                    {`${basicData?.lastName} ${basicData?.firstName} ${basicData?.middleName || ''}`}
                </PageTitle>
            </FlexDiv>

            <FlexCentredRow className="gap-x-14  mt-[1.6rem]">
                <Link to={`/admissions/sessions/candidate/edit/${basicData?._id}`} state={studentDetails}>
                    <FlexCentredRow className="gap-x-[9.98px] cursor-pointer">
                        <Edit fill="#6366F1" />
                        <Text weight="500" lineHeight="2.4rem">
                            Edit
                        </Text>
                    </FlexCentredRow>
                </Link>

                <FlexCentredRow
                    className={`gap-x-[9.98px] ${
                        isSendOfferDisabled ? 'cursor-not-allowed opacity-20' : 'cursor-pointer'
                    }`}
                    onClick={!isSendOfferDisabled ? handleClickSendOffer : undefined}
                >
                    <Send />
                    <Text weight="500" lineHeight="2.4rem">
                        Send Offer
                    </Text>
                </FlexCentredRow>
            </FlexCentredRow>

            <MainContainer>
                <Details className="mt-[3rem]">
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Basic Details
                            </PageTitle>
                        </FlexCentredRow>

                        <StatusContainer className="ml-3" style={getStatusStyles(basicData?.status?.toLowerCase())}>
                            <StatusText>{basicData?.status?.toLowerCase()}</StatusText>
                        </StatusContainer>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        {uploads?.passport?.[0]?.url ? (
                            <img alt="profile" src={uploads?.passport?.[0]?.url} className="h-[18rem] w-[15rem]" />
                        ) : (
                            <Avatar
                                name={`${basicData?.lastName} ${basicData?.firstName} ${basicData?.middleName || ''}`}
                                size="150"
                                textSizeRatio={1.75}
                                className="rounded-full"
                            />
                        )}
                        <RowContainer className="lg:text-left text-center">
                            {leftRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <TitleText>{item.title}</TitleText>
                                    <SubText>{item.subtitle}</SubText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="lg:text-right text-center">
                            {rightRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <TitleText align="right">{item.title}</TitleText>
                                    <SubText align="right">{item.subtitle}</SubText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>

                <Details>
                    <FlexCentredRow>
                        <People fill="#3B82F6" className="mr-[1.719rem]" />
                        <PageTitle size="1.9rem" lineheight="2.8rem">
                            Other Details
                        </PageTitle>
                    </FlexCentredRow>

                    <BasicContent>
                        <RowContainer className="lg:text-left text-center">
                            {otherLeft?.map((item, index) => (
                                <FlexCol key={index}>
                                    <TitleText>{item.title}</TitleText>
                                    <SubText>{item.subtitle}</SubText>
                                </FlexCol>
                            ))}
                        </RowContainer>

                        <RowContainer className="lg:text-left text-center">
                            {otherCenter?.map((item, index) => (
                                <FlexCol key={index}>
                                    <TitleText>{item.title}</TitleText>
                                    <SubText>{item.subtitle}</SubText>
                                </FlexCol>
                            ))}
                        </RowContainer>

                        <RowContainer className="lg:text-right text-center">
                            {otherRight?.map((item, index) => (
                                <FlexCol>
                                    <TitleText align="right">{item.title}</TitleText>
                                    <SubText align="right">{item.subtitle}</SubText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>

                <SideCard className="lg:mt-[3rem]">
                    <Text size="1.9rem" align="left" bottom="2.4rem" weight="600">
                        O'level Results ({resultInfo?.length})
                    </Text>
                    {resultInfo?.map((info, index) => (
                        <Fragment key={index}>
                            {info?.examType && (
                                <>
                                    <PageTitle align="left" bottom="1.6rem">
                                        {`${info?.examYear?.slice(0, 4)} ${info?.examType}`}
                                    </PageTitle>
                                    <div className="bg-[#F3F4F6] pl-[2.4rem] pr-[1.6rem] mb-[4rem]">
                                        {info?.results?.slice(0, 4)?.map((item) => (
                                            <FlexRowSpaceBetween key={item._id}>
                                                <MainText top="1rem">{item.subject}</MainText>

                                                <ResultCardSubText top="1rem">{item.grade}</ResultCardSubText>
                                            </FlexRowSpaceBetween>
                                        ))}

                                        <CenteredContainer>
                                            <Button
                                                onClick={() => handleViewMore(info)}
                                                bgColor="#fff"
                                                bottom="1.6rem"
                                                color="#1F2937"
                                            >
                                                View More
                                            </Button>
                                        </CenteredContainer>
                                    </div>
                                </>
                            )}
                        </Fragment>
                    ))}
                </SideCard>

                <SmallCard>
                    <Text size="1.9rem" align="left" bottom="1.6rem">
                        Utme Result
                    </Text>
                    <FlexRowSpaceBetween className="mb-[1.6rem]">
                        <PageTitle size="5.7rem" lineHeight="6.9rem" weight="500">
                            {averageScore || ''}
                        </PageTitle>
                    </FlexRowSpaceBetween>
                    {utmeInfo?.map((utme) => (
                        <ColContainer className="bg-[#F3F4F6]">
                            {utme?.utmeResults?.map((itm) => (
                                <FlexRowSpaceBetween key={itm._id}>
                                    <MainText top="1rem">{itm.subject}</MainText>
                                    <ResultCardSubText top="1rem">{itm.score}</ResultCardSubText>
                                </FlexRowSpaceBetween>
                            ))}
                        </ColContainer>
                    ))}
                </SmallCard>
            </MainContainer>

            <Result show={viewMore} title={` O'level Results ${resultInfo?.length}`} close={() => setViewMore(false)}>
                <div className="flex flex-wrap gap-[2rem]">
                    {resultInfo?.map((info) => (
                        <>
                            <PageTitle align="left" top="1.6rem">
                                {`${info?.examYear?.slice(0, 4)} ${info?.examType}`}
                            </PageTitle>
                            <div key={info._id} className="bg-[#F3F4F6] p-[2.4rem] rounded-md w-full md:w-[45%]">
                                <div>
                                    {info?.results.map((item) => (
                                        <FlexRowSpaceBetween key={item._id}>
                                            <MainText top="1rem">{item.subject}</MainText>

                                            <ResultCardSubText top="1rem">{item.grade}</ResultCardSubText>
                                        </FlexRowSpaceBetween>
                                    ))}
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </Result>
            <ConfirmActionDialogue
                show={openModal === 'send-offer'}
                title="Send Offer"
                subtitle="Are you sure you want to send an offer to this applicant?"
                close={() => setOpenModal('')}
                confirmAction={() => handleSendOffer()}
            />
            <SuccessPopup
                show={openModal === 'success'}
                onClose={() => setOpenModal('')}
                title="Awesome!"
                subtitle="Admission offer has been sent successfully"
                btnText="Done"
            />
        </div>
    );
};

export default AdmissionCandidateDetails;
