import React, { useEffect, useRef, useState } from 'react';

import { SelectInput, TextInput } from '@belrald_hq/belrald-ui';
import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../assets/icons/arrow-down-white.svg';
import { ReactComponent as AttachmentIcon } from '../../../../assets/icons/attachment.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { ReactComponent as PenIcon } from '../../../../assets/icons/pen.svg';
import { ReactComponent as PeopleIcon } from '../../../../assets/icons/peopleIcon.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/upload5.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import Editor from '../../../../components/editor';
import { CancelButton, SubmitButton } from '../../../../components/forms/sharedStyles';
import PDFPreview from '../../../../components/pdf-preview';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { getAdmissionSessions } from '../../../../redux/admissions/actions';
import { getCommunicationsSettings, sendBulletin } from '../../../../redux/communications/actions';
import { getDistributionListByUnitname } from '../../../../redux/distribution-list/actions';
import { getStaffInUnit } from '../../../../redux/staff/actions';

const UploadContainer = tw.div`
flex
flex-row
border
border-dashed
border-[#D1D5DB]
rounded-[8px]
items-center
px-[.5rem]
py-[.5rem]
gap-[1rem]
`;

const ComposeForm = () => {
    const dispatch = useDispatch();
    const hiddenFileInput = useRef(null);
    const navigate = useNavigate();

    const { allDistributionList } = useSelector((store) => store.distributionList);
    const { sessions } = useSelector((store) => store.admission);
    const { unitStaffs } = useSelector((store) => store.staff);
    const { isLoading: createLoading } = useSelector((store) => store.communications);
    const { currentUnit } = useSelector((store) => store.organisationUnits);

    const [selectedDistributionList, setSelectedDistributionList] = useState([]);
    const [showForm, setShowForm] = useState('1');
    const [editorValue, setEditorValue] = useState('');
    const [commsSettings, setCommsSettings] = useState(null);
    const [selectedCommsType, setSelectedCommsType] = useState('');
    const [attachment, setAttachment] = useState(null);

    const modifiedStaffs = unitStaffs?.map((staff) => {
        return {
            name: `${staff?.surname} ${staff?.firstName} ${staff?.otherName}`,
            ...staff,
        };
    });

    useEffect(() => {
        dispatch(getDistributionListByUnitname());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getStaffInUnit());
    }, [dispatch]);



    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const addAttachment = async (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf'];

        if (file && allowedTypes.includes(file.type)) {
            setAttachment(file);
        } else {
            toast.error(`Upload a PDF file`);
        }
    };

    useEffect(() => {
        const getCommsSettings = async () => {
            const res = await dispatch(getCommunicationsSettings());
            setCommsSettings(res);
        };

        getCommsSettings();
    }, [dispatch]);

    const handleEditorChange = (content, delta, source, editor) => {
        setEditorValue(editor.getHTML());
    };

    const handleSubmit = async (data) => {
        const formData = new FormData();
        const { session, title, distributionList, format, restrictedPublishersOrReviewer } = data || {};
        const headers = {
            'Content-Type': 'multi-part/form-data',
        };

        formData.append('type', selectedCommsType);
        formData.append('session', session);
        formData.append('title', title);
        distributionList.map((item, i) => formData.append(`distributionListIds[]`, item));
        formData.append('format', format);
        formData.append('content', editorValue);
        restrictedPublishersOrReviewer?.map((item, i) => formData.append(`restrictedPublishersOrReviewer[]`, item));
        formData.append('attachment', attachment);

        const res = await dispatch(sendBulletin({ formData, headers }));
        if (res) {
            navigate(-1);
        }
    };

    return (
        <div>
            <PageLayout backTitle="Communications / Compose" pageTitle="Compose" />
            <Formik
                initialValues={{
                    session: '',
                    type: '',
                    title: '',
                    distributionList: '',
                    format: '',
                    content: '',
                    restrictedPublishersOrReviewer: [],
                    attachment: '',
                }}
                onSubmit={(values) => {
                    setShowForm('2');
                    console.log(values);
                }}
            >
                {({ errors, values, handleChange, setFieldValue }) => (
                    <Form>
                        <div className="">
                            {showForm === '1' ? (
                                <div className="flex flex-col gap-[1rem]">
                                    <div className="px-[3.2rem] pt-[3.6rem] pb-[1.5rem] rounded-[1.6rem] bg-white">
                                        <div className="flex items-center gap-[1rem] font-semibold mb-[3.2rem]">
                                            <PenIcon />
                                            <Text align="left" weight="600" size="1.6rem" color="#1F2937">
                                                Compose
                                            </Text>
                                        </div>
                                        <div className="grid grid-cols-2 gap-x-[3.2rem] gap-y-[2.4rem]">
                                            <SelectInput
                                                name="session"
                                                label="Session"
                                                placeholder="Select Session"
                                                objProp="admissionYear"
                                                data={sessions}
                                                onChange={(selected) => {
                                                    setFieldValue('session', selected?.[0]?.admissionYear);
                                                }}
                                                value={values?.session}
                                            />
                                            <TextInput
                                                name="title"
                                                label="Title"
                                                placeholder="Input Title"
                                                onChange={handleChange}
                                                value={values?.title}
                                            />
                                            <SelectInput
                                                name="type"
                                                label="Type"
                                                placeholder="Select Type"
                                                objProp="name"
                                                data={[
                                                    { name: 'Internal memo', value: 'internalMemo' },
                                                    ...(currentUnit?.title?.toLowerCase() === 'registry'
                                                        ? [{ name: 'Bulletin', value: 'bulletin' }]
                                                        : []),
                                                    { name: 'Notice', value: 'notice' },
                                                    { name: 'Mail', value: 'mail' },
                                                ]}
                                                onChange={(selected) => {
                                                    setFieldValue('type', selected?.[0]?.value);
                                                    setSelectedCommsType(selected?.[0]?.name);
                                                }}
                                                passedSelectedItems={values?.type}
                                            />
                                            <div className="">
                                                <SelectInput
                                                    name="distributionList"
                                                    label="Distribution List"
                                                    placeholder="Select Distribution List"
                                                    objProp="distributionName"
                                                    multiSelect
                                                    data={allDistributionList}
                                                    onChange={(selected) => {
                                                        setFieldValue(
                                                            'distributionList',
                                                            selected?.map((item) => item?._id),
                                                        );
                                                        setSelectedDistributionList(selected);
                                                    }}
                                                    passedSelectedItems={values?.distributionList}
                                                    showValues={false}
                                                />
                                                {selectedDistributionList?.length > 0 && (
                                                    <div className="flex gap-[8px] flexWrap py-[8px]">
                                                        {selectedDistributionList?.map((item) => (
                                                            <div className="py-[2px] px-[12px] bg-[#E1EFFE] rounded-[6px] flex items-center w-max gap-[4px]">
                                                                <Text color="#1E429F" weight="500" size="1.4rem">
                                                                    {item?.distributionName}
                                                                </Text>
                                                                <Close
                                                                    color="#3F83F8"
                                                                    width={8.1}
                                                                    height={8.1}
                                                                    onClick={() => {
                                                                        const filteredDistributions =
                                                                            selectedDistributionList?.filter(
                                                                                (dl) => dl?._id !== item?._id,
                                                                            );
                                                                        setFieldValue(
                                                                            'distributionList',
                                                                            filteredDistributions?.map(
                                                                                (item) => item?._id,
                                                                            ),
                                                                        );
                                                                        setSelectedDistributionList(
                                                                            filteredDistributions,
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            <SelectInput
                                                name="format"
                                                label="Format"
                                                placeholder="Select Format"
                                                objProp="name"
                                                onChange={(selected) => {
                                                    setFieldValue('format', selected?.[0]?.name);
                                                }}
                                                data={[{ name: 'Upload' }, { name: 'Text' }]}
                                                passedSelectedItems={values?.format}
                                            />
                                            {values?.format?.toLowerCase() === 'upload' && (
                                                <div className="">
                                                    <Text align="left" size="1.4rem" color="#1F2937" weight="500">
                                                        Upload
                                                    </Text>
                                                    <UploadContainer>
                                                        <Button
                                                            type="button"
                                                            onClick={handleClick}
                                                            className="border border-[#E5E7EB] shadow-[0_1px_2px_rgba(6,25,56,0.05)] "
                                                        >
                                                            <UploadIcon /> Upload Files
                                                        </Button>
                                                        <input
                                                            type="file"
                                                            className="hidden"
                                                            ref={hiddenFileInput}
                                                            id="fileInput"
                                                            accept=".pdf"
                                                            onChange={addAttachment}
                                                        />
                                                        <Text size="1.4rem" weight="500" color="#6B7280">
                                                            {attachment ? attachment?.name : 'Click here to Upload'}
                                                        </Text>
                                                    </UploadContainer>
                                                </div>
                                            )}
                                        </div>
                                        {values?.format?.toLowerCase() === 'upload' && (
                                            <div className="flex items-center gap-[8px] justify-end mt-[1rem] px-[3.2rem] pb-[1.5rem]">
                                                <CancelButton>Cancel</CancelButton>
                                                <SubmitButton>Preview</SubmitButton>
                                            </div>
                                        )}
                                    </div>
                                    {values?.format?.toLowerCase() === 'text' && (
                                        <div className="bg-white rounded-[8px] flex flex-col gap-[2.4rem] p-[2.4rem]">
                                            <FlexRowSpaceBetween>
                                                <FlexCentredRow className="gap-[1.015rem]">
                                                    <PageTitle>{selectedCommsType}</PageTitle>
                                                    <Dropdown />
                                                </FlexCentredRow>
                                                <FlexCentredRow className=" cursor-pointer gap-[6.315rem]">
                                                    <Close onClick={() => setFieldValue('format', '')} />
                                                </FlexCentredRow>
                                            </FlexRowSpaceBetween>
                                            {/* <div className="h-[10rem]"> */}
                                            <Editor
                                                className=""
                                                value={editorValue}
                                                handleChange={handleEditorChange}
                                            />
                                            {/* </div> */}
                                            <FlexRowSpaceBetween>
                                                <input
                                                    type="file"
                                                    className="hidden"
                                                    ref={hiddenFileInput}
                                                    id="fileInput"
                                                    accept=".pdf"
                                                    onChange={addAttachment}
                                                />
                                                <FlexCentredRow className="gap-[1rem]">
                                                    <AttachmentIcon onClick={handleClick} />
                                                    <Text size="1.4rem" weight="500" color="#6B7280">
                                                        {values?.format?.toLowerCase() === 'text' && attachment
                                                            ? attachment?.name
                                                            : ''}
                                                    </Text>
                                                </FlexCentredRow>

                                                <div className="flex items-center gap-[8px] flex-end justify-end mt-[3rem] px-[3.2rem] pb-[1.5rem]">
                                                    <CancelButton onClick={() => navigate(-1)}>Cancel</CancelButton>
                                                    <SubmitButton>Preview</SubmitButton>
                                                </div>
                                            </FlexRowSpaceBetween>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="flex flex-col gap-[8px]">
                                    <div className="bg-white p-[2.4rem] rounded-[0.8rem] flex flex-col gap-[2.8rem]">
                                        <Text align="left" weight="600" size="1.9rem" color="#1F2937">
                                            Overview
                                        </Text>
                                        <div className="grid grid-cols-4 gap-y-[2.8rem]">
                                            <div className="flex flex-col gap-[4px]">
                                                <Text align="left" size="1.2rem" weight="500" color="#9CA3AF">
                                                    Session
                                                </Text>
                                                <Text align="left" size="1.4rem" weight="500" color="#4B5563">
                                                    {values?.session}
                                                </Text>
                                            </div>

                                            <div className="flex flex-col gap-[4px]">
                                                <Text align="left" size="1.2rem" weight="500" color="#9CA3AF">
                                                    Type
                                                </Text>
                                                <Text align="left" size="1.4rem" weight="500" color="#4B5563">
                                                    {values?.type}
                                                </Text>
                                            </div>
                                            <div className="flex flex-col gap-[4px]">
                                                <Text align="left" size="1.2rem" weight="500" color="#9CA3AF">
                                                    Title
                                                </Text>
                                                <Text align="left" size="1.4rem" weight="500" color="#4B5563">
                                                    {values?.title}
                                                </Text>
                                            </div>
                                            <div className="flex flex-col gap-[4px]">
                                                <Text align="left" size="1.2rem" weight="500" color="#9CA3AF">
                                                    Format
                                                </Text>
                                                <Text align="left" size="1.4rem" weight="500" color="#4B5563">
                                                    {values?.format}
                                                </Text>
                                            </div>
                                            <div className="flex flex-col gap-[4px]">
                                                <Text align="left" size="1.2rem" weight="500" color="#9CA3AF">
                                                    Distribution List
                                                </Text>
                                                <div className="flex items-center gap-[1rem] flex-wrap">
                                                    {selectedDistributionList?.map((item) => (
                                                        <div className="py-[2px] px-[12px] bg-[#E1EFFE] rounded-[6px] w-max">
                                                            <Text color="#1E429F" weight="500" size="1.4rem">
                                                                {item?.distributionName}
                                                            </Text>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white rounded-[0.8rem] p-[2.4rem]">
                                        {values?.format?.toLowerCase() === 'text' && (
                                            <div
                                                className="text-[#1F2937] text-[1.4rem] font-normal leading-[2.4rem]"
                                                dangerouslySetInnerHTML={{ __html: editorValue }}
                                            />
                                        )}
                                        {values?.format?.toLowerCase() === 'upload' && (
                                            <PDFPreview url={URL.createObjectURL(attachment)} />
                                        )}
                                        {!commsSettings?.[values?.type]?.allowReview && (
                                            <FlexRowSpaceBetween>
                                                <input
                                                    type="file"
                                                    className="hidden"
                                                    ref={hiddenFileInput}
                                                    id="fileInput"
                                                    accept=".pdf"
                                                    onChange={addAttachment}
                                                />
                                                <FlexCentredRow className="gap-[1rem]">
                                                    <Text size="1.4rem" weight="500" color="#6B7280">
                                                        {values?.format?.toLowerCase() === 'text' && attachment
                                                            ? attachment?.name
                                                            : ''}
                                                    </Text>
                                                </FlexCentredRow>
                                                <div className="flex items-center gap-[8px] justify-end pt-[2rem] px-[3.2rem] pb-[1.5rem]">
                                                    <CancelButton onClick={() => setShowForm('1')}>Cancel</CancelButton>
                                                    <LoadingButton
                                                        type="button"
                                                        loading={createLoading}
                                                        bgColor="#6366F1"
                                                        color="#ffffff"
                                                        onClick={() => handleSubmit(values)}
                                                    >
                                                        Create {selectedCommsType}
                                                    </LoadingButton>
                                                </div>
                                            </FlexRowSpaceBetween>
                                        )}
                                    </div>
                                    {commsSettings?.[values?.type]?.allowReview === true && (
                                        <div className="px-[3.2rem] flex flex-col gap-[1.5rem] pt-[3.6rem] pb-[1.5rem] rounded-[1.6rem] bg-white">
                                            <div className="flex items-center gap-[8px]">
                                                <PeopleIcon />
                                                <Text weight="600" color="#1F2937" size="1.6rem">
                                                    Add Reviewer(s)
                                                </Text>
                                            </div>
                                            <SelectInput
                                                name="restrictedPublishersOrReviewer"
                                                data={modifiedStaffs || []}
                                                multiSelect={
                                                    commsSettings?.[values?.type]?.minReviewersBeforePublishing > 1
                                                        ? true
                                                        : false
                                                }
                                                objProp="name"
                                                label="Name of Reviewer(s)"
                                                placeholder="Select Reviewer(s)"
                                                onChange={(selected) => {
                                                    setFieldValue(
                                                        'restrictedPublishersOrReviewer',
                                                        selected?.map((item) => item?._id),
                                                    );
                                                }}
                                            />
                                            <div className="flex items-center gap-[8px] justify-end mt-[1rem] px-[3.2rem] pb-[1.5rem]">
                                                <CancelButton onClick={() => setShowForm('1')}>Cancel</CancelButton>
                                                <LoadingButton
                                                    type="button"
                                                    loading={createLoading}
                                                    bgColor="#6366F1"
                                                    color="#ffffff"
                                                    onClick={() => handleSubmit(values)}
                                                >
                                                    Create {selectedCommsType}
                                                </LoadingButton>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ComposeForm;
