import React, { useEffect, useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../../assets/icons/arrow-back-blue.svg';
import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
import { PageTitle } from '../../../../containers/MesssageContainers';
import {
    FlexCentredRow,
    FlexColumn,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
} from '../../../../containers/ScreenContainers';
import { getCoursesInSecondDegree } from '../../../../redux/departments/actions';

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const CourseDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, currentDepartment, courses } = useSelector((state) => state.department);
    const { currentFaculty } = useSelector((state) => state.faculty);

    const { splitAcademicStructureTwo: structTwo, academicStructureId, _id: facultyId } = currentFaculty;
    const { _id: departmentId } = currentDepartment || {};

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const totalPages = Math.ceil(courses?.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);

    const fields = [
        {
            key: 'action',
            label: <Checkbox />,
        },
        {
            label: 'Name',
            key: 'name',
        },
        {
            label: 'Matric No',
            key: 'matricNumber',
        },
        {
            label: structTwo,
            key: 'department',
        },
        {
            label: 'Level',
            key: 'courseLevel',
        },
    ];

    useEffect(() => {
        if (departmentId) {
            dispatch(getCoursesInSecondDegree(academicStructureId, facultyId, departmentId));
        }
    }, [dispatch, departmentId, academicStructureId, facultyId]);

    return (
        <>
            <FlexCentredRow onClick={() => navigate('/faculties/departments')} className="hover:cursor-pointer">
                <BackArrow className="mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    {structTwo}
                </PageTitle>
            </FlexCentredRow>

            <FlexColumn className="mt-[3.2rem] mb-[3.2rem]">
                <PageTitle align="start">{currentDepartment?.departmentName}</PageTitle>
            </FlexColumn>

            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} />
                    </FlexCentredRow>
                </FilterContainer>
                <CDataTable
                    items={courses || []}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    // onRowClick={}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                        name: (item) => <td>{item.name}</td>,

                        // courseNname: (item) => <td>{item.courseNname}</td>,
                        // department: (item) => <td>{item.department}</td>,
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>
        </>
    );
};

export default CourseDetails;
