import React, { useState, useEffect, useMemo } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-blue.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/forward-arrow.svg';
import { ReactComponent as LineThroughPin } from '../../../assets/icons/line-through-pin.svg';
import { ReactComponent as OrderIcon } from '../../../assets/icons/order.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as RepeatIcon } from '../../../assets/icons/repeat.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send-black.svg';
import { ReactComponent as SlantedPin } from '../../../assets/icons/slanted-pin.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload3.svg';
import { ActionsPopup, ConfirmActionDialogue } from '../../../components';
import { Button, SecondaryButton } from '../../../components/buttons';
import { AllFilter } from '../../../components/filter-select';
import FilterPopup from '../../../components/filter-select/filter-popup';
import { GoBack } from '../../../components/go-back';
import ListEmptyContent from '../../../components/list-empty';
import AttachToAgent from '../../../components/popups/attach-to-agent';
import ConfirmActionDialogueWithInput from '../../../components/popups/confirm-action-with-input';
import SendOfferList from '../../../components/popups/send-offer-list';
import { getStatusStyles, StatusContainer, StatusText } from '../../../components/shared';
import TableWithUtils from '../../../components/table-with-utils';
import { PageTitle } from '../../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexColumn,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../containers/ScreenContainers';
import { LEVELS } from '../../../data/constants';
import useDebounce from '../../../hooks/useDebounce';
import { getSessionDetails, revokeAdmissionOffer, sendMultipleAdmissionOffer } from '../../../redux/admissions/actions';
import {
    attachStudentToAgent,
    detachStudentFromAgent,
    getAllAgents,
    moveStudentToSession,
} from '../../../redux/bursary/actions';
import { getColleges } from '../../../redux/college/actions';
import { getFaculties } from '../../../redux/faculty/actions';
import { removeStudent } from '../../../redux/students/actions';

import GenerateMatricNumber from './generate-matric-number-modal';
import MoveStudentToSession from './move-student';
import OverrideMatricNumber from './override-matric-number-modal';
import PublishMatricNumber from './publish-matric-number-modal';
import ReverseMatricNumber from './reverse-matric-number-modal';
import RevokeOffer from './revoke-offer-modal';

const FilterTag = tw.div`
    flex
    px-[8px]
    py-[4px]
    h-[25px]
    min-w-max
    bg-[#EEF2FF]
    text-[#4F46E5]
    text-[12px]
    font-semibold
`;

const StyledTag = styled(FilterTag)`
    border-top-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-bottom-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-top-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
    border-bottom-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
`;

const OFFER_STATUS_FILTER_OPTIONS = ['Accepted', 'Not Sent', 'Sent'];
const ADMISSION_TYPE_FILTER_OPTIONS = ['UTME', 'Direct Entry'];

const formatStudents = (students) =>
    students?.map((item) => ({
        ...item,
        fullName: `${item?.lastName} ${item?.firstName} ${item?.middleName || ''}`,
    }));

const AdmissionDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state: sessionState } = useLocation();
    const { id: sessionId } = useParams();

    const { isLoading, sessions: admissionsSessions } = useSelector((state) => state.admission);
    const { isLoading: studentsLoading } = useSelector((state) => state.student);
    const {
        firstDegreeOptionsStr,
        secondDegreeOptionsStr,
        firstDegreeOptionsArray = [],
    } = useSelector((state) => state.academicStructure);
    const { isLoading: facultyLoading, faculties } = useSelector((state) => state.faculty);
    const { isLoading: collegeLoading } = useSelector((state) => state.college);
    const { allAgents, isLoading: isBursaryLoading } = useSelector((store) => store.bursary);

    const [details, setDetails] = useState({});
    const admissionStatus = details?.status?.toLowerCase();
    const [openModal, setOpenModal] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);
    const [isMatricDropdownOpen, setIsMatricDropdownOpen] = useState(false);
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const [filterCategories, setFilterCategories] = useState([]);
    const [currentFilterCategory, setCurrentFilterCategory] = useState('');
    const [studentLevelFilter, setStudentLevelFilter] = useState('');
    const [admissionTypeFilter, setAdmissionTypeFilter] = useState('');
    const [firstDegreeFilter, setFirstDegreeFilter] = useState('');
    const [courseFilter, setcourseFilter] = useState('');
    const [dateCreatedFilter, setDateCreatedFilter] = useState('');
    const [offerStatusFilter, setOfferStatusFilter] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(true);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [destinationSession, setDestinationSession] = useState('');
    const [allStudents, setAllStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);

    const studentListToUse = searchValue ? filteredStudents : allStudents;

    const DateCreatedList = studentListToUse?.map((item) => item?.admissionYear);
    const secondDegreeList = studentListToUse?.map((item) => item?.program);

    const isMatricNumberGenerated = useMemo(
        () => allStudents.some((student) => student.matricNumberGeneratedStatus === 'Generated'),
        [allStudents],
    );

    const FILTER_OPTIONS = useMemo(() => {
        return [
            'Level',
            'Admission Type',
            ...firstDegreeOptionsArray,
            // ...secondDegreeOptionsArray,
            'Date Created',
            'Offer Status',
        ];
    }, [firstDegreeOptionsArray]);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.studentSearch = debouncedSearchValue;
        }
        if (studentLevelFilter) {
            queryParams.studentLevelFilter = studentLevelFilter;
        }
        if (admissionTypeFilter) {
            queryParams.admissionTypeFilter = admissionTypeFilter;
        }
        if (firstDegreeFilter) {
            queryParams.firstDegreeFilter = firstDegreeFilter;
        }
        if (courseFilter) {
            queryParams.courseFilter = courseFilter;
        }
        if (dateCreatedFilter) {
            queryParams.dateCreatedFilter = dateCreatedFilter;
        }
        if (offerStatusFilter) {
            queryParams.offerStatusFilter = offerStatusFilter;
        }

        return queryParams;
    }, [
        admissionTypeFilter,
        dateCreatedFilter,
        debouncedSearchValue,
        firstDegreeFilter,
        offerStatusFilter,
        courseFilter,
        studentLevelFilter,
    ]);

    const handleSendOffer = async () => {
        const studentIds = selectedStudents?.map((itm) => itm._id);
        const payload = {
            selectedStudentIds: [...studentIds],
        };
        const res = await dispatch(sendMultipleAdmissionOffer(payload));
        if (res) {
            setSelectedStudents([]);
            setOpenModal('');
            toast.success('Offer sent to student(s) successfully!');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const handleRevokeOffer = async () => {
        const studentIds = selectedStudents?.map((itm) => itm._id);
        const payload = {
            studentIds: [...studentIds],
        };
        const res = await dispatch(revokeAdmissionOffer(payload));
        if (res) {
            setSelectedStudents([]);
            setOpenModal('');
            setInputValue('');
            toast.success('Offer to student(s) revoked successfully!');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const handleDelete = async () => {
        const res = await dispatch(removeStudent(selectedStudents[0]?._id));
        if (res) {
            setSelectedStudents([]);
            setOpenModal('');
            setInputValue('');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const generateFilterOptions = (data = [], setStateAction, objaccessorKey) => {
        const filterOptions = data?.map((option) => {
            let item;

            if (typeof option === 'object') {
                item = option[objaccessorKey];
            } else {
                item = option;
            }

            return {
                name: item,
                click: () => {
                    setStateAction(item);
                    setOpenModal(null);
                },
            };
        });

        return filterOptions;
    };

    const fields = [
        {
            header: 'Full Name',
            accessorKey: 'fullName',
            cell: (props) => (
                <Link style={{ color: '#2563EB' }} to={`/admissions/sessions/candidate/${props.row.original?._id}`}>
                    {props.getValue()}
                </Link>
            ),
        },
        {
            header: 'Matric Number',
            accessorKey: 'matricNumber',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'UTME Reg. No.',
            accessorKey: 'utmeRegNumber',
        },
        {
            header: 'Admitted Level',
            accessorKey: 'admittedLevel',
        },
        {
            header: 'Admission Type',
            accessorKey: 'admissionType',
        },
        {
            header: firstDegreeOptionsStr,
            accessorKey: 'firstDegree',
            cell: ({ row }) => {
                const firstDegreeStruct = row.original?.academicStructure?.split('-')?.[0];

                return <p>{row.original?.[firstDegreeStruct] || '-'}</p>;
            },
        },
        {
            header: secondDegreeOptionsStr,
            accessorKey: 'secondDegree',
            cell: ({ row }) => {
                const secondDegreeStruct = row.original?.academicStructure?.split('-')?.[1];

                return <p>{row.original?.[secondDegreeStruct] || row.original?.subProgram || '-'}</p>;
            },
        },
        {
            header: 'Created At',
            accessorKey: 'admissionYear',
            cell: (props) => (
                <p>
                    {props.getValue()
                        ? `${new Date(props.getValue()).toLocaleDateString()}, ${new Date(
                              props.getValue(),
                          ).toLocaleTimeString()}`
                        : ''}
                </p>
            ),
        },
        {
            header: 'Email Address',
            accessorKey: 'studentEmail',
        },
        {
            header: 'Offer Status',
            accessorKey: 'status',
            cell: (props) => (
                <StatusContainer style={getStatusStyles(props.getValue()?.toLowerCase())}>
                    <span>{props.getValue()}</span>
                </StatusContainer>
            ),
        },
        {
            header: 'Referred Status',
            accessorKey: 'referredStatus',
            cell: (props) => (
                <StatusContainer
                    style={getStatusStyles(props?.row?.original?.agentReferred?.referred ? 'referred' : 'not referred')}
                >
                    {props?.row?.original?.agentReferred?.referred ? 'Referred' : 'Not Referred'}
                </StatusContainer>
            ),
        },
        {
            header: 'Matric No. Generation Mode',
            accessorKey: 'howGenerated',
        },
        {
            header: 'Matric No. Generation Status',
            accessorKey: 'matricNumberGeneratedStatus',
            cell: (props) => (
                <p className={props.getValue() === 'Generated' ? 'text-[#3B82F6]' : 'text-[#EB4898]'}>
                    {props.getValue()}
                </p>
            ),
        },
        {
            header: 'Matric No. Publish Status',
            accessorKey: 'matricPublishStatus',
            cell: (props) => (
                <p className={props.getValue() === 'Published' ? 'text-[#10B981]' : 'text-[#EB4898]'}>
                    {props.getValue()}
                </p>
            ),
        },
        {
            header: 'Acceptance Fee',
            accessorKey: 'isAcceptanceFee',
            cell: (props) => (
                <span className={props.getValue() ? 'text-[#10B981]' : 'text-[#EB4898]'}>
                    {props.getValue() ? 'Yes' : 'No'}
                </span>
            ),
        },
    ];

    const handleReset = () => {
        setFilterCategories([]);
        setCurrentFilterCategory('');
        setOfferStatusFilter('');
        setSearchValue('');
        setStudentLevelFilter('');
        setAdmissionTypeFilter('');
        setFirstDegreeFilter('');
        setcourseFilter('');
        setDateCreatedFilter('');
        setOfferStatusFilter('');
    };

    const handleAddStudent = () => {
        navigate('/admissions/sessions/add-student', {
            state: details?.admissionYear || sessionState?.state,
        });
    };

    const FILTER_POPUP_OPTIONS = useMemo(
        () => ({
            Level: generateFilterOptions(LEVELS, setStudentLevelFilter),
            'Admission Type': generateFilterOptions(ADMISSION_TYPE_FILTER_OPTIONS, setAdmissionTypeFilter),
            College: generateFilterOptions(faculties, setFirstDegreeFilter, 'facultyName'),
            Faculty: generateFilterOptions(secondDegreeList, setcourseFilter, 'facultyName'),
            // 'School': generateFilterOptions(LEVELS, '', ),
            'Date Created': generateFilterOptions(DateCreatedList, setDateCreatedFilter),
            'Offer Status': generateFilterOptions(OFFER_STATUS_FILTER_OPTIONS, setOfferStatusFilter),
        }),
        [faculties, secondDegreeList, DateCreatedList],
    );

    const FILTER_VALUES_LOOKUP = {
        Level: {
            value: studentLevelFilter,
            stateAction: setStudentLevelFilter,
        },
        'Admission Type': {
            value: admissionTypeFilter,
            stateAction: setAdmissionTypeFilter,
        },
        College: {
            value: firstDegreeFilter,
            stateAction: setFirstDegreeFilter,
        },
        Faculty: {
            value: courseFilter,
            stateAction: setcourseFilter,
        },
        // 'School': {
        // value: '',
        // stateAction: },
        'Date Created': {
            value: dateCreatedFilter,
            stateAction: setDateCreatedFilter,
        },
        'Offer Status': {
            value: offerStatusFilter,
            stateAction: setOfferStatusFilter,
        },
    };

    const handleSelectCategories = (category) => {
        const isSelected = filterCategories.includes(category);
        if (isSelected) return;
        setFilterCategories((prevState) => [...prevState, category]);
    };

    const handleRemoveFilterCategory = (category, stateAction) => {
        const newItems = filterCategories.filter((item) => item !== category);
        setFilterCategories(newItems);
        if (newItems.length === 0) setCurrentFilterCategory('');
        stateAction('');
    };

    const MANAGE_MATRIC_OPTIONS = useMemo(
        () => [
            {
                icon: <CheckIcon />,
                name: 'Generate Matric Number for All Students',
                click: () => {
                    setOpenModal('confirm-matric-all');
                },
                disabled: isMatricNumberGenerated,
            },
            {
                icon: <CheckIcon />,
                name: 'Generate Matric Number for Selected Students',
                click: () => {
                    setOpenModal('confirm-matric');
                },
                disabled: !selectedStudents.length,
            },
            {
                icon: <RepeatIcon />,
                name: 'Reverse Matric Number for All Students',
                click: () => {
                    setOpenModal('confirm-reverse-matric-all');
                },
            },
            {
                icon: <RepeatIcon />,
                name: 'Reverse Matric Number for Selected Students',
                click: () => {
                    setOpenModal('confirm-reverse-matric');
                },
                disabled:
                    !selectedStudents.length ||
                    selectedStudents.some((item) => !item?.matricNumber) ||
                    selectedStudents.some((item) => item?.matricPublishStatus === 'Published'),
            },
            {
                icon: <UploadIcon />,
                name: 'Publish Matric Number',
                click: () => {
                    setOpenModal('publish-matric');
                },
                disabled: !selectedStudents.length || selectedStudents.some((item) => !item?.matricNumber),
            },
            {
                icon: <RepeatIcon />,
                name: 'Override Matric Number',
                click: () => {
                    setOpenModal('override-matric');
                },
                disabled:
                    !selectedStudents.length ||
                    selectedStudents.length > 1 ||
                    selectedStudents.some((item) => !item?.matricNumber),
            },
        ],
        [isMatricNumberGenerated, selectedStudents],
    );
    const selectedStudentAgentId = selectedStudents?.[0]?.agentReferred?.agentId;

    useEffect(() => {
        const defaultAgent = allAgents?.find((item) => item?._id === selectedStudentAgentId);
        setSelectedAgent(defaultAgent);
    }, [selectedStudentAgentId, allAgents]);

    const handleAttachStudents = async () => {
        const payload = {
            agentId: selectedAgent?._id,
            session: sessionState?.state,
            studentIds: selectedStudents?.map((item) => item?._id),
        };
        const res = await dispatch(attachStudentToAgent(payload));
        if (res) {
            setOpenModal('');
        }
    };

    const handleDetachStudent = async () => {
        const payload = {
            agentId: selectedAgent?._id,
            session: sessionState?.state,
            studentIds: selectedStudents?.map((item) => item?._id),
        };
        const res = await dispatch(detachStudentFromAgent(payload));
        if (res) {
            setOpenModal('');
        }
    };

    const onMatricActionEnd = () => {
        setToggleRefetch((prev) => !prev);
        setIsActionsDropdownOpen(false);
        setIsMatricDropdownOpen(false);
    };

    const handleMoveStudentsToSession = async () => {
        const payload = { students: selectedStudents?.map((item) => item?._id) };
        const res = await dispatch(moveStudentToSession(destinationSession?.admissionYear, payload));
        if (res) {
            setSelectedStudents([]);
            setToggleRefetch(!toggleRefetch);
            setIsActionsDropdownOpen(false);
            setOpenModal('');
        }
    };

    useEffect(() => {
        const currStructName = currentFilterCategory?.toLowerCase() || '';
        if (currStructName === 'college') {
            dispatch(getColleges());
        }
        if (currStructName === 'faculty') {
            dispatch(getFaculties());
        }
    }, [dispatch, currentFilterCategory]);

    useEffect(() => {
        if (!sessionId) return;

        const updateStudentList = (students) => {
            if (query.studentSearch) {
                setFilteredStudents(students || []);
            } else {
                setAllStudents(students || []);
            }
        };

        const getDetails = async () => {
            const data = await dispatch(getSessionDetails(sessionId, query));
            const studentss = data?.students;
            if (data) {
                setDetails(data);
                const modifiedStudents = formatStudents(studentss);

                updateStudentList(modifiedStudents);
            } else {
                updateStudentList();
            }
        };

        getDetails();
    }, [dispatch, sessionId, query, toggleRefetch]);

    useEffect(() => {
        dispatch(getAllAgents());
    }, [dispatch]);

    return (
        <div>
            <FlexColumn className="mb-0 pt-0">
                <GoBack title="Admissions" subTitle={`${sessionState?.state}`} />
            </FlexColumn>

            <FlexRowSpaceBetween className="mt-0">
                <FlexCentredRow className="mt-4">
                    <PageTitle align="left">{details?.admissionYear}</PageTitle>
                    {admissionStatus && (
                        <StatusContainer className="ml-3" style={getStatusStyles(details?.status?.toLowerCase())}>
                            <StatusText>{details?.status?.toLowerCase()}</StatusText>
                        </StatusContainer>
                    )}
                </FlexCentredRow>

                <FlexCentredRow className="gap-x-4">
                    <SecondaryButton onClick={handleAddStudent}>
                        <Plus />
                        Add New Student
                    </SecondaryButton>

                    <RelativeContainer>
                        <Button bgColor="#6366F1" color="#fff" onClick={() => setIsActionsDropdownOpen(true)}>
                            Actions
                            <Dropdown />
                        </Button>
                        <ActionsPopup
                            open={isActionsDropdownOpen}
                            close={() => setIsActionsDropdownOpen(false)}
                            items={[
                                {
                                    icon: <SendIcon />,
                                    name: 'Send Offer',
                                    disabled:
                                        !selectedStudents?.length ||
                                        selectedStudents?.some(
                                            (item) =>
                                                item?.status?.toLowerCase() === 'accepted' ||
                                                item?.status?.toLowerCase() === 'sent',
                                        ),
                                    click: () => {
                                        setOpenModal('send-offer');
                                        setIsActionsDropdownOpen(false);
                                    },
                                    notShown: admissionStatus === 'closed',
                                },
                                {
                                    icon: <EditIcon />,
                                    name: 'Edit Student',
                                    click: () => {
                                        navigate(`/admissions/sessions/candidate/edit/${selectedStudents[0]?._id}`);
                                    },
                                    disabled: !selectedStudents.length || selectedStudents.length > 1,
                                    notShown: admissionStatus === 'closed',
                                },
                                {
                                    icon: <OrderIcon />,
                                    name: 'Matric Number Management',
                                    click: () => setIsMatricDropdownOpen(true),
                                    notShown: admissionStatus === 'closed',
                                    withOptions: true,
                                },
                                {
                                    icon: <EditIcon />,
                                    name: 'Revoke Offer',
                                    click: () => {
                                        setOpenModal('revoke-offer');
                                        setIsActionsDropdownOpen(false);
                                    },
                                    disabled:
                                        !selectedStudents.length ||
                                        selectedStudents?.find((stu) => stu?.status?.toLowerCase() === 'accepted') ||
                                        selectedStudents?.find((stu) => stu?.status?.toLowerCase() === 'not sent') ||
                                        selectedStudents?.find((stu) => stu?.status?.toLowerCase() === 'revoked'),
                                    notShown: admissionStatus === 'closed',
                                },
                                {
                                    icon: <ArrowIcon />,
                                    name: 'Move Student to Another Session',
                                    disabled: selectedStudents?.length > 1 || !selectedStudents?.length,
                                    click: () => {
                                        setOpenModal('move-student');
                                    },
                                    notShown: admissionStatus === 'closed',
                                },
                                {
                                    icon: <SlantedPin />,
                                    name: 'Attach Student to Agent',
                                    disabled: selectedStudents?.find((item) => item?.agentReferred?.referred === true),
                                    click: () => {
                                        setOpenModal('attach-agent');
                                    },
                                    notShown: admissionStatus === 'closed',
                                },
                                {
                                    icon: <LineThroughPin fill="#1F2937" />,
                                    name: 'Detach Student from Agent',
                                    disabled: selectedStudents?.find((item) => item?.agentReferred?.referred === false),
                                    click: () => {
                                        setOpenModal('detach-agent');
                                    },
                                    notShown: admissionStatus === 'closed',
                                },
                                {
                                    icon: <DeleteIcon />,
                                    name: 'Delete Student',
                                    disabled:
                                        !selectedStudents.length ||
                                        selectedStudents.length > 1 ||
                                        selectedStudents?.some((item) => item?.status?.toLowerCase() === 'accepted'),
                                    click: () => {
                                        setOpenModal('confirm-delete');
                                        setIsActionsDropdownOpen(false);
                                    },
                                },
                            ]}
                        />

                        <div className="absolute right-[330px] top-[20px]">
                            <ActionsPopup
                                open={isMatricDropdownOpen}
                                close={() => setIsMatricDropdownOpen(false)}
                                items={MANAGE_MATRIC_OPTIONS}
                            />
                        </div>
                    </RelativeContainer>
                </FlexCentredRow>
            </FlexRowSpaceBetween>

            <TableWithUtils
                fields={fields}
                data={studentListToUse || []}
                showTableUtils
                searchable
                isActionable
                loading={isLoading}
                actionType="checkbox"
                onSelectedItemsChange={(items) => setSelectedStudents(items)}
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                showFilter
                applySettings={true}
                showClear={currentFilterCategory}
                filterItems={FILTER_OPTIONS.map((item) => ({
                    name: item,
                    click: () => {
                        handleSelectCategories(item);
                        setCurrentFilterCategory(item);
                        setOpenModal('options-popup-main');
                    },
                }))}
                handleFilterReset={handleReset}
                openFilterPop={openModal === 'options-popup-main'}
                isFilterPopLoading={facultyLoading || collegeLoading}
                closeFilterPop={() => setOpenModal(null)}
                filterPopItems={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                filterCategories={
                    <div>
                        {filterCategories?.length > 0 && (
                            <div className="flex flex-wrap items-center gap-4 p-[18px] border-t">
                                {filterCategories.map((category) => (
                                    <div key={category} className="flex gap-[1px]">
                                        <StyledTag withLeftRadius>{category}</StyledTag>

                                        {FILTER_VALUES_LOOKUP[category].value && (
                                            <StyledTag withRightRadius>
                                                {FILTER_VALUES_LOOKUP[category].value}
                                                <CloseIcon
                                                    className="ml-[4px] hover:cursor-pointer"
                                                    onClick={() =>
                                                        handleRemoveFilterCategory(
                                                            category,
                                                            FILTER_VALUES_LOOKUP[category].stateAction,
                                                        )
                                                    }
                                                />
                                            </StyledTag>
                                        )}
                                    </div>
                                ))}
                                <RelativeContainer>
                                    <AllFilter
                                        useSecondaryBtn
                                        absolutePosition="left-[0px]"
                                        showClear={currentFilterCategory}
                                        items={FILTER_OPTIONS.map((item) => ({
                                            name: item,
                                            click: () => {
                                                handleSelectCategories(item);
                                                setCurrentFilterCategory(item);
                                                setOpenModal('options-popup-sub');
                                            },
                                        }))}
                                        handleReset={handleReset}
                                    />
                                    <FilterPopup
                                        absolutePosition="left-[0px]"
                                        open={openModal === 'options-popup-sub'}
                                        isLoading={facultyLoading || collegeLoading}
                                        close={() => setOpenModal(null)}
                                        items={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                                    />
                                </RelativeContainer>
                            </div>
                        )}
                    </div>
                }
                noItemView={
                    <CenteredContainer className="mt-[2rem]">
                        <ListEmptyContent
                            isInTable
                            title={searchValue ? '' : 'You have not added any students!'}
                            subtitle={searchValue ? 'No student matches your search criteria' : 'Add New Student'}
                        >
                            <Button bgColor="#6366F1" color="#fff" onClick={handleAddStudent}>
                                Add New Student
                            </Button>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
            />

            <SendOfferList
                show={openModal === 'send-offer'}
                list={selectedStudents}
                subtitle="Are you sure you want to send Admission offer to these people?"
                close={() => setOpenModal('')}
                onSend={handleSendOffer}
                isLoading={isLoading}
            />

            <RevokeOffer
                show={openModal === 'revoke-offer'}
                list={selectedStudents}
                close={() => setOpenModal('')}
                isLoading={isLoading}
                onSend={handleRevokeOffer}
                setInputValue={setInputValue}
                value={inputValue}
            />

            <GenerateMatricNumber
                show={openModal === 'generate-matric'}
                students={selectedStudents}
                close={() => setOpenModal('')}
                session={sessionState?.state}
                onFinish={onMatricActionEnd}
            />

            <GenerateMatricNumber
                isSendToAll
                show={openModal === 'generate-matric-all'}
                students={selectedStudents}
                close={() => setOpenModal('')}
                session={sessionState?.state}
                onFinish={onMatricActionEnd}
            />

            <ReverseMatricNumber
                show={openModal === 'reverse-matric'}
                students={selectedStudents}
                close={() => setOpenModal('')}
                session={sessionState?.state}
                onFinish={onMatricActionEnd}
            />

            <ReverseMatricNumber
                isSendToAll
                show={openModal === 'reverse-matric-all'}
                students={selectedStudents}
                close={() => setOpenModal('')}
                session={sessionState?.state}
                onFinish={onMatricActionEnd}
            />
            <OverrideMatricNumber
                show={openModal === 'override-matric'}
                students={selectedStudents}
                close={() => setOpenModal('')}
                session={sessionState?.state}
                onFinish={onMatricActionEnd}
            />

            <PublishMatricNumber
                show={openModal === 'publish-matric'}
                students={selectedStudents}
                close={() => setOpenModal('')}
                session={sessionState?.state}
                onFinish={onMatricActionEnd}
            />

            <ConfirmActionDialogueWithInput
                show={openModal === 'confirm-delete'}
                title="Delete Student(s)?"
                subtitle="This student(s) will be completely deleted."
                subtitle2="To confirm deletion, enter 'delete' in the text field."
                close={() => setOpenModal('')}
                confirmAction={() => handleDelete()}
                btn2Text="Yes, Delete"
                setInputValue={setInputValue}
                value={inputValue}
                isLoading={studentsLoading}
                btnDisabled={inputValue?.toLowerCase() !== 'delete'}
                placeholder="Input delete"
            />

            <ConfirmActionDialogue
                show={openModal === 'confirm-matric'}
                title="Generate Matric Number"
                subtitle="Kindly Complete the Matric Number Settings before generating matric number to students."
                close={() => setOpenModal('')}
                confirmAction={() => setOpenModal('generate-matric')}
                btn2Text="Continue"
                bgColor="#6366F1"
            />

            <ConfirmActionDialogue
                show={openModal === 'confirm-matric-all'}
                title="Generate Matric Number"
                subtitle="Kindly Complete the Matric Number Settings before generating matric number to students."
                close={() => setOpenModal('')}
                confirmAction={() => setOpenModal('generate-matric-all')}
                btn2Text="Continue"
                bgColor="#6366F1"
            />
            <ConfirmActionDialogue
                show={openModal === 'confirm-reverse-matric'}
                title="Reverse Matric Number"
                close={() => setOpenModal('')}
                confirmAction={() => setOpenModal('reverse-matric')}
                btn2Text="Continue"
                bgColor="#6366F1"
            />

            <ConfirmActionDialogue
                show={openModal === 'confirm-reverse-matric-all'}
                title="Reverse Matric Number"
                close={() => setOpenModal('')}
                confirmAction={() => setOpenModal('reverse-matric-all')}
                btn2Text="Continue"
                bgColor="#6366F1"
            />

            <AttachToAgent
                show={openModal === 'attach-agent'}
                title="Attach Student(s) to Agent"
                subtitle="All students attached to the agent will be considered referred by the agent."
                list={selectedStudents}
                agentData={allAgents}
                subtitle2="To confirm, enter attach in the text field to confirm."
                value={inputValue}
                setInputValue={setInputValue}
                close={() => setOpenModal('')}
                btnText="Attach"
                placeholder="Enter Attach"
                onConfirm={handleAttachStudents}
                setSelectedAgent={setSelectedAgent}
                disabled={inputValue?.toLowerCase() !== 'attach'}
            />

            <AttachToAgent
                show={openModal === 'detach-agent'}
                title={`Detach Student from ${selectedAgent?.companyName}`}
                subtitle="The selected Student(s) will be detached permanently."
                list={selectedStudents}
                agentData={allAgents}
                subtitle2="To confirm, enter Delete in the text field."
                value={inputValue}
                setInputValue={setInputValue}
                close={() => setOpenModal('')}
                btnText="Yes, Delete"
                placeholder="Input Delete"
                bgColor="#EF4444"
                icon={<LineThroughPin fill="#EF4444" />}
                setSelectedAgent={setSelectedAgent}
                onConfirm={handleDetachStudent}
                studentAgent={selectedStudents?.[0]?.agentReferred?.agentId}
                disabled={inputValue?.toLowerCase() !== 'delete'}
            />

            <MoveStudentToSession
                show={openModal === 'move-student'}
                close={() => setOpenModal('')}
                details={selectedStudents?.[0]}
                sessions={admissionsSessions}
                value={inputValue}
                setInputValue={setInputValue}
                onSend={handleMoveStudentsToSession}
                isLoading={isBursaryLoading}
                onChange={(selected) => {
                    setDestinationSession(selected?.[0]);
                }}
            />
        </div>
    );
};

export default AdmissionDetails;
