import React from 'react';

import { getStatusStyles, StatusContainer } from '../../../components/shared';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { capitalizeFirstLetter } from '../../../utils';

import { getOrgRoleTypeName } from './utils';

const fields = [
    {
        header: 'Staff Name',
        accessorKey: 'staffName',
        cell: ({ row }) => (
            <p>{row?.original?.surname + ' ' + row?.original?.firstName + ' ' + row?.original?.otherName}</p>
        ),
    },
    {
        header: 'Staff Number',
        accessorKey: 'staffNumber',
    },
    {
        header: 'Staff Email',
        accessorKey: 'staffEmail',
    },
    {
        header: 'Number of Campuses',
        accessorKey: 'campuses',
        cell: (props) => <span>{props.row?.original?.campusId?.length}</span>,
    },
    {
        header: 'Organization Role',
        accessorKey: 'organizationRoleType',
        cell: (props) => <span>{getOrgRoleTypeName(props.getValue())}</span>,
    },
    {
        header: 'Invite Status',
        accessorKey: 'status',
        cell: (props) => {
            const value = props?.row?.original?.isInvitedToOrganization;
            const formatted_str = value?.split('_');
            const join_form = formatted_str?.join(' ');
            const new_str = join_form?.toLowerCase();

            return (
                <StatusContainer style={getStatusStyles(new_str)}>
                    <span>{capitalizeFirstLetter(new_str)}</span>
                </StatusContainer>
            );
        },
    },
    {
        header: 'Added by',
        accessorKey: 'addedBy',
        cell: (props) => <span>{props.row?.original?.creationInfo?.createdBy}</span>,
    },
    {
        header: 'Added at',
        accessorKey: 'createdAt',
        cell: (props) => (
            <p>
                {props.getValue()
                    ? `${new Date(props.getValue()).toLocaleDateString()}, ${new Date(
                          props.getValue(),
                      ).toLocaleTimeString()}`
                    : ''}
            </p>
        ),
    },
];

const ActiveStaff = ({ organizationStaffs, setSelectedItem, setSearchValue, isLoading, searchValue }) => {
    return (
        <div>
            <PageLayout
                noMargin
                fields={fields}
                data={organizationStaffs || []}
                onSelectedItemsChange={(items) => setSelectedItem(items[0])}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                isActionable
                actionType="radio"
                loading={isLoading}
            />
        </div>
    );
};

export default ActiveStaff;
