import React, { useState } from 'react';

import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ListEmptyContent from '../../../../components/list-empty';
import { getStatusStyles, StatusContainer } from '../../../../components/shared';
import { CenteredContainer } from '../../../../containers/ScreenContainers';
import PageLayout from '../../../../layout/page-layout/PageLayout';

const fields = [
    {
        header: 'Full Name',
        accessorKey: 'fullName',
        cell: (props) => (
            <Link style={{ color: '#2563EB' }} to={props.row.original._id}>
                {props.getValue()}
            </Link>
        ),
    },
    {
        header: 'Designation',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'designation',
    },
    {
        header: 'Email',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'staffEmail',
    },
    {
        header: 'Emp. Year',
        accessorKey: 'yearOfEmployment',
        cell: (props) => <p>{props?.getValue() ? format(new Date(props?.getValue()), 'PP') : ''}</p>,
    },
    {
        header: 'Status',
        accessorKey: 'staffStatus',
        cell: (props) => (
            <StatusContainer style={getStatusStyles(props.getValue().toLowerCase())}>
                <span>{props.getValue()}</span>
            </StatusContainer>
        ),
    },
];

const InactiveStaff = ({ handleSelect }) => {
    const isLoading = useSelector((state) => state.staff.isLoading);
    const staff = useSelector((state) => state.staff.deactivatedStaff);

    const [searchValue, setSearchValue] = useState('');

    return (
        <>
            <PageLayout
                noMargin
                showTableUtils
                loading={isLoading}
                data={staff || []}
                fields={fields}
                isActionable
                actionType="checkbox"
                onSelectedItemsChange={(items) => handleSelect(items)}
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Inactive Staff"></ListEmptyContent>
                    </CenteredContainer>
                }
            />
        </>
    );
};

export default InactiveStaff;
