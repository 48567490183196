import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { LOADING, STOP_LOADING } from './slice';

// for department
export const getCurriculum = (academicStructureId, lastDegreeId, session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}curriculum/:campusId/:unitId/get_curriculum/${academicStructureId}/${lastDegreeId}/${session}`,
        );

        if (response.status === 200) {
            return response.data.coursesByLevel;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

// export const addCourseToCurriculum = (deptId, level, payload) => async (dispatch, getState) => {
//     dispatch(LOADING());
//     try {
//

//         const response = await axiosInstance.post(
//             `${currentAPI}curriculum/:campusId/:unitId/add_curriculum/${deptId}/${level}`,
//             payload,
//         );
//         if (response.status === 200) {
//             return response.data.data.courses;
//         }
//     } catch (error) {
//         const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
//         toast.error(errorMessage);
//     } finally {
//         dispatch(STOP_LOADING());
//     }
// };
export const addCourseToCurriculumInCollege =
    (academicStructureId, lastDegreeId, level, payload) => async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.post(
                `${currentAPI}curriculum/:campusId/:unitId/add_curriculum_college/${academicStructureId}/${lastDegreeId}/${level}`,
                payload,
            );
            if (response.status === 200) {
                const { data } = response?.data || {};
                return data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };
export const addCourseToCurriculumInFaculty =
    (academicStructureId, lastDegreeId, level, payload) => async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.post(
                `${currentAPI}curriculum/:campusId/:unitId/add_curriculum_faculty/${academicStructureId}/${lastDegreeId}/${level}`,
                payload,
            );
            if (response.status === 200) {
                const { data } = response?.data || {};
                return data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };
export const addCourseToCurriculumInSchool = (deptId, level, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}curriculum/:campusId/:unitId/add_curriculum_school/${deptId}/${level}`,
            payload,
        );
        if (response.status === 200) {
            return response.data.data.courses;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
// FOR DEPARTMENt
// export const getCurriculumBySession = (deptId, session) => async (dispatch, getState) => {
//     dispatch(LOADING());
//     try {
//

//         const response = await axiosInstance.get(`${currentAPI}curriculum/:campusId/:unitId/fetch_curriculum/${deptId}/${session}`);
//         if (response.status === 200) {
//             return response.data.data;
//         }
//     } catch (error) {
//         const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
//         toast.error(errorMessage);
//     } finally {
//         dispatch(STOP_LOADING());
//     }
// };
// export const getCurriculumByProgram = (programId, session) => async (dispatch, getState) => {
//     dispatch(LOADING());
//     try {
//

//         const response = await axiosInstance.get(
//             `${currentAPI}curriculum/:campusId/:unitId/get_curriculum_by_subprogram/${programId}/${session}`,
//         );
//         if (response.status === 200) {
//             return response.data.data;
//         }
//     } catch (error) {
//         const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
//         toast.error(errorMessage);
//     } finally {
//         dispatch(STOP_LOADING());
//     }
// };

export const getCurriculumByLastDegreeId =
    (academicStructureId, lastDegreeId, session, level) => async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.get(
                // Old api
                // `${currentAPI}curriculum/:campusId/:unitId/get_curriculum_in_subprogram_level_and_session/${programId}/${level}/${session}`,
                `${currentAPI}curriculum/:campusId/:unitId/get_curriculum_by_lastdegree/${academicStructureId}/${lastDegreeId}/${session}?level=${level}`,
            );
            if (response.status === 200) {
                const { data } = response?.data || {};
                return data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const removeCourseFromCurriculum = (curriculumId, courseId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}curriculum/:campusId/:unitId/remove_course_from_curriculum/${curriculumId}/${courseId}`,
        );
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const changeMinimumPassScore =
    ({ structureId, lastDegreeId, curriculumId, courseId, payload }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.put(
                `${currentAPI}curriculum/:campusId/:unitId/update_minimum_pass_mark/${structureId}/${lastDegreeId}/${curriculumId}/${courseId}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };
export const updateCourseClass =
    (academicStructureId, lastDegreeId, curriculumId, payload) => async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.put(
                `${currentAPI}curriculum/:campusId/:unitId/update_course_class_status/${academicStructureId}/${lastDegreeId}/${curriculumId}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const approveCurriculum = (curriculumId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}curriculum/:campusId/:unitId/departmental_approval/${curriculumId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const importCurriculum = (academicStructureId, lastDegreeId, level, payload) => async (dispatch, getState) => {
    // dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}curriculum/:campusId/:unitId/import_curriculum/${academicStructureId}/${lastDegreeId}/${level}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const publishCurriculum = (curriculumId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}curriculum/:campusId/:unitId/publish_curriculum/${curriculumId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const saveCurriculum = (curriculumId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}curriculum/:campusId/:unitId/save_curriculum/${curriculumId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteCurriculum = (curriculumId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(
            `${currentAPI}curriculum/:campusId/:unitId/delete_curriculum/${curriculumId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getCurriculumCourses =
    ({ academicStructureId, lastDegreeId, session, level, semester, curriculumId }) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.get(
                `${currentAPI}result/:campusId/:unitId/courses_in_curriculum/${academicStructureId}/${lastDegreeId}/${session}/${level}/${curriculumId}/${semester}`,
            );
            if (response.status === 200) {
                const { data } = response?.data || {};
                return data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };
