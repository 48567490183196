import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ListEmptyContent from '../../../components/list-empty';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getStudentsInFirstDegree } from '../../../redux/faculty/actions';

const FacultyStudents = () => {
    const dispatch = useDispatch();
    const { isLoading, currentFaculty, students } = useSelector((state) => state.faculty);

    const {
        _id: facultyId,
        splitAcademicStructureTwo: structTwo,
        splitAcademicStructureThree: structThree,
        academicStructureId,
    } = currentFaculty;

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const fields = [
        {
            header: 'Full Name',
            accessorKey: 'firstName',
            cell: ({ row }) => {
                const student = row.original;
                return (
                    <Link
                        style={{ color: '#2563EB' }}
                        to={`/faculties/students/${student?._id}`}
                        state={{
                            studentId: student?._id,
                        }}
                    >
                        {student?.lastName} {student?.firstName} {student?.middleName || ''}
                    </Link>
                );
            },
        },
        {
            header: 'Matric Number',
            accessorKey: 'matricNumber',
            cell: (props) => <p>{props?.getValue() || '-'}</p>,
        },
        {
            header: structTwo,
            accessorKey: 'department',
            cell: ({ row }) => <span>{row.original?.[structTwo?.toLowerCase()] || '-'}</span>,
        },
        ...(structThree
            ? [
                  {
                      header: structThree,
                      accessorKey: 'program',
                      cell: ({ row }) => <span>{row.original?.[structThree?.toLowerCase()] || '-'}</span>,
                  },
              ]
            : []),
        {
            header: 'Level',
            accessorKey: 'level',
            cell: (props) => <p>{props?.getValue() || '-'}</p>,
        },
        {
            header: 'Admission Session',
            accessorKey: 'admissionSession',
            cell: (props) => <p>{props?.getValue() || '-'}</p>,
        },
    ];

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.studentSearch = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    const acceptedStudents = useMemo(() => students?.filter((student) => student?.status === 'Accepted'), [students]);


    useEffect(() => {
        if (facultyId) {
            dispatch(getStudentsInFirstDegree(academicStructureId, facultyId, query));
        }
    }, [academicStructureId, dispatch, facultyId, query]);

    return (
        <>
            <PageLayout
                pageTitle={currentFaculty?.facultyName}
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                fields={fields}
                data={acceptedStudents}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Students "></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                isActionable
            />
        </>
    );
};

export default FacultyStudents;
