import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useDebounce from '../../hooks/useDebounce';
import PageLayout from '../../layout/page-layout/PageLayout';
import { getRolesInUnit } from '../../redux/roles/actions';
import { getStaffInUnit } from '../../redux/staff/actions';

const fields = [
    {
        cell: ({ row }) => (
            <p>
                {row.original.surname} {row.original.firstName}
            </p>
        ),
        header: 'Name',
        accessorKey: 'name',
    },
    {
        header: 'Staff Number',
        accessorKey: 'staffNumber',
    },
    {
        header: 'Email Address',
        accessorKey: 'staffEmail',
    },
];

const Staff = ({ unitName }) => {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.staff);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [staff, setStaff] = useState([]);
    const [roles, setRoles] = useState([]);
    const [roleQuery, setRoleQuery] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(null);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.searchTerm = debouncedSearchValue;
        }
        if (roleQuery) {
            queryParams.role = roleQuery;
        }

        return queryParams;
    }, [debouncedSearchValue, roleQuery]);

    const rolesActionItems = roles?.map((role) => {
        return {
            name: role?.role,
            click: () => {
                setRoleQuery(role?.role);
                setDropdownOpen(null);
            },
        };
    });

    useEffect(() => {
        const getRoles = async () => {
            const data = await dispatch(getRolesInUnit(unitName));
            data && setRoles(data);
        };

        getRoles();
    }, [dispatch, unitName]);

    useEffect(() => {
        if (unitName) {
            const getDetails = async () => {
                const data = await dispatch(getStaffInUnit({ query }));
                data && setStaff(data);
            };

            getDetails();
        }
    }, [unitName, dispatch, query]);

    // const handleResetClick = () => {
    //     setRoleQuery('');
    //     setSearchValue('');
    // };

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                showFilter
                filterItems={rolesActionItems}
                data={staff || []}
                fields={fields}
                loading={isLoading}
                openFilter={dropdownOpen}
                isActionable
            />
        </>
    );
};

export default Staff;
