import React, { useEffect, useMemo, useState } from 'react';

import { Form, Formik, FieldArray } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PlusCircle } from '../../../../assets/icons/circlePlus.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/delete.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../components/go-back';
import Select from '../../../../components/inputs/new-select';
import TextInput from '../../../../components/inputs/text-input-with-formik';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexCentredCol } from '../../../../containers/ScreenContainers';
import { getCurriculumCourses } from '../../../../redux/curriculum/actions';
import { recordPreviousResult } from '../../../../redux/exam-magt/actions';
import { capitalizeFirstLetter, getDegreeStructs } from '../../../../utils';

import { ValidationSchema } from './validationSchema';

const Container = tw.div`
    bg-white
    h-auto
    px-[3.2rem]
    py-[3.6rem]
    rounded-[16px]
    mt-10
    max-w-[1000px]
    mb-[100px]
`;

const UploadResultsBacklog = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { state } = useLocation();

    const {
        student,
        session,
        level,
        semester,
        // courses,
        firstDegree,
        secondDegree,
        thirdDegree,
        results = [],
        curriculumId,
    } = state || {};

    const { isLoading } = useSelector((store) => store.examMagt);
    const { isLoading: altLoading } = useSelector((store) => store.curriculum);

    const { firstDegreeStruct, secondDegreeStruct, thirdDegreeStruct } = getDegreeStructs(
        firstDegree?.academicStructure,
    );

    const [courses, setCourses] = useState([]);

    const selectedStudentResults = useMemo(
        () => results?.find((item) => item?.studentId === student.studentId),
        [results, student.studentId],
    );
    const registeredCourses = useMemo(
        () => selectedStudentResults?.courses?.filter((item) => item.status === 'Registered'),
        [selectedStudentResults],
    );

    const overviewItems = [
        { title: 'Session', value: session },
        { title: 'Name', value: student?.name },
        { title: 'Matric Number', value: student?.matricNumber || '' },
        { title: capitalizeFirstLetter(firstDegreeStruct), value: firstDegree?.facultyName },
        { title: capitalizeFirstLetter(secondDegreeStruct), value: secondDegree?.departmentName },
        { title: capitalizeFirstLetter(thirdDegreeStruct), value: thirdDegree?.programName },
        { title: 'Level', value: level },
        { title: 'Semester', value: semester },
    ];

    useEffect(() => {
        const shouldFetch = semester && session && curriculumId && firstDegree && secondDegree && level;
        if (shouldFetch) {
            const getData = async () => {
                const academicStructureId = firstDegree.academicStructureId;
                const lastDegreeId = secondDegree._id;

                const queries = {
                    academicStructureId,
                    lastDegreeId,
                    session,
                    level,
                    curriculumId,
                    semester: semester.toLowerCase(),
                };

                const data = await dispatch(getCurriculumCourses(queries));

                if (data) {
                    return setCourses(data);
                }
                setCourses([]);
            };
            getData();
        }
    }, [dispatch, firstDegree, secondDegree, semester, session, curriculumId, level]);

    return (
        <>
            <GoBack title="Results Backlog" subTitle="Upload Results" />

            <Container>
                <Text size="1.9rem" bottom="2.4rem" weight="600" align="left">
                    Student Details
                </Text>

                <div className="grid grid-cols-4 gap-y-[34px] mb-[32px]">
                    {overviewItems
                        .filter((item) => item.title)
                        .map((item) => (
                            <div key={item.title}>
                                <Text align="left" lineHeight="17px" color="#9CA3AF" size="1.2rem" weight="500">
                                    {item.title || ''}
                                </Text>
                                <Text align="left" weight="500" color="#4B5563">
                                    {item.value || ''}
                                </Text>
                            </div>
                        ))}
                </div>

                <Formik
                    initialValues={{
                        result:
                            registeredCourses?.length > 0
                                ? registeredCourses.map((item) => ({
                                      courseCode: item.courseCode,
                                      score: item.totalScore,
                                  }))
                                : [
                                      {
                                          courseCode: '',
                                          score: '',
                                      },
                                      {
                                          courseCode: '',
                                          score: '',
                                      },
                                      {
                                          courseCode: '',
                                          score: '',
                                      },
                                      {
                                          courseCode: '',
                                          score: '',
                                      },
                                      {
                                          courseCode: '',
                                          score: '',
                                      },
                                  ],
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const params = {
                            studentId: student?.studentId,
                            level,
                            semester: semester.toLowerCase(),
                            session,
                            academicStructureId: firstDegree?.academicStructureId,
                            firstDegreeId: firstDegree?._id,
                            secondDegreeId: secondDegree?._id,
                            ...(thirdDegree ? { thirdDegreeId: thirdDegree?._id } : {}),
                        };

                        // Remove objects with empty values
                        const finalPayload = { result: values.result.filter((item) => item.courseCode) };

                        const res = await dispatch(recordPreviousResult(params, finalPayload));

                        if (res) navigate(-1);
                    }}
                >
                    {({ handleChange, errors, values, setFieldValue, touched }) => (
                        <Form>
                            <Text size="1.6rem" weight="600" align="left">
                                Results
                            </Text>
                            <FieldArray
                                name="result"
                                render={({ insert, remove, push }) => (
                                    <FlexCentredCol className="gap-[5.6rem]">
                                        <div className="flex flex-col gap-[1rem]">
                                            {values.result?.map((course, index) => (
                                                <div
                                                    key={index}
                                                    className="grid grid-cols-[1fr_1fr_50px] gap-x-[1.6rem] mt-6"
                                                >
                                                    <Select
                                                        name={`result.${index}.courseCode`}
                                                        objProp="courseCode"
                                                        label="Course Code"
                                                        searchable
                                                        isLoading={altLoading}
                                                        placeholder="Select Course Code"
                                                        data={courses?.filter(
                                                            (item) =>
                                                                // Only show items that havent been selected
                                                                !values.result
                                                                    .map((itm) => itm.courseCode)
                                                                    .includes(item.courseCode),
                                                        )}
                                                        useComponentState={false}
                                                        passedSelectedItems={
                                                            course.courseCode
                                                                ? [{ courseCode: course.courseCode }]
                                                                : null
                                                        }
                                                        onChange={(selected) => {
                                                            setFieldValue(
                                                                `result.${index}.courseCode`,
                                                                selected[0].courseCode,
                                                            );
                                                        }}
                                                        error={errors.result?.[index]?.courseCode || ''}
                                                    />
                                                    <TextInput
                                                        name={`result.${index}.score`}
                                                        label="Score"
                                                        onChange={handleChange}
                                                        type="number"
                                                        value={course.score}
                                                        placeholder="Input Score"
                                                        errors={errors}
                                                    />

                                                    {values.result.length > 1 && (
                                                        <Delete
                                                            onClick={() => remove(index)}
                                                            className="cursor-pointer mt-14"
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                        <div>
                                            <Button
                                                border="1px solid #2563EB"
                                                type="button"
                                                color="#2563EB"
                                                onClick={() => push({ courseCode: '', score: '' })}
                                            >
                                                <PlusCircle fill="#2563EB" />
                                                Add Another Course
                                            </Button>
                                        </div>
                                    </FlexCentredCol>
                                )}
                            />

                            <div className="flex items-center gap-[1rem] mt-[2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)} className="ml-auto">
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} disabled={isLoading} type="submit">
                                    Upload Result
                                </LoadingButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default UploadResultsBacklog;
