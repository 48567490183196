import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    auditLogsData: {},
    customDomains: [],
    billingPlans: [],
    payment: [],
    paymentHistory: [],
    alternateContact: {},
    contactInfo: {},
};

export const ictSlice = createSlice({
    name: 'ict',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },

        GET_AUDIT_LOGS: (state, action) => {
            state.auditLogsData = action.payload;
        },
        SAVE_CUSTOM_DOMAINS: (state, action) => {
            state.customDomains = action.payload;
        },
        SAVE_BILLING_PLANS: (state, action) => {
            state.billingPlans = action.payload;
        },
        SAVE_PAYMENT: (state, action) => {
            state.payment = action.payload;
        },
        SAVE_PAYMENT_HISTORY: (state, action) => {
            state.paymentHistory = action.payload;
        },
        GET_ALTERNATE_CONTACT: (state, action) => {
            state.alternateContact = action.payload;
        },
        GET_CONTACT_INFO: (state, action) => {
            state.contactInfo = action.payload;
        },
    },
});

export const {
    LOADING,
    STOP_LOADING,
    GET_ALTERNATE_CONTACT,
    GET_CONTACT_INFO,
    GET_AUDIT_LOGS,
    SAVE_CUSTOM_DOMAINS,
    SAVE_BILLING_PLANS,
    SAVE_PAYMENT,
    SAVE_PAYMENT_HISTORY,
} = ictSlice.actions;

export default ictSlice.reducer;
