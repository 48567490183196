import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ListEmptyContent from '../../../../../components/list-empty';
import { CenteredContainer } from '../../../../../containers/ScreenContainers';
import PageLayout from '../../../../../layout/page-layout/PageLayout';
import { getProgrammeStudents } from '../../../../../redux/programmes/actions';

const fields = [
    {
        header: 'Full Name',
        accessorKey: 'name',
        cell: ({ row }) => (
            <span>
                {row.original?.lastName} {row.original?.firstName} {row.original?.middleName || ''}
            </span>
        ),
    },
    {
        header: 'Level',
        accessorKey: 'Level',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Admission Year',
        accessorKey: 'admissionSession',
    },
];

const ProgrammeStudents = () => {
    const dispatch = useDispatch();
    const { isLoading, students } = useSelector((state) => state.programmes);

    const { currentProgramme } = useSelector((state) => state.programmes);
    const { _id: programId, academicStructureId } = currentProgramme || {};
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        dispatch(getProgrammeStudents(academicStructureId, programId));
    }, [dispatch, academicStructureId, programId]);

    return (
        <>
            <PageLayout
                pageTitle={currentProgramme?.subprogram}
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                data={students || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Students "></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                isActionable
            />
        </>
    );
};

export default ProgrammeStudents;
