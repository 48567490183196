import React, { useEffect, useMemo, useState } from 'react';

import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete2.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit3.svg';
import { Button } from '../../../components/buttons';
import AddNewDesignationForm from '../../../components/forms/add-designation';
import EditDesignationForm from '../../../components/forms/edit-designation';
import ListEmptyContent from '../../../components/list-empty';
import ConfirmActionDialogue from '../../../components/popups/confirm-action';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { deleteDesignation, emptyDesignation, getDesignations } from '../../../redux/designations/actions';

const fields = [
    {
        header: 'Name',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'designation',
    },
    {
        header: 'Number of Staff',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorKey: 'numberOfStaffs',
    },
];

const Designations = () => {
    const dispatch = useDispatch();
    const { isLoading, designations } = useSelector((state) => state.designations);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const [openModal, setOpenModal] = useState(null);
    const [selected, setSelected] = useState(null);
    const [altLoading, setAltLoading] = useState(false);

    const handleCloseModal = (modal) => {
        if (openModal === modal) {
            setOpenModal(null);
        } else {
            setOpenModal(modal);
        }
    };

    const handleEdit = () => {
        if (!selected) return toast.error('Select a designation to edit!');
        setOpenModal('edit');
    };

    const handleDelete = async () => {
        setAltLoading(true);
        const res = await dispatch(deleteDesignation(selected?.id));
        if (res) {
            toast.success('Designation deleted successfully!');
            setSelected(null);
            setOpenModal(null);
        }
        setAltLoading(false);
    };

    const handleEmpty = async () => {
        setAltLoading(true);
        const res = await dispatch(emptyDesignation(selected?.id));
        if (res) {
            toast.success('Designation emptied successfully!');
            setSelected(null);
            setOpenModal(null);
        }
        setAltLoading(false);
    };

    const query = useMemo(() => {
        let queryParams = {};
        if (debouncedSearchValue) {
            queryParams.searchTerm = debouncedSearchValue;
        }
        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        dispatch(getDesignations(query));
    }, [dispatch, query]);

    return (
        <>
            <PageLayout
                pageTitle="Designations"
                secondaryButtonText="Add Designation"
                data={designations || []}
                loading={isLoading}
                fields={fields}
                isActionable
                actionType="radio"
                dataIdField="id"
                onSelectedItemsChange={(items) => setSelected(items[0])}
                onSecondaryButtonClick={() => setOpenModal('add')}
                onActionBtnClick={() => handleCloseModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <EditIcon />,
                        name: 'Edit Designation',
                        click: () => handleEdit(),
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Designation',
                        disabled: !selected,
                        click: () => {
                            setOpenModal('confirm');
                        },
                    },
                ]}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Designations " subtitle="Add Designations">
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Button onClick={() => setOpenModal('add')} bgColor="#10B981" color="#fff">
                                    <Circularplus className="mr-[1.15rem]" />
                                    Add New
                                </Button>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
            />

            <AddNewDesignationForm show={openModal === 'add'} setShow={setOpenModal} />

            <EditDesignationForm
                item={selected}
                show={openModal === 'edit'}
                setSelected={setSelected}
                setShow={setOpenModal}
            />
            <ConfirmActionDialogue
                isLoading={altLoading}
                show={openModal === 'confirm'}
                title="Delete Designation?"
                subtitle="Kindly make sure this designation is empty before you proceed to delete."
                close={() => setOpenModal(null)}
                confirmAction={() => handleDelete()}
                btn2Text="Yes, Delete"
            />
            <ConfirmActionDialogue
                isLoading={altLoading}
                show={openModal === 'empty'}
                title="Empty Designation?"
                subtitle="Are you sure you want to empty this designation?."
                close={() => setOpenModal(null)}
                confirmAction={() => handleEmpty()}
                btn2Text="Yes, Empty"
            />
        </>
    );
};

export default Designations;
