import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    hostelName: Yup.string().nullable().required('Required'),
    hostelAddress: Yup.string(),
    gender: Yup.string().nullable('Required'),
    aboutHostel: Yup.string(),
    hostelPartitionName: Yup.string().nullable('Required'),
    hostelPhoneNumber: Yup.string(),
    hostelEmailAddress: Yup.string(),
    hostelLogo: Yup.mixed(),
    hostelImages: Yup.array().of(
        Yup.object().shape({
            url: Yup.string(),
        }),
    ),
});
