import React, { useEffect, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { ReactComponent as Dropdown } from '../../../assets/icons/dropdown.svg';
import { ActionsPopup } from '../../../components';
import { Button, SecondaryButton } from '../../../components/buttons';
import ModalSelect from '../../../components/modal-select';
import TableWithUtils from '../../../components/table-with-utils';
import { PageTitle } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween, RelativeContainer } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import useGetFirstDegrees from '../../../hooks/useGetFirstDegrees';
import useGetSecondDegrees from '../../../hooks/useGetSecondDegrees';
import useSearch from '../../../hooks/useSearch';
import { getStudentsInSecondDegree } from '../../../redux/departments/actions';
import { promoteStudents } from '../../../redux/exam-magt/actions';
import { capitalizeFirstLetter, getDegreeStructs } from '../../../utils';

import PromoteStudents from './promote-students-modal';

const fields = [
    {
        header: 'Full Name',
        accessorKey: 'name',
        cell: ({ row }) => (
            <p>
                {row.original?.lastName} {row.original?.firstName} {row.original?.middleName || ''}
            </p>
        ),
    },
    {
        header: 'Matric No.',
        accessorKey: 'matricNumber',
        cell: (props) => <p>{props.getValue()}</p>,
    },

    {
        header: 'Admitted Level',
        accessorKey: 'admittedLevel',
    },
    {
        header: 'Current Level',
        accessorKey: 'level',
    },
    {
        header: 'Current Session',
        accessorKey: 'currentSession',
    },
];

const ManualPromotion = () => {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.examMagt);
    const { isLoading: studentLoading } = useSelector((state) => state.department);
    const { firstDegreeOptionsArray = [] } = useSelector((state) => state.academicStructure);
    const { sessions, activeSession } = useSelector((store) => store.admission);

    const [session, setSession] = useState(activeSession || '');
    const [selectedFirstDegree, setSelectedFirstDegree] = useState(null);
    const [selectedSecondDegree, setSelectedSecondDegree] = useState(null);

    const { secondDegreeStruct } = getDegreeStructs(selectedFirstDegree?.academicStructure);

    const [academicUnit, setAcademicUnit] = useState(
        firstDegreeOptionsArray.length === 1 ? firstDegreeOptionsArray[0] : '',
    );
    const [openModal, setOpenModal] = useState(null);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [students, setStudents] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [toggleRefetch, setToggleRefetch] = useState(true);

    const filteredStudents = useSearch(debouncedSearchValue, students, ['name']);
    const { firstDegrees } = useGetFirstDegrees(academicUnit);
    const { secondDegrees } = useGetSecondDegrees(academicUnit, selectedFirstDegree);

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenModal(null);
        },
    }));

    const actionItemAcademicunit = firstDegreeOptionsArray?.map((item) => ({
        name: item,
        click: () => {
            setSelectedFirstDegree(null);
            setSelectedSecondDegree(null);
            setAcademicUnit(item);
            setOpenModal(null);
        },
    }));

    const actionItemsFirstDegree = firstDegrees?.map((item) => ({
        name: item.facultyName,
        click: () => {
            setSelectedSecondDegree(null);
            setSelectedFirstDegree(item);
            setOpenModal(null);
        },
    }));

    const actionItemsSecondDegree = secondDegrees?.map((item) => ({
        name: item.departmentName,
        click: () => {
            setSelectedSecondDegree(item);
            setOpenModal(null);
        },
    }));

    const handleOpenDropdown = (dropdown) => {
        if (openModal === dropdown) {
            setOpenModal(null);
        } else {
            setOpenModal(dropdown);
        }
    };

    const handlePromote = async () => {
        const studentIds = selectedStudents?.map((itm) => itm._id);
        const payload = {
            students: [...studentIds],
        };

        const res = await dispatch(
            promoteStudents(selectedFirstDegree.academicStructureId, selectedSecondDegree._id, payload),
        );
        if (res) {
            setToggleRefetch((prevState) => !prevState);
            setOpenModal('');
            setSelectedStudents([]);
            toast.success('Student(s) promoted successfully!');
        }
    };

    useEffect(() => {
        if (selectedSecondDegree) {
            const getData = async () => {
                const res = await dispatch(
                    getStudentsInSecondDegree(
                        selectedFirstDegree.academicStructureId,
                        selectedFirstDegree._id,
                        selectedSecondDegree._id,
                        session,
                    ),
                );

                if (res) {
                    return setStudents(res || []);
                }
                return setStudents([]);
            };

            getData();
        }
    }, [dispatch, selectedSecondDegree, selectedFirstDegree, session, toggleRefetch]);

    return (
        <div>
            <FlexRowSpaceBetween className="mt-0">
                <PageTitle>Manual Promotion</PageTitle>

                <FlexCentredRow className="gap-x-4">
                    <SecondaryButton disabled={!selectedStudents.length} onClick={() => setOpenModal('promote')}>
                        <ArrowIcon className=" ml-auto" />
                        Promote
                    </SecondaryButton>

                    <RelativeContainer>
                        <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(true)}>
                            Actions
                            <Dropdown />
                        </Button>
                        <ActionsPopup
                            open={dropdownOpen}
                            close={() => setDropdownOpen(false)}
                            items={[
                                {
                                    icon: <ArrowIcon className="rotate-180" />,
                                    name: 'Revert Promotion',
                                    disabled: !selectedStudents?.length,
                                    click: () => {
                                        setOpenModal('revert');
                                        setDropdownOpen(false);
                                    },
                                },
                            ]}
                        />
                    </RelativeContainer>
                </FlexCentredRow>
            </FlexRowSpaceBetween>

            <div className="flex gap-5 flex-wrap p-[16px] shadow-sm rounded-t-lg bg-white my-6">
                <ModalSelect
                    placeholder="Session"
                    value={session}
                    onClick={() => handleOpenDropdown('session')}
                    open={openModal === 'session'}
                    close={() => setOpenModal(null)}
                    items={actionItemSessions}
                    absolutePosition="left-[0px]"
                />
                {firstDegreeOptionsArray.length > 1 && (
                    <ModalSelect
                        placeholder="Academic Unit"
                        value={academicUnit}
                        onClick={() => handleOpenDropdown('academic-unit')}
                        open={openModal === 'academic-unit'}
                        close={() => setOpenModal(null)}
                        items={actionItemAcademicunit}
                    />
                )}
                {academicUnit && (
                    <ModalSelect
                        placeholder={academicUnit}
                        value={selectedFirstDegree?.facultyName}
                        onClick={() => handleOpenDropdown('first-degrees')}
                        open={openModal === 'first-degrees'}
                        close={() => setOpenModal(null)}
                        items={actionItemsFirstDegree}
                        absolutePosition="left-[0px]"
                    />
                )}
                {secondDegreeStruct && (
                    <ModalSelect
                        placeholder={capitalizeFirstLetter(secondDegreeStruct)}
                        value={selectedSecondDegree?.departmentName}
                        onClick={() => handleOpenDropdown('second-degrees')}
                        open={openModal === 'second-degrees'}
                        close={() => setOpenModal(null)}
                        items={actionItemsSecondDegree}
                    />
                )}
            </div>

            <TableWithUtils
                fields={fields}
                data={filteredStudents || []}
                showTableUtils
                isActionable
                actionType="checkbox"
                onSelectedItemsChange={(items) => setSelectedStudents(items)}
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                loading={isLoading || studentLoading}
            />

            <PromoteStudents
                show={openModal === 'promote'}
                list={selectedStudents}
                idField="_id"
                close={() => setOpenModal('')}
                onSend={handlePromote}
                isLoading={isLoading}
            />
        </div>
    );
};

export default ManualPromotion;
