import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Loader } from '../../../../components/loader';
import { verifyPayment } from '../../../../redux/ict/actions';

const BillingVerification = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const url = window.location.href;
    const urlParams = url?.split('=');
    const transactionId = urlParams?.[3];
    const txRef = urlParams?.[2]?.split('&')?.[0];

    const { isLoading } = useSelector((store) => store.ict);

    useEffect(() => {
        const verifyBilling = async () => {
            const res = await dispatch(verifyPayment(transactionId, txRef));
            if (res) {
                navigate('/ict/billing');
            }
        };

        verifyBilling();
    }, [dispatch, navigate, transactionId, txRef]);

    if (isLoading) return <Loader />;
    return <div>BillingVerification</div>;
};

export default BillingVerification;
