import { toast } from 'react-hot-toast';

import { axiosInstance, commsAPI, currentAPI, billingAPI } from '../../config';
// import { encodeQuery } from '../../utils';

import {
    GET_ALTERNATE_CONTACT,
    GET_AUDIT_LOGS,
    GET_CONTACT_INFO,
    LOADING,
    SAVE_BILLING_PLANS,
    SAVE_CUSTOM_DOMAINS,
    SAVE_PAYMENT,
    SAVE_PAYMENT_HISTORY,
    STOP_LOADING,
} from './slice';

export const getAuditLogs = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}audit-logs/:campusId/:unitId`);
        if (response.status === 200) {
            dispatch(GET_AUDIT_LOGS(response.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const createAdminDomain = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}custom-domain`, payload);
        if (response.status === 201) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const verifyTXTRECORD = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${commsAPI}custom-domain/verify`, payload);
        if (response.status === 201 || response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getCustomDomain = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${commsAPI}custom-domain`);
        if (response.status === 200) {
            dispatch(SAVE_CUSTOM_DOMAINS(response.data.payload.subDomains));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteDomain = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await fetch(`${commsAPI}custom-domain`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
            },
            body: JSON.stringify(payload),
        });
        if (response.status === 200) {
            const successMessage = response?.data?.message || 'Account Deleted Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getBillingPlans = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${billingAPI}user/all-plans`);
        if (response.status === 200) {
            dispatch(SAVE_BILLING_PLANS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createPlan = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${billingAPI}user/payment`, payload);
        if (response.status === 201 || response.status === 200) {
            return response.data.data.data.link;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getPaymentSuccess = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${billingAPI}user/payment-success`);
        if (response.status === 200) {
            dispatch(SAVE_PAYMENT(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getPaymentHistory = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${billingAPI}user/all-plans`);

        if (response.status === 200) {
            dispatch(SAVE_PAYMENT_HISTORY(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const verifyPayment = (transactionId, txRef) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${billingAPI}verification/payment?transaction_id=${transactionId}&tx_ref=${txRef}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editContactInfo = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}setting/:campusId/:unitId/contact_info`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const editAlternateContact = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}setting/:campusId/:unitId/alternate_contact_info`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAlternateContact = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}setting/:campusId/:unitId/get_contact_alternate_info`);
        if (response.status === 200) {
            dispatch(GET_ALTERNATE_CONTACT(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getContactInfo = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}setting/:campusId/:unitId/get_contact_info`);
        if (response.status === 200) {
            dispatch(GET_CONTACT_INFO(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
