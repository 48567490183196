import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../../../assets/icons/back-arrow.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/icons/copy.svg';
import { LoadingButton } from '../../../../../components/buttons';
import { CancelButton } from '../../../../../components/forms/sharedStyles';
import TextInput from '../../../../../components/inputs/text-input-with-formik';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../../../containers/ScreenContainers';
import { getCustomDomain, verifyTXTRECORD } from '../../../../../redux/ict/actions';

const StudentProceed = () => {
    const navigate = useNavigate();
    const { customDomains, isLoading } = useSelector((store) => store.ict);
    const [subdomain, setSubDomain] = useState({});
    const searchParams = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
    const [copied, setCopied] = useState({
        verificationCode: false,
        cName: false,
        cValue: false,
        sslCName: false,
        sslCValue: false,
        txtRecordName: false,
    });
    const name = searchParams.get('name');

    useEffect(() => {
        dispatch(getCustomDomain());
    }, [dispatch]);

    useEffect(() => {
        const res = customDomains?.find((item) => item.subDomainName === name);
        setSubDomain({
            subDomainName: res?.subDomainName,
            verificationCode: res?.TXTRecord?.content,
            txtRecordName: res?.TXTRecord?.name,
            cName: res?.cnameRecord?.name,
            cValue: res?.cnameRecord?.value,
            sslCName: res?.sslCertificate?.cnameName,
            sslCValue: res?.sslCertificate?.cnameValue,
        });
    }, [customDomains, name]);

    return (
        <div>
            <FlexRowSpaceBetween>
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.6rem]">
                    <BackArrow className="mr-[8px]" />
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Settings
                    </PageTitle>
                    <Text left="1rem" size="1.6rem">
                        / Custom Domains
                    </Text>
                    <Text left="1rem" size="1.6rem">
                        / Verify Custom Domains
                    </Text>
                </FlexCentredRow>
            </FlexRowSpaceBetween>
            <FlexCentredRow className="mb-[4rem]">
                <PageTitle weight="600" size="2.3rem">
                    Verify Custom Domains
                </PageTitle>
            </FlexCentredRow>

            <Formik
                enableReinitialize={true}
                initialValues={
                    subdomain || {
                        subDomainName: '',
                        verificationCode: '',
                        txtRecordName: '',
                        cName: '',
                        cValue: '',
                        sslCName: '',
                        sslCValue: '',
                    }
                }
                onSubmit={async (values) => {
                    const res = await dispatch(
                        verifyTXTRECORD({
                            portalType: 'student',
                            subDomainName: values.subDomainName,
                            verificationCode: values.verificationCode,
                        }),
                    );
                    if (res) navigate('/ict/settings/custom-domain');
                }}
            >
                {({ errors, handleChange, values }) => (
                    <Form className="w-[837px]">
                        <Text align="none" weight="500" size="1.6rem" bottom="0.9rem">
                            Student Portal Domain URL
                        </Text>
                        <Text as="span" color="#6B7280" size="1.4rem" weight="400" lineHeight="2.4rem" bottom="0.8rem">
                            Enter the Url of the domain you want to add.
                        </Text>

                        <div className="gap-5 flex items-center mb-5">
                            <TextInput
                                name="subDomainName"
                                className="bg-[inherit]"
                                type="text"
                                placeholder="admin.organisation.com"
                                onChange={handleChange}
                                value={values.subDomainName}
                                errors={errors}
                                disabled
                            />
                        </div>

                        <Text align="none" weight="500" size="1.6rem" bottom="1.6rem">
                            Verification Records
                        </Text>
                        <Text align="none" weight="500" size="1.4rem" bottom="0.9rem">
                            TXT record on organisation.com
                        </Text>
                        <Text as="span" color="#6B7280" size="1.4rem" weight="500" lineHeight="2.8rem" bottom="0.9rem">
                            Add the following token as a TXT Name associated with organisation.com
                        </Text>
                        <div className="gap-5 flex items-center mb-5">
                            <TextInput
                                name="txtRecordName"
                                className="bg-[inherit]"
                                type="text"
                                placeholder="admin.organisation-verification"
                                onChange={handleChange}
                                value={values.txtRecordName}
                                errors={errors}
                                disabled
                            />
                            {copied.txtRecordName ? (
                                <Text as="span" color="#6B7280" size="1.4rem" weight="500">
                                    Copied!
                                </Text>
                            ) : (
                                <CopyIcon
                                    className="ml-[8px] cursor-pointer"
                                    onClick={() => {
                                        navigator.clipboard.writeText(values.txtRecordName);
                                        setCopied({ ...copied, txtRecordName: true });
                                    }}
                                />
                            )}
                        </div>
                        <Text as="span" color="#6B7280" size="1.4rem" weight="500" lineHeight="2.8rem" bottom="0.9rem">
                            Add the following token as a TXT content associated with organisation.com
                        </Text>

                        <div className="gap-5 flex items-center mb-5">
                            <TextInput
                                name="verificationCode"
                                className="bg-[inherit]"
                                type="text"
                                placeholder="admin.organisation-verification"
                                onChange={handleChange}
                                value={values.verificationCode}
                                errors={errors}
                                disabled
                            />
                            {copied.verificationCode ? (
                                <Text as="span" color="#6B7280" size="1.4rem" weight="500">
                                    Copied!
                                </Text>
                            ) : (
                                <CopyIcon
                                    className="ml-[8px] cursor-pointer"
                                    onClick={() => {
                                        navigator.clipboard.writeText(values.verificationCode);
                                        setCopied({ ...copied, verificationCode: true });
                                    }}
                                />
                            )}
                        </div>

                        <Text align="none" weight="500" size="1.6rem" bottom="0.9rem">
                            CNAME to phs.getbelrald.com
                        </Text>
                        <Text as="span" color="#6B7280" size="1.4rem" weight="500" lineHeight="2.8rem" bottom="0.9rem">
                            Add a CNAME record for organisation.com to phs.getbelrald.com
                        </Text>

                        <div className="gap-5 flex items-center mb-5">
                            <TextInput
                                name="input"
                                className="bg-[inherit]"
                                type="text"
                                placeholder="phs.getbelrald.com"
                                value={values.cName}
                                onChange={handleChange}
                                errors={errors}
                                disabled
                            />
                            {copied.cName ? (
                                <Text as="span" color="#6B7280" size="1.4rem" weight="500">
                                    Copied!
                                </Text>
                            ) : (
                                <CopyIcon
                                    className="ml-[8px] cursor-pointer"
                                    onClick={() => {
                                        navigator.clipboard.writeText(values.cName);
                                        setCopied({ ...copied, cName: true });
                                    }}
                                />
                            )}
                        </div>

                        <Text align="none" weight="500" size="1.6rem" bottom="0.9rem">
                            CVALUE to phs.getbelrald.com
                        </Text>
                        <Text as="span" color="#6B7280" size="1.4rem" weight="500" lineHeight="2.8rem" bottom="0.9rem">
                            Add a CVALUE record for organisation.com to phs.getbelrald.com
                        </Text>

                        <div className="gap-5 flex items-center mb-5">
                            <TextInput
                                name="input"
                                className="bg-[inherit]"
                                type="text"
                                placeholder="phs.getbelrald.com"
                                value={values.cValue}
                                onChange={handleChange}
                                errors={errors}
                                disabled
                            />
                            {copied.cValue ? (
                                <Text as="span" color="#6B7280" size="1.4rem" weight="500">
                                    Copied!
                                </Text>
                            ) : (
                                <CopyIcon
                                    className="ml-[8px] cursor-pointer"
                                    onClick={() => {
                                        navigator.clipboard.writeText(values.cValue);
                                        setCopied({ ...copied, cValue: true });
                                    }}
                                />
                            )}
                        </div>
                        <Text align="none" weight="600" size="2.3rem" top="4.8rem" bottom="4rem">
                            SSL Certificate Credentials
                        </Text>
                        <Text align="none" weight="500" size="1.6rem" bottom="1.6rem">
                            Verification Records
                        </Text>
                        <Text align="none" weight="500" size="1.6rem" bottom="0.9rem">
                            CNAME to phs.getbelrald.com
                        </Text>
                        <Text as="span" color="#6B7280" size="1.4rem" weight="500" lineHeight="2.8rem" bottom="0.9rem">
                            Add a CNAME record for organisation.com to phs.getbelrald.com
                        </Text>

                        <div className="gap-5 flex items-center mb-5">
                            <TextInput
                                name="input"
                                className="bg-[inherit]"
                                type="text"
                                placeholder="phs.getbelrald.com"
                                value={values.sslCName}
                                onChange={handleChange}
                                errors={errors}
                                disabled
                            />
                            {copied.sslCName ? (
                                <Text as="span" color="#6B7280" size="1.4rem" weight="500">
                                    Copied!
                                </Text>
                            ) : (
                                <CopyIcon
                                    className="ml-[8px] cursor-pointer"
                                    onClick={() => {
                                        navigator.clipboard.writeText(values.sslCName);
                                        setCopied({ ...copied, sslCName: true });
                                    }}
                                />
                            )}
                        </div>

                        <Text align="none" weight="500" size="1.6rem" bottom="0.9rem">
                            CVALUE to phs.getbelrald.com
                        </Text>
                        <Text as="span" color="#6B7280" size="1.4rem" weight="500" lineHeight="2.8rem" bottom="0.9rem">
                            Add a CVALUE record for organisation.com to phs.getbelrald.com
                        </Text>

                        <div className="gap-5 flex items-center mb-5">
                            <TextInput
                                name="input"
                                className="bg-[inherit]"
                                type="text"
                                placeholder="phs.getbelrald.com"
                                value={values.sslCValue}
                                onChange={handleChange}
                                errors={errors}
                                disabled
                            />
                            {copied.sslCValue ? (
                                <Text as="span" color="#6B7280" size="1.4rem" weight="500">
                                    Copied!
                                </Text>
                            ) : (
                                <CopyIcon
                                    className="ml-[8px] cursor-pointer"
                                    onClick={() => {
                                        navigator.clipboard.writeText(values.sslCValue);
                                        setCopied({ ...copied, sslCValue: true });
                                    }}
                                />
                            )}
                        </div>

                        <Text align="none" weight="700" size="1.9rem" bottom="0.9rem">
                            Record addition
                        </Text>
                        <FlexCentredRow>
                            <input
                                type="checkbox"
                                value={checked}
                                onChange={(e) => {
                                    setChecked(!checked);
                                }}
                                checked={checked}
                            />
                            <Text align="none" weight="700" size="1.5rem">
                                The TXT and CNAME records have been added.
                            </Text>
                        </FlexCentredRow>

                        <FlexRowEnd className="p-[1.6rem] gap-[2rem]">
                            <CancelButton onClick={() => navigate('/ict/settings/custom-domain')} type="button">
                                Verify Later
                            </CancelButton>
                            <LoadingButton
                                disabled={!checked}
                                loading={isLoading}
                                type="submit"
                                bgColor="#6366F1"
                                color="#FFF"
                            >
                                Verify Domain
                            </LoadingButton>
                        </FlexRowEnd>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default StudentProceed;
