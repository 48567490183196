import { createContext, useContext, useState } from 'react';

const TableContext = createContext(null);

export const TableProvider = ({ children }) => {
    const [tableSettings, setTableSettings] = useState({
        stripedRow: false,
        stickyColumns: null,
        numOfPages: 50,
        isWrapText: false,
    });

    const saveTableSettings = (value) => {
        setTableSettings(value);
    };

    const values = { saveTableSettings, tableSettings };
    return <TableContext.Provider value={values}>{children}</TableContext.Provider>;
};

export const useTableContext = () => useContext(TableContext);
