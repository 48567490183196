import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ListEmptyContent from '../../../components/list-empty';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getFaculties } from '../../../redux/faculty/actions';

const fields = [
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Faculty Name',
        accessorKey: 'facultyName',
    },
    {
        cell: (props) => <p>{props?.getValue()?.length || 0}</p>,
        header: 'Departments',
        accessorKey: 'departments',
    },
    {
        cell: (props) => <p>{props?.getValue()?.length || 0}</p>,
        header: 'Courses',
        accessorKey: 'courses',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Students',
        accessorKey: 'acceptedStudents',
    },
];

const Faculty = () => {
    const dispatch = useDispatch();
    const { isLoading, faculties } = useSelector((state) => state.faculty);

    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        dispatch(getFaculties());
    }, [dispatch]);

    return (
        <div>
            <PageLayout
                noMargin
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={faculties || []}
                fields={fields}
                loading={isLoading}
                noItemsViewSlot={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Faculty"></ListEmptyContent>
                    </CenteredContainer>
                }
                isActionable
            />
        </div>
    );
};

export default Faculty;
