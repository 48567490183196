import React, { useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down-ash.svg';
import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as ArrowUp } from '../../../../assets/icons/ChevronRight.svg';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow } from '../../../../containers/ScreenContainers';
import currencyFormatter from '../../../../utils/formatCurrency';
import Installment from '../tabs/Installment';
import NotPaidStudents from '../tabs/NotPaidStudents';
import PaidStudents from '../tabs/PaidStudents';

const FeeDetailsContainer = tw.div`
bg-white
p-[2.4rem]
rounded-[8px]
my-[2.4rem]
`;

const BursaryStudentDetails = () => {
    const navigate = useNavigate();
    const { currentFee, isLoading } = useSelector((store) => store.bursary);

    const { id } = useParams();
    const [paidStudents, setPaidStudents] = useState([]);
    const [unpaidStudents, setUnpaidStudents] = useState([]);
    const [installmentStudents, setInstallmentStudents] = useState([]);
    const [showBreakdown, setShowBreakdown] = useState(false);

    const generalFeeDetails = [
        {
            key: 'Type of Fee',
            value: currentFee?.fee?.feeType || '-',
        },
        {
            key: 'Span',
            value: currentFee?.fee?.allFees?.[0].span || '-',
        },
        {
            key: 'Payment Gateway',
            value: currentFee?.fee?.allFees?.[0].paymentConfig || '-',
        },
        {
            key: 'Target',
            value: currentFee?.fee?.target || '-',
        },
        {
            key: 'Session',
            value: currentFee?.fee?.session || '-',
        },
        {
            key: 'Amount Payable',
            value: currencyFormatter.format(currentFee?.fee?.allFees?.[0].amountPayable || 0),
        },
        {
            key: 'Deadline',
            value: currentFee?.fee?.allFees?.[0].deadline?.slice(0, 10) || '-',
        },
        {
            key: 'Payment Schedule',
            value: currentFee?.fee?.allFees?.[0].paymentSchedule || '-',
        },
    ];

    return (
        <>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[3.3rem] gap-[0.5rem]">
                <BackArrow className="" />

                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <PageTitle
                    as="span"
                    size="1.6rem"
                    weight="400"
                    color="#6366F1"
                    lineHeight="2.8rem"
                    className="mx-[1.4rem]"
                >
                    /
                </PageTitle>
                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    {currentFee?.fee?.feeType}
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                    /
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                    {currentFee?.fee?.allFees?.[0].feeName}
                </PageTitle>
            </FlexCentredRow>

            <FlexCentredCol>
                <PageTitle align="left">{currentFee?.fee?.allFees?.[0].feeName}</PageTitle>
            </FlexCentredCol>

            <FeeDetailsContainer>
                <PageTitle align="left" bottom="2.4rem">
                    Overview
                </PageTitle>
                <div className="grid grid-cols-4 gap-[2.4rem]">
                    {generalFeeDetails?.map((item) => (
                        <div className="">
                            <Text align="left" size="1.2rem" weight="500" color="#9CA3AF">
                                {item?.key}
                            </Text>
                            <Text align="left" size="1.4rem" weight="500" color="#4B5563">
                                {item?.value}
                            </Text>
                        </div>
                    ))}
                </div>
                <div className="">
                    {currentFee?.fee?.allFees?.[0].feeBreakdown?.length > 0 && (
                        <FlexCentredRow
                            className="pt-[3.2rem] gap-[1rem]"
                            onClick={() => setShowBreakdown(!showBreakdown)}
                        >
                            <Text size="1.6rem" weight="600" align="left">
                                Fee Breakdown
                            </Text>

                            <div className="">{showBreakdown ? <ArrowDown height={20} width={20} /> : <ArrowUp />}</div>
                        </FlexCentredRow>
                    )}
                    {showBreakdown && (
                        <div className="grid grid-cols-2 gap-[2.4rem]">
                            {currentFee?.fee?.allFees?.[0].feeBreakdown?.map((fee) => (
                                <div className="flex items-center gap-[15rem]">
                                    <Text align="left" size="1.4rem" weight="400" transform="capitalize">
                                        {fee?.description}
                                    </Text>
                                    <Text align="left" size="1.4rem" weight="400">
                                        {currencyFormatter.format(fee?.amount || 0)}
                                    </Text>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </FeeDetailsContainer>

            <CTabs>
                <CNav variant="tabs" className="">
                    <CNavItem>
                        <CNavLink>Not Paid Students</CNavLink>
                    </CNavItem>
                    {currentFee?.fee?.allFees?.[0].paymentSchedule?.toLowerCase() === 'one-off' ? (
                        ''
                    ) : (
                        <CNavItem>
                            <CNavLink>Installments</CNavLink>
                        </CNavItem>
                    )}
                    <CNavItem>
                        <CNavLink>Paid Students</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <NotPaidStudents
                            id={id}
                            setUnpaidStudents={setUnpaidStudents}
                            unpaidStudents={unpaidStudents}
                            loading={isLoading}
                            currentFee={currentFee}
                        />
                    </CTabPane>
                    {currentFee?.fee?.allFees?.[0].paymentSchedule?.toLowerCase() === 'one-off' ? null : (
                        <CTabPane>
                            <Installment
                                id={id}
                                setInstallmentStudents={setInstallmentStudents}
                                installment={installmentStudents}
                                loading={isLoading}
                                currentFee={currentFee}
                            />
                        </CTabPane>
                    )}
                    <CTabPane>
                        <PaidStudents
                            id={id}
                            setPaidStudents={setPaidStudents}
                            paidStudents={paidStudents}
                            loading={isLoading}
                            currentFee={currentFee}
                        />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </>
    );
};

export default BursaryStudentDetails;
