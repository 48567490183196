import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { Button } from '../../../../components/buttons';
import { Loader } from '../../../../components/loader';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { getSpecialLetter } from '../../../../redux/special-letter/actions';

const MainContainer = tw.div`
h-auto
bg-white
mx-auto
py-[2.4rem]
px-[3.2rem]
rounded-lg
`;

const View = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [specialLetter, setSpecialLetter] = useState({});
    const { isLoading } = useSelector((store) => store.specialLetter);



    useEffect(() => {
        const fetchSpecialLetter = async () => {
            const res = await dispatch(getSpecialLetter(id));
            setSpecialLetter(res);
        };

        fetchSpecialLetter();
    }, [dispatch, id]);

    if (isLoading || !specialLetter) return <Loader />;

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer mb-[1.4rem] gap-[8px]">
                <BackArrow />
                <PageTitle size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Special Letters
                </PageTitle>
                <Text>/</Text>
                <Text>{specialLetter.type}</Text>
            </FlexCentredRow>

            <FlexCentredRow className="justify-end mb-[4.6rem]">
                <Button
                    onClick={() => navigate(`/registry/special-letters/edit/${id}`)}
                    type="button"
                    bgColor="#6366F1"
                    color="#fff"
                >
                    Edit
                </Button>
            </FlexCentredRow>

            <MainContainer>
                <FlexRowSpaceBetween className="mb-[4.9rem]">
                    <PageTitle size="3.3rem" weight="600">
                        {specialLetter.type}
                    </PageTitle>

                    <Close onClick={() => navigate(-1)} />
                </FlexRowSpaceBetween>
                <div className="flex items-center mb-[2rem] gap-[8px]">
                    <Text align="left" size="1.9rem">
                        Dear{' '}
                    </Text>
                    <Text align="left" size="1.9rem" color="#6B7280">
                        [First Name & Surname],
                    </Text>
                </div>
                <Text align="left" size="1.6rem" lineHeight="2.4rem" color="#374151" bottom="5rem">
                    {specialLetter.content}
                </Text>
            </MainContainer>
        </div>
    );
};

export default View;
