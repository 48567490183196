import React, { useEffect, useState } from 'react';

import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import { useDispatch, useSelector } from 'react-redux';

import { ExportSheetDialogue } from '../../../../components';
import { FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { useTableContext } from '../../../../context/tableContext';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { getInstallmentPaid } from '../../../../redux/bursary/actions';
import currencyFormatter from '../../../../utils/formatCurrency';

const Installment = ({ installment, loading, currentFee, setInstallmentStudents, id }) => {
    const dispatch = useDispatch();
    const { installmentStudents: responseData } = useSelector((store) => store.bursary);
    const { totalPages, totalNoOfStudents } = responseData || {};
    const { tableSettings } = useTableContext();

    const currentFeeType = currentFee?.fee?.feeType;
    const numOfPages = tableSettings?.numOfPages;
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [showExportSheetDialogue, setShowExportSheetDialogue] = useState(false);
    const [currentPage, setCurrentPage] = useState('1');

    const fields = [
        {
            header: 'Names',
            cell: (props) => (
                <p>
                    {props?.row?.original?.lastName +
                        ' ' +
                        props?.row?.original?.firstName +
                        ' ' +
                        props?.row?.original?.middleName}
                </p>
            ),
            accessorKey: 'name',
        },
        {
            header: 'Matric No',
            accessorKey: 'matricNumber',
        },
        {
            header: 'Amount Paid',
            cell: (props) => <p>{currencyFormatter.format(props.getValue() || 0)}</p>,
            accessorKey: 'totalAmountPaid',
        },
        {
            header: '% Paid',
            accessorKey: 'percentage',
        },
        {
            header: 'Amount Payable',
            cell: (props) => <p>{currencyFormatter.format(props.getValue() || 0)}</p>,
            accessorKey: 'amountExpected',
        },
        {
            header: 'Installments',
            accessorKey: 'installment',
        },
        {
            header: 'Outstanding',
            cell: (props) => <p>{currencyFormatter.format(props.getValue() || 0)}</p>,
            accessorKey: 'amountOutstanding',
        },
    ];

    useEffect(() => {
        if (id) {
            const queries = {
                search: debouncedSearchValue,
                page: currentPage,
                limit: numOfPages,
            };
            const getInstallmentStudents = async () => {
                if (currentFeeType === 'Acceptance-Fee') return;
                const res = await dispatch(getInstallmentPaid(id, queries));
                setInstallmentStudents(res);
            };

            getInstallmentStudents();
        }
    }, [id, debouncedSearchValue, currentPage, numOfPages, currentFeeType, dispatch, setInstallmentStudents]);

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={installment || []}
                fields={fields}
                // onRowClick={(item) => {
                //     const selectedItem = installment?.find((inst) => inst?._id === item?._id);
                //     handleRowClick(selectedItem);
                // }}
                loading={loading}
                isPaginated={true}
                paginationComponent={
                    <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                        <PaginationStatus
                            currentPage={currentPage}
                            itemsPerPage={tableSettings?.numOfPages}
                            totalItems={totalNoOfStudents}
                        />
                        <Pagination
                            pageRangeDisplayed={3}
                            totalPages={totalPages}
                            onPageChange={(nextPage) => {
                                setCurrentPage(nextPage);
                            }}
                            currentPage={currentPage}
                        />
                    </FlexRowSpaceBetween>
                }
            />
            <ExportSheetDialogue
                show={showExportSheetDialogue}
                close={() => setShowExportSheetDialogue(false)}
                // chooseAction={} exportAction={}
            />
        </>
    );
};

export default Installment;
