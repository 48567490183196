import React, { useState, useEffect, useMemo } from 'react';

import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as AttachmentIcon } from '../../../../assets/icons/attachment.svg';
import { ReactComponent as ChangeIcon } from '../../../../assets/icons/change-icon.svg';
import { ReactComponent as Plus } from '../../../../assets/icons/circularplus-black.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-black.svg';
import { ReactComponent as ManageIcon } from '../../../../assets/icons/manage-icon.svg';
import { ActionsPopup, ConfirmActionDialogue } from '../../../../components';
import { Button, SecondaryButton } from '../../../../components/buttons';
import AddRoom from '../../../../components/forms/add-room';
import EditRoom from '../../../../components/forms/edit-room';
import { GoBack } from '../../../../components/go-back';
import ConfirmActionRadio from '../../../../components/popups/confirm-with-radio';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import {
    changeRoomStatus,
    deleteRoom,
    getAllRooms,
    getBlockById,
    getRoomById,
    manageRoomReservation,
} from '../../../../redux/hostel/action';
import { capitalizeFirstLetter } from '../../../../utils';
import { storeUnit } from '../../../../utils/originUnit';

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;
const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-[2.4rem]
    rounded-lg
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
`;

const statuses = ['Available', 'Not Available'];
const reservations = ['Reserve', 'Unreserve'];

const HostelRoom = ({ unitName }) => {
    const dispatch = useDispatch();

    const {
        state: { _id: id, blockName, hostelId, totalBedspaces, totalRooms, hostelName },
    } = useLocation();

    const { isLoading, allRooms, blockDetails } = useSelector((state) => state.hostel);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedReservation, setSelectedReservation] = useState('');

    useEffect(() => {
        if (id) {
            dispatch(getBlockById(id));
        }
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(getAllRooms(id));
    }, [dispatch, toggleRefetch, id]);

    const handleChangeStatus = async () => {
        if (!selectedStatus) {
            toast.error('Please select a status');
            return;
        }
        if (selectedStatus) {
            const res = await dispatch(changeRoomStatus(selectedRoom?._id));
            if (res) {
                toast.success('Room status changed successfully!');
                setToggleRefetch((prevState) => !prevState);
                setOpenModal(false);
                setSelectedStatus('');
                setInputValue('');
            }
        }
    };
    const handleManageReservation = async () => {
        if (selectedStatus) {
            const res = await dispatch(manageRoomReservation(selectedRoom?._id));
            if (res) {
                toast.success('Reservations changed successfully!');
                setToggleRefetch((prevState) => !prevState);
                setOpenModal(false);
                setSelectedReservation('');
            }
        }
    };

    useEffect(() => {
        if (!selectedRoom && !selectedRoom?._id) return;
        dispatch(getRoomById(selectedRoom?._id));
    }, [selectedRoom, dispatch]);

    const handleDelete = async () => {
        const res = await dispatch(deleteRoom(selectedRoom?._id));
        if (res) {
            toast.success('Room deleted successfully!');
            setToggleRefetch((prevState) => !prevState);
            setOpenModal(false);
        }
    };

    const handleEdit = () => {
        if (!selectedRoom?._id) return toast.error('Select a Room to edit!');
        setOpenModal('edit');
    };

    const handleCloseModal = (modal) => {
        if (openModal === modal) {
            setOpenModal(null);
        } else {
            setOpenModal(modal);
        }
    };

    const fields = [
        {
            cell: (props) => (
                <Link
                    to={`/student-affairs/accomodation-management/block/room/${props.row.original._id}`}
                    state={{
                        hostelName: hostelName,
                        hostelId: hostelId,
                        blockName: blockName,
                        gender: blockDetails?.gender,
                        _id: props.row.original._id,
                        totalRooms: props.row.original.totalRooms,
                        totalBedspaces: props.row.original.totalBedSpaces,
                    }}
                    style={{ color: '#2563EB', cursor: 'pointer' }}
                >
                    {props?.getValue()}
                </Link>
            ),
            header: 'Room Name',
            accessorKey: 'roomName',
        },

        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'No of Bedspaces',
            accessorKey: 'numberOfBedSpaces',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Floor Placement',
            accessorKey: 'floorPlacement',
        },
    ];

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.search = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        if (!id && id !== '') return;
        dispatch(getAllRooms(id, query));
    }, [id, dispatch, toggleRefetch, query]);

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return (
        <>
            <div>
                <GoBack title={`Accomodation Management / ${hostelName}`} subTitle={blockDetails?.blockName}></GoBack>
                <FlexRowSpaceBetween className="mt-[4.5rem]">
                    <PageTitle align="left">{blockDetails?.blockName}</PageTitle>
                    <FlexCentredRow className="gap-x-4">
                        <SecondaryButton onClick={() => setOpenModal('add')}>
                            <Plus className="mr-[1.315rem]" />
                            Add Room
                        </SecondaryButton>

                        <RelativeContainer>
                            <Button bgColor="#6366F1" color="#fff" onClick={() => handleCloseModal('actions')}>
                                Actions
                                <Dropdown className="ml-[13.15px]" />
                            </Button>
                            <ActionsPopup
                                open={openModal === 'actions'}
                                close={() => setOpenModal(null)}
                                items={[
                                    {
                                        icon: <EditIcon />,
                                        name: 'Edit Room',
                                        disabled: !selectedRoom,
                                        click: () => handleEdit(),
                                    },

                                    {
                                        icon: <ChangeIcon />,
                                        name: 'Change Room Status',
                                        disabled: !selectedRoom,
                                        click: () => {
                                            setOpenModal('change');
                                        },
                                    },
                                    {
                                        icon: <ManageIcon />,
                                        name: 'Manage Room Reservation',
                                        disabled: !selectedRoom,
                                        click: () => {
                                            setOpenModal('manage');
                                        },
                                    },
                                    {
                                        icon: <DeleteIcon />,
                                        name: 'Delete Room',
                                        disabled: !selectedRoom,
                                        click: () => {
                                            setOpenModal('delete');
                                        },
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
                <Details className="mt-[3rem]">
                    <FlexCentredCol>
                        <PageTitle align="left" size="1.9rem" lineheight="2.8rem">
                            Overview
                        </PageTitle>
                        <Text align="left">{blockDetails?.blockDescription}</Text>
                    </FlexCentredCol>

                    <BasicContent>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Number of Floors</GreyText>
                                <DarkText>{blockDetails?.numberOfFloors}</DarkText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Number of Rooms</GreyText>
                                <DarkText>{totalRooms}</DarkText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Number of Bedspaces</GreyText>
                                <DarkText>{totalBedspaces}</DarkText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Gender</GreyText>
                                <DarkText>{capitalizeFirstLetter(blockDetails?.gender)}</DarkText>
                            </FlexCol>
                        </RowContainer>
                    </BasicContent>
                </Details>

                <PageLayout
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    searchValue={searchValue}
                    onSearchClose={() => setSearchValue('')}
                    showTableUtils
                    showFilter
                    data={allRooms || []}
                    fields={fields}
                    loading={isLoading}
                    width={'100%'}
                    isActionable
                    actionType="radio"
                    onSelectedItemsChange={(items) => setSelectedRoom(items[0])}
                    openFilterPop={openModal === 'options-popup-main'}
                    closeFilterPop={() => setOpenModal(null)}
                />
            </div>
            <AddRoom
                show={openModal === 'add'}
                setShow={setOpenModal}
                setToogleRefetch={() => setToggleRefetch((prevState) => !prevState)}
                hostelId={blockDetails?.hostelId}
            />
            <EditRoom
                item={selectedRoom}
                show={openModal === 'edit'}
                setSelected={setSelectedRoom}
                setShow={setOpenModal}
                setToogleRefetch={() => setToggleRefetch((prevState) => !prevState)}
            />

            <ConfirmActionDialogue
                show={openModal === 'delete'}
                title="Delete room?"
                isLoading={isLoading}
                subtitle="Are you sure you want to remove this room?"
                close={() => setOpenModal(null)}
                confirmAction={() => handleDelete()}
            />

            <ConfirmActionRadio
                show={openModal === 'change'}
                title="Change Room Status"
                placeholder="Input reason"
                svgIcon={<ChangeIcon fill="#6366F1" />}
                close={() => setOpenModal(null)}
                statuses={statuses}
                confirmAction={handleChangeStatus}
                btn2Text="Save Changes"
                setInputValue={setInputValue}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                value={inputValue}
                input
                label="Reason for the change (Optional)"
                isLoading={isLoading}
                btnDisabled={!selectedStatus}
            />
            <ConfirmActionRadio
                show={openModal === 'manage'}
                title="Manage Room Reservation"
                svgIcon={<AttachmentIcon />}
                close={() => setOpenModal(null)}
                statuses={reservations}
                confirmAction={handleManageReservation}
                btn2Text="Save Changes"
                selectedStatus={selectedReservation}
                setSelectedStatus={setSelectedReservation}
                isLoading={isLoading}
                btnDisabled={!selectedReservation}
            />
        </>
    );
};
export default HostelRoom;
