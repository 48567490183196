import React from 'react';

import { Table } from '@belrald_hq/belrald-ui';

import { Text } from '../../../../../containers/MesssageContainers';
import { FlexColumn } from '../../../../../containers/ScreenContainers';

const fields = [
    {
        header: 'Name  of Fee',
        accessorKey: 'feeName',
    },
    {
        header: 'Amount',
        accessorKey: 'amount',
    },
    {
        header: 'Fee Type',
        accessorKey: 'feeType',
    },
];

const AttachedFees = () => {
    return (
        <div className="bg-white w-full py-[1.4rem] px-[1.6rem] rounded-lg h-[28rem] overflow-y-auto">
            <FlexColumn className="gap-[2.4rem]">
                <Text align="left" size="1.4rem" color="#6B7280" weight="500">
                    Attached Fees
                </Text>
                <Table width={'100%'} columns={fields} data={[]} />
            </FlexColumn>
        </div>
    );
};

export default AttachedFees;
