import React from 'react';

import { TextInput } from '@belrald_hq/belrald-ui';
import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg';
import { Select } from '../../../../components';
import { Button, LoadingButton } from '../../../../components/buttons';
import { Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowEnd,
    FlexRowSpaceBetween,
} from '../../../../containers/ScreenContainers';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 45.8rem;
        max-height: 60rem;
        margin: auto;
    }
`;

const ItemsContainer = tw.ul`
    overflow-y-auto
    border-b
    pb-2
    mt-5
    // flex
    // gap-4
`;

const AttachStudentToFee = ({
    show,
    close,
    list,
    onConfirm,
    subtitle,
    title,
    nameField = 'name',
    matricField = 'matricNumber',
    idField = 'id',
    subtitle2,
    value,
    setInputValue,
    isLoading,
    btnText,
    placeholder,
    bgColor,
    icon,
    disabled,
    acceptedStudents,
    setSelectedStudents,
    selectedStudents,
    isSelect = true,
}) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween>
                <FlexCentredRow className="gap-2">
                    {icon && icon}
                    <Text as="h3" size="1.9rem" weight="600">
                        {title}
                    </Text>
                </FlexCentredRow>
                <CloseIcon className="hover:cursor-pointer" onClick={close} />
            </FlexRowSpaceBetween>
            <Text align="start" size="1.6rem" color="#374151" top="1.6rem" lineHeight="2.4rem" bottom="1rem">
                {subtitle}
            </Text>

            {isSelect && (
                <div className="">
                    <FlexCentredCol>
                        <Select
                            searchable
                            label="Search For Students"
                            name="search"
                            multiSelect={true}
                            objProp="fullName"
                            data={acceptedStudents.map((item) => ({
                                ...item,
                                fullName: `${item.matricNumber ? item.matricNumber : ''} (${item.lastName} ${
                                    item.firstName
                                })`,
                                name: `${item.lastName} ${item.firstName} ${item.middleName}`,
                            }))}
                            placeholder="Select For Student(s) Here"
                            onChange={(selected) => {
                                setSelectedStudents(selected);
                            }}
                            showValues={false}
                            useComponentState={false}
                            passedSelectedItems={selectedStudents}
                        />
                    </FlexCentredCol>
                </div>
            )}

            <ItemsContainer>
                {list?.map((item, index) => (
                    <FlexRowSpaceBetween>
                        <Text align="left" key={item?.[idField] || index}>{`${item?.[nameField]}`}</Text>
                        <FlexCentredRow className="gap-[3.6rem]">
                            <Text align="left" key={item?.[idField] || index}>{`${item?.[matricField]}`}</Text>
                            {setSelectedStudents && (
                                <CloseIcon
                                    className="cursor-pointer"
                                    onClick={() => {
                                        setSelectedStudents((prev) => prev?.filter((sele) => sele?._id !== item?._id));
                                    }}
                                />
                            )}
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>
                ))}
            </ItemsContainer>
            <Text color="#6B7280" size="1.2rem" align="left">
                {list?.length} name(s) selected
            </Text>
            <div className="flex flex-col mt-[1.6rem] gap-[3.2rem]">
                <Text size="1.6rem" color="#374151" align="start">
                    {subtitle2}
                </Text>

                <TextInput
                    type="text"
                    placeholder={placeholder}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={value}
                    required
                />
            </div>
            <FlexRowEnd className="gap-x-[16px] mt-[1.5rem]">
                <Button type="button" onClick={close} border="0.5px solid #D1D5DB">
                    Cancel
                </Button>
                <LoadingButton bgColor={bgColor} loading={isLoading} onClick={onConfirm} disabled={disabled}>
                    {btnText}
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default AttachStudentToFee;
