import React, { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ChooseColumnsToExport, ExportSheetDialogue } from '../../components';
import ListEmptyContent from '../../components/list-empty';
import { getStatusStyles, StatusContainer } from '../../components/shared';
import UploadFile from '../../components/upload-file';
import { CenteredContainer } from '../../containers/ScreenContainers';
import useDebounce from '../../hooks/useDebounce';
import PageLayout from '../../layout/page-layout/PageLayout';
import { getSavedAcademicStructure } from '../../redux/academic-structure/actions';
import { getAdmissionSessions } from '../../redux/admissions/actions';
import { getStudents } from '../../redux/students/actions';
import { storeUnit } from '../../utils/originUnit';

const Records = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.student.isLoading);
    const { firstDegreeOptionsStr, secondDegreeOptionsStr } = useSelector((state) => state.academicStructure);

    const { students = [] } = useSelector((store) => store.student);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [showExportSheetDialogue, setShowExportSheetDialogue] = useState(false);
    const [showChooseColumns, setShowChooseColumns] = useState(false);

    const acceptedStudents = useMemo(() => students?.filter((student) => student?.status === 'Accepted'), [students]);

    const query = useMemo(() => {
        let queryParams = {};
        if (debouncedSearchValue) {
            queryParams.studentSearch = debouncedSearchValue;
        }
        return queryParams;
    }, [debouncedSearchValue]);

    const mapStudent = useMemo(() => {
        return acceptedStudents?.map((stud) => {
            const firstLevel = stud?.academicStructure?.split('-')[0];
            const secondLevel = stud?.academicStructure?.split('-')[1];

            const firstDegree = stud[firstLevel];
            const secondDegree = stud[secondLevel];

            return { ...stud, secondDegree: secondDegree, firstDegree: firstDegree };
        });
    }, [acceptedStudents]);

    const fields = [
        {
            header: 'Full Name',
            accessorKey: 'name',
            cell: ({ row }) => (
                <span style={{ color: '#2563EB' }} className="cursor-pointer">
                    {row.original?.lastName} {row.original?.firstName} {row.original?.middleName || ''}
                </span>
            ),
        },
        {
            header: 'Matric Number',
            accessorKey: 'matricNumber',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: firstDegreeOptionsStr,
            accessorKey: 'firstDegree',
        },
        {
            header: secondDegreeOptionsStr,
            accessorKey: 'secondDegree',
        },
        {
            header: 'Level',
            accessorKey: 'level',
        },
        {
            header: 'Created Date',
            accessorKey: 'admissionYear',
        },
        {
            header: 'Session',
            accessorKey: 'admissionSession',
        },
        {
            header: 'Status',
            accessorKey: 'status',
            cell: (props) => (
                <StatusContainer style={getStatusStyles(props.getValue()?.toLowerCase())}>
                    <span>{props.getValue()}</span>
                </StatusContainer>
            ),
        },
    ];

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    useEffect(() => {
        dispatch(getStudents(query));
    }, [dispatch, query]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    return (
        <>
            <PageLayout
                pageTitle="Records"
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={mapStudent || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Records"></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                onRowClick={(item) => navigate(`${item?._id}`)}
            />

            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
            <ExportSheetDialogue
                show={showExportSheetDialogue}
                close={() => setShowExportSheetDialogue(false)}
                chooseAction={() => {
                    setShowChooseColumns(true);
                    setShowExportSheetDialogue(false);
                }}
                // exportAction={}
            />
            <ChooseColumnsToExport
                show={showChooseColumns}
                close={() => setShowChooseColumns(false)}
                // exportAction={}
            />
        </>
    );
};

export default Records;
