import React from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as HeaderIcon } from '../../../assets/icons/circularplus-blue.svg';
import { createPaymentSubGroup } from '../../../redux/bursary/actions';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end

`;

const staffTypes = ['Academics', 'Non-Academics', 'Any'];

const AddStep = ({ show, onClose, onFinish, id }) => {
   
    const dispatch = useDispatch();
   
    const { isLoading } = useSelector((store) => store.bursary);

    return (
        <>
            <FormContainer headerIcon={<HeaderIcon />} close={onClose} show={show} title={'Add Step'}>
                <Formik
                    initialValues={{
                        stepName: '',
                        staffType: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const res = await dispatch(createPaymentSubGroup(values, id));
                        if (res) {
                            actions.resetForm();
                            onClose();
                            onFinish()
                            toast.success('Step was added successfully!');
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <div className="gap-10 flex flex-col">
                                <TextInput
                                    name="stepName"
                                    type="text"
                                    label="Step Name"
                                    placeholder="Enter step Name"
                                    value={values.stepName}
                                    onChange={handleChange}
                                    errors={errors}
                                />

                                <Select
                                    name="staffType"
                                    objProp="staffType"
                                    label="Staff Type"
                                    data={staffTypes.map((item) => ({ staffType: item }))}
                                    placeholder="Select type"
                                    onChange={(selected) => {
                                        setFieldValue('staffType', String(selected[0].staffType));
                                    }}
                                    error={errors.staffType}
                                    passedSelectedItems={values.staffType}
                                />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} disabled={isLoading} type="submit">
                                    Add Step
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default AddStep;
