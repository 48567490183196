import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    allCampus: [],
    organizationProfile: {},
    organizationStaffs: [],
    archiveStaffs: [],
};

export const campusSlice = createSlice({
    name: 'campus',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ALL_CAMPUS: (state, action) => {
            state.allCampus = action.payload;
        },
        GET_ORGANIZATION_PROFILE: (state, action) => {
            state.organizationProfile = action.payload;
        },
        GET_ORGANIZATION_STAFFS: (state, action) => {
            state.organizationStaffs = action.payload;
        },
        GET_ARCHIVE_STAFFS: (state, action) => {
            state.archiveStaffs = action.payload;
        },
    },
});

export const {
    LOADING,
    STOP_LOADING,
    GET_ALL_CAMPUS,
    GET_ORGANIZATION_PROFILE,
    GET_ORGANIZATION_STAFFS,
    GET_ARCHIVE_STAFFS,
} = campusSlice.actions;

export default campusSlice.reducer;
