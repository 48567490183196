import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';
import { toast } from 'react-hot-toast';
import styled from 'styled-components';

import { Text } from '../containers/MesssageContainers';

export const range = (start, end, step = 1) => {
    let output = [];
    if (typeof end === 'undefined') {
        end = start;
        start = 1;
    }
    for (let i = start; i <= end; i += step) {
        output.push(i);
    }
    return output;
};

export const generateLevels = (duration) => {
    const arr = [];

    for (let i = 1; i <= duration; i++) {
        arr.push(i * 100);
    }
    return arr;
};

export const getUniqueSortedYearsFromRanges = (array, propName = 'admissionYear') => {
    const result = [];
    array?.forEach((item) => {
        const [start, end] = typeof item !== 'object' ? item.split('-') : item[propName].split('-');
        result.push(start, end);
    });
    return [...new Set(result)].sort();
};

export const generateWeeklyEvents = (data, weekCount = 13) => {
    const events = [];
    const startDate = new Date(data.startTime);
    const endDate = new Date(data.endTime);

    const startHours = startDate.getHours();
    const startMinutes = startDate.getMinutes();
    const endHours = endDate.getHours();
    const endMinutes = endDate.getMinutes();

    const weekdayNumber = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(
        data.weekday,
    );

    const currentDate = new Date(startDate);
    for (let i = 0; i < weekCount; i++) {
        const weekday = currentDate.getDay();
        const daysUntilWeekday = (weekdayNumber + 7 - weekday) % 7;
        const date = new Date(currentDate.getTime());
        date.setDate(date.getDate() + daysUntilWeekday);
        if (date) {
            const startTime = new Date(date);
            startTime.setHours(startHours, startMinutes, 0);
            const endTime = new Date(date);
            endTime.setHours(endHours, endMinutes, 0);
            const event = {
                title: data.course,
                start: new Date(startTime),
                end: new Date(endTime),
            };
            events.push(event);
        }
        currentDate.setDate(currentDate.getDate() + 7);
    }

    return events;
};

export const formatDate = (givenDate) => {
    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - new Date(givenDate).getTime();

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days === 0) {
        return new Date(givenDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
        return new Date(givenDate).toLocaleDateString([], { month: 'short', day: 'numeric' });
    }
};

export const addLabelsToData = (data, unitName) => {
    const combinedArray = [];

    const addLabel = (dataArray, label) => {
        dataArray?.forEach((item) => {
            const itemWithLabel = { ...item, label };
            combinedArray.push(itemWithLabel);
        });
    };

    if (unitName === 'Registry') {
        addLabel(data?.bulletins, 'Bulletin');
    }

    addLabel(data?.mails, 'Mail');
    addLabel(data?.externalMemos, 'External Memo');
    addLabel(data?.internalMemos, 'Internal Memo');
    addLabel(data?.notices, 'Notice');
    addLabel(data?.letters, 'Letter');
    addLabel(data?.memos, 'Memo');

    return combinedArray;
};

export const getStatusBadge = (status) => {
    let bgColor, textColor, statusText;

    switch (status) {
        case 'Draft':
            bgColor = '#EFF6FF';
            textColor = '#3B82F6';
            statusText = status;
            break;
        case 'Published':
            bgColor = '#ECFDF5';
            textColor = '#10B981';
            statusText = status;
            break;
        case 'Unpublished':
            bgColor = '#FFE5E5';
            textColor = '#FF0000';
            statusText = status;
            break;
        // case 'Mail':
        //     bgColor = '#EFF6FF';
        //     textColor = '#3B82F6';
        //     labelText = label;
        //     break;
        // case 'External Memo':
        //     bgColor = '#F5F3FF';
        //     textColor = '#EB4898';
        //     labelText = label;
        //     break;
        // // case 'Internal Memo':
        // //     bgColor = '#EFF6FF';
        // //     textColor = '#3B82F6';
        // //     labelText = label;
        // //     break;
        // case 'Letter':
        //     bgColor = '#F5F3FF';
        //     textColor = '#EB4898';
        //     labelText = label;
        //     break;
        default:
            bgColor = '#F5F3FF';
            textColor = '#8B5CF6';
            statusText = status;
            break;
    }

    const StatusContainer = styled.div`
        padding: 2px 4px;
        border-radius: 4px;
        background-color: ${({ bgColor }) => bgColor || '#FEF2F2'};
        width: fit-content;
    `;

    return (
        <StatusContainer bgColor={bgColor}>
            <Text color={textColor} weight="500" size="1.2rem">
                {statusText}
            </Text>
        </StatusContainer>
    );
};

export const getNumOfWeeksBetweeenDates = (from, to) => {
    if (!from || !to) return;
    const fromDate = new Date(from);
    const toDate = new Date(to);

    return differenceInCalendarWeeks(toDate, fromDate);
};

export const arrayToObject = (array) => {
    return array.reduce((result, item) => {
        const { key, value } = item;

        if (value !== undefined && value !== '') {
            result[key] = value;
        }

        return result;
    }, {});
};

export const objectToArray = (obj) => {
    return Object.entries(obj)
        ?.filter(([key, value]) => value !== undefined && value)
        ?.map(([key, value]) => ({ key, value }));
};

export const sortData = (newData = []) => {
    const sortedData = [...newData];
    sortedData?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return sortedData;
};

export const encodeQuery = (queryObj) => {
    const encodedQuery = Object.entries(queryObj).map(([key, value]) => {
        if (!value) return '';
        return encodeURIComponent(key) + '=' + encodeURIComponent(value.toString());
    });
    return encodedQuery.join('&');
};

export const capitalizeEachWord = (sentence = '') => {
    let words = sentence.toLowerCase().split(' ');
    let capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
};

export const capitalizeFirstLetter = (str = '') => {
    // e.g 'let me leave' => Let Me Leave OR LET => Let
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};

export const transformThirdDegreeData = (data = []) => {
    const newData = data?.map((item) => {
        if (!item) return null;

        const { academicStructure } = item || {};
        const structThree = academicStructure?.split('-')[2];

        const structMapping = {
            department: ['department', 'departmentCode', 'departmentNumberingCode'],
            program: ['subprogram', 'subProgramCode', 'subProgramNumberingCode'],
        };

        const fields = structMapping[structThree?.toLowerCase()];

        if (!fields) {
            return item;
        }

        const newItem = {
            ...item,
            subprogram: item[fields[0]],
            abbreviation: item[fields[1]]?.join('') || '',
            numberingCode: item[fields[2]] || '',
        };

        return newItem;
    });

    return newData;
};

export const getDegreeStructs = (academicStructure = '') => {
    const splitStruct = academicStructure?.split('-');

    const firstDegreeStruct = splitStruct?.[0];
    const secondDegreeStruct = splitStruct?.[1];
    const thirdDegreeStruct = splitStruct?.[2] || '';

    return { firstDegreeStruct, secondDegreeStruct, thirdDegreeStruct };
};

export const getDegreeInfo = (details, structureField = 'structure') => {
    const academicStructure = details?.[structureField];
    const { firstDegreeStruct, secondDegreeStruct, thirdDegreeStruct } = getDegreeStructs(academicStructure);

    const firstDegree = details?.[firstDegreeStruct] || '';
    const secondDegree =
        secondDegreeStruct === 'program'
            ? details?.programme || details?.subProgram || details?.subProgran || ''
            : details?.[secondDegreeStruct] || '';
    const thirdDegree = details?.[thirdDegreeStruct] || '';

    return { firstDegree, secondDegree, thirdDegree };
};

export const handleImagePreview = (url) => {
    if (url) {
        const newWindow = window.open();
        if (newWindow) {
            newWindow.document.write(`
                <html>
                    <head>
                        <title>Image Preview</title>
                        <style>
                            body { margin: 0; display: flex; justify-content: center; align-items: center; height: 100vh; }
                            img { max-width: 100%; max-height: 100%; }
                        </style>
                    </head>
                    <body>
                        <img src="${url}" alt="Preview"/>
                    </body>
                </html>
            `);
            newWindow.document.close();
        }
    } else {
        toast.error('Failed to open preview.');
    }
};
export const handlePdfPreview = async (url) => {
    try {
        const response = await fetch(url);
        const data = await response.blob();
        const base64Data = await convertBlobToBase64(data);
        const newWindow = window.open();
        if (newWindow) {
            newWindow.document.write(`
                <html>
                    <head>
                        <title>PDF Preview</title>
                        <style>
                            body {
                                margin: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 100vh;
                                background-color: #f0f2f5;
                            }
                            .pdf-container {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            .pdf-document {
                                width: 100%;
                                height: 100%;
                                border: none;
                            }
                        </style>
                    </head>
                    <body>
                        <div class="pdf-container">
                            <iframe class="pdf-document" src="data:application/pdf;base64,${base64Data}" type="application/pdf"></iframe>
                        </div>
                    </body>
                </html>
            `);
            newWindow.document.close();
        } else {
            alert('Please allow popups for this website');
        }
    } catch (error) {
        console.error('Error opening PDF in new tab:', error);
    }
};

const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

export const handleDownload = async (url, fileName) => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(objectUrl);
        toast.success('Download successful!');
    } catch (error) {
        toast.error(`Error occurred during download: ${error.message}`);
    }
};

export const splitLettersAndNumbers = (str) => {
    // GNS103 => GNS 103
    return str.replace(/([a-zA-Z])(\d)/g, '$1 $2').replace(/(\d)([a-zA-Z])/g, '$1 $2');
};
