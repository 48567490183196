import React, { useEffect, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Delete } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as Edit } from '../../../assets/icons/edit3.svg';
import { ConfirmActionDialogue } from '../../../components';
import ListEmptyContent from '../../../components/list-empty';
import { CenteredContainer } from '../../../containers/ScreenContainers';
// import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { deleteCourse } from '../../../redux/courses/actions';
import { getGeneralCoursesInFirstDegree } from '../../../redux/faculty/actions';

const fields = [
    {
        header: 'Course',
        accessorKey: 'courseTitle',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Course Code',
        accessorKey: 'courseCode',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Semester',
        accessorKey: 'courseSemester',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Course Unit',
        accessorKey: 'courseUnit',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Minimum Pass Mark(%)',
        accessorKey: 'minimumPassScore',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
    {
        header: 'Level',
        accessorKey: 'courseLevel',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
];

const CONFIRM_TEXT = 'delete';

const FacultyCourses = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const { isLoading, courses, currentFaculty } = useSelector((state) => state.faculty);
    const {
        isLoading,
        currentFaculty,
        generalCourses: firstDegreeGeneralCourses,
    } = useSelector((state) => state.faculty);
    const { _id: facultyId, academicStructureId } = currentFaculty;

    const [searchValue, setSearchValue] = useState('');
    // const debouncedSearchValue = useDebounce(searchValue, 700);
    const [selectedCourse, setSelectedCourse] = useState({});
    const [confirmDeleteInput, setConfirmDeleteInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    // const handleNoActionFor2ndDegreeCourse = (item, action) => {
    //     const structMapping = {
    //         program: 'subProgramId',
    //     };
    //     const { academicStructure } = item;
    //     const splitStructTwo = academicStructure.split('-')[1];

    //     if (item[structMapping[splitStructTwo]]) {
    //         toast.error(`You can only ${action} general courses on this page`);
    //         return true;
    //     }
    // };

    const handleDelete = async () => {
        setLoading(true);
        if (CONFIRM_TEXT.toLowerCase() !== confirmDeleteInput.toLowerCase()) {
            toast.error(`Input ${CONFIRM_TEXT}`);
        } else {
            const res = await dispatch(deleteCourse(selectedCourse._id));
            if (res) {
                toast.success('Course deleted successfully');
                dispatch(getGeneralCoursesInFirstDegree(academicStructureId, facultyId));
                setSelectedCourse({});
                setShowDelete(false);
            }
        }
        setLoading(false);
    };

    // const query = useMemo(() => {
    //     let queryParams = {};

    //     if (debouncedSearchValue) {
    //         queryParams.searchParam = debouncedSearchValue;
    //     }

    //     return queryParams;
    // }, [debouncedSearchValue]);

    useEffect(() => {
        if (facultyId) {
            // dispatch(getFacultyCourses(academicStructureId, facultyId, query));
            dispatch(getGeneralCoursesInFirstDegree(academicStructureId, facultyId));
        }
    }, [dispatch, academicStructureId, facultyId]);

    return (
        <>
            <div>
                <PageLayout
                    pageTitle={currentFaculty?.facultyName}
                    secondaryButtonText="Add General Course"
                    onSecondaryButtonClick={() => navigate('add-new')}
                    fields={fields}
                    data={firstDegreeGeneralCourses}
                    isActionable
                    actionType="radio"
                    onSelectedItemsChange={(items) => setSelectedCourse(items[0])}
                    onActionBtnClick={() => {
                        setDropdownOpen(!dropdownOpen);
                    }}
                    actionItems={[
                        {
                            icon: <Edit />,
                            name: 'Edit General Course',
                            disabled: !selectedCourse?._id,
                            click: () => {
                                navigate(`edit-course/${selectedCourse?._id}`, {
                                    state: { course: selectedCourse },
                                });
                            },
                        },
                        {
                            icon: <Delete />,
                            name: 'Delete General Course',
                            disabled: !selectedCourse?._id,
                            click: () => {
                                setShowDelete(true);
                                setDropdownOpen(false);
                            },
                        },
                    ]}
                    actionOpen={dropdownOpen}
                    actionClose={() => setDropdownOpen(false)}
                    searchable
                    searchValue={searchValue}
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    onSearchClose={() => setSearchValue('')}
                    noItemView={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent
                                isInTable
                                title="No Courses"
                                // subtitle="Course(s) will be added at the departmental level"
                            />
                        </CenteredContainer>
                    }
                    loading={isLoading}
                />
            </div>

            <ConfirmActionDialogue
                show={showDelete}
                close={() => setShowDelete(false)}
                title={`Delete ${selectedCourse?.courseTitle || 'Course'}?`}
                subtitle="This course will be deleted."
                borderLine
                bodyText="To confirm, enter 'delete' in the text field."
                input
                placeholder="Input delete"
                value={confirmDeleteInput}
                onChange={(e) => setConfirmDeleteInput(e.target.value)}
                btn2Text="Yes, Delete"
                confirmAction={handleDelete}
                isLoading={loading}
                btnDisabled={!confirmDeleteInput}
            />
        </>
    );
};

export default FacultyCourses;
