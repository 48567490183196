import React from 'react';

import { Table } from '@belrald_hq/belrald-ui';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';

const fields = [
    {
        header: 'Date',
        accessorKey: 'date',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Activity',
        accessorKey: 'activity',
    },
];

const BillingPaymentHistory = () => {
    const navigate = useNavigate();

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[8px] pb-[3.6rem]">
                <BackArrow className="mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    /
                </Text>
                <Text size="1.6rem" color="#6366F1" weight="400">
                    Billing
                </Text>
                <Text size="1.6rem">/</Text>
                <Text size="1.6rem">View Payment History</Text>
            </FlexCentredRow>
            <div className="p-[2.4rem] bg-white rounded-[8px]">
                <Text align="left" size="2.3rem" weight="600" color="#6B7280">
                    Payment History
                </Text>
                <Table data={[]} columns={fields} />
            </div>
        </div>
    );
};

export default BillingPaymentHistory;
