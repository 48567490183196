import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { PageTitle } from '../../containers/MesssageContainers';

// import ActivityGraph from './Activity-Graph';
// import CourseDetails from './CourseDetails';
// import DashboardStaff from './DashboardStaff';
// import DashboardStudents from './DashboardStudents';
// import NewlyAdmitted from './Newly-Admitted';
import OrganisationUnits from './OrganisationUnit';
// import OtherDetails from './OtherDetails';
// import ProfileStatus from './ProfileStatus';
import StaffArena from './Staff-Arena';

const Dashboard = () => {
    const { adminData, staffData } = useSelector((state) => state.user);

    const { designation = '', surname = '', firstName = '', staffEmail = '', profile = {} } = staffData || {};

    const fullName = adminData?.firstName
        ? `${adminData?.firstName} ${adminData?.lastName}`
        : `${surname} ${firstName}`;

    useEffect(() => {
        sessionStorage.removeItem('activeTab');
    }, []);

    return (
        <>
            <PageTitle align="left">Dashboard</PageTitle>
            <div className="flex flex-col gap-8">
                <div className=" mt-5 grid grid-cols-[2fr_1fr] gap-8">
                    <OrganisationUnits />
                    {staffEmail && (
                        <StaffArena
                            staffName={fullName}
                            staffAppointment={designation}
                            staffEmail={staffEmail}
                            staffImage={profile?.url}
                        />
                    )}
                </div>
                {/* <div className="grid grid-cols-[2fr_1fr] gap-8">
                    <div className="grid grid-cols-2 gap-x-8 gap-y-8">
                        <ActivityGraph />
                        <NewlyAdmitted />
                        <DashboardStaff />
                        <DashboardStudents />
                    </div>
                    <ProfileStatus />
                </div>
                <div className="grid grid-cols-[1fr_2fr] gap-8">
                    <OtherDetails />
                    <CourseDetails />
                </div> */}
            </div>
        </>
    );
};

export default Dashboard;
