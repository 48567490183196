import React from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';
import TextAreaInput from '../../inputs/text-area';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const ConfirmActionRadio = ({
    show,
    close,
    svgIcon,
    title,
    confirmAction,
    btn2Text,
    input,
    placeholder,
    bgColor,
    value,
    label,
    setInputValue,
    isLoading,
    withIcon = true,
    statuses,
    btnDisabled,
    selectedStatus,
    setSelectedStatus,
}) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween>
                <FlexCentredRow className="gap-[1.215rem]">
                    {withIcon ? svgIcon ? svgIcon : <WarningIcon fill="#EF4444" /> : null}
                    <Text as="h3" size="1.9rem" weight="600">
                        {title}
                    </Text>
                </FlexCentredRow>
                <CloseIcon onClick={close} className="cursor-pointer" />
            </FlexRowSpaceBetween>
            <div className="space-y-2 mb-[3rem]">
                {statuses.map((status) => (
                    <FlexCentredCol key={status} className="mt-[3rem]">
                        <FlexCentredRow>
                            <input
                                type="radio"
                                name="status"
                                value={status}
                                checked={selectedStatus === status}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            />
                            <Text className="ml-2">{status}</Text>
                        </FlexCentredRow>
                    </FlexCentredCol>
                ))}
            </div>
            {input && (
                <TextAreaInput
                    label={label}
                    type="text"
                    placeholder={placeholder}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={value}
                    height="7rem"
                />
            )}
            <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                <Button onClick={close} disabled={isLoading} border="0.5px solid #D1D5DB">
                    Cancel
                </Button>
                <LoadingButton
                    loading={isLoading}
                    disabled={btnDisabled || isLoading}
                    onClick={confirmAction}
                    bgColor={bgColor || '#6366F1'}
                    color="#fff"
                >
                    {btn2Text || 'Confirm'}
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default ConfirmActionRadio;
