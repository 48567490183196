import React from 'react';

import PaymentGatewayForm from '../../../../components/forms/payment-gateway-form';
import { GoBack } from '../../../../components/go-back';

const AddPaymentGateway = () => {
    return (
        <div>
            <GoBack title="Payment Gateway" subTitle="Add Gateway" />
            <PaymentGatewayForm action="add" />
        </div>
    );
};

export default AddPaymentGateway;
