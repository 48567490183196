import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    allDebtors: [],
    allFees: [],
    allReceivingAccounts: [],
    allBanks: [],
    feeDetails: [],
    paidStudents: [],
    unpaidStudents: [],
    installmentStudents: [],
    paymentHistory: [],
    studentPaymentHistory: [],
    currentFee: {},
    studentReceipt: {},
    fee: {},
    allDiscounts: [],
    discountDetails: [],
    allAgents: [],
    allAgentFee: [],
    paymentGroups: [],
    subPaymentGroups: [],
    groupDetails: {},
    stepDetails: {},
    staffInPaymentGroup:[],
    paymentGateway: {},
    agentDetails: {},
    feesAttached: [],
};

export const bursarySlice = createSlice({
    name: 'bursary',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_ALL_FEES: (state, action) => {
            state.allFees = action.payload;
        },
        GET_ALL_DEBTORS: (state, action) => {
            state.allDebtors = action.payload;
        },
        GET_ALL_RECEIVING_ACCOUNTS: (state, action) => {
            state.allReceivingAccounts = action.payload;
        },
        GET_ALL_BANKS: (state, action) => {
            state.allBanks = action.payload;
        },
        GET_FEE_DETAILS: (state, action) => {
            state.feeDetails = action.payload;
        },
        GET_PAID_DETAILS: (state, action) => {
            state.paidStudents = action.payload;
        },
        GET_UNPAID_DETAILS: (state, action) => {
            state.unpaidStudents = action.payload;
        },
        GET_INSTALLMENT_DETAILS: (state, action) => {
            state.installmentStudents = action.payload;
        },
        GET_PAYMENT_HISTORY: (state, action) => {
            state.paymentHistory = action.payload;
        },
        GET_STUDENT_PAYMENT_HISTORY: (state, action) => {
            state.studentPaymentHistory = action.payload;
        },
        GET_CURRENT_FEE: (state, action) => {
            state.currentFee = action.payload;
        },
        GET_STUDENT_RECEIPT: (state, action) => {
            state.studentReceipt = action.payload;
        },
        GET_FEE: (state, action) => {
            state.fee = action.payload;
        },
        GET_ALL_DISCOUNTS: (state, action) => {
            state.allDiscounts = action.payload;
        },
        GET_DISCOUNT_DETAILS: (state, action) => {
            state.discountDetails = action.payload;
        },
        GET_ALL_AGENTS: (state, action) => {
            state.allAgents = action.payload;
        },
        GET_ALL_AGENT_FEES: (state, action) => {
            state.allAgentFee = action.payload;
        },
        GET_PAYMENT_GROUPS: (state, action) => {
            state.paymentGroups = action.payload
        }, 
        GET_SUB_GROUPS: (state, action) => {
            state.subPaymentGroups = action.payload
        },
        GET_GROUP_DETAILS: (state, action) => {
            state.groupDetails = action.payload
        }, 
        GET_SUBGROUP_DETAILS: (state, action) => {
            state.stepDetails = action.payload
        },
        GET_ALL_STAFF_PAYMENT_GROUP: (state, action) => {
            state.staffInPaymentGroup = action.payload
        },
        
        GET_PAYMENT_GATEWAY: (state, action) => {
            state.paymentGateway = action.payload;
        },
        GET_AGENT_DETAILS: (state, action) => {
            state.agentDetails = action.payload;
        },
        GET_FEES_ATTACHED: (state, action) => {
            state.feesAttached = action.payload;
        },
    },
});

export const {
    LOADING,
    STOP_LOADING,
    GET_ALL_FEES,
    GET_ALL_DEBTORS,
    GET_ALL_RECEIVING_ACCOUNTS,
    GET_ALL_BANKS,
    GET_FEE_DETAILS,
    GET_INSTALLMENT_DETAILS,
    GET_PAID_DETAILS,
    GET_UNPAID_DETAILS,
    GET_PAYMENT_HISTORY,
    GET_STUDENT_PAYMENT_HISTORY,
    GET_CURRENT_FEE,
    GET_STUDENT_RECEIPT,
    GET_FEE,
    GET_ALL_DISCOUNTS,
    GET_DISCOUNT_DETAILS,
    GET_ALL_AGENTS,
    GET_ALL_AGENT_FEES,
    GET_PAYMENT_GROUPS,
    GET_SUB_GROUPS, 
    GET_GROUP_DETAILS,
    GET_SUBGROUP_DETAILS, 
    GET_ALL_STAFF_PAYMENT_GROUP,
    GET_PAYMENT_GATEWAY,
    GET_AGENT_DETAILS,
    GET_FEES_ATTACHED,
} = bursarySlice.actions;

export default bursarySlice.reducer;
