import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import {
    GET_COURSES,
    LOADING,
    STOP_LOADING,
    GET_ALL_COURSES,
    GET_COURSES_IN_FIRST_DEGREE,
    // GET_COURSE
} from './slice';

// all courses in organization
export const getAllCourses = (semester) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}course/:campusId/:unitId/all_courses/?semester=${semester}`,
        );
        if (response.status === 200) {
            dispatch(GET_ALL_COURSES(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllCoursesInDepartment = (departmentId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}course/:campusId/:unitId/get_all_courses/${departmentId}`,
        );
        if (response.status === 200) {
            dispatch(GET_COURSES(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createCourse = (structureId, departmentId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}course/:campusId/:unitId/create_course/${structureId}/${departmentId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const allotCourse = (academicStructureId, session, courseId, payload) => async (dispatch, getState) => {
    // if (useLoading) {
    dispatch(LOADING());
    // }
    try {
        const response = await axiosInstance.put(
            `${currentAPI}course/:campusId/:unitId/allot_course_to_lecturer/${academicStructureId}/${session}/${courseId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const allotGeneralCourse = (academicStructureId, session, courseId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}course/:campusId/:unitId/allot_general_course_to_lecturer/${academicStructureId}/${session}/${courseId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const importAllotedLecturers = (academicStructureId, secondDegreeId, payload) => async (dispatch, getState) => {
    try {
        const response = await axiosInstance.post(
            `${currentAPI}course/:campusId/:unitId/import_courses_allocation/${academicStructureId}/${secondDegreeId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const importGeneralAllotedLecturers =
    (academicStructureId, firstDegreeId, payload) => async (dispatch, getState) => {
        try {
            const response = await axiosInstance.post(
                `${currentAPI}course/:campusId/:unitId/import_alloted_general_courses_lecturer/${academicStructureId}/${firstDegreeId}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const removeLecturerFromCourse =
    (session, courseId, payload, useLoading = true) =>
    async (dispatch, getState) => {
        if (useLoading) {
            dispatch(LOADING());
        }
        try {
            const response = await axiosInstance.put(
                `${currentAPI}course/:campusId/:unitId/disallot_lecturers_from_course/${session}/${courseId}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getAllotedLecturers =
    (session, courseId, useLoader = true) =>
    async (dispatch, getState) => {
        if (useLoader) {
            dispatch(LOADING());
        }
        try {
            const response = await axiosInstance.get(
                `${currentAPI}course/:campusId/:unitId/get_lecturer_alloted_to_course/${courseId}/${session}`,
            );
            if (response.status === 200) {
                const { data } = response?.data || {};
                return data;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getCourseAllocationHistory = (courseId) => async (dispatch, getState) => {
    dispatch(LOADING());

    try {
        const response = await axiosInstance.get(
            `${currentAPI}course/:campusId/:unitId/course_allocation_history/${courseId}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteCourse = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}course/:campusId/:unitId/delete_course/${id}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const editCourse = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}course/:campusId/:unitId/edit_course/${id}`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createFacultyCourse = (structureId, facultyId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}course/:campusId/:unitId/general_create_course/${structureId}/${facultyId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getCoursesInFirstDegree = (structureId, firstDegreeId, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}course/:campusId/:unitId/fetch_all_courses_first_degree_without_lecturer/${structureId}/${firstDegreeId}?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_COURSES_IN_FIRST_DEGREE(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
