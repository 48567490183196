import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { GET_APPOINTMENT_CATEGORIES, GET_APPOINTMENT_DETAILS, LOADING, STAFF_COUNT, STOP_LOADING } from './slice';

export const createAppointment = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.post(`${currentAPI}appointment/:campusId/:unitId`, payload);
        const successMessage = response?.data?.message || 'Appointment Created Successfully!.';
        toast.success(successMessage);
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAppointmentCatgories = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}appointment/:campusId/:unitId`);
        dispatch(GET_APPOINTMENT_CATEGORIES(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStaffCount = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.get(`${currentAPI}appointment/:campusId/:unitId/staff/count`);
        dispatch(STAFF_COUNT(response.data.data));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAppointment = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.get(`${currentAPI}appointment/:campusId/:unitId/${id}`);
        return response;
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addVc = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.post(
            `${currentAPI}appointment/:campusId/:unitId/vice-chancellor`,
            payload,
        );
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Vice Chancellor appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllVc = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.get(`${currentAPI}appointment/:campusId/:unitId/vice-chancellors/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeVc = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.delete(`${currentAPI}appointment/:campusId/:unitId/vice-chancellor/${id}`);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addDeputyVc = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.post(
            `${currentAPI}appointment/:campusId/:unitId/deputy-vice-chancellor`,
            payload,
        );
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Deputy Vice Chancellor appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeDVc = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.delete(
            `${currentAPI}appointment/:campusId/:unitId/deputy-vice-chancellor/${id}`,
        );
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllDVc = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.get(
            `${currentAPI}appointment/:campusId/:unitId/deputy-vice-chancellors/all`,
        );
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addRegistrar = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.post(`${currentAPI}appointment/:campusId/:unitId/registrar`, payload);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Registrar appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeRegistrar = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.delete(`${currentAPI}appointment/:campusId/:unitId/registrar/${id}`);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllRegistrars = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.get(`${currentAPI}appointment/:campusId/:unitId/registrars/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addBursar = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.post(`${currentAPI}appointment/:campusId/:unitId/bursar`, payload);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Bursar appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeBursar = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.delete(`${currentAPI}appointment/:campusId/:unitId/bursar/${id}`);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllBursars = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.get(`${currentAPI}appointment/:campusId/:unitId/bursars/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addProvost = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.post(`${currentAPI}appointment/:campusId/:unitId/provost`, payload);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Provost appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeProvost = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.delete(`${currentAPI}appointment/:campusId/:unitId/provost/${id}`);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllProvosts = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.get(`${currentAPI}appointment/:campusId/:unitId/provosts/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addDirector = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.post(`${currentAPI}appointment/:campusId/:unitId/director`, payload);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Director appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeDirector = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.delete(`${currentAPI}appointment/:campusId/:unitId/director/${id}`);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllDirectors = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.get(`${currentAPI}appointment/:campusId/:unitId/directors/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addDean = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.post(`${currentAPI}appointment/:campusId/:unitId/dean`, payload);
        if (response.status === 201 || response.status === 200) {
            const successMessage = response?.data?.message || 'Dean appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeDean = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.delete(`${currentAPI}appointment/:campusId/:unitId/dean/${id}`);
        if (response.status === 201 || response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllDeans = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.get(`${currentAPI}appointment/:campusId/:unitId/deans/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addSubDean = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.post(`${currentAPI}appointment/:campusId/:unitId/subdean`, payload);
        if (response.status === 201 || response.status === 200) {
            const successMessage = response?.data?.message || 'Sub Dean appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeSubdean = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.delete(`${currentAPI}appointment/:campusId/:unitId/subdean/${id}`);
        if (response.status === 201 || response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllSubDeans = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.get(`${currentAPI}appointment/:campusId/:unitId/subdeans/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addHOD = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.post(
            `${currentAPI}appointment/:campusId/:unitId/headofdepartment`,
            payload,
        );
        if (response.status === 201 || response.status === 200) {
            const successMessage = response?.data?.message || 'Head of department appointed!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeHOD = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.delete(
            `${currentAPI}appointment/:campusId/:unitId/headofdepartment/${id}`,
        );
        if (response.status === 201 || response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllHODs = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.get(`${currentAPI}appointment/:campusId/:unitId/headofdepartments/all`);
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addNew = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.post(`${currentAPI}appointment/:campusId/:unitId/newCategory`, payload);
        if (response.status === 201 || response.status === 200) {
            const successMessage = response?.data?.message || `${payload.appointmentType} appointed!.`;
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllNew = (name) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.get(
            `${currentAPI}appointment/:campusId/:unitId/newcategorys/all?appointmentType=${name}`,
        );
        if (response.status === 200) {
            dispatch(GET_APPOINTMENT_DETAILS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeNew = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.delete(`${currentAPI}appointment/:campusId/:unitId/newcategory/${id}`);
        if (response.status === 201 || response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully deleted!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editVc = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.put(
            `${currentAPI}appointment/:campusId/:unitId/vice-chancellor/${id}`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editDvc = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.put(
            `${currentAPI}appointment/:campusId/:unitId/deputy-vice-chancellor/${id}`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editRegistrar = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.put(`${currentAPI}appointment/:campusId/:unitId/registrar/${id}`, payload);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editBursar = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.put(`${currentAPI}appointment/:campusId/:unitId/bursar/${id}`, payload);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editProvost = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.put(`${currentAPI}appointment/:campusId/:unitId/provost/${id}`, payload);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editDirector = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.put(`${currentAPI}appointment/:campusId/:unitId/director/${id}`, payload);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editDean = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.put(`${currentAPI}appointment/:campusId/:unitId/dean/${id}`, payload);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editSubdean = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.put(`${currentAPI}appointment/:campusId/:unitId/subdean/${id}`, payload);
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editHod = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.put(
            `${currentAPI}appointment/:campusId/:unitId/headofdepartment/${id}`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editNew = (id, payload, close) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {


        const response = await axiosInstance.put(
            `${currentAPI}appointment/:campusId/:unitId/newcategory/${id}`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = response.data.message || 'Appointee Successfully Edited!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
