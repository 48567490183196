import React, { useState } from 'react';

import toast from 'react-hot-toast';
import tw, { styled } from 'twin.macro';

import { ReactComponent as Download } from '../../../../../assets/icons/download.svg';
import { ReactComponent as OpenEye } from '../../../../../assets/icons/open-eye.svg';
import { ReactComponent as People } from '../../../../../assets/icons/people2.svg';
import { ReactComponent as Retry } from '../../../../../assets/icons/retry.svg';
import { ReactComponent as ThreeDots } from '../../../../../assets/icons/three-dot.svg';
import { ReactComponent as UploadedFile } from '../../../../../assets/icons/uploaded-file.svg';
import { ActionsPopup } from '../../../../../components';
import CardPopup from '../../../../../components/popups/card-popup';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../../../containers/ScreenContainers';

const MainContainer = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-2
    grid-flow-row
    gap-6
    mt-8
`;

const BasicContent = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-2
    mt-8
`;

const RowContainer = tw.div`
    grid
    grid-rows-2
    gap-8
`;

const FlexCol = tw.div`
    gap-6
    
`;
const ColContainer = tw.div`
    gap-6
    flex
    flex-col
`;

const Details = tw.div`
    col-span-1
    bg-white
    p-6
    rounded-lg
`;
const ImageDetails = tw.div`

mb-[2.8rem]
`;
const DetailsCardTitleText = styled(Text)`
    color: #9ca3af;
    font-weight: 500;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
`;

const DetailsCardSubText = styled(Text)`
    color: #4b5563;
    font-weight: 500;
    line-height: 2.4rem;
    font-size: 1.4rem;
    text-align: ${({ align }) => align || 'left'};
`;

const Medical = ({ details }) => {
    const [openDropdown, setOpenDropdown] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');

    const medicalLeft = [
        {
            title: 'Height (in feet)',
            subtitle: details?.medicalData?.height || 'Nil',
        },
        {
            title: 'Alleries',
            subtitle: details?.medicalData?.allergic || 'Nil',
        },
        {
            title: 'Genotype',
            subtitle: details?.medicalData?.genotype || 'Nil',
        },

        {
            title: 'Blood Group',
            subtitle: details?.medicalData?.bloodGroup || 'Nil',
        },
        {
            title: 'Weight (in Kilogram)',
            subtitle: details?.medicalData?.weight || 'Nil',
        },
        {
            title: 'Rhesus Factor',
            subtitle: details?.medicalData?.rhesus || 'Nil',
        },
    ];
    const treatmentLeft = [
        {
            title: 'Any Special/ Continuous Medical Treatment',
            subtitle: details?.medicalData?.specialTreatment || 'Nil',
        },
        {
            title: 'Description of Treatment',
            subtitle: details?.medicalData?.disabilityDescription || 'Nil',
        },

        {
            title: 'Place of Treatment',
            subtitle: details?.medicalData?.treatmentAddress || 'Nil',
        },
    ];
    const disabilityLeft = [
        {
            title: 'Learning Disabilities',
            subtitle: details?.medicalData?.specialTreatment || 'Nil',
        },
        {
            title: 'Description',
            subtitle: details?.medicalData?.disabilityDescription || 'Nil',
        },
        {
            title: 'Special or Long-Term Health Condition',
            subtitle: details?.medicalData?.treatmentAddress || 'Nil',
        },
    ];
    const medicalUpload = [
        {
            subtitle: details?.uploads?.medical?.[0]?.fileName,
            size: details?.uploads?.medical?.[0]?.size,
            url: details?.uploads?.medical?.[0]?.url,
        },
    ];

    const handleDownload = (url, fileName) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <div>
            <MainContainer>
                <ColContainer>
                    <Details>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Medical Information
                            </PageTitle>
                        </FlexCentredRow>

                        <BasicContent>
                            <RowContainer>
                                {medicalLeft?.slice(0, Math.ceil(medicalLeft?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {medicalLeft?.slice(Math.ceil(medicalLeft?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                    <Details>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Special/Continuous Medical Treatment
                            </PageTitle>
                        </FlexCentredRow>

                        <BasicContent>
                            <RowContainer>
                                {treatmentLeft?.slice(0, Math.ceil(treatmentLeft?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {treatmentLeft?.slice(Math.ceil(treatmentLeft?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                </ColContainer>
                <ColContainer>
                    <Details>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Disabilities
                            </PageTitle>
                        </FlexCentredRow>

                        <BasicContent>
                            <RowContainer>
                                {disabilityLeft?.slice(0, Math.ceil(disabilityLeft?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {disabilityLeft?.slice(Math.ceil(disabilityLeft?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                    <Details>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Test Result Upload
                            </PageTitle>
                        </FlexCentredRow>
                        <div className="mt-[2.8rem]">
                            {medicalUpload?.map((item) => (
                                <ImageDetails>
                                    <FlexRowSpaceBetween className="bg-white p-6 border rounded-xl items-center">
                                        <FlexCentredRow>
                                            <UploadedFile className="mr-[2.308rem]" />

                                            <FlexCentredCol>
                                                <Text weight="500" size="1.6rem" lineHeight="2.8rem" align="left">
                                                    {item?.subtitle}
                                                </Text>
                                                <Text
                                                    weight="500"
                                                    align="left"
                                                    color="#6B7280"
                                                    size="1.4rem"
                                                    lineHeight="2.4rem"
                                                >
                                                    Size - {item?.size}kb
                                                </Text>
                                            </FlexCentredCol>
                                        </FlexCentredRow>
                                        <RelativeContainer>
                                            <ThreeDots onClick={() => setOpenDropdown(item.title)} />
                                            <ActionsPopup
                                                open={openDropdown === item.title}
                                                close={() => setOpenDropdown(null)}
                                                items={[
                                                    {
                                                        icon: <Download />,
                                                        name: 'Download',
                                                        click: () => {
                                                            const downloadUrl = item?.url;
                                                            const fileName = item.subtitle;

                                                            if (downloadUrl && fileName) {
                                                                handleDownload(downloadUrl, fileName);
                                                            }
                                                        },
                                                    },

                                                    {
                                                        icon: <OpenEye fill="#1F2937" />,
                                                        name: 'Preview',
                                                        click: () => {
                                                            const previewUrl = item?.url;
                                                            if (previewUrl) {
                                                                setPreviewUrl(previewUrl);
                                                                setOpenDropdown('preview');
                                                            } else {
                                                                toast.error('Failed to open preview.');
                                                            }
                                                        },
                                                    },
                                                    { icon: <Retry />, name: 'Request for Re-Upload' },
                                                ]}
                                            />
                                        </RelativeContainer>
                                    </FlexRowSpaceBetween>
                                </ImageDetails>
                            ))}
                        </div>
                    </Details>
                </ColContainer>
            </MainContainer>
            <CardPopup show={openDropdown === 'preview'} close={() => setOpenDropdown(null)} noPadding>
                <div className="h-[30rem]">
                    <img src={previewUrl} alt={previewUrl} className="object-contain w-full h-full" />
                </div>
            </CardPopup>
        </div>
    );
};

export default Medical;
