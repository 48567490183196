import React from 'react';

import { ReactComponent as CashIcon } from '../../../../../assets/icons/cash.svg';
import { Text } from '../../../../../containers/MesssageContainers';
import { FlexColumn, FlexRowSpaceBetween } from '../../../../../containers/ScreenContainers';
import currencyFormatter from '../../../../../utils/formatCurrency';

const AccountDetails = () => {
    return (
        <FlexColumn className="gap-[2.4rem]">
            <div className="bg-white w-full py-[1.4rem] px-[1.6rem] rounded-lg">
                <FlexColumn className="gap-[1rem]">
                    <Text align="left" size="1.4rem" color="#6B7280" weight="500">
                        Wallet Balance
                    </Text>
                    <CashIcon />
                    <Text align="left" size="2.3rem" weight="600">
                        {currencyFormatter.format(1000000)}
                    </Text>
                </FlexColumn>
            </div>
            <div className="bg-white w-full py-[1.4rem] px-[1.6rem] rounded-lg">
                <FlexColumn className="gap-[1rem]">
                    <FlexRowSpaceBetween>
                        <Text align="left" size="1.4rem" color="#6B7280" weight="500">
                            Payout Account Details
                        </Text>
                        <Text align="left" size="1.4rem" color="#3B82F6" weight="500" className="cursor-pointer">
                            Manage
                        </Text>
                    </FlexRowSpaceBetween>
                    <div className="grid grid-cols-3 gap-[3rem]">
                        <FlexColumn className="gap-[4px] border-r boder-r-[1px] border-r-[#D1D5DB]">
                            <Text align="left" color="#6B7280" size="1.2rem" lineHeight="1.7rem">
                                Bank Name
                            </Text>
                            <Text align="left" size="1.4rem" weight="500" lineHeight="1.7rem">
                                Brahimic Bank Of Africa
                            </Text>
                        </FlexColumn>
                        <FlexColumn className="gap-[4px] border-r boder-r-[1px] border-r-[#D1D5DB]">
                            <Text align="left" color="#6B7280" size="1.2rem" lineHeight="1.7rem">
                                Bank Account Name
                            </Text>
                            <Text align="left" size="1.4rem" weight="500" lineHeight="1.7rem">
                                Belrald University
                            </Text>
                        </FlexColumn>
                        <FlexColumn className="gap-[4px]">
                            <Text align="left" color="#6B7280" size="1.2rem" lineHeight="1.7rem">
                                Bank Account Number
                            </Text>
                            <Text align="left" size="1.4rem" weight="500" lineHeight="1.7rem">
                                Belrald University
                            </Text>
                        </FlexColumn>
                    </div>
                </FlexColumn>
            </div>
        </FlexColumn>
    );
};

export default AccountDetails;
