import * as Yup from 'yup';

import { emailRegExp, utmeRegExp } from '../../../utils/regex';

export const ValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .nullable()
        .required('Required')
        .matches(/^[a-zA-Z-]+$/, 'First name must contain only alphabetic characters and no spaces'),

    lastName: Yup.string()
        .nullable()
        .required('Required')
        .matches(/^[a-zA-Z-]+$/, 'Surname must contain only alphabetic characters and no spaces'),

    middleName: Yup.string()
        .nullable()
        // .required('Required')
        .matches(/^[a-zA-Z-]+$/, 'Other Name must contain only alphabetic characters and no spaces'),
    studentEmail: Yup.string().matches(emailRegExp, 'Email address is not valid').nullable().required('Required'),
    utmeRegNumber: Yup.string().matches(utmeRegExp, 'Enter a valid UTME number').nullable(),
    academicUnit: Yup.string().nullable().required('Required'),
    faculty: Yup.string().nullable().required('Required'),
    department: Yup.string().nullable().required('Required'),
    level: Yup.string().nullable().required('Required'),
    admissionType: Yup.string().nullable().required('Required'),
    utmeScore: Yup.string().nullable(),
    postUtmeScore: Yup.string().nullable(),
    subProgram: Yup.string().nullable(),
    matricNumber: Yup.string().nullable(),
    nin: Yup.string().nullable(),
});
