import React from 'react';

import tw, { styled } from 'twin.macro';

import { ReactComponent as People } from '../../../../../assets/icons/people2.svg';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../../containers/ScreenContainers';

const MainContainer = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-2
    grid-flow-row
    gap-6
    mt-8
`;

const BasicContent = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-2
    mt-8
`;

const RowContainer = tw.div`
    grid
    grid-rows-2
    gap-8
`;

const FlexCol = tw.div`
    gap-6
    
`;
const ColContainer = tw.div`
    gap-6
    flex
    flex-col
`;

const Details = tw.div`
    col-span-1
    bg-white
    p-6
    rounded-lg
`;

const DetailsCardTitleText = styled(Text)`
    color: #9ca3af;
    font-weight: 500;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
`;

const DetailsCardSubText = styled(Text)`
    color: #4b5563;
    font-weight: 500;
    line-height: 2.4rem;
    font-size: 1.4rem;
    text-align: ${({ align }) => align || 'left'};
    line-height: 2.4rem;
    text-align: ${({ align }) => align || 'left'};
`;

const StudentOverview = ({ details }) => {
    const leftRow = [
        {
            title: 'Accomodation Type',
            subtitle: '',
        },
        {
            title: 'Block',
            subtitle: '',
        },
        {
            title: 'Bedspace',
            subtitle: '',
        },

        {
            title: 'Hostel',
            subtitle: '',
        },
        {
            title: 'Room',
            subtitle: '',
        },
    ];

    const exeat = [
        {
            title: 'Subject',
            subtitle: '',
        },
        {
            title: 'Purpose of Exeat',
            subtitle: '',
        },
        {
            title: 'Destination Address',
            subtitle: details?.bioData?.updateInfo?.nextOfKinAddress,
        },
        {
            title: 'Date',
            subtitle: details?.bioData?.updateInfo?.relationshipWithNextOfKin,
        },
    ];

    const accomodationHistory = [
        {
            title: 'Accomodation Type',
            subtitle: '',
        },
        {
            title: 'Hostel',
            subtitle: '',
        },
        {
            title: 'Room',
            subtitle: '',
        },
        {
            title: 'Session',
            subtitle: '',
        },
        {
            title: 'Block',
            subtitle: '',
        },
        {
            title: 'Bedspace',
            subtitle: '',
        },
    ];

    return (
        <div>
            <MainContainer>
                <ColContainer>
                    <Details>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Current Accomodation
                            </PageTitle>
                        </FlexCentredRow>

                        <BasicContent>
                            <RowContainer className="lg:text-left text-center">
                                {leftRow?.slice(0, Math.ceil(leftRow?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {leftRow?.slice(Math.ceil(leftRow?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                    <Details>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Exeat History
                            </PageTitle>
                        </FlexCentredRow>

                        <BasicContent>
                            <RowContainer>
                                {exeat?.slice(0, Math.ceil(exeat?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {exeat?.slice(Math.ceil(exeat?.length / 2)).map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item?.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                </ColContainer>
                <ColContainer>
                    <Details>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[0.919rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Accomodation History
                            </PageTitle>
                        </FlexCentredRow>

                        <BasicContent>
                            <RowContainer>
                                {accomodationHistory
                                    ?.slice(0, Math.ceil(accomodationHistory?.length / 2))
                                    .map((item, index) => (
                                        <FlexCol key={index}>
                                            <DetailsCardTitleText>{item?.title}</DetailsCardTitleText>
                                            <DetailsCardSubText>{item?.subtitle}</DetailsCardSubText>
                                        </FlexCol>
                                    ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {accomodationHistory
                                    ?.slice(Math.ceil(accomodationHistory?.length / 2))
                                    .map((item, index) => (
                                        <FlexCol key={index}>
                                            <DetailsCardTitleText align="right">{item?.title}</DetailsCardTitleText>
                                            <DetailsCardSubText align="right">{item?.subtitle}</DetailsCardSubText>
                                        </FlexCol>
                                    ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                </ColContainer>
            </MainContainer>
        </div>
    );
};

export default StudentOverview;
