import React, { useState } from 'react';

import { CModal } from '@coreui/react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as Warning } from '../../../assets/icons/warning.svg';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { deleteRole } from '../../../redux/roles/actions';
import { LoadingButton } from '../../buttons';
import { CancelButton } from '../../forms/sharedStyles';
import TextInput from '../../inputs/text-input';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 3.2rem;
        width: 48.8rem;
        background-color: white;
    }
`;

const INPUT_VALUE = 'delete';

const DeleteRole = ({ show, setShow, role, onFinish }) => {
    const dispatch = useDispatch();

    const handleClose = () => setShow(null);

    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const isBtnDisabled = () => inputValue !== INPUT_VALUE || isLoading;

    const handleDelete = async () => {
        setIsLoading(true);
        const res = await dispatch(deleteRole(role?._id));
        if (res) {
            setInputValue('');
            toast.success('Role deleted Successfully');
            onFinish();
            handleClose();
        }
        setIsLoading(false);
    };

    return (
        <StyledModal show={show} onClose={handleClose} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween className="mb-[1.6rem]">
                <FlexCentredRow className=" items-center  gap-[1.215rem]">
                    <Warning fill="#EF4444" />
                    <PageTitle size="1.9rem" lineHeight="2.8rem" weight="600">
                        Delete Role?
                    </PageTitle>
                </FlexCentredRow>
                <CloseIcon onClick={handleClose} />
            </FlexRowSpaceBetween>

            <Text align="left" bottom="2.4rem" size="1.6rem" lineHeight="2.4rem">
                This role will be completely deleted
            </Text>
            <Text align="left" bottom="2.4rem" size="1.6rem" lineHeight="2.4rem">
                To confirm deletion, enter '{INPUT_VALUE}' in the text field.
            </Text>
            <TextInput
                type="text"
                value={inputValue}
                placeholder="Input delete"
                onChange={(e) => setInputValue(e.target.value)}
            />

            <FlexRowEnd className="gap-[1.6rem] mt-8">
                <CancelButton onClick={handleClose}>Cancel</CancelButton>
                <LoadingButton onClick={handleDelete} disabled={isBtnDisabled()} bgColor="red" loading={isLoading}>
                    Yes, Delete
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default DeleteRole;
