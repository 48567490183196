import React from 'react';

import { SelectInput, TextInput } from '@belrald_hq/belrald-ui';
import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-grey.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 45.8rem;
        max-height: 60rem;
        margin: auto;
    }
`;

const ItemsContainer = tw.ul`
    overflow-y-auto
    border-b
    pb-2
    mt-5
    // flex
    // gap-4
`;

const AttachToAgent = ({
    show,
    close,
    list,
    onConfirm,
    subtitle,
    title,
    nameField = 'fullName',
    idField = 'id',
    subtitle2,
    value,
    setInputValue,
    isLoading,
    setSelectedAgent,
    agentData,
    btnText,
    placeholder,
    bgColor,
    icon,
    disabled,
    studentAgent,
}) => {
    const defaultAgent = agentData?.find((item) => item?._id === studentAgent);
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween>
                <FlexCentredRow className="gap-2">
                    {icon && icon}
                    <Text as="h3" size="1.9rem" weight="600">
                        {title}
                    </Text>
                </FlexCentredRow>
                <CloseIcon className="hover:cursor-pointer" onClick={close} />
            </FlexRowSpaceBetween>
            <Text align="start" size="1.6rem" color="#374151" top="1.6rem" lineHeight="2.4rem" bottom="1rem">
                {subtitle}
            </Text>

            <ItemsContainer>
                {list?.map((item, index) => (
                    <Text align="left" key={item?.[idField] || index}>{`${item?.[nameField]}`}</Text>
                ))}
            </ItemsContainer>
            <Text color="#6B7280" size="1.2rem" align="left">
                {list?.length} name(s) selected
            </Text>
            <div className="flex flex-col mt-[1.6rem] gap-[3.2rem]">
                <SelectInput
                    name="agentId"
                    objProp="companyName"
                    data={agentData}
                    placeholder="Choose Agent Name"
                    label="Agent Name"
                    onChange={(selected) => {
                        setSelectedAgent(selected?.[0]);
                    }}
                    useComponentState={studentAgent ? false : true}
                    // error={errors.fee}
                    passedSelectedItems={studentAgent ? [defaultAgent] : null}
                    disabled={studentAgent}
                />

                <Text size="1.6rem" color="#374151" align="start">
                    {subtitle2}
                </Text>

                <TextInput
                    type="text"
                    placeholder={placeholder}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={value}
                    required
                />
            </div>
            <FlexRowEnd className="gap-x-[16px] mt-[1.5rem]">
                <Button type="button" onClick={close} border="0.5px solid #D1D5DB">
                    Cancel
                </Button>
                <LoadingButton bgColor={bgColor} loading={isLoading} onClick={onConfirm} disabled={disabled}>
                    {btnText}
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default AttachToAgent;
