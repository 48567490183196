import React, { useState, useEffect, useMemo } from 'react';

import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as AttachmentIcon } from '../../../../assets/icons/attachment.svg';
import { ReactComponent as ChangeIcon } from '../../../../assets/icons/change-icon.svg';
import { ReactComponent as Plus } from '../../../../assets/icons/circularplus-black.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-black.svg';
import { ReactComponent as ManageIcon } from '../../../../assets/icons/manage-icon.svg';
import { ActionsPopup, ConfirmActionDialogue } from '../../../../components';
import { Button, SecondaryButton } from '../../../../components/buttons';
import AddBedspace from '../../../../components/forms/add-bedspaces';
import EditBedspace from '../../../../components/forms/edit-bedspace';
import { GoBack } from '../../../../components/go-back';
import ConfirmActionRadio from '../../../../components/popups/confirm-with-radio';
import { getStatusStyles, StatusContainer } from '../../../../components/shared';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import {
    changeBedspaceStatus,
    deleteBedspace,
    getAllBedspaces,
    getBedspaceById,
    getRoomById,
    manageBedspaceReservation,
} from '../../../../redux/hostel/action';
import { capitalizeFirstLetter } from '../../../../utils';
import { storeUnit } from '../../../../utils/originUnit';

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;
const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-[2.4rem]
    rounded-lg
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
`;

const statuses = ['Available', 'Not Available', 'Reserved', 'Occupied'];
const reservations = ['Reserve', 'Unreserve'];

const Bedspaces = ({ unitName }) => {
    const dispatch = useDispatch();

    const {
        state: { _id: id, totalBedspaces, hostelName, gender, blockName },
    } = useLocation();

    const { isLoading, allBedspaces, roomDetails } = useSelector((state) => state.hostel);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [openModal, setOpenModal] = useState(false);
    const [selectedBedspace, setSelectedBedspace] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedReservation, setSelectedReservation] = useState('');

    const handleChangeStatus = async () => {
        if (!selectedStatus) {
            toast.error('Please select a status');
            return;
        }
        if (selectedStatus) {
            const res = await dispatch(changeBedspaceStatus(selectedBedspace?._id));
            if (res) {
                toast.success('Bedspace status changed successfully!');
                setToggleRefetch((prevState) => !prevState);
                setOpenModal(false);
                setSelectedStatus('');
            }
        }
    };
    const handleManageReservation = async () => {
        if (selectedReservation) {
            const res = await dispatch(manageBedspaceReservation(selectedBedspace?._id));
            if (res) {
                toast.success('Reservations changed successfully!');
                setToggleRefetch((prevState) => !prevState);
                setOpenModal(false);
                setSelectedReservation('');
            }
        }
    };

    useEffect(() => {
        if (!selectedBedspace && !selectedBedspace?._id) return;
        dispatch(getBedspaceById(selectedBedspace?._id));
    }, [selectedBedspace, dispatch]);

    const handleDelete = async () => {
        const res = await dispatch(deleteBedspace(selectedBedspace?._id));
        if (res) {
            toast.success('Bedspace deleted successfully!');
            setToggleRefetch((prevState) => !prevState);
            setOpenModal(false);
        }
    };

    const handleEdit = () => {
        if (!selectedBedspace?._id) return toast.error('Select a bedspace to edit!');
        setOpenModal('edit');
    };

    const handleCloseModal = (modal) => {
        if (openModal === modal) {
            setOpenModal(null);
        } else {
            setOpenModal(modal);
        }
    };

    const fields = [
        {
            cell: (props) => (
                <Link
                    to={`/student-affairs/accomodation-management/${props.row.original._id}`}
                    state={{
                        hostelName: hostelName,
                        _id: props.row.original._id,
                        totalRooms: props.row.original.totalRooms,
                        totalBedspaces: props.row.original.totalBedspaces,
                    }}
                    style={{ color: '#2563EB', cursor: 'pointer' }}
                >
                    {props?.getValue()}
                </Link>
            ),
            header: 'Bedspace Name',
            accessorKey: 'bedSpaceName',
        },
        {
            header: 'Status',
            accessorKey: 'bedSpaceStatus',
            cell: (props) => (
                <StatusContainer style={getStatusStyles(props.getValue()?.toLowerCase())}>
                    <span>{props.getValue()}</span>
                </StatusContainer>
            ),
        },

        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Tenants',
            accessorKey: 'tenants',
        },
        {
            cell: (props) => {
                const dateTimeString = props.getValue();
                const [datePart, timePart] = dateTimeString.split('T');
                const formattedDateTime = `${datePart} ${timePart.split('.')[0]}`;

                return <td>{formattedDateTime}</td>;
            },
            header: 'Last Updated',
            accessorKey: 'updatedAt',
        },
    ];

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.search = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        if (!id && id !== '') return;
        dispatch(getAllBedspaces(id, query));
    }, [id, dispatch, toggleRefetch, query]);

    useEffect(() => {
        dispatch(getRoomById(id));
    }, [id, dispatch]);

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return (
        <>
            <div>
                <GoBack
                    title={`Accomodation Management / ${hostelName} / ${blockName}`}
                    subTitle={roomDetails?.roomName}
                ></GoBack>
                <FlexRowSpaceBetween className="mt-[4.5rem]">
                    <PageTitle align="left">{roomDetails?.roomName}</PageTitle>
                    <FlexCentredRow className="gap-x-4">
                        <SecondaryButton
                        // onClick={() => setOpenModal('add')}
                        >
                            <Plus className="mr-[1.315rem]" />
                            Assign Bedspaces
                        </SecondaryButton>

                        <RelativeContainer>
                            <Button bgColor="#6366F1" color="#fff" onClick={() => handleCloseModal('actions')}>
                                Actions
                                <Dropdown className="ml-[13.15px]" />
                            </Button>
                            <ActionsPopup
                                open={openModal === 'actions'}
                                close={() => setOpenModal(null)}
                                items={[
                                    {
                                        icon: <Plus />,
                                        name: 'Add Bedspace',
                                        disabled: selectedBedspace,
                                        click: () => setOpenModal('add'),
                                    },
                                    {
                                        icon: <EditIcon />,
                                        name: 'Edit Bedspace',
                                        disabled: !selectedBedspace,
                                        click: () => handleEdit(),
                                    },

                                    {
                                        icon: <ChangeIcon />,
                                        name: 'Change Bedspace Status',
                                        disabled: !selectedBedspace,
                                        click: () => {
                                            setOpenModal('change');
                                        },
                                    },
                                    {
                                        icon: <ManageIcon />,
                                        name: 'Manage Reservation',
                                        disabled: !selectedBedspace,
                                        click: () => {
                                            setOpenModal('manage');
                                        },
                                    },
                                    {
                                        icon: <DeleteIcon />,
                                        name: 'Remove Bedspace',
                                        disabled: !selectedBedspace,
                                        click: () => {
                                            setOpenModal('delete');
                                        },
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
                <Details className="mt-[3rem]">
                    <FlexCentredCol>
                        <PageTitle align="left" size="1.9rem" lineheight="2.8rem">
                            Overview
                        </PageTitle>
                        <Text align="left">{roomDetails?.roomDescription}</Text>
                    </FlexCentredCol>

                    <BasicContent>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Number of Occupants</GreyText>
                                <DarkText>{roomDetails?.numberOfOccupants}</DarkText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Number of Bedspaces</GreyText>
                                <DarkText>{totalBedspaces}</DarkText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Floor Placement</GreyText>
                                <DarkText>{roomDetails?.floorPlacement}</DarkText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Gender</GreyText>
                                <DarkText>{capitalizeFirstLetter(gender)}</DarkText>
                            </FlexCol>
                        </RowContainer>
                    </BasicContent>
                </Details>

                <PageLayout
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    searchValue={searchValue}
                    onSearchClose={() => setSearchValue('')}
                    showTableUtils
                    showFilter
                    data={allBedspaces || []}
                    fields={fields}
                    loading={isLoading}
                    width={'100%'}
                    isActionable
                    actionType="radio"
                    onSelectedItemsChange={(items) => setSelectedBedspace(items[0])}
                    openFilterPop={openModal === 'options-popup-main'}
                    closeFilterPop={() => setOpenModal(null)}
                />
            </div>
            <AddBedspace
                show={openModal === 'add'}
                setShow={setOpenModal}
                setToogleRefetch={() => setToggleRefetch((prevState) => !prevState)}
                hostelId={roomDetails?.hostelId}
                blockId={roomDetails?.blockId}
            />
            <EditBedspace
                item={selectedBedspace}
                show={openModal === 'edit'}
                setSelected={setSelectedBedspace}
                setShow={setOpenModal}
                setToogleRefetch={() => setToggleRefetch((prevState) => !prevState)}
            />

            <ConfirmActionDialogue
                show={openModal === 'delete'}
                title="Delete Bedspaces?"
                isLoading={isLoading}
                subtitle="Are you sure you want to remove this bedspace?"
                close={() => setOpenModal(null)}
                confirmAction={() => handleDelete()}
            />

            <ConfirmActionRadio
                show={openModal === 'change'}
                title="Change Bedspace Status"
                placeholder="Input reason"
                svgIcon={<ChangeIcon fill="#6366F1" />}
                close={() => setOpenModal(null)}
                statuses={statuses}
                confirmAction={handleChangeStatus}
                btn2Text="Save Changes"
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                isLoading={isLoading}
                btnDisabled={!selectedStatus}
            />
            <ConfirmActionRadio
                show={openModal === 'manage'}
                title="Manage Bedspace Reservation"
                svgIcon={<AttachmentIcon />}
                close={() => setOpenModal(null)}
                statuses={reservations}
                confirmAction={handleManageReservation}
                btn2Text="Save Changes"
                selectedStatus={selectedReservation}
                setSelectedStatus={setSelectedReservation}
                isLoading={isLoading}
                btnDisabled={!selectedReservation}
            />
        </>
    );
};
export default Bedspaces;
