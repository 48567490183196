import React, { useState, useEffect } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ConfirmActionDialogue } from '../../../components';
import Checkbox from '../../../components/inputs/checkbox';
import ReceiversList from '../../../components/popups/receivers-list';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../containers/ScreenContainers';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getSavedAcademicStructure } from '../../../redux/academic-structure/actions';
import { deleteDistributionList, getDistributionListByUnitname } from '../../../redux/distribution-list/actions';

const fields = [
    {
        accessorKey: 'action',
        header: <Checkbox />,
        size: 5,
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Name',
        accessorKey: 'distributionName',
        cell: (props) => <p>{props.getValue()}</p>,
    },
];

const DistributionList = ({ unitName, title }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((store) => store.communications);

    const [openDropdown, setOpenDropdown] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [openReceiver, setOpenReceiver] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [allDistributionList, setAllDistributionList] = useState([]);
    const [name, setName] = useState('');
    const [distributionId, setDistributionId] = useState('');
    const [receivers, setReceivers] = useState([]);

    const singleItem = allDistributionList?.find((item) => item._id === distributionId);

    useEffect(() => {
        const getDistributionList = async () => {
            const res = await dispatch(getDistributionListByUnitname());
            setAllDistributionList(res);
        };
        getDistributionList();
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    const modifiedDistributionData = allDistributionList?.map((list) => {
        const allReceivers = [];
        const filterList = ['', undefined, 'undefined'];
        const distributionReceivers = allReceivers?.filter((el) => !filterList?.includes(el));
        return {
            id: list._id,
            no: distributionReceivers?.length,
            action: (
                <td>
                    <Checkbox checked={selectedId === list?._id} onChange={() => handleCheck(list)} />
                </td>
            ),
            distributionName: (
                <td
                    onClick={() => {
                        setSelectedId(list.id);
                        setOpenReceiver(true);
                    }}
                >
                    <Text
                        align="left"
                        className="hover:cursor-pointer"
                        color="#2563EB"
                        weight="400"
                        size="14px"
                        lineHeight="24px"
                        transform="capitalize"
                    >
                        {list?.distributionName}
                    </Text>
                </td>
            ),
            receiversList: (
                <td>
                    <FlexCentredRow className="gap-[0.8rem]">
                        {distributionReceivers?.[0]}
                        <div className="bg-[#FEE2E2] py-[0.2rem] px-[0.6rem] rounded-2xl">
                            <Text color="#EF4444" weight="600" size="1.2rem" lineHeight="1.7rem">
                                {distributionReceivers?.length - 1 <= 0 ? '' : `+${distributionReceivers?.length - 1}`}
                            </Text>
                        </div>
                    </FlexCentredRow>
                </td>
            ),
            ...list,
        };
    });

    useEffect(() => {
        if (!selectedId) return;
        const ReceiverList = modifiedDistributionData.find((data) => data.id === selectedId);
        setReceivers(ReceiverList.list);
    }, [modifiedDistributionData, selectedId]);

    const handleDelete = async () => {
        const check = singleItem.distributionName === name;
        if (check) {
            const res = await dispatch(deleteDistributionList(distributionId));
            if (res) {
                setName('');
                setDistributionId('');
                setSelectedId('');
            }
            setOpenDelete(false);
        } else {
            toast.error('Distribution List Name is wrong!');
        }
    };

    const handleCheck = (item) => {
        if (selectedId === item?._id) {
            setSelectedId('');
        } else {
            setSelectedId(item?._id);
        }
    };

    return (
        <div className="relative">
            <PageLayout
                pageTitle="Distribution List"
                backTitle={`${title}/Distribution List`}
                backLink=""
                secondaryButtonText="Add Distribution List"
                onSecondaryButtonClick={() => navigate('add')}
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onActionBtnClick={() => {
                    setOpenDropdown(!openDropdown);
                }}
                actionOpen={openDropdown}
                actionClose={() => setOpenDropdown(false)}
                actionItems={[
                    {
                        name: 'Edit Distribution',
                        disabled: !selectedId,
                        click: () => {
                            navigate(`edit/${selectedId}`);
                            setOpenDropdown(false);
                        },
                    },
                    {
                        name: 'Delete Distribution',
                        disabled: !selectedId,
                        click: () => {
                            setOpenDelete(true);
                            setOpenDropdown(false);
                            setDistributionId(selectedId);
                        },
                    },
                ]}
                fields={fields}
                data={modifiedDistributionData || []}
                loading={isLoading}
                onRowClick={(item) => {
                    const selecetedItem = allDistributionList?.find((list) => list?._id === item?.id);
                    handleCheck(selecetedItem);
                }}
                isActionable
            />

            <ConfirmActionDialogue
                show={openDelete}
                close={() => setOpenDelete(false)}
                title="Distribution List"
                input
                subtitle="Are you sure you want to delete this distribution list"
                bodyText="To confirm deletion, enter the name of the distribution list in the text field"
                btnText="Yes Delete"
                confirmAction={handleDelete}
                value={name}
                isLoading={isLoading}
                onChange={(e) => setName(e.target.value)}
                placeholder="Type distribution list name"
            />
            <ReceiversList show={openReceiver} close={() => setOpenReceiver(false)} Receivers={receivers} />
        </div>
    );
};

export default DistributionList;
