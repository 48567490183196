import React from 'react';

import tw from 'twin.macro';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-down.svg';

const ItemContainer = tw.ul`
  flex
  flex-col
//   gap-[0.5rem]
  text-[1.6rem]
  leading-[2.4rem]
  font-normal
  text-[#1F2937]
  px-[8px]
  py-[12px]
  mb-0
  max-h-[100%]
  overflow-auto
`;

const Item = tw.li`
  flex
  items-center
  gap-[0.8rem]
  hover:bg-[#F3F4F6]
  rounded-[8px]
  py-[1.115rem]
  px-[1.715rem]
`;

const ActionsPopup = ({ open, items, close, width, absolutePosition = 'right-[0px]' }) => {
    const handleClick = (item) => {
        if (item.notShown || item.disabled) return;
        item.click();
    };
    return (
        <div>
            {open && <div className="fixed z-10 inset-0" onClick={close}></div>}
            <div
                className={`${
                    open
                        ? `visible absolute shadow-lg z-[1000] rounded w-[${width}] min-w-max bg-white ${absolutePosition} top-[50px] cursor-pointer`
                        : 'hidden'
                }`}
            >
                <ItemContainer>
                    {items
                        ?.filter((el) => !el.notShown)
                        ?.map((item, index) => (
                            <Item
                                key={index.toString()}
                                className={`${item.disabled ? 'opacity-40' : ''} ${item.color || ''}`}
                                onClick={() => handleClick(item)}
                            >
                                {item.icon && item.icon}
                                {item.name}
                                {item?.withOptions && <ArrowIcon className="-rotate-90 ml-auto" />}
                            </Item>
                        ))}
                </ItemContainer>
            </div>
        </div>
    );
};

export default ActionsPopup;
