import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import RadioInput from '../../../components/inputs/radio';
import UploadFile from '../../../components/upload-file';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { capitalizeFirstLetter, formatDate, getStatusBadge } from '../../../utils';

const All = ({ tableData = [], tableHeader = [], selectedItem, handleSelect }) => {
    const navigate = useNavigate();
    const { isLoading } = useSelector((store) => store.communications);

    const [searchValue, setSearchValue] = useState('');
    const [showUploadFile, setShowUploadFile] = useState(false);

    const handleClick = (item) => {
        const label = item?.type?.toLowerCase();
        if (label === 'internal memo') {
            navigate(`view/${item._id}`, { state: item?.type });
        } else if (label === 'notice') {
            navigate(`view/${item._id}`, { state: item?.type });
        } else if (label === 'mail') {
            navigate(`view/${item._id}`, { state: item?.type });
        } else if (label === 'bulletin') {
            navigate(`view/${item._id}`, { state: item?.type });
        }
    };

    const AllCommsData = tableData?.map((item) => {
        const formattedDate = formatDate(item.createdAt);
        return {
            action: (
                <td className="cursor-pointer">
                    <RadioInput checked={selectedItem?._id === item?._id} />
                </td>
            ),
            time: formattedDate,
            subject: (
                <td className="cursor-pointer text-[#2563EB]" onClick={() => handleClick(item)}>
                    {item?.title}
                </td>
            ),
            commsStatus: getStatusBadge(capitalizeFirstLetter(item.status === 'sent' ? 'Published' : item?.status)),
            ...item,
        };
    });

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={AllCommsData}
                fields={tableHeader}
                loading={isLoading}
                onRowClick={(item) => handleSelect(item)}
                isActionable
                showFilter
            />

            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </>
    );
};

export default All;
