import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { Loader } from '../../../../components/loader';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';
import { getAlternateContact, getContactInfo } from '../../../../redux/ict/actions';

import AlternateInfo from './alternate-info';
import ContactInfo from './contact-info-card';

const ContactInformation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, contactInfo, alternateContact } = useSelector((store) => store.campus);

    useEffect(() => {
        dispatch(getContactInfo());
        dispatch(getAlternateContact());
    }, [dispatch]);

    if (isLoading) return <Loader />;

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[0.8rem]">
                <BackArrow />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Organization Settings
                </PageTitle>
                <Text size="1.6rem">/</Text>
                <Text size="1.6rem">Contact Information</Text>
            </FlexCentredRow>
            <PageTitle align="left" top="4.5rem" bottom="2.4rem">
                Contact Information
            </PageTitle>
            <div className="flex flex-col gap-[2.3rem]">
                <ContactInfo contact={contactInfo} />
                <AlternateInfo alternateContact={alternateContact} />
            </div>
        </div>
    );
};

export default ContactInformation;
