import React from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as HeaderIcon } from '../../../assets/icons/circularplus-blue.svg';
import { addRoom } from '../../../redux/hostel/action';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end

`;
const status = ['Available', 'Not Available'];
const placement = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const AddRoom = ({ show, setShow, setToogleRefetch, hostelId }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const onClose = () => {
        setShow(false);
    };
    const { isLoading } = useSelector((store) => store.hostel);

    return (
        <>
            <FormContainer headerIcon={<HeaderIcon />} close={onClose} show={show} title={'Add Room'}>
                <Formik
                    initialValues={{
                        roomName: '',
                        roomDescription: '',
                        floorPlacement: '',
                        roomStatus: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const res = await dispatch(addRoom(values, hostelId, id));
                        if (res) {
                            actions.resetForm();
                            onClose();
                            setToogleRefetch(true);
                            toast.success('Room was created successfully!');
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <div className="gap-10 flex flex-col">
                                <TextInput
                                    name="roomName"
                                    type="text"
                                    label="Room Name"
                                    placeholder="Input Room Name"
                                    value={values.roomName}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                                <TextInput
                                    name="roomDescription"
                                    type="text"
                                    label="Room Description (Optional)"
                                    placeholder="Input Room Description"
                                    value={values.roomDescription}
                                    onChange={handleChange}
                                    errors={errors}
                                />

                                <Select
                                    name="floorPlacement"
                                    objProp="floorPlacement"
                                    label="Floor Placement"
                                    data={placement.map((item) => ({ floorPlacement: item }))}
                                    placeholder="Select Floor Placement"
                                    onChange={(selected) => {
                                        setFieldValue('floorPlacement', String(selected[0].floorPlacement));
                                    }}
                                    error={errors.floorPlacement}
                                    passedSelectedItems={values.floorPlacement}
                                />

                                <Select
                                    name="roomStatus"
                                    objProp="roomStatus"
                                    label="Room Status"
                                    data={status.map((item) => ({ roomStatus: item }))}
                                    placeholder="Select Room Status"
                                    onChange={(selected) => {
                                        setFieldValue('roomStatus', String(selected[0].roomStatus));
                                    }}
                                    error={errors.roomStatus}
                                    passedSelectedItems={values.roomStatus}
                                />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} disabled={isLoading} type="submit">
                                    Add Room
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default AddRoom;
