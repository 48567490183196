import React from 'react';

import { CModal } from '@coreui/react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'twin.macro';

import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexRowEnd } from '../../../containers/ScreenContainers';
import { applyDiscounts } from '../../../redux/bursary/actions';
import { LoadingButton } from '../../buttons';
import { CancelButton } from '../../forms/sharedStyles';
import Select from '../../inputs/new-select';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const ApplyDiscount = ({ show, close, discount, onFinish, students }) => {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.bursary);

    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween className="pb-[1rem]">
                <Text as="h3" size="1.9rem" weight="600">
                    Apply {discount?.discountName} to Student(s)
                </Text>

                <Close onClick={close} className="cursor-pointer" />
            </FlexRowSpaceBetween>

            <Formik
                initialValues={{
                    search: [],
                }}
                onSubmit={async (values, actions) => {
                    const payload = {
                        search: values?.search?.map((student) => student?._id),
                    };

                    const res = await dispatch(applyDiscounts(discount._id, payload));
                    if (res) {
                        actions.resetForm();
                        onFinish();
                        close();
                    }
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="mb-[2rem]">
                            <Text className="mb-[2rem]" weight="500" align="left" size="1.4rem"></Text>
                            <Select
                                searchable
                                label="Students' Name or Matric Number"
                                name="search"
                                multiSelect={true}
                                objProp="fullName"
                                useComponentState={false}
                                data={students.map((item) => ({
                                    ...item,
                                    fullName: `${item.matricNumber ? item.matricNumber + ' -' : ''} ${item.lastName} ${
                                        item.firstName
                                    } ${item.middleName}`,
                                }))}
                                passedSelectedItems={values.search ? values.search : null}
                                placeholder="Select student(s)"
                                onChange={(selected) => {
                                    setFieldValue('search', selected);
                                }}
                            />
                        </div>

                        <FlexRowEnd className="gap-[1.5rem] mt-12">
                            <CancelButton type="button" onClick={close}>
                                Cancel
                            </CancelButton>
                            <LoadingButton loading={isLoading} disabled={isLoading} type="submit">
                                Apply Discount
                            </LoadingButton>
                        </FlexRowEnd>
                    </Form>
                )}
            </Formik>
        </StyledModal>
    );
};

export default ApplyDiscount;
