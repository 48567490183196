import React from 'react';

import { SelectInput, TextInput } from '@belrald_hq/belrald-ui';
import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/forward-arrow.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import { Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowEnd,
    FlexRowSpaceBetween,
} from '../../../../containers/ScreenContainers';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 45.8rem;
        height: 600px;
        margin: auto;
        overflow-y: auto;

        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none; /* For Internet Explorer and Edge */

        &::-webkit-scrollbar {
            display: none; /* For Chrome, Safari, and Opera */
        }
    }
`;

const MoveStudentToSession = ({
    show,
    close,
    onChange,
    sessions,
    value,
    setInputValue,
    isLoading,
    onSend,
    details,
}) => {
    const studentDetails = [
        { title: 'Current Session', value: details?.admissionSession },
        { title: 'Name', value: details?.fullName },
        { title: 'Matric Number', value: details?.matricNumber },
        { title: 'Email Address', value: details?.studentEmail },
    ];
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexCentredCol className="gap-[3.2rem]">
                <FlexRowSpaceBetween>
                    <FlexCentredRow className="gap-3">
                        <ArrowIcon />
                        <Text weight="600" size="1.9rem" color="#1F2937">
                            Move Student to Another Session
                        </Text>
                    </FlexCentredRow>
                    <CloseIcon className="hover:cursor-pointer" onClick={close} />
                </FlexRowSpaceBetween>

                <FlexCentredCol className="gap-[1rem] border-[1px] border-[#D1D5DB] rounded-[8px] p-[1.6rem]">
                    {studentDetails?.map((student) => (
                        <FlexCentredCol className="gap-0" key={student?.title}>
                            <Text align="left" lineHeight="1.7rem" size="1.2rem" weight="400" color="#9CA3AF">
                                {student?.title}
                            </Text>

                            <Text align="left" lineHeight="2.4rem" size="1.4rem" weight="400" color="#1F2937">
                                {student?.value}
                            </Text>
                        </FlexCentredCol>
                    ))}
                </FlexCentredCol>
                <SelectInput
                    objProp="admissionYear"
                    label="Destination Session"
                    placeholder="Session"
                    data={sessions?.filter((session) => session?.admissionYear !== details?.admissionSession)}
                    onChange={onChange}
                />
                <FlexCentredCol className="gap-[1rem]">
                    <Text align="left" weight="400" size="1.6rem" lineHeight="2.4rem">
                        All student’s records will be moved to the selected session, which will then become their
                        admitted session. To proceed, type move in the text field to confirm.
                    </Text>
                    <TextInput
                        type="text"
                        placeholder={'Type move'}
                        onChange={(e) => setInputValue(e.target.value)}
                        value={value}
                        required
                    />
                </FlexCentredCol>
                <FlexRowEnd className="gap-x-[16px] mt-[1rem]">
                    <Button type="button" onClick={close} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton bgColor="#6366F1" disabled={value !== 'move'} loading={isLoading} onClick={onSend}>
                        Move Student
                    </LoadingButton>
                </FlexRowEnd>
            </FlexCentredCol>
        </StyledModal>
    );
};

export default MoveStudentToSession;
