import React from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { FlexCentredCol, FlexCentredRow } from '../../../containers/ScreenContainers';
import { editCourse } from '../../../redux/courses/actions';
import { capitalizeFirstLetter, splitLettersAndNumbers } from '../../../utils';
import { LoadingButton } from '../../buttons';
import { CancelButton } from '../../forms/sharedStyles';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const TextArea = tw.textarea`
    w-full
    outline-none
    placeholder:text-[#9CA3AF]
    placeholder:text-[1.4rem]
    placeholder:font-semibold
    leading-6
    font-normal
    text-[1.4rem]
    border-[1px]
    border-[#E5E7EB]
    pl-3
`;

const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[100.5rem]
`;

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#1F2937]
  mb-[0]
`;

const Label = tw.label`
    text-[#6B7280]
    font-bold
    text-[1.4rem]
    mt-[2.4rem]
`;

const EditCourse = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        state: { course },
    } = useLocation();

    const { isLoading } = useSelector((state) => state.courses);

    return (
        <>
            <MainContainer>
                <Formik
                    initialValues={{
                        courseTitle: course?.courseTitle || '',
                        courseCode: course?.courseCode || '',
                        courseUnit: course?.courseUnit || '',
                        courseSemester: course?.courseSemester || '',
                        courseLevel: course?.courseLevel || '',
                        minimumPassScore: course?.minimumPassScore || '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const { courseTitle, courseCode, ...otherValues } = values;

                        const payload = {
                            ...otherValues,
                            courseTitle: capitalizeFirstLetter(courseTitle),
                            courseCode: splitLettersAndNumbers(courseCode.trim().toUpperCase()),
                        };
                        const res = await dispatch(editCourse(course?._id, payload));
                        if (res) {
                            toast.success('Course edited successfully');
                            navigate(-1);
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <FlexCentredRow className="mb-[2.4rem] mt-[1.2rem] gap-[0.715rem]">
                                <PeopleIcon />
                                <Title>Edit Course</Title>
                            </FlexCentredRow>
                            <div className="gap-y-[2.4rem] gap-x-[3.2rem] grid grid-cols-2">
                                <TextInput
                                    name="courseTitle"
                                    label="Course Title"
                                    type="text"
                                    placeholder="Course Title"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.courseTitle}
                                />
                                <TextInput
                                    name="courseCode"
                                    label="Course Code e.g. GNS 101"
                                    placeholder="Course Code e.g. GNS 101"
                                    type="text"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.courseCode}
                                />
                                <Select
                                    name="courseLevel"
                                    objProp="name"
                                    label="Course Level"
                                    data={[1, 2, 3, 4, 5, 6, 7].map((item) => ({ name: `${item}00` }))}
                                    placeholder="Level"
                                    onChange={(selected) => {
                                        setFieldValue('courseLevel', selected[0].name);
                                    }}
                                    error={errors.courseLevel}
                                    passedSelectedItems={values.courseLevel}
                                />

                                <TextInput
                                    name="courseUnit"
                                    label="Course Unit"
                                    type="number"
                                    placeholder="Course Unit"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.courseUnit}
                                />

                                <Select
                                    name="courseSemester"
                                    objProp="name"
                                    label="Semester"
                                    data={[{ name: 'First Semester' }, { name: 'Second Semester' }]}
                                    placeholder="Course Semester"
                                    onChange={(selected) => {
                                        setFieldValue('courseSemester', selected[0].name);
                                    }}
                                    error={errors.courseSemester}
                                    passedSelectedItems={values.courseSemester}
                                />
                                <TextInput
                                    name="minimumPassScore"
                                    label="Minimum Pass Mark (%)"
                                    placeholder="Minimum Pass Mark (%)"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.minimumPassScore}
                                />
                            </div>
                            <FlexCentredCol>
                                <Label>Description</Label>
                                <TextArea />
                            </FlexCentredCol>
                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} type="submit">
                                    Update
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </>
    );
};

export default EditCourse;
