import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    staffDirectory: [],
    staffBio: {},
    staffAcad: {},
    staffUploads: {},
    staffPublications: [],
    staff: {},
    staffProfile: {},
    staffFiles: [],
    receivedFiles: [],
    singleFile: {},
    allLetters: [],
    allSent: [],
    sentLetters: [],
    letter: {},
    handbooks: [],
    handbookDetails: {},
    sessions: [],
    uploadedFiles: [],
    createdFolders: [],
    searchStaff: [],
    searchFolder: {},
    folderDetails: [],
    fileDetails: [],
    payrollDetails: {},
    allPayroll: [],
};

export const staffArenaSlice = createSlice({
    name: 'staffArena',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_STAFF_DIRECTORY: (state, action) => {
            state.staffDirectory = action.payload;
        },
        GET_STAFF_BIO: (state, action) => {
            state.staffBio = action.payload;
        },
        GET_STAFF_ACAD: (state, action) => {
            state.staffAcad = action.payload;
        },
        GET_STAFF_UPLOADS: (state, action) => {
            state.staffUploads = action.payload;
        },
        GET_STAFF_PUBLICATION: (state, action) => {
            state.staffPublications = action.payload;
        },
        GET_STAFF: (state, action) => {
            state.staff = action.payload;
        },
        GET_PROFILE_DETAILS: (state, action) => {
            state.staffProfile = action.payload;
        },
        GET_UPLOADED_FILES: (state, action) => {
            state.uploadedFiles = action.payload;
        },
        GET_CREATED_FOLDERS: (state, action) => {
            state.createdFolders = action.payload;
        },
        GET_STAFF_FILES: (state, action) => {
            state.staffFiles = action.payload;
        },
        GET_RECEIVED_FILES: (state, action) => {
            state.receivedFiles = action.payload;
        },
        GET_SINGLE_FILE: (state, action) => {
            state.singleFile = action.payload;
        },
        GET_ALL_LETTER: (state, action) => {
            state.allLetters = action.payload;
        },
        GET_ALL_SENT: (state, action) => {
            state.allSent = action.payload;
        },
        GET_SENT_LETTERS: (state, action) => {
            state.sentLetters = action.payload;
        },
        GET_LETTER: (state, action) => {
            state.letter = action.payload;
        },
        GET_STAFF_HANDBOOK: (state, action) => {
            state.handbooks = action.payload;
        },
        GET_STAFF_HANDBOOK_BY_ID: (state, action) => {
            state.handbookDetails = action.payload;
        },
        GET_SESSIONS: (state, action) => {
            state.sessions = action.payload;
        },
        GET_SEARCH_STAFF: (state, action) => {
            state.searchStaff = action.payload;
        },
        GET_SEARCH_FOLDER: (state, action) => {
            state.searchFolder = action.payload;
        },
        GET_FOLDER_DETAILS: (state, action) => {
            state.folderDetails = action.payload;
        },
        GET_FILE_DETAILS: (state, action) => {
            state.fileDetails = action.payload;
        },
        GET_PAYROLL_DETAILS: (state, action) => {
            state.payrollDetails = action.payload;
        },
        GET_ALLPAYROLL: (state, action) => {
            state.allPayroll = action.payload;
        },
    },
});

export const {
    LOADING,
    STOP_LOADING,
    GET_STAFF_DIRECTORY,
    GET_STAFF_BIO,
    GET_STAFF_ACAD,
    GET_STAFF,
    GET_PROFILE_DETAILS,
    GET_STAFF_FILES,
    GET_RECEIVED_FILES,
    GET_SINGLE_FILE,
    GET_ALL_LETTER,
    GET_ALL_SENT,
    GET_SENT_LETTERS,
    GET_LETTER,
    GET_STAFF_HANDBOOK,
    GET_STAFF_HANDBOOK_BY_ID,
    GET_SESSIONS,
    GET_STAFF_UPLOADS,
    GET_STAFF_PUBLICATION,
    GET_UPLOADED_FILES,
    GET_CREATED_FOLDERS,
    GET_SEARCH_STAFF,
    GET_SEARCH_FOLDER,
    GET_FOLDER_DETAILS,
    GET_FILE_DETAILS,
    GET_PAYROLL_DETAILS,
    GET_ALLPAYROLL,
} = staffArenaSlice.actions;

export default staffArenaSlice.reducer;
