import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ChevronIcon } from '../../../assets/icons/arrow-down-blue.svg';
import { ReactComponent as Dropdown } from '../../../assets/icons/arrow-down2.svg';
import { ReactComponent as BreadcrumbIcon } from '../../../assets/icons/Breadcrumbs.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-icon.svg';
import { ReactComponent as Plus } from '../../../assets/icons/circularplus-black.svg';
// import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/document-icon.svg';
import { ReactComponent as DropdownWhite } from '../../../assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit3.svg';
import { ReactComponent as PlusWhite } from '../../../assets/icons/plus-white.svg';
import { ActionsPopup } from '../../../components';
import { Button, SecondaryButton } from '../../../components/buttons';
import SetAdmissionYearDuration from '../../../components/forms/set-admission-year-duration';
import ListEmptyContent from '../../../components/list-empty';
import { Loader } from '../../../components/loader';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import {
    FlexRowSpaceBetween,
    CenteredContainer,
    FlexCentredRow,
    RelativeContainer,
} from '../../../containers/ScreenContainers';
import { getCalendarActivities } from '../../../redux/academic-calendar/actions';
import { getActiveSemester, getActiveSession, getAdmissionSessions } from '../../../redux/admissions/actions';

const MainContainer = tw.div`
    h-auto
    bg-white
    mx-auto
    lg:p-[2.4rem]
    p-[5.5rem]
    rounded-lg
`;

const Card = tw.div`
    h-auto
    bg-[#EEF2FF]
    p-[1.6rem]
    rounded-lg
    mb-[0.8rem]
`;

const Details = tw.div`
    pt-[6rem]
`;

const CalendarDetails = tw.div`
   pb-[5rem]
`;

const AcademicCalendar = ({ withCreate = false, academic = false }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { sessions, activeSession, activeSemester } = useSelector((state) => state.admission);
    const { isLoading: altLoading } = useSelector((state) => state.calendar);

    const [openDropdown, setOpenDropdown] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [step, setStep] = useState('Calendar');
    const [session, setSession] = useState(activeSession || '');
    const [semester, setSemester] = useState(activeSemester?.split(' ')?.[0] || 'First');
    const [activities, setActivities] = useState([]);
    const user = useSelector((state) => state.user.adminData);
    const [activityId, setActivityId] = useState('');

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const actionItemSemester = ['First', 'Second'].map((item) => ({
        name: item,
        click: () => {
            setSemester(item);
            setOpenDropdown(null);
        },
    }));

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const getActivities = async () => {
        const res = await dispatch(getCalendarActivities(session, semester.toLowerCase()));
        if (res) return setActivities(res);
        setActivities([]);
    };

    useEffect(() => {
        if (session && semester) {
            getActivities();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, session, semester]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
        dispatch(getActiveSession());
        dispatch(getActiveSemester());
    }, [dispatch]);

    if (altLoading) return <Loader />;

    return (
        <>
            <FlexRowSpaceBetween className="mb-[2.8rem]">
                {academic ? null : <PageTitle align="left">Academic Calendar</PageTitle>}

                {withCreate && (
                    <FlexCentredRow className="gap-x-4">
                        <Link to="create-academic-calendar">
                            <SecondaryButton>
                                <Plus />
                                Create Calendar
                            </SecondaryButton>
                        </Link>

                        <RelativeContainer>
                            <Button bgColor="#6366F1" color="#fff" onClick={() => handleOpenDropdown('actions')}>
                                Actions
                                <DropdownWhite />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'actions'}
                                close={() => setOpenDropdown(null)}
                                items={[
                                    {
                                        icon: <EditIcon />,
                                        name: 'Set session duration',
                                        click: () => {
                                            setShowForm(true);
                                            setOpenDropdown(false);
                                        },
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    </FlexCentredRow>
                )}
            </FlexRowSpaceBetween>

            <MainContainer>
                <FlexRowSpaceBetween>
                    <FlexCentredRow className="mr-[2.065rem] gap-5">
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('sessions')}
                            >
                                {session || 'Session'}
                                <Dropdown />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'sessions'}
                                close={() => setOpenDropdown(null)}
                                items={actionItemSessions}
                            />
                        </RelativeContainer>
                        <RelativeContainer>
                            <Button
                                color="#6B7280"
                                border="1px solid #9CA3AF"
                                onClick={() => handleOpenDropdown('semester')}
                            >
                                {`${semester} Semester` || 'Semester'}
                                <Dropdown />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'semester'}
                                close={() => setOpenDropdown(null)}
                                items={actionItemSemester}
                            />
                        </RelativeContainer>
                    </FlexCentredRow>
                    {activities.length ? (
                        <RelativeContainer>
                            <Button
                                bgColor="#FFF"
                                color="#6366F1"
                                border="0.5px solid #6366F1"
                                onClick={() => handleOpenDropdown('calendar-type')}
                            >
                                {step}
                                <ChevronIcon />
                            </Button>
                            <ActionsPopup
                                open={openDropdown === 'calendar-type'}
                                close={() => setOpenDropdown(null)}
                                items={[
                                    {
                                        icon: <CalendarIcon />,
                                        name: 'Calendar',
                                        click: () => {
                                            setStep('Calendar');
                                            handleOpenDropdown('calendar-type');
                                        },
                                    },
                                    {
                                        icon: <DocumentIcon />,
                                        name: 'Document',
                                        click: () => {
                                            setStep('Document');
                                            handleOpenDropdown('calendar-type');
                                        },
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    ) : null}
                </FlexRowSpaceBetween>

                {!activities.length ? (
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent title="No Calendar " subtitle={withCreate ? 'Add new academic Calendar' : ''}>
                            {withCreate && (
                                <Button
                                    bgColor="#10B981"
                                    color="#fff"
                                    onClick={() => navigate('/admissions/academic-calendar/create-academic-calendar')}
                                >
                                    <PlusWhite className="mr-[1.15rem]" />
                                    Create New
                                </Button>
                            )}
                        </ListEmptyContent>
                    </CenteredContainer>
                ) : (
                    <>
                        {step === 'Calendar' ? (
                            <Details>
                                {activities?.map((item) => (
                                    <>
                                        <PageTitle as="h2" size="2.8rem" align="left" bottom="1.2rem">
                                            Week {item.week}
                                        </PageTitle>
                                        {item?.activities?.map((activity, i) => (
                                            <>
                                                <Card key={activity._id}>
                                                    <div
                                                        className={
                                                            'flex flex-col items-center sm:gap-2 md:flex-row md:justify-between'
                                                        }
                                                    >
                                                        <Text align="left" lineHeight="2.4rem" weight="600">
                                                            {activity.description}
                                                        </Text>
                                                        <FlexCentredRow className="gap-[1.6rem]">
                                                            <FlexCentredRow>
                                                                <Text
                                                                    color="#6B7280"
                                                                    align="right"
                                                                    lineHeight="1.7rem"
                                                                    weight="400"
                                                                >
                                                                    {format(new Date(activity.startDate), 'cccc PP')} -
                                                                </Text>
                                                                <Text
                                                                    color="#6B7280"
                                                                    align="right"
                                                                    lineHeight="1.7rem"
                                                                    weight="400"
                                                                >
                                                                    {format(new Date(activity.endDate), 'cccc PP')}
                                                                </Text>
                                                            </FlexCentredRow>
                                                            {withCreate && (
                                                                <BreadcrumbIcon
                                                                    className="cursor-pointer"
                                                                    onClick={() => {
                                                                        setActivityId(activity._id);
                                                                    }}
                                                                />
                                                            )}
                                                            <RelativeContainer>
                                                                <ActionsPopup
                                                                    open={activityId === activity._id}
                                                                    close={() => setActivityId('')}
                                                                    items={[
                                                                        {
                                                                            icon: <Plus />,
                                                                            name: 'Add Activity to Week',
                                                                            click: () => {
                                                                                navigate('create-activity');
                                                                            },
                                                                        },
                                                                        {
                                                                            icon: <EditIcon />,
                                                                            name: 'Edit',
                                                                            click: () => {
                                                                                navigate('edit-academic-calendar');
                                                                            },
                                                                        },
                                                                        // {
                                                                        //     icon: <DeleteIcon />,
                                                                        //     name: (
                                                                        //         <span className="text-[#EF4444]">
                                                                        //             Delete
                                                                        //         </span>
                                                                        //     ),
                                                                        //     click: () => {
                                                                        //         navigate('edit-academic-calendar');
                                                                        //     },
                                                                        // },
                                                                    ]}
                                                                />
                                                            </RelativeContainer>
                                                        </FlexCentredRow>
                                                    </div>
                                                </Card>
                                            </>
                                        ))}
                                    </>
                                ))}
                            </Details>
                        ) : (
                            <CalendarDetails>
                                <>
                                    <PageTitle as="h2" size="2.8rem" align="left" bottom="5rem" top="6rem">
                                        {user?.institutionName}
                                    </PageTitle>
                                    <Text align="left" lineHeight="2.4rem" weight="600" bottom="2.4rem">
                                        Academic Calendar for {session} Session
                                    </Text>

                                    <Text align="left" lineHeight="2.4rem" weight="600" bottom="3.2rem">
                                        {/* TODO  need student type here, but can it work?*/}
                                        {/* {item.studentType} */}
                                    </Text>
                                    <Text align="left" lineHeight="2.4rem" weight="600" bottom="3.2rem">
                                        {semester} Semester
                                    </Text>
                                </>
                                {activities?.map((item) => (
                                    <ol type="a">
                                        {item?.activities?.map((activity) => (
                                            <li>
                                                <FlexRowSpaceBetween key={activity._id}>
                                                    <Text align="left" lineHeight="2.4rem" weight="600" color="#000000">
                                                        {activity.description}
                                                    </Text>
                                                    <FlexCentredRow className="gap-[0.5rem]">
                                                        <Text
                                                            color="#000000"
                                                            align="right"
                                                            lineHeight="2.4rem"
                                                            weight="500"
                                                        >
                                                            {format(new Date(activity.startDate), 'cccc PP')} -
                                                        </Text>
                                                        <Text
                                                            color="#000000"
                                                            align="right"
                                                            lineHeight="2.4rem"
                                                            weight="500"
                                                        >
                                                            {format(new Date(activity.endDate), 'cccc PP')}
                                                        </Text>
                                                    </FlexCentredRow>
                                                </FlexRowSpaceBetween>
                                            </li>
                                        ))}
                                    </ol>
                                ))}
                            </CalendarDetails>
                        )}
                    </>
                )}
            </MainContainer>

            <SetAdmissionYearDuration
                show={showForm}
                setShow={setShowForm}
                // onFinish={() => getDetails()}
            />
        </>
    );
};

export default AcademicCalendar;
