const currentEnv = process.env.REACT_APP_ENV;

export const bursaryNavLinks = [
    {
        title: 'Fee Management',
        to: '/bursary/fees',
    },
    {
        title: 'Debt Management',
        to: '/bursary/debtors-list',
    },
    {
        title: 'Agents Management',
        to: '/bursary/agent-list',
    },
    {
        title: 'Students',
        to: '/bursary/students',
    },

    {
        title: 'Communications',
        to: '/bursary/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/bursary/communications/compose',
            },
            {
                title: 'Received',
                to: '/bursary/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/bursary/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/bursary/communications/settings',
            },
        ],
    },
    {
        title: 'Discounts',
        to: '/bursary/discounts',
    },
    {
        title: 'Access',
        to: '/bursary/access',
    },
    ...(currentEnv === 'development'
        ? [
              {
                  title: 'Billing',
                  to: '/bursary/billing',
              },
          ]
        : []),
        {
            title: 'Payroll',
            to: '/bursary/payroll',
            subMenu: [
                {
                    title: 'Wallet',
                    to: '/bursary/payroll/wallet',
                },
                {
                    title: 'Staff Details',
                    to: '/bursary/payroll/staff-details',
                },
                {
                    title: 'Payment Group',
                    to: '/bursary/payroll/payment-group',
                },
                {
                    title: 'Settings',
                    to: '/bursary/payroll/settings',
                },
            ],
        },
    {
        title: 'Settings',
        to: '/bursary/settings',
    },
];

export const bursaryRoutes = bursaryNavLinks.map((item) => item.to);
