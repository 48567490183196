import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
    session: Yup.string().nullable().required('Required'),
    feeType: Yup.string().nullable().required('Required'),
    amountOwed: Yup.string().nullable().required('Required'),
    span: Yup.string().nullable().required('Required'),
    paymentConfig: Yup.string().nullable().required('Required'),
    debtSchedule: Yup.string().nullable().required('Required'),
    deadline: Yup.string()
        .nullable()
        .when('debtSchedule', {
            is: 'One-Off',
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable(),
        }),
    numberOfInstallments: Yup.string()
        .nullable()
        .when('debtSchedule', {
            is: 'Installment',
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable(),
        }),

    studentDecides: Yup.string()
        .nullable()
        .when('debtSchedule', {
            is: 'Installment',
            then: Yup.string().required('Required'),
            otherwise: Yup.string().nullable(),
        }),
    // ratios: Yup.string()
    //     .nullable()
    //     .when('studentDecides', {
    //         is: false,
    //         then: Yup.string().required('Required'),
    //         otherwise: Yup.string().nullable(),
    //     }),
    feeBreakdown: Yup.array().of(
        Yup.object().shape({
            amount: Yup.string(),
            description: Yup.string(),
        }),
    ),
});
