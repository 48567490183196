import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ReactComponent as DetachIcon } from '../../../assets/icons/Minus-Circle.svg';
import { Select } from '../../../components';
import { LoadingButton } from '../../../components/buttons';
import { CancelButton } from '../../../components/forms/sharedStyles';
import DetachFeeModal from '../../../components/popups/detach-fee';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow } from '../../../containers/ScreenContainers';
import { detachAgentFromFee, getFeesAttachedToAgent } from '../../../redux/bursary/actions';

const Container = tw.div`
    bg-white
    h-auto
    max-w-[1000px]
    px-[3.2rem]
    py-[3.6rem]
    rounded-[16px]
`;

const DetachFee = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { agent } = state || {};

    const { feesAttached, isLoading } = useSelector((store) => store.bursary);

    const [openModal, setOpenModal] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const { companyName, _id: companyId } = agent || {};
    const [selectedFees, setSelectedFees] = useState('');

    const handleCloseModal = () => {
        setInputValue('');
        setOpenModal(false);
    };

    const handleDetachFee = async () => {
        const payload = { feeId: selectedFees, agentId: companyId };
        const res = await dispatch(detachAgentFromFee(payload));
        if (res) {
            handleCloseModal();
            navigate(-1);
        }
    };

    useEffect(() => {
        dispatch(getFeesAttachedToAgent(companyId));
    }, [dispatch, companyId]);

    return (
        <>
            <FlexCentredRow onClick={() => navigate(-1)} className="gap-[0.5rem] hover:cursor-pointer mb-[3.3rem]">
                <BackArrow className="mr-[8px]" />

                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                    /
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Detach Fee From {companyName}
                </PageTitle>
            </FlexCentredRow>

            <Formik
                initialValues={{
                    feeId: '',
                }}
                // validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    setSelectedFees(values?.feeId);
                    setOpenModal('detach');
                }}
            >
                {({ handleChange, errors, setFieldValue, isSubmitting }) => (
                    <Form>
                        <div className="max-w-[900px]">
                            <Container>
                                <FlexCentredRow className="gap-[7.15px] mb-[1.5rem]">
                                    <DetachIcon />
                                    <Text size="1.6rem" weight="600">
                                        Detach Fee from {companyName}
                                    </Text>
                                </FlexCentredRow>
                                <FlexCentredCol className="gap-[2rem]">
                                    <Text align="left" weight="600" size="1.6rem">
                                        Attached Fee(s)
                                    </Text>
                                    <Text align="left" weight="400" size="1.6rem">
                                        Select fee(s) to detach from agent
                                    </Text>
                                    <FlexCentredCol className="gap-[1.6rem]">
                                        <Select
                                            name="feeId"
                                            objProp="feeName"
                                            placeholder="Select Fee(s) to detach"
                                            // label="Select "
                                            data={feesAttached}
                                            onChange={(selected) => {
                                                setFieldValue('feeId', selected?.[0]?.feeId);
                                            }}
                                            // multiSelect
                                            error={errors?.feeId}
                                        />
                                    </FlexCentredCol>
                                </FlexCentredCol>
                            </Container>
                            <div className="flex items-center gap-[1rem] my-[2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)} className="ml-auto">
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isSubmitting} bgColor="#6366F1" color="#fff" type="submit">
                                    Continue
                                </LoadingButton>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <DetachFeeModal
                input
                isLoading={isLoading}
                placeholder="detach"
                value={inputValue}
                onChange={(e) => setInputValue(e?.target?.value)}
                show={openModal === 'detach'}
                title="Detach Fee From Agent"
                close={handleCloseModal}
                subtitle="Detaching this fee from the agent will remove all students associated with the agent from this fee. This action cannot be undone."
                bodyText={
                    <p className="text-[16px] font-normal my-5">
                        To confirm, enter <b>Detach</b> in the text field
                    </p>
                }
                list={feesAttached?.filter((item) => selectedFees?.includes(item.feeId))}
                nameField="feeName"
                onSend={handleDetachFee}
                disabled={inputValue?.toLowerCase() !== 'detach'}
            />
        </>
    );
};

export default DetachFee;
