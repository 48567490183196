import React, { useEffect, useState } from 'react';

import { CNav, CTabs, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as UploadIcon } from '../../../assets/icons/upload4.svg';
import { SecondaryButton } from '../../../components/buttons';
// import { AllFilter } from '../../../components/filter-select';
// import FilterPopup from '../../../components/filter-select/filter-popup';
// import { Loader } from '../../../components/loader';
import ModalSelect from '../../../components/modal-select';
import { PageTitle } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { LEVELS } from '../../../data/constants';
import useGetFirstDegrees from '../../../hooks/useGetFirstDegrees';
import useGetSecondDegrees from '../../../hooks/useGetSecondDegrees';
import { getCurriculumByLastDegreeId } from '../../../redux/curriculum/actions';
import { getResultsBacklog } from '../../../redux/exam-magt/actions';
import { capitalizeFirstLetter, getDegreeStructs } from '../../../utils';
import { stringifyJSON, parseJSON } from '../../../utils/useJSON';

import Results from './tabs/Results';
import Students from './tabs/Students';

const LS_KEY = 'resultsState';

// TODO REMOVE FILTERS

const ResultsBacklog = () => {
    const dispatch = useDispatch();

    const localStorageState = parseJSON(LS_KEY, {});

    const { firstDegreeOptionsArray = [] } = useSelector((state) => state.academicStructure);
    const { sessions, activeSession, activeSemester } = useSelector((state) => state.admission);
    // const { isLoading: facultyLoading } = useSelector((state) => state.faculty);
    // const { isLoading: collegeLoading } = useSelector((state) => state.college);

    // const [/*searchValue,*/ setSearchValue] = useState('');
    const [openModal, setOpenModal] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(false);
    const [session, setSession] = useState(localStorageState?.session || activeSession || '');
    const [semester, setSemester] = useState(localStorageState?.semester || activeSemester?.split(' ')?.[0] || 'First');

    const [selectedFirstDegree, setSelectedFirstDegree] = useState(localStorageState?.selectedFirstDegree || null);
    const [selectedSecondDegree, setSelectedSecondDegree] = useState(localStorageState?.selectedSecondDegree || null);
    const { firstDegreeStruct, secondDegreeStruct } = getDegreeStructs(selectedFirstDegree?.academicStructure);
    const [academicUnit, setAcademicUnit] = useState(capitalizeFirstLetter(firstDegreeStruct) || '');

    const [selectedStudent, setSelectedStudent] = useState(null);
    const [curriculumData, setCurriculumData] = useState(localStorageState?.curriculumData || null);
    const [resultsData, setResultsData] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState(localStorageState?.selectedLevel || '');

    const { firstDegrees } = useGetFirstDegrees(academicUnit);
    const { secondDegrees } = useGetSecondDegrees(academicUnit, selectedFirstDegree);

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setCurriculumData(null);
            setSession(item.admissionYear);
            setOpenModal(null);
        },
    }));

    const actionItemSemester = ['First', 'Second'].map((item) => ({
        name: item,
        click: () => {
            setCurriculumData(null);
            setSemester(item);
            setOpenModal(null);
        },
    }));

    const actionItemAcademicunit = firstDegreeOptionsArray?.map((item) => ({
        name: item,
        click: () => {
            setSelectedFirstDegree(null);
            setSelectedSecondDegree(null);
            setAcademicUnit(item);
            setOpenModal(null);
        },
    }));

    const actionItemsFirstDegree = firstDegrees?.map((item) => ({
        name: item.facultyName,
        click: () => {
            setSelectedSecondDegree(null);
            setSelectedFirstDegree(item);
            setOpenModal(null);
        },
    }));

    const actionItemsSecondDegree = secondDegrees?.map((item) => ({
        name: item.departmentName,
        click: () => {
            setCurriculumData(null);
            setSelectedSecondDegree(item);
            setOpenModal(null);
        },
    }));

    const actionItemsLevels = LEVELS.map((item) => ({
        name: item,
        click: () => {
            setCurriculumData(null);
            setSelectedLevel(item);
            setOpenModal(null);
        },
    }));

    const handleOpenDropdown = (dropdown) => {
        if (openModal === dropdown) {
            setOpenModal(null);
        } else {
            setOpenModal(dropdown);
        }
    };

    const handleSelectStudent = (student) => {
        if (student?.studentId === selectedStudent?.studentId) return setSelectedStudent(null);
        setSelectedStudent(student);
    };

    const UPLOAD_STATE = {
        student: selectedStudent,
        session,
        level: selectedLevel,
        semester,
        // courses: curriculumData?.courses || [],
        firstDegree: selectedFirstDegree,
        secondDegree: selectedSecondDegree,
        results: resultsData?.data,
        curriculumId: curriculumData?._id,
    };

    useEffect(() => {
        const shouldFetchData =
            semester && session && selectedLevel && selectedFirstDegree && selectedSecondDegree && curriculumData;

        if (shouldFetchData) {
            const saveToLocalStorage = () => {
                const itemsToSaveInLocalStorage = {
                    semester,
                    session,
                    selectedLevel,
                    selectedFirstDegree,
                    selectedSecondDegree,
                    curriculumData,
                };
                stringifyJSON(LS_KEY, itemsToSaveInLocalStorage);
            };

            const getData = async () => {
                const params = {
                    level: selectedLevel,
                    session,
                    curriculumId: curriculumData._id,
                    academicStructureId: selectedFirstDegree.academicStructureId,
                    lastDegreeId: selectedSecondDegree._id,
                    semester: semester.toLowerCase(),
                };

                const res = await dispatch(getResultsBacklog(params));
                if (res) {
                    return setResultsData(res);
                }
                return setResultsData({});
            };

            saveToLocalStorage();
            getData();
        }
    }, [
        curriculumData,
        dispatch,
        selectedFirstDegree,
        selectedLevel,
        selectedSecondDegree,
        semester,
        session,
        toggleRefetch,
    ]);

    useEffect(() => {
        const shouldFetch = semester && session && selectedFirstDegree && selectedSecondDegree && selectedLevel;
        if (shouldFetch) {
            setResultsData({});
            setCurriculumData(null);

            const getData = async () => {
                const academicStructureId = selectedFirstDegree.academicStructureId;
                const lastDegreeId = selectedSecondDegree._id;

                const data = await dispatch(
                    getCurriculumByLastDegreeId(academicStructureId, lastDegreeId, session, selectedLevel),
                );

                if (data) {
                    const semesterTouse = semester + ' Semester';

                    const curriculumDat = data.find(
                        (item) => item.semester?.toLowerCase() === semesterTouse?.toLowerCase(),
                    );

                    if (!curriculumDat) return toast.error('No curriculum set');

                    return setCurriculumData(curriculumDat);
                }
            };
            getData();
        }
    }, [dispatch, selectedFirstDegree, selectedLevel, selectedSecondDegree, semester, session]);

    return (
        <div className="pb-10">
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Results Backlog</PageTitle>

                <FlexRowEnd className="gap-x-4">
                    <Link to="upload" state={UPLOAD_STATE}>
                        <SecondaryButton disabled={!selectedStudent}>
                            <UploadIcon />
                            Update Results
                        </SecondaryButton>
                    </Link>
                </FlexRowEnd>
            </FlexRowSpaceBetween>

            <div className=" flex gap-5 flex-wrap p-[16px] shadow-sm rounded-t-lg bg-white mb-6">
                <ModalSelect
                    placeholder="Session"
                    value={session}
                    onClick={() => handleOpenDropdown('sessions')}
                    open={openModal === 'sessions'}
                    close={() => setOpenModal(null)}
                    items={actionItemSessions}
                />
                <ModalSelect
                    placeholder="Semester"
                    value={semester ? `${semester} Semester` : ''}
                    onClick={() => handleOpenDropdown('semester')}
                    open={openModal === 'semester'}
                    close={() => setOpenModal(null)}
                    items={actionItemSemester}
                />
                <ModalSelect
                    placeholder="Level"
                    value={selectedLevel ? `${selectedLevel} Level` : ''}
                    onClick={() => handleOpenDropdown('levels')}
                    open={openModal === 'levels'}
                    close={() => setOpenModal(null)}
                    items={actionItemsLevels}
                />

                <ModalSelect
                    placeholder="Academic Unit"
                    value={academicUnit}
                    onClick={() => handleOpenDropdown('academic-unit')}
                    open={openModal === 'academic-unit'}
                    close={() => setOpenModal(null)}
                    items={actionItemAcademicunit}
                />

                {academicUnit && (
                    <ModalSelect
                        placeholder={academicUnit}
                        value={selectedFirstDegree?.facultyName}
                        onClick={() => handleOpenDropdown('first-degrees')}
                        open={openModal === 'first-degrees'}
                        close={() => setOpenModal(null)}
                        items={actionItemsFirstDegree}
                    />
                )}

                {secondDegreeStruct && (
                    <ModalSelect
                        placeholder={capitalizeFirstLetter(secondDegreeStruct)}
                        value={selectedSecondDegree?.departmentName}
                        onClick={() => handleOpenDropdown('second-degrees')}
                        open={openModal === 'second-degrees'}
                        close={() => setOpenModal(null)}
                        items={actionItemsSecondDegree}
                        absolutePosition="left-0"
                    />
                )}

                {/* <SearchInput withRadius onChange={(e) => setSearchValue(e.target.value)} value={searchValue} /> */}

                {/* // FILTERS.js */}
            </div>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink>Students</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Results</CNavLink>
                    </CNavItem>
                </CNav>

                <CTabContent>
                    <CTabPane>
                        <Students
                            selectedStudent={selectedStudent}
                            handleSelectStudent={handleSelectStudent}
                            session={session}
                            semester={semester}
                            firstDegree={selectedFirstDegree}
                            secondDegree={selectedSecondDegree}
                            level={selectedLevel}
                            curriculumId={curriculumData?._id}
                        />
                    </CTabPane>
                    <CTabPane>
                        <Results
                            selectedStudent={selectedStudent}
                            handleSelectStudent={handleSelectStudent}
                            // session={session}
                            // semester={semester}
                            // firstDegree={selectedFirstDegree}
                            // secondDegree={selectedSecondDegree}
                            // level={selectedLevel}
                            // curriculumId={curriculumData?._id}
                            resultsData={resultsData}
                            toggleRefetch={toggleRefetch}
                            setToggleRefetch={setToggleRefetch}
                        />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default ResultsBacklog;
