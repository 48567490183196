import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { Button } from '../../../../components/buttons';
import { Loader } from '../../../../components/loader';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';
import { getOrganizationProfile } from '../../../../redux/campus-management/actions';

const Container = tw.div`
p-[2.4rem]
bg-white
rounded-[0.8rem]
mt-[3.6rem]
flex
flex-col
gap-[1.6rem]
`;

const Details = tw.div`
flex
flex-col
gap-[1.6rem]
border-b-[1px]
py-[1.6rem]
border-b-[#E5E7EB]
last:border-b-[0px]
`;

const OrganizationProfile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { organizationProfile, isLoading } = useSelector((store) => store.campus);

    useEffect(() => {
        dispatch(getOrganizationProfile());
    }, [dispatch]);

    const orgProfile = [
        {
            title: 'Organization Name',
            value: organizationProfile?.institutionName,
        },
        {
            title: 'Organization Abbreviation',
            value: organizationProfile?.organizationAbbreviation,
        },
        {
            title: 'Organization Logo',
            value: organizationProfile?.logo?.url,
            type: 'image',
        },
        {
            title: 'Organization Address',
            value: organizationProfile?.institutionAddress,
        },
        {
            title: 'Country',
            value: organizationProfile?.institutionCountry,
        },
        {
            title: 'State',
            value: organizationProfile?.info?.state,
        },
        {
            title: 'Phone Number(s)',
            value: organizationProfile?.phoneNumber,
        },
        {
            title: 'Email Address',
            value: organizationProfile?.organizationEmail,
        },
        {
            title: 'P.O Box',
            value: organizationProfile?.info?.pob,
        },
        {
            title: 'Website',
            value: organizationProfile?.info?.website,
        },
        {
            title: 'About Organization',
            value: organizationProfile?.info?.about,
        },
        {
            title: 'Vision State',
            value: organizationProfile?.info?.visionStatement,
        },
        {
            title: 'Mission State',
            value: organizationProfile?.info?.missionStatement,
        },
        {
            title: 'Organization Images',
            value: organizationProfile?.organizationImages,
            type: 'image',
        },
    ];

    if (isLoading) return <Loader />;

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[0.8rem]">
                <BackArrow />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Organization Settings
                </PageTitle>
                <Text size="1.6rem">/</Text>
                <Text size="1.6rem">Organization Profile</Text>
            </FlexCentredRow>
            <Container>
                <div className="flex items-center justify-between">
                    <Text align="left" size="2.3rem" weight="600">
                        Organization Profile
                    </Text>
                    <Button type="button" onClick={() => navigate('edit')} bgColor="#6366F1" color="#FFF">
                        Edit
                    </Button>
                </div>
                {orgProfile?.map((data, i) => (
                    <Details key={i}>
                        <Text align="left" weight="500" size="1.6rem">
                            {data.title}
                        </Text>
                        {data.type === 'image' ? (
                            <>
                                {typeof data.value !== 'object' ? (
                                    <div className="w-[20.8rem] h-[18.4rem]">
                                        {data.value ? <img src={data.value} alt="org-logo" className="" /> : null}
                                    </div>
                                ) : (
                                    <div className="w-[20.8rem] h-[18.4rem] flex items-center gap-[3.2rem]">
                                        {data.value?.map((item, i) => (
                                            <img key={i} src={item?.url} alt="org-logo" className="" />
                                        ))}
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {typeof data.value !== 'object' ? (
                                    <Text align="left" weight="400" size="1.4rem">
                                        {data.value}
                                    </Text>
                                ) : (
                                    <div className="flex item-centers gap-[0.8rem] flexWrap">
                                        {data.value?.map((value, i) => (
                                            <Text key={i} align="left" weight="400" size="1.4rem">
                                                {value}
                                            </Text>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </Details>
                ))}
            </Container>
        </div>
    );
};

export default OrganizationProfile;
