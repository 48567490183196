import React, { useState } from 'react';

import { TextInput } from '@belrald_hq/belrald-ui';
import { CModal } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { generateMatricNumberToAll, generateMatricNumberToSelected } from '../../../../redux/admissions/actions';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 45.8rem;
        max-height: 60rem;
        margin: auto;
    }
`;

const ItemsContainer = tw.ul`
    overflow-y-auto
    border-b
    pb-2
    mt-5
`;

const CONFIRM_TEXT = 'generate';

const GenerateMatricNumber = ({ show, close, students, onFinish, isSendToAll, session }) => {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.admission);

    const [confirmTextValue, setConfirmTextValue] = useState('');

    const onClose = () => {
        setConfirmTextValue('');
        close();
    };

    const handleSubmit = async () => {
        const payload = {
            students: students?.map((item) => item._id),
        };

        const res = isSendToAll
            ? await dispatch(generateMatricNumberToAll(session))
            : await dispatch(generateMatricNumberToSelected(session, payload));

        if (res) {
            onClose();
            onFinish?.();
        }
    };

    return (
        <div>
            <StyledModal show={show} onClose={onClose} centered closeOnBackdrop={true} backdrop={true} color="info">
                <FlexRowSpaceBetween>
                    <Text weight="600" size="1.9rem" align="left">
                        Generate Matric Number for {isSendToAll ? 'All' : 'Selected'} Student(s)
                    </Text>
                    <CloseIcon className="hover:cursor-pointer" onClick={onClose} />
                </FlexRowSpaceBetween>

                <Text align="start" size="1.6rem" color="#374151" top="1rem" lineHeight="2.4rem" bottom="2.4rem">
                    Matric Numbers will be generated for students based on the Matric Number Settings.
                </Text>

                {!isSendToAll && (
                    <>
                        <ItemsContainer>
                            {students?.map((item, index) => (
                                <Text align="left" key={index}>{`${item?.fullName}`}</Text>
                            ))}
                        </ItemsContainer>
                        <hr />
                        <Text color="#6B7280" size="1.2rem" align="left">
                            {students?.length} name(s) selected
                        </Text>
                    </>
                )}

                <Text align="left" weight="400" size="1.6rem" bottom="1rem">
                    To confirm, enter <strong>generate</strong> in the text field
                </Text>

                <TextInput
                    type="text"
                    placeholder="Enter generate"
                    onChange={(e) => setConfirmTextValue(e.target.value)}
                    value={confirmTextValue}
                    required
                />

                <FlexRowEnd className="gap-x-[16px] mt-[1rem]">
                    <Button type="button" onClick={onClose} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton
                        disabled={confirmTextValue.toLowerCase() !== CONFIRM_TEXT || isLoading}
                        loading={isLoading}
                        onClick={handleSubmit}
                    >
                        Generate
                    </LoadingButton>
                </FlexRowEnd>
            </StyledModal>
        </div>
    );
};

export default GenerateMatricNumber;
