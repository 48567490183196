import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PageLayout from '../../../layout/page-layout/PageLayout';
import { getSavedAcademicStructure } from '../../../redux/academic-structure/actions';
import { SAVE_CURRENT_STRUCTURE } from '../../../redux/academic-structure/slice';
import { capitalizeEachWord } from '../../../utils';
import { storeUnit } from '../../../utils/originUnit';

const fields = [
    {
        accessorKey: 'name',
        header: 'Name',
        cell: (props) => <p>{props?.getValue()}</p>,
    },
];

const Academics = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, savedAcademicStructure } = useSelector((state) => state.academicStructure);
    const tableItems = Object.keys(savedAcademicStructure || {}).map((item) => ({ name: capitalizeEachWord(item) }));

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    useEffect(() => {
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    const academicData = tableItems?.map((item) => {
        return {
            name: (
                <td
                    style={{ color: '#2563EB' }}
                    className="cursor-pointer"
                    onClick={() => {
                        if (item?.name?.toLowerCase() === 'school') return;
                        navigate(`faculties`);
                    }}
                >
                    {item.name}
                </td>
            ),
        };
    });

    return (
        <div>
            <PageLayout
                showTableUtils
                backTitle="Dashboard"
                backLink="/dashboard"
                backSubtitle="Academics"
                pageTitle="Academics"
                fields={fields}
                loading={isLoading}
                data={academicData}
                onRowClick={(item) => {
                    const structure = {
                        name: item?.name?.props?.children,
                        items: savedAcademicStructure[item?.name?.props?.children?.toLowerCase()],
                    };
                    dispatch(SAVE_CURRENT_STRUCTURE(structure));
                }}
            />
        </div>
    );
};

export default Academics;
