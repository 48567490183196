import React, { useEffect, useMemo, useState } from 'react';

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as Circularplus } from '../../../../assets/icons/circularplus.svg';
import { Button } from '../../../../components/buttons';
import ListEmptyContent from '../../../../components/list-empty';
import { CountBadge, getStatusStyles, StatusContainer } from '../../../../components/shared';
import { Text } from '../../../../containers/MesssageContainers';
import { CenteredContainer } from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { getNonAcademicStaff } from '../../../../redux/staff/actions';

const fields = [
    {
        header: 'Full Name',
        accessorKey: 'fullName',
        cell: (props) => (
            <Link style={{ color: '#2563EB' }} to={props.row.original._id}>
                {props.getValue()}
            </Link>
        ),
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Designation',
        accessorKey: 'designation',
    },
    {
        header: 'Unit',
        accessorKey: 'unitName',
        cell: (props) => (
            <div className="flex items-center gap-[4px]">
                <Text color="#6b728">{props.getValue()[0] || '-'}</Text>
                {props.getValue()?.length > 1 ? <CountBadge>+{props.getValue()?.length - 1}</CountBadge> : null}
            </div>
        ),
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Email',
        accessorKey: 'staffEmail',
    },
    {
        header: 'Emp. Year',
        accessorKey: 'yearOfEmployment',
        cell: (props) => <p>{props?.getValue() ? format(new Date(props?.getValue()), 'PP') : ''}</p>,
    },
    {
        header: 'Admin Status',
        accessorKey: 'staffStatus',
        cell: (props) => (
            <StatusContainer style={getStatusStyles(props.getValue().toLowerCase())}>
                <span>{props.getValue()}</span>
            </StatusContainer>
        ),
    },
];

const NonAcademicStaff = ({ handleSelect }) => {
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.staff.isLoading);
    const staff = useSelector((state) => state.staff.nonAcademicStaff);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.searchTerm = debouncedSearchValue;
        } else {
            queryParams.staffEmail = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        dispatch(getNonAcademicStaff(query));
        // dispatch(getStaffInUnit());
    }, [dispatch, query]);

    return (
        <>
            <PageLayout
                noMargin
                showTableUtils
                loading={isLoading}
                data={staff || []}
                fields={fields}
                isActionable
                actionType="checkbox"
                onSelectedItemsChange={(items) => handleSelect(items)}
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent
                            isInTable
                            title="No Non-Academic Staff "
                            subtitle="Create new Non-Academic Staff"
                        >
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Link to="add-staff">
                                    <Button bgColor="#10B981" color="#fff">
                                        <Circularplus className="mr-[1.15rem]" />
                                        Create New
                                    </Button>
                                </Link>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
            />
        </>
    );
};

export default NonAcademicStaff;
