import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import {
    GET_All_HOSTELS,
    LOADING,
    STOP_LOADING,
    GET_BLOCK_BY_ID,
    GET_All_BLOCKS,
    GET_All_ROOMS,
    GET_ROOM_BY_ID,
    GET_All_PRICING_CATEGORIES,
    GET_BEDSPACE_BY_ID,
    GET_All_BEDSPACES,
} from './slice';

export const addNewHostel = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());

    try {
        const headers = {
            'Content-Type': 'multi-part/form-data',
        };

        const response = await axiosInstance.post(
            `${currentAPI}hostel/:campusId/:unitId/create_hostel`,
            payload,
            headers,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editNewHostel = (payload, headers, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}hostel/:campusId/:unitId/edit_hostel/${id}`,
            payload,
            headers,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const changeRoomStatus = (roomId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}hostel/:campusId/:unitId/change_room_status/${roomId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const manageRoomReservation = (roomId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}hostel/:campusId/:unitId/manage_room_reservations/${roomId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getHostelById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}hostel/:campusId/:unitId/get_hostel/${id}`);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllHostel = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}hostel/:campusId/:unitId/get_all_hostels?${queries}`);
        if (response.status === 200) {
            dispatch(GET_All_HOSTELS(response.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteHostel = (hostelId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}hostel/:campusId/:unitId/delete_hostel/${hostelId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getBlockById = (blockId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}hostel/:campusId/:unitId/get_block/${blockId}`);
        if (response.status === 200) {
            dispatch(GET_BLOCK_BY_ID(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllBlocks = (hostelId, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(
            `${currentAPI}hostel/:campusId/:unitId/get_all_blocks/${hostelId}?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_All_BLOCKS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteBlock = (blockId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}hostel/:campusId/:unitId/delete_block/${blockId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createBlock = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}hostel/:campusId/:unitId/create_block/${id}`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editBlock = (payload, hostelId, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}hostel/:campusId/:unitId/edit_block/${id}/${hostelId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addRoom = (payload, hostelId, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}hostel/:campusId/:unitId/create_room/${hostelId}/${id}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editRoom = (payload, roomId, blockId, hostelId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}hostel/:campusId/:unitId/edit_room/${roomId}/${blockId}/${hostelId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getRoomById = (roomId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}hostel/:campusId/:unitId/get_room/${roomId}`);
        if (response.status === 200) {
            dispatch(GET_ROOM_BY_ID(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllRooms = (blockId, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(
            `${currentAPI}hostel/:campusId/:unitId/get_all_rooms/${blockId}?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_All_ROOMS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteRoom = (roomId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}hostel/:campusId/:unitId/delete_room/${roomId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addBedspace = (payload, hosteld, blockId, roomId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}hostel/:campusId/:unitId/add_bed_space/${hosteld}/${blockId}/${roomId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editBedSpace = (payload, id, roomId, blockId, hostelId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}hostel/:campusId/:unitId/edit_bed_space/${id}/${roomId}/${blockId}/${hostelId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const changeBedspaceStatus = (bedspaceId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}hostel/:campusId/:unitId/change_bed_space_status/${bedspaceId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const manageBedspaceReservation = (bedspaceId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}hostel/:campusId/:unitId/manage_bed_space_reservations/${bedspaceId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getBedspaceById = (bedspaceId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}hostel/:campusId/:unitId/get_bedspace/${bedspaceId}`);
        if (response.status === 200) {
            dispatch(GET_BEDSPACE_BY_ID(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllBedspaces = (roomId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}hostel/:campusId/:unitId/get_all_bedspaces/${roomId}`);
        if (response.status === 200) {
            dispatch(GET_All_BEDSPACES(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteBedspace = (bedspaceId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(
            `${currentAPI}hostel/:campusId/:unitId/remove_bed_space/${bedspaceId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createRoom = (payload, roomId, blockId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}hostel/:campusId/:unitId/create_room/${roomId}/${blockId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllPricingCategories = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}hostel/:campusId/:unitId/get_all_pricing_tiers`);
        if (response.status === 200) {
            dispatch(GET_All_PRICING_CATEGORIES(response.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deletePricingTier = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}hostel/:campusId/:unitId/delete_pricing_tier/${id}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createPricingTier = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());

    try {
        const headers = {
            'Content-Type': 'multi-part/form-data',
        };

        const response = await axiosInstance.post(
            `${currentAPI}hostel/:campusId/:unitId/create_pricing_tier`,
            payload,
            headers,
        );
        if (response.status === 201) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editPricingTier = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    const headers = {
        'Content-Type': 'multi-part/form-data',
    };
    try {
        const response = await axiosInstance.put(
            `${currentAPI}hostel/:campusId/:unitId/edit_pricing_tier/${id}`,
            payload,
            headers,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
