import tw, { styled } from 'twin.macro';

export const StatusContainer = styled.div`
    background-color: ${({ bgColor }) => bgColor || '#ECFDF5'};
    color: ${({ color }) => color || '#059669'};
    padding: 2px 4px;
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 4px;
    line-height: 1.7rem;
    width: fit-content;
`;

export const StatusText = styled.span`
    font-size: ${({ size }) => size || '1.2rem'};
    font-weight: ${({ weight }) => weight || '500'};
    color: inherit;
    text-transform: capitalize;
`;

export const getStatusStyles = (status) => {
    switch (status) {
        case 'open':
        case 'accepted':
        case 'active':
        case 'published':
        case 'referred':
        case 'occupied':
            return {
                color: '#059669',
                backgroundColor: '#ECFDF5',
            };

        case 'closed':
        case 'expelled':
        case 'rejected':
        case 'deactivated':
        case 'unpublished':
        case 'inactive':
            return {
                color: '#DC2626',
                backgroundColor: '#FEF2F2',
            };

        case 'suspended':
        case 'in_active':
        case 'pending':
        case 'sent':
            return {
                color: '#F59E0B',
                backgroundColor: '#FFFBEB',
            };

        case 'not sent':
        case 'not referred':
            return {
                color: '#1F2937',
                backgroundColor: '#F3F4F6',
            };

        case 'not submitted':
        case 'available':
            return {
                color: '#2563EB',
                backgroundColor: '#F0FCFF',
            };

        case 'approved':
            return {
                color: '#10B981',
                backgroundColor: '#ECFDF5',
            };
        case 'custom':
            return {
                color: '#4F46E5',
                backgroundColor: '#EEF2FF',
            };

        case 'reserved':
            return {
                color: '#5B21B6',
                backgroundColor: '#EFF6FF',
            };

        case 'not available':
            return {
                color: '#92400E',
                backgroundColor: '#FFFBEB',
            };
        default:
            return {};
    }
};

export const CountBadge = tw.div`
    flex
    items-center
    justify-center
    h-[16px]
    px-[6px]
    text-center
    py-[2px]
    bg-[#DBEAFE]
    text-[#3B82F6]
    rounded-full
    text-[12px]
    font-semibold
`;
