import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    surname: Yup.string().required('Surname is required'),
    otherName: Yup.string().required('Other name is required'),
    staffEmail: Yup.string().required('Staff email is required').email('Staff email must be a valid email address'),
    yearOfEmployment: Yup.string().required('Year of employment is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    staffNumber: Yup.string().required('Staff number is required'),
    organizationRoleType: Yup.string()
        .required('Organization role type is required')
        .oneOf(['owner', 'member', 'view-only', 'administrator', 'access-manager'], 'Invalid organization role type'),
});

export default validationSchema;
