import React, { useMemo, useState } from 'react';

import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PaperIcon } from '../../../../assets/icons/people.svg';
import { LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../components/go-back';
import DatePicker from '../../../../components/inputs/date-picker';
import Select from '../../../../components/inputs/new-select';
import TextInput from '../../../../components/inputs/text-input-with-formik';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../../containers/ScreenContainers';
// import { getDesignationsInUnit } from '../../../../redux/designations/actions';
import { STAFF_TYPES } from '../../../../data/constants';
import useGetFirstDegrees from '../../../../hooks/useGetFirstDegrees';
import useGetSecondDegrees from '../../../../hooks/useGetSecondDegrees';
import { editStaff } from '../../../../redux/staff/actions';
import { capitalizeFirstLetter, getDegreeInfo, getDegreeStructs } from '../../../../utils';

import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
    max-w-[1000px]
    overflow-y-auto
    mt-[3.2rem]
`;

const GridRows = tw.div`
    grid
    md:grid-cols-2
    grid-rows-4
    gap-x-6
    gap-y-5
`;

const EditStaff = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state: staffDetails } = useLocation();

    const { isLoading } = useSelector((state) => state.staff);
    // const { activeOrgUnits: organisationUnits } = useSelector((state) => state.organisationUnits);
    // const { designations } = useSelector((state) => state.designations);

    const { firstDegreeStruct, secondDegreeStruct, thirdDegreeStruct } = getDegreeStructs(
        staffDetails?.academicStructure,
    );
    const { secondDegree, thirdDegree } = getDegreeInfo(staffDetails, 'academicStructure');
    const lastDegreeStruct = thirdDegreeStruct || secondDegreeStruct;
    const lastDegree = thirdDegreeStruct ? thirdDegree : secondDegree;
    const allowLastDegree = lastDegree && staffDetails?.staffType === STAFF_TYPES.Teaching;

    const { firstDegrees } = useGetFirstDegrees(firstDegreeStruct);
    const selectedFirstDegree = useMemo(
        () => firstDegrees?.find((item) => item?.facultyName === staffDetails?.[firstDegreeStruct]),

        [firstDegreeStruct, firstDegrees, staffDetails],
    );
    const { isLoading: secondDegreesLoading, secondDegrees } = useGetSecondDegrees(
        firstDegreeStruct,
        selectedFirstDegree,
    );

    const lastDegreeField = lastDegreeStruct === 'program' ? 'subProgram' : lastDegreeStruct;
    const secondaryLastDegreeField =
        lastDegreeStruct === 'program' ? 'secondarySubProgram' : 'secondary' + capitalizeFirstLetter(lastDegreeField);

    const prevSecondaryDegrees = secondDegrees.filter((item) =>
        staffDetails?.[secondaryLastDegreeField]?.includes(item._id),
    );

    const [selectedSecondarySecondDegree, setSelectedSecondarySecondDegree] = useState([...prevSecondaryDegrees]);

    // useEffect(() => {
    // dispatch(getDesignationsInUnit(unitId));
    // eslint-disable-next-line
    // }, [dispatch]);
    return (
        <>
            <GoBack title={`Staff Management`} subtitle="Edit Staff" />

            <Container>
                <Formik
                    initialValues={{
                        surname: staffDetails?.surname || '',
                        firstName: staffDetails?.firstName || '',
                        otherName: staffDetails?.otherName || '',
                        yearOfEmployment: staffDetails?.yearOfEmployment || '',
                        designation: staffDetails?.designation || '',
                    }}
                    enableReinitialize
                    validationSchema={ValidationSchema}
                    onSubmit={async (values) => {
                        const { staffType, ...otherFormValues } = values;
                        const date = format(new Date(values.yearOfEmployment), 'yyyy-MM-dd');

                        const secondaryLastDegreeIds = selectedSecondarySecondDegree
                            .filter((item) => item.departmentName !== secondDegree)
                            .map((item) => item._id);

                        const payload = {
                            ...otherFormValues,
                            yearOfEmployment: date,
                            ...(allowLastDegree && secondaryLastDegreeIds?.length > 0
                                ? { [secondaryLastDegreeField]: secondaryLastDegreeIds }
                                : {}),
                        };

                        const res = await dispatch(
                            editStaff(staffDetails.academicStructureId, staffDetails._id, payload),
                        );

                        if (res) {
                            toast.success('Staff details updated successfully');
                            navigate(-1);
                        }
                    }}
                >
                    {({ errors, handleChange, values, touched, setFieldValue }) => (
                        <Form>
                            <FlexCentredRow className="gap-2 mb-8">
                                <PaperIcon />
                                <Text weight="600" size="1.6rem">
                                    Edit Staff
                                </Text>
                            </FlexCentredRow>
                            <GridRows>
                                <TextInput
                                    name="surname"
                                    type="text"
                                    label="Surname"
                                    placeholder="Surname"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.surname}
                                />

                                <TextInput
                                    name="firstName"
                                    type="text"
                                    label="First Name"
                                    placeholder="First Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.firstName}
                                />

                                <TextInput
                                    name="otherName"
                                    label="Other Names"
                                    type="text"
                                    placeholder="Other Names"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.otherName}
                                />

                                {/* <Select
                                name="designation"
                                objProp="designation"
                                label="Designation"
                                placeholder="Designation"
                                data={designations}
                                passedSelectedItems={
                                    values.designation ? { designation: `${values.designation}` } : null
                                }
                                onChange={(selected) => {
                                    setFieldValue('designation', selected[0].designation);
                                }}
                                error={errors.designation}
                            /> */}

                                {allowLastDegree && (
                                    <Select
                                        name="secondarySecondDegree"
                                        objProp="departmentName"
                                        multiSelect
                                        useComponentState={false}
                                        placeholder={capitalizeFirstLetter(lastDegreeStruct)}
                                        label={`Secondary ${capitalizeFirstLetter(lastDegreeStruct)} (Optional)`}
                                        isLoading={secondDegreesLoading}
                                        data={secondDegrees.filter((item) => item.departmentName !== secondDegree)}
                                        passedSelectedItems={selectedSecondarySecondDegree}
                                        onChange={(selected) => {
                                            setSelectedSecondarySecondDegree(selected);
                                        }}
                                    />
                                )}

                                <DatePicker
                                    label="Employment Date"
                                    touched={touched}
                                    name="yearOfEmployment"
                                    maxDate={new Date()}
                                    placeholder="Employment Date"
                                    initialValue={values?.yearOfEmployment ? new Date(values?.yearOfEmployment) : null}
                                />
                            </GridRows>
                            <FlexRowEnd className="gap-3 mt-4">
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} type="submit">
                                    Update
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default EditStaff;
