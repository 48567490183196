import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../../../assets/icons/arrow-down2.svg';
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download.svg';
import { ReactComponent as PeopleIcon } from '../../../../../assets/icons/peopleIcon.svg';
import { ActionsPopup } from '../../../../../components';
import { Button, LoadingButton } from '../../../../../components/buttons';
import { CancelButton } from '../../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../../components/go-back';
import TextAreaInput from '../../../../../components/inputs/text-area';
import TextInput from '../../../../../components/inputs/text-input-with-formik';
import { Loader } from '../../../../../components/loader';
import ImportAcademicSettings from '../../../../../components/popups/import-settings';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { RelativeContainer, FlexRowSpaceBetween } from '../../../../../containers/ScreenContainers';
import {
    createProbationListSetting,
    getDegreeSetting,
    getProbationListSetting,
    importProbationList,
} from '../../../../../redux/settings/actions';
import { generateLevels } from '../../../../../utils';

import { ValidationSchema } from './ValidationSchema';

const Header = tw.h3`
    font-semibold
    text-[1.4rem]
    text-[#1F2937]
    mb-[2rem]
    mt-10
`;

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[10.3rem]
    justify-end
`;

const MainContainer = tw.div`
    bg-white
    px-[3rem]
    py-[3rem]
`;

const STEP = 0.01;
const MIN = 0.0;

const ProbationList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { sessions, activeSession } = useSelector((state) => state.admission);
    const { currentDepartment } = useSelector((state) => state.department);
    const { isLoading, probationSettings, degreeSettings } = useSelector((state) => state.settings);
    const { currentProgramme } = useSelector((state) => state.programmes);
    const { currentFaculty } = useSelector((state) => state.faculty);

    const { _id: firstDegreeId } = currentFaculty;
    const { departmentId, studyDuration, academicStructure = '', academicStructureId } = currentDepartment;
    const { _id: programId } = currentProgramme || {};
    const { degreePoint } = degreeSettings || {};

    const splitStruct = academicStructure.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const [openDropdown, setOpenDropdown] = useState(null);
    const [altLoading, setAltLoading] = useState(false);
    const [session, setSession] = useState(activeSession || '');
    const levels = generateLevels(studyDuration);

    const createFormikLevelsData = () => {
        const formikLevelsData = {};
        levels?.forEach((num) => {
            formikLevelsData[num] = 0;
        });
        return formikLevelsData;
    };

    const handleChange = (e, setFieldValue, fieldName) => {
        if (!degreePoint) return;

        const value = e.target.value;
        if (value > degreePoint) {
            toast.error(`You can't set a value greater than ${degreePoint}`);
            return;
        }
        setFieldValue(fieldName, value);
    };

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const handleImportProbationList = async (values) => {
        const res = await dispatch(importProbationList(academicStructureId, lastDegreeId, values));
        if (res) {
            setOpenDropdown(null);
        }
    };

    useEffect(() => {
        if (!session) return;
        dispatch(getProbationListSetting(academicStructureId, lastDegreeId, session));
    }, [academicStructureId, dispatch, lastDegreeId, session]);

    useEffect(() => {
        if (!session) return;
        dispatch(getDegreeSetting(academicStructureId, firstDegreeId, session));
    }, [academicStructureId, dispatch, firstDegreeId, session]);

    if (isLoading) return <Loader />;

    return (
        <div className="max-w-[100.3rem]">
            <GoBack title="Settings" subtitle="Probation List" />

            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle weight="600" size="2.3rem" lineHeight="3.4rem" top="3.6rem" bottom="3.7rem" align="left">
                    Probation List
                </PageTitle>
                <Button border="0.5px solid #E5E7EB" onClick={() => handleOpenDropdown('import')}>
                    <DownloadIcon />
                    Import Probation List
                </Button>
            </FlexRowSpaceBetween>
            <MainContainer>
                {!degreePoint && (
                    <Text color="red" align="left" bottom="2rem">
                        Organisation needs to set the GPA scale in class of degree settings!
                    </Text>
                )}

                <Formik
                    initialValues={{
                        message: probationSettings?.message || '',
                        minimumCgpa: probationSettings?.minimumCgpa || {
                            ...createFormikLevelsData(),
                        },
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values) => {
                        setAltLoading(true);
                        const payload = {
                            ...values,
                            message: values.message.trim(),
                        };

                        const res = await dispatch(
                            createProbationListSetting({
                                session,
                                academicStructureId,
                                lastDegreeId,
                                payload,
                                useLoader: false,
                            }),
                        );
                        if (res) {
                            toast.success('Setting saved succesfully!');
                        }
                        setAltLoading(false);
                    }}
                >
                    {({ errors, setFieldValue, values }) => (
                        <Form>
                            <RelativeContainer className="max-w-max my-12">
                                <Button
                                    color="#6B7280"
                                    type="button"
                                    border="1px solid #9CA3AF"
                                    onClick={() => handleOpenDropdown('sessions')}
                                >
                                    {session || 'Session'}
                                    <Dropdown />
                                </Button>
                                <ActionsPopup
                                    open={openDropdown === 'sessions'}
                                    close={() => setOpenDropdown(null)}
                                    items={actionItemSessions}
                                />
                            </RelativeContainer>

                            <div className="flex items-center gap-[1.6rem] mb-5">
                                <PeopleIcon />
                                <Text size="1.6rem" weight="600">
                                    Set Probation List Conditions
                                </Text>
                            </div>

                            <TextAreaInput
                                label="Input Message"
                                value={values.message}
                                name="message"
                                height="8rem"
                                errorMessage={errors.message}
                                onChange={(e) => setFieldValue('message', e.target.value)}
                                placeholder={
                                    'e.g. "Students who do not meet up with the minimum required GPA in either of both semesters would be placed on probation and hence would be added to the probation list."'
                                }
                            />

                            <Header>Choose Minimum CGPA</Header>
                            <div className="gap-8 flex flex-col">
                                {levels?.map((level) => {
                                    const fieldName = `minimumCgpa.${level}`;
                                    return (
                                        <div key={fieldName}>
                                            <TextInput
                                                name={fieldName}
                                                type="number"
                                                label={`${level} Level`}
                                                step={STEP}
                                                min={MIN}
                                                max={degreePoint}
                                                errors={errors}
                                                placeholder="Enter minimum CGPA"
                                                value={values.minimumCgpa[`${level}`]}
                                                onChange={(e) => handleChange(e, setFieldValue, fieldName)}
                                                width="31rem"
                                            />
                                        </div>
                                    );
                                })}
                            </div>

                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={altLoading} disabled={!session || altLoading} type="submit">
                                    Save
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
            <ImportAcademicSettings
                show={openDropdown === 'import'}
                close={() => setOpenDropdown(null)}
                sessions={sessions}
                title={'Import Probation List Settings'}
                onSubmitAction={(values) => handleImportProbationList(values)}
            />
        </div>
    );
};

export default ProbationList;
