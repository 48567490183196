import React, { useState } from 'react';

import { Table } from '@belrald_hq/belrald-ui';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ReactComponent as Dropdown } from '../../../assets/icons/dropdown.svg';
import { ReactComponent as People } from '../../../assets/icons/people2.svg';
import { ReactComponent as ProfilePicture } from '../../../assets/images/profile-picture.svg';
import { Button } from '../../../components/buttons';
import EditStudentRecordsForm from '../../../components/forms/edit-student-records';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexCentredRow, FlexDiv } from '../../../containers/ScreenContainers';

const MainContainer = tw.div`
    grid
    gap-8
    mt-8
    mb-[6.4rem]
`;

const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    line-height: 2.4rem;
    text-align: left;
`;

const fields = [
    {
        header: 'Course Code',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorkey: 'courseCode',
    },
    {
        header: 'Session',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorkey: 'session',
    },
    {
        header: 'Course Title',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorkey: 'courseTitle',
    },
    {
        header: 'Unit',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorkey: 'unit',
    },
    {
        header: 'Status',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorkey: 'status',
    },
    {
        header: 'Marks(%)',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorkey: 'mark',
    },
    {
        header: 'G.P',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorkey: 'gp',
    },
    {
        header: 'W.G.P',
        cell: (props) => <p>{props?.getValue()}</p>,
        accessorkey: 'wgp',
    },
];

const ExamsRecordsStudentDetails = () => {
    const navigate = useNavigate();

    const [showForm, setShowForm] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
        <div>
            <FlexRowSpaceBetween>
                <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer">
                    <BackArrow className="mr-[8px]" />
                    <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                        Exams & Records / Departments / Students
                    </PageTitle>
                </FlexCentredRow>

                <FlexCentredRow>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                        Actions
                        <Dropdown />
                    </Button>
                </FlexCentredRow>
            </FlexRowSpaceBetween>

            <MainContainer>
                <Details className="mt-[3rem]">
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Students Details
                            </PageTitle>
                        </FlexCentredRow>
                        <FlexDiv className="justify-end gap-[3.2rem]">
                            <Button bgColor="#ECFDF5">
                                <Text weight="500" lineHeight="2.4rem" color="#059669">
                                    Active
                                </Text>
                            </Button>
                            <Button bgColor="#fff" onClick={() => setShowForm(true)}>
                                <Text weight="500" lineHeight="2.4rem" color="#059669">
                                    Edit
                                </Text>
                            </Button>
                        </FlexDiv>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        <ProfilePicture className="col-span-1" />
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Full Name</GreyText>
                                {/* <DarkText>Emmanuel Olukunle Peter</DarkText> */}
                            </FlexCol>
                            <FlexCol>
                                <GreyText>Gender</GreyText>
                                <DarkText>{/* Male */}</DarkText>
                            </FlexCol>
                            <FlexCol>
                                <GreyText>Date Of Birth</GreyText>
                                {/* <DarkText>23 March, 2001</DarkText> */}
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Matric No.</GreyText>
                                {/* <DarkText>MEE148633</DarkText> */}
                            </FlexCol>
                            <FlexCol>
                                <GreyText>Year of Admission</GreyText>
                                {/* <DarkText>2018/2019 Session</DarkText> */}
                            </FlexCol>
                            <FlexCol>
                                <GreyText>Faculty</GreyText>
                                {/* <DarkText>Engineering</DarkText> */}
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Department</GreyText>
                                {/* <DarkText>Mechanical Engineering</DarkText> */}
                            </FlexCol>
                            <FlexCol>
                                <GreyText>Mode of Entry</GreyText>
                                {/* <DarkText>Direct Entry</DarkText> */}
                            </FlexCol>
                            <FlexCol>
                                <GreyText>Admission Year</GreyText>
                                {/* <DarkText>March, 2015.</DarkText> */}
                            </FlexCol>
                        </RowContainer>
                    </BasicContent>
                </Details>

                <Details>
                    <FlexCentredRow>
                        <People fill="#3B82F6" className="mr-[1.719rem]" />
                    </FlexCentredRow>

                    <Table data={[]} columns={fields} />

                    <FlexRowSpaceBetween className="px-[1.8rem]">
                        <Text color="#9CA3AF" size="1.6rem">
                            Cumulative Units Registered:
                            <Text as="span" weight="500">
                                {/* 240 */}
                            </Text>
                        </Text>
                        <Text color="#9CA3AF" size="1.6rem">
                            Cumulative Units Passed:
                            <Text as="span" weight="500">
                                {/* 240 */}
                            </Text>
                        </Text>
                        <Text color="#9CA3AF" size="1.6rem">
                            Cumulative GPA:
                            <Text as="span" weight="500">
                                {/* 4.0 */}
                            </Text>
                        </Text>
                        <Text color="#9CA3AF" size="1.6rem">
                            Total Weighted Grade Points:
                            <Text as="span" weight="500">
                                {/* 240 */}
                            </Text>
                        </Text>
                    </FlexRowSpaceBetween>
                </Details>
            </MainContainer>
            {showForm && <EditStudentRecordsForm show={showForm} setShow={setShowForm} />}
        </div>
    );
};

export default ExamsRecordsStudentDetails;
