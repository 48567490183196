import React from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { FlexCentredCol, FlexCentredRow } from '../../../containers/ScreenContainers';
import { createCourse } from '../../../redux/courses/actions';
import { capitalizeFirstLetter, generateLevels } from '../../../utils';
import { LoadingButton } from '../../buttons';
import { CancelButton } from '../../forms/sharedStyles';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const TextArea = tw.textarea`
    w-full
    outline-none
    placeholder:text-[#9CA3AF]
    placeholder:text-[1.4rem]
    placeholder:font-semibold
    leading-6
    font-normal
    text-[1.4rem]
    border-[1px]
    border-[#E5E7EB]
    pl-3
    py-4
`;

const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[100.5rem]
`;

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#1F2937]
  mb-[0]
`;

const Label = tw.label`
    text-[#6B7280]
    font-bold
    text-[1.4rem]
    mt-[2.4rem]
`;

const CreateNewCourse = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentDepartment } = useSelector((state) => state.department);
    const { currentFaculty } = useSelector((state) => state.faculty);
    const { isLoading } = useSelector((state) => state.courses);

    const { _id: departmentId, departmentCode: departmentCodeData, studyDuration } = currentDepartment || {};
    const { splitAcademicStructureTwo, academicStructureId } = currentFaculty;

    return (
        <>
            <MainContainer>
                <Formik
                    initialValues={{
                        courseCode: '',
                        courseTitle: '',
                        courseUnit: '',
                        courseSemester: '',
                        courseLevel: '',
                        minimumPassScore: '',
                        departmentCode: '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const { departmentCode, courseTitle, courseCode, ...commonValues } = values;
                        const structTwo = splitAcademicStructureTwo.toLowerCase();

                        const structMapping = {
                            department: 'departmentCode',
                            program: 'subProgramCode',
                            school: 'schoolCode',
                            faculty: 'facultyCode',
                            college: 'collegeCode',
                        };

                        const payload = {
                            ...commonValues,
                            [structMapping[structTwo]]: departmentCode,
                            courseTitle: capitalizeFirstLetter(courseTitle),
                            courseCode: String(courseCode),
                        };

                        const res = await dispatch(createCourse(academicStructureId, departmentId, payload));

                        if (res) {
                            toast.success('Course was added successfully');
                            actions.resetForm();
                            navigate(-1);
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <FlexCentredRow className="mb-[2.4rem] mt-[1.2rem] gap-[0.715rem]">
                                <PeopleIcon />
                                <Title>Add Course</Title>
                            </FlexCentredRow>
                            <div className="gap-y-[2.4rem] gap-x-[3.2rem] grid grid-cols-2">
                                <TextInput
                                    name="courseTitle"
                                    label="Course Title"
                                    type="text"
                                    placeholder="Course Title"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.courseTitle}
                                />
                                <Select
                                    name="departmentCode"
                                    objProp="name"
                                    label={`${splitAcademicStructureTwo} Code (Prefix for course code)`}
                                    data={departmentCodeData?.map((item) => ({ name: item }))}
                                    placeholder={`${splitAcademicStructureTwo} Code`}
                                    onChange={(selected) => {
                                        setFieldValue('departmentCode', selected[0].name);
                                    }}
                                    error={errors.departmentCode}
                                />
                                <TextInput
                                    name="courseCode"
                                    label="Course Code"
                                    type="number"
                                    placeholder="Course Code e.g. 101"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.courseCode}
                                />

                                <Select
                                    name="courseLevel"
                                    objProp="name"
                                    label="Course Level"
                                    data={generateLevels(studyDuration).map((item) => ({ name: `${item}` }))}
                                    placeholder="Level"
                                    onChange={(selected) => {
                                        setFieldValue('courseLevel', selected[0].name);
                                    }}
                                    error={errors.courseLevel}
                                />

                                <TextInput
                                    name="courseUnit"
                                    label="Course Unit"
                                    type="number"
                                    placeholder="Course Unit"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.courseUnit}
                                />

                                <Select
                                    name="courseSemester"
                                    objProp="name"
                                    label="Semester"
                                    data={[
                                        { name: 'First', value: '1' },
                                        { name: 'Second', value: '2' },
                                    ]}
                                    placeholder="Course Semester"
                                    onChange={(selected) => {
                                        setFieldValue('courseSemester', selected[0].value);
                                    }}
                                    error={errors.courseSemester}
                                />
                                <TextInput
                                    name="minimumPassScore"
                                    label="Minimum Pass Mark (%)"
                                    placeholder="Minimum Pass Mark (%)"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.minimumPassScore}
                                />
                            </div>
                            <FlexCentredCol>
                                <Label>Description</Label>
                                <TextArea />
                            </FlexCentredCol>
                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    disabled={isLoading}
                                    loading={isLoading}
                                    bgColor="#6366F1"
                                    color="#ffffff"
                                    type="submit"
                                >
                                    Create Course
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </>
    );
};
export default CreateNewCourse;
