import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as RemoveIcon } from '../../../assets/icons/circular-cancel-red.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send-black.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { ConfirmActionDialogue } from '../../../components';
import PageLayout from '../../../layout/page-layout/PageLayout';
import {
    archiveOrganizationStaff,
    deleteOrganizationStaff,
    getOrganizationArchiveStaff,
    getOrganizationStaffs,
    inviteOrganizationStaff,
    reinstateOrganizationStaff,
    revokeOrganizationStaff,
} from '../../../redux/campus-management/actions';

import ActiveStaff from './ActiveStaff';
import ArchivedStaff from './ArchivedStaff';

const AccessManager = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, organizationStaffs, archiveStaffs } = useSelector((store) => store.campus);

    const [searchValue, setSearchValue] = useState('');
    const [openModal, setOpenModal] = useState(null);
    const [activeTab, setActiveTab] = useState('active');
    const [selectedItem, setSelectedItem] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [refetchData, setRefetchData] = useState(false);

    useEffect(() => {
        dispatch(getOrganizationStaffs());
        dispatch(getOrganizationArchiveStaff());
    }, [dispatch, refetchData]);

    const handlePostAction = () => {
        setSelectedItem(null);
        setInputValue('');
        setRefetchData(!refetchData);
        setOpenModal(null);
    };

    const handleArchiveStaff = async () => {
        const res = await dispatch(archiveOrganizationStaff(selectedItem?._id));
        if (res) {
            handlePostAction();
        }
    };

    const handleReInstateStaff = async () => {
        const res = await dispatch(reinstateOrganizationStaff(selectedItem?._id));
        if (res) {
            handlePostAction();
        }
    };

    const handleDeleteStaff = async () => {
        const res = await dispatch(deleteOrganizationStaff(selectedItem?._id));
        if (res) {
            handlePostAction();
        }
    };

    const handleRevokeStaff = async () => {
        const res = await dispatch(revokeOrganizationStaff(selectedItem?._id));
        if (res) {
            handlePostAction();
        }
    };

    const handleInviteStaff = async () => {
        const res = await dispatch(inviteOrganizationStaff(selectedItem?._id));
        if (res) {
            handlePostAction();
        }
    };

    const handleCloseModal = () => {
        setInputValue('');
        setOpenModal(null);
    };

    return (
        <div>
            <PageLayout
                pageTitle="Access Manager"
                secondaryButtonText="Add Staff"
                onSecondaryButtonClick={() => navigate('add-staff')}
                actionItems={[
                    ...(activeTab === 'active'
                        ? [
                              {
                                  icon: <EditIcon fill="#1f2937" />,
                                  name: 'Edit Staff',
                                  click: () => navigate(`edit-staff/${selectedItem?._id}`, { state: selectedItem }),
                                  disabled: !selectedItem,
                              },
                              {
                                  icon: <SendIcon />,
                                  name: 'Send Invite',
                                  click: () => {
                                      setOpenModal('send-invite');
                                  },
                                  disabled: !selectedItem || selectedItem?.staffStatus?.toLowerCase() === 'active',
                              },
                              {
                                  icon: <RemoveIcon />,
                                  name: 'Revoke Invite',
                                  click: () => setOpenModal('revoke'),
                                  disabled:
                                      !selectedItem ||
                                      selectedItem?.staffStatus?.toLowerCase() === 'in_active' ||
                                      selectedItem?.isInvitedToOrganization?.toLowerCase() === 'accepted',
                              },
                              {
                                  icon: <DeleteIcon />,
                                  name: 'Archive Staff',
                                  click: () => setOpenModal('archive-staff'),
                                  disabled: !selectedItem,
                              },
                          ]
                        : []),
                    ...(activeTab === 'archived'
                        ? [
                              {
                                  icon: <EditIcon fill="#1f2937" />,
                                  name: 'Re-instate Staff',
                                  click: () => setOpenModal('reinstate'),
                                  disabled: !selectedItem,
                              },
                              {
                                  icon: <DeleteIcon />,
                                  name: 'Delete Staff',
                                  click: () => setOpenModal('delete'),
                                  disabled: !selectedItem,
                              },
                          ]
                        : []),
                ]}
                tabComponent={
                    <div className="mt-[3rem]">
                        <CTabs>
                            <CNav variant="tabs" className="">
                                <CNavItem onClick={() => setActiveTab('active')}>
                                    <CNavLink>Active</CNavLink>
                                </CNavItem>
                                <CNavItem onClick={() => setActiveTab('archived')}>
                                    <CNavLink>Archive</CNavLink>
                                </CNavItem>
                            </CNav>
                            <CTabContent>
                                <CTabPane>
                                    <ActiveStaff
                                        organizationStaffs={organizationStaffs?.filter(
                                            (item) => item?.staffStatus !== 'ARCHIVED',
                                        )}
                                        setSelectedItem={setSelectedItem}
                                        searchValue={searchValue}
                                        setSearchValue={setSearchValue}
                                        isLoading={isLoading}
                                    />
                                </CTabPane>
                                <CTabPane>
                                    <ArchivedStaff
                                        organizationStaffs={archiveStaffs}
                                        setSelectedItem={setSelectedItem}
                                        searchValue={searchValue}
                                        setSearchValue={setSearchValue}
                                        isLoading={isLoading}
                                    />
                                </CTabPane>
                            </CTabContent>
                        </CTabs>
                    </div>
                }
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
            />
            <ConfirmActionDialogue
                borderLine
                input
                isLoading={isLoading}
                placeholder="Enter archive"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                show={openModal === 'archive-staff'}
                title="Archive Staff"
                close={handleCloseModal}
                subtitle="This staff will be archived."
                bodyText="To confirm, enter 'archive' in the text field."
                btn2Text="Yes, Archive"
                btnDisabled={inputValue.toLowerCase() !== 'archive'}
                confirmAction={handleArchiveStaff}
            />
            <ConfirmActionDialogue
                svgIcon={<WarningIcon fill="#3B82F6" />}
                borderLine
                input
                isLoading={isLoading}
                placeholder="Input Re-instate"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                show={openModal === 'reinstate'}
                title="Re-instate Staff"
                close={handleCloseModal}
                subtitle="This staff will be re-instated."
                bodyText="To confirm, enter 're-instate' in the text field."
                btn2Text="Yes, Re-instate"
                btnDisabled={inputValue.toLowerCase() !== 're-instate'}
                confirmAction={handleReInstateStaff}
            />
            <ConfirmActionDialogue
                borderLine
                input
                isLoading={isLoading}
                placeholder="Input delete"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                show={openModal === 'delete'}
                title="Delete Staff"
                close={handleCloseModal}
                subtitle="This staff will be deleted."
                bodyText="To confirm, enter 'delete' in the text field."
                btn2Text="Yes, Delete"
                btnDisabled={inputValue.toLowerCase() !== 'delete'}
                confirmAction={handleDeleteStaff}
            />
            <ConfirmActionDialogue
                borderLine
                input
                isLoading={isLoading}
                placeholder="Input revoke"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                show={openModal === 'revoke'}
                title="Revoke Invite"
                close={handleCloseModal}
                subtitle="This staff's invite will be revoked."
                bodyText="To confirm, enter 'revoke' in the text field."
                btn2Text="Yes, Revoke"
                btnDisabled={inputValue.toLowerCase() !== 'revoke'}
                confirmAction={handleRevokeStaff}
            />
            <ConfirmActionDialogue
                borderLine
                input
                isLoading={isLoading}
                placeholder="Input invite"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                show={openModal === 'send-invite'}
                title="Send Invite"
                close={handleCloseModal}
                subtitle="This staff's will be invited to the organization."
                bodyText="To confirm, enter 'invite' in the text field."
                btn2Text="Yes, Invite"
                bgColor={'#6366F1'}
                btnDisabled={inputValue.toLowerCase() !== 'invite'}
                confirmAction={handleInviteStaff}
            />
        </div>
    );
};

export default AccessManager;
