import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import {
    GET_ACADEMIC_STAFF,
    GET_ALL_STAFF,
    GET_DEACTIVATED_STAFF,
    GET_NON_ACADEMIC_STAFF,
    GET_STAFF_ID,
    GET_UNIT_STAFFS,
    LOADING,
    STOP_LOADING,
} from './slice';

export const getAllStaff = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}staff/:campusId/:unitId/all_staffs?${queries}`);

        if (response.status === 200) {
            const { data } = response?.data || {};
            const staff = data?.map((item) => ({
                ...item,
                fullName: `${item.firstName} ${item.otherName || ''} ${item.surname}`,
            }));
            dispatch(GET_ALL_STAFF(staff));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAcademicStaff = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}staff/:campusId/:unitId/academic_staffs?${queries}`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            const staff = data?.map((item) => ({
                ...item,
                fullName: `${item.firstName} ${item.otherName || ''} ${item.surname}`,
            }));
            dispatch(GET_ACADEMIC_STAFF(staff));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getNonAcademicStaff = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}staff/:campusId/:unitId/non_academic_staffs?${queries}`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            const staff = data?.map((item) => ({
                ...item,
                fullName: `${item.firstName} ${item.otherName || ''} ${item.surname}`,
            }));
            dispatch(GET_NON_ACADEMIC_STAFF(staff));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getDeactivatedStaff = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        // const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}staff/:campusId/:unitId/get_deactivated_staff`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            const staff = data?.map((item) => ({
                ...item,
                fullName: `${item.firstName} ${item.otherName || ''} ${item.surname}`,
            }));
            dispatch(GET_DEACTIVATED_STAFF(staff));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStaffInUnit = (params) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const { query, unitName } = params || {};
        const altUnitName = sessionStorage.getItem('unitName');

        const queries = encodeQuery({ unitName: !unitName ? altUnitName : unitName, ...query });

        const response = await axiosInstance.get(`${currentAPI}staff/:campusId/:unitId/get_staffs_by_unit?${queries}`);
        if (response.status === 200) {
            dispatch(GET_UNIT_STAFFS(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createStaff =
    (academicStructureId = '', payload) =>
    async (dispatch, getState) => {
        dispatch(LOADING());
        try {
            const response = await axiosInstance.post(
                `${currentAPI}staff/:campusId/:unitId/add_staff?structureId=${academicStructureId}`,
                payload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const editStaff = (academicStructureId, staffId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}staff/:campusId/:unitId/edit_staff/${academicStructureId}/${staffId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editStaffEmail = (staffId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}staff/:campusId/:unitId/edit_staff_email/${staffId}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Staff email was updated successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const inviteStaffToAdmin = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}staff/:campusId/:unitId/send_invitation_to_staffs`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Invite sent to staff successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const inviteStaffToLecturersPortal = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}staff/:campusId/:unitId/resend_invitation_to_lecturer_portal`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Invite sent to staff successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStaffById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff/:campusId/:unitId/get_staff_by_id/${id}`);
        if (response.status === 200) {
            dispatch(GET_STAFF_ID(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const disengageStaff = (staffId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}staff/:campusId/:unitId/disengage_staff/${staffId}`);
        if (response.status === 200) {
            toast.success('Staff disengaged successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deactivateStaff = (staffId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}staff/:campusId/:unitId/deactivate_staff/${staffId}`);
        if (response.status === 200) {
            toast.success('Staff deactivated successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteStaff = (staffId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}staff/:campusId/:unitId/delete_staff/${staffId}`);
        if (response.status === 200) {
            toast.success('Staff deleted successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getPersonnelStaff = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        // const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}organization/personnel_staffs`);

        if (response.status === 200) {
            const { data } = response?.data || {};
            console.log(response, 'response');
            const staff = data?.map((item) => ({
                ...item,
                fullName: `${item.firstName} ${item.otherName || ''} ${item.surname}`,
            }));
            return staff;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};