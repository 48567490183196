import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { Button } from '../../../components/buttons';
import ListEmptyContent from '../../../components/list-empty';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import { facultyDepartmentData } from '../../../data/facultyDepartments';
import PageLayout from '../../../layout/page-layout/PageLayout';

const fields = [
    {
        header: 'Departments',
        accessorKey: 'departments',
        cell: (props) => (
            <span style={{ color: '#2563EB' }} className="cursor-pointer">
                {props.getValue()}
            </span>
        ),
    },
    {
        header: 'Personnel',
        accessorKey: 'personnels',
    },
    {
        header: 'Courses',
        accessorKey: 'courses',
    },
    {
        header: 'Students',
        accessorKey: 'students',
    },
];

const ExamsRecordsDepartments = () => {
    const navigate = useNavigate();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    return (
        <div>
            <PageLayout
                backTitle="Exams & Records / Departments"
                pageTitle="Faculty of Administration"
                onActionBtnClick={() => setDropdownOpen(!dropdownOpen)}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={facultyDepartmentData || []}
                fields={fields}
                onRowClick={(item) => navigate(`/exams-records/records/departments/${item.id}`)}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent title="No Department " subtitle="Create new Department(s)">
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Button bgColor="#10B981" color="#fff">
                                    <Circularplus className="mr-[1.15rem]" />
                                    Create New
                                </Button>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
                isActionable
            />
        </div>
    );
};

export default ExamsRecordsDepartments;
