import React, { useMemo, useState } from 'react';

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ActionsPopup, DeleteItem, EditTimeTable } from '../';
import { ReactComponent as ThreeDots } from '../../assets/icons/three-dot.svg';
import { PageTitle, Text } from '../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween, RelativeContainer } from '../../containers/ScreenContainers';
import { deleteLectureTimetable } from '../../redux/timetable/actions';
import EditExamTimeTable from '../forms/edit-exam-timetable';

const Card = tw.div`
    h-[100%]
    bg-[#C7D2FE]
    p-[1.6rem]
    rounded-lg
    relative
    // hover:
    before:content-['']
    before:w-[8px]
    before:h-[8px]
    before:bg-[#E5E7EB]
    before:absolute
    before:rounded-full
    before:left-[-4.3rem]
    before:top-[50%]
    before:-translate-y-1/2
    hover:before:bg-[#6366F1]
    focus:before:bg-[#6366F1]
`;

const InputDetails = tw.div`
  flex
  flex-col
  gap-y-[8px]
  border-l
  border-[#E5E7EB]
  pl-[3.9rem]
  ml-[18.3rem]
  mb-[2.3rem]
`;
const TimelineContainer = tw.div`
`;

const formatTime = (time24hr) => {
    // convert time from 24hrs to 12hrs
    if (!time24hr) return '';
    const newTime = format(new Date(`1970-01-01T${time24hr}:00`), 'hh:mm a');
    return newTime;
};

const CalenderDoc = ({ data, dayOfTheWeek, type, refetchAllTimetable, session, semester }) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state) => state.timetable);

    const [showEditLectureTimetable, setShowEditLectureTimetable] = useState(false);
    const [showEditExamTimetable, setShowEditExamTimetable] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [openActions, setOpenActions] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const onDelete = async () => {
        const res = await dispatch(deleteLectureTimetable(selectedId));
        if (res) {
            refetchAllTimetable();
            setShowDelete(false);
        }
    };

    const timetableData = data?.map((item) => {
        const startDateTime = item.startTime;
        const endDateTime = item.endTime;

        const startTime = startDateTime?.split('T');
        const newStartTime = startTime?.[1].split('.')?.[0].slice(0, 5);

        const endTime = endDateTime?.split('T');
        const newEndTime = endTime?.[1].split('.')?.[0].slice(0, 5);
        return {
            ...item,
            startTime: newStartTime,
            endTime: newEndTime,
            date: item.startTime,
        };
    });

    const filteredData = useMemo(
        () =>
            timetableData
                ?.filter((item) => item.session === session && item.semester === semester)
                ?.sort((a, b) => a?.startTime?.localeCompare(b?.startTime)) || [],
        [timetableData, session, semester],
    );

    return (
        <div className="relative max-w-[1000px]">
            <TimelineContainer>
                <PageTitle className="ml-[4rem]" align="left" size="2.8rem" bottom="1.2rem" top="2.5rem">
                    {dayOfTheWeek}
                </PageTitle>

                <InputDetails>
                    {filteredData?.map((timetable, i) => (
                        <Card key={i}>
                            <Text
                                className="absolute -left-[18rem] top-[50%] -translate-y-[40%]"
                                align="left"
                                lineHeight="2.4rem"
                            >
                                {formatTime(timetable.startTime)} -{formatTime(timetable.endTime)}
                            </Text>
                            <FlexRowSpaceBetween>
                                <Text className="font-semibold text-[1.4rem]">
                                    {timetable.course} -{' '}
                                    <span className="font-normal text-[1.4rem]">{timetable.courseTitle}</span>
                                </Text>
                                <FlexCentredRow className="gap-[2.2rem]">
                                    {type !== 'lecture' && <Text>{timetable.date?.slice(0, 10)}</Text>}
                                    <Text>{timetable.venue}</Text>
                                    <RelativeContainer className="ml-auto mr-0">
                                        <ThreeDots
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setSelectedId(timetable.id);
                                                setOpenActions(!openActions);
                                            }}
                                        />

                                        <ActionsPopup
                                            open={openActions && selectedId === timetable.id}
                                            close={() => setOpenActions(false)}
                                            items={[
                                                {
                                                    name: 'Edit Schedule',
                                                    click: () => {
                                                        if (type === 'lecture') {
                                                            setShowEditLectureTimetable(true);
                                                        } else {
                                                            setShowEditExamTimetable(true);
                                                        }
                                                        setOpenActions(false);
                                                    },
                                                },
                                                {
                                                    name: 'Delete Schedule',
                                                    click: () => {
                                                        setShowDelete(true);
                                                        setOpenActions(false);
                                                    },
                                                },
                                            ]}
                                        />
                                    </RelativeContainer>
                                </FlexCentredRow>
                            </FlexRowSpaceBetween>
                        </Card>
                    ))}
                </InputDetails>
            </TimelineContainer>
            <DeleteItem
                show={showDelete}
                setShow={setShowDelete}
                close={() => setShowDelete(false)}
                assignAction={onDelete}
                loading={isLoading}
            />
            {showEditLectureTimetable && (
                <EditTimeTable
                    show={showEditLectureTimetable}
                    setShow={setShowEditLectureTimetable}
                    timetableId={selectedId}
                    refetch={refetchAllTimetable}
                />
            )}
            {showEditExamTimetable && (
                <EditExamTimeTable
                    show={showEditExamTimetable}
                    setShow={setShowEditExamTimetable}
                    timetableId={selectedId}
                />
            )}
        </div>
    );
};
export default CalenderDoc;
