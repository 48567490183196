import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ConfirmActionDialogue } from '../../components';
import PageLayout from '../../layout/page-layout/PageLayout';
import { deleteCampus, getAllCampus } from '../../redux/campus-management/actions';

const DELETE_CONFIRM_TEXT = 'delete';

const CampusManagement = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, allCampus } = useSelector((store) => store.campus);

    const [searchValue, setSearchValue] = useState('');
    const [openModal, setOpenModal] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [selected, setSelected] = useState(null);
    const [refetch, setRefetch] = useState(false);

    const handleCloseModal = () => {
        setSearchValue('');
        setInputValue('');
        setSearchValue(null);
        setOpenModal(null);
    };

    const handleDeleteCampus = async () => {
        const res = await dispatch(deleteCampus(selected?._id));
        if (res) {
            handleCloseModal();
            setRefetch(!refetch);
        }
    };

    const handleSelect = (item) => {
        if (item) {
            setSelected(item);
            sessionStorage.setItem('campusId', item._id);
        } else {
            setSelected(null);
            sessionStorage.removeItem('campusId');
        }
    };

    const handleNavigate = (item) => {
        navigate('/dashboard');
        sessionStorage.setItem('campusId', item._id);
    };

    const fields = [
        {
            header: 'Campus Name',
            accessorKey: 'campusName',
            cell: ({ row }) => (
                <p style={{ color: '#2563EB' }} onClick={() => handleNavigate(row.original)} className="cursor-pointer">
                    {row.original.campusName}
                </p>
            ),
        },
        {
            header: 'Campus Address',
            accessorKey: 'campusAddress',
        },
    ];

    useEffect(() => {
        dispatch(getAllCampus());
    }, [dispatch, refetch]);

    return (
        <div>
            <PageLayout
                pageTitle="Campuses"
                secondaryButtonText="Add Campus"
                onSecondaryButtonClick={() => navigate('add-campus')}
                fields={fields}
                data={allCampus || []}
                onSelectedItemsChange={(items) => handleSelect(items[0])}
                actionItems={[
                    {
                        icon: <EditIcon fill="#1f2937" />,
                        name: 'Edit Campus',
                        click: () => navigate(`edit-campus/${selected?._id}`, { state: { campus: selected } }),
                        disabled: !selected,
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Campus',
                        click: () => setOpenModal('delete-campus'),
                        disabled: !selected,
                    },
                ]}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                isActionable
                actionType="radio"
            />

            <ConfirmActionDialogue
                borderLine
                input
                isLoading={isLoading}
                placeholder={`Input ${DELETE_CONFIRM_TEXT}`}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                show={openModal === 'delete-campus'}
                title="Delete Campus?"
                close={handleCloseModal}
                subtitle="This campus will be deleted."
                bodyText={`To confirm, enter ${DELETE_CONFIRM_TEXT} in the text field.`}
                btn2Text="Yes, Delete"
                bgColor={'#ef4444'}
                confirmAction={handleDeleteCampus}
                btnDisabled={inputValue?.toLowerCase() !== DELETE_CONFIRM_TEXT.toLowerCase()}
            />
        </div>
    );
};

export default CampusManagement;
