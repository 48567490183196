import { ADMISSIONS } from '../../utils/unitNames';
import {
    AcademicCalendar,
    // AddAdministrator,
    AddDistributionList,
    Administrator,
    AdmissionCandidateDetails,
    AdmissionDetails,
    Admissions,
    CreateAcademicCalendar,
    CreateActivityInWeek,
    CreateRole,
    CreateStudent,
    DistributionList,
    EditAdministrator,
    EditRole,
    Received,
    Roles,
    AdmissionSettings,
    Staff,
    StaffDetail,
    UpdateDistributionList,
    MatricNumberSettings,
    EditStudent,
    EditAcademicCalendar,
    Access,
    AssignRole,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
    AgentProfile,
    AgentDetails,
} from '../../views';
import Student from '../../views/admission/students';
import StudentById from '../../views/admission/students/studentDetails';

export const admissionRoutes = [
    {
        path: '/admissions/sessions',
        element: <Admissions unitName={ADMISSIONS} />,
    },
    {
        path: '/admissions/sessions/:id',
        element: <AdmissionDetails />,
    },
    {
        path: '/admissions/sessions/candidate/:id',
        element: <AdmissionCandidateDetails />,
    },
    {
        path: '/admissions/sessions/candidate/edit/:id',
        element: <EditStudent />,
    },
    {
        path: '/admissions/sessions/add-student',
        element: <CreateStudent />,
    },
    {
        path: '/admissions/access',
        element: (
            <Access
                staffs={<Staff unitName={ADMISSIONS} />}
                administrators={<Administrator unitName={ADMISSIONS} />}
                roles={<Roles unitName={ADMISSIONS} />}
            />
        ),
    },
    {
        path: '/admissions/access/create-role',
        element: <CreateRole unitName={ADMISSIONS} />,
    },
    {
        path: '/admissions/access/edit-role',
        element: <EditRole unitName={ADMISSIONS} />,
    },
    {
        path: '/admissions/access/add-administrator',
        // element: <AddAdministrator unitName={ADMISSIONS} />,
        element: <AssignRole unitName={ADMISSIONS} />,
    },
    {
        path: '/admissions/access/edit-administrator',
        element: <EditAdministrator unitName={ADMISSIONS} />,
    },
    {
        path: '/admissions/access/staff/:id',
        element: <StaffDetail />,
    },
    {
        path: '/admissions/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/admissions/communications/received',
        element: <Received unitName={ADMISSIONS} />,
    },
    {
        path: '/admissions/academic-calendar',
        element: <AcademicCalendar withCreate />,
    },
    {
        path: '/admissions/academic-calendar/create-academic-calendar',
        element: <CreateAcademicCalendar />,
    },
    {
        path: '/admissions/academic-calendar/edit-academic-calendar',
        element: <EditAcademicCalendar />,
    },
    {
        path: '/admissions/academic-calendar/create-activity',
        element: <CreateActivityInWeek />,
    },
    {
        path: '/admissions/settings',
        element: <AdmissionSettings />,
    },
    {
        path: '/admissions/settings/matric-number',
        element: <MatricNumberSettings />,
    },
    {
        path: '/admissions/communications/distribution-list',
        element: <DistributionList unitName={ADMISSIONS} title="Communications" />,
    },
    {
        path: '/admissions/communications/distribution-list/add',
        element: <AddDistributionList unitName={ADMISSIONS} title="Communications" />,
    },
    {
        path: '/admissions/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList unitName={ADMISSIONS} title="Communications" />,
    },
    {
        path: '/admissions/students',
        element: <Student />,
    },
    {
        path: '/admissions/students/:id',
        element: <StudentById />,
    },
    {
        path: '/admissions/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/admissions/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/admissions/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
    {
        path: '/admissions/agent-profile',
        element: <AgentProfile />,
    },
    {
        path: '/admissions/agent-profile/:id',
        element: <AgentDetails />,
    },
];
