import React, { useEffect, useMemo, useState } from 'react';

import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import { format } from 'date-fns';
import { prettyPrintJson } from 'pretty-print-json';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-sm.svg';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { useTableContext } from '../../../context/tableContext';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getAuditLogs } from '../../../redux/ict/actions';
import { filterData } from '../../../utils/filterData';

const fields = [
    {
        accessorKey: 'event',
        header: 'EVENT',
        cell: (props) => {
            const event = props?.row?.original?.activity;
            return <p>{event}</p>;
        },
    },
    {
        accessorKey: 'originUnit',
        header: 'UNIT',
    },
    {
        accessorKey: 'action',
        header: 'ACTION',
        cell: (props) => {
            const method = props?.row?.original?.method;
            return <p>{method === 'POST' ? 'CREATE' : method === 'PUT' || method === 'PATCH' ? 'UPDATE' : method}</p>;
        },
    },
    {
        accessorKey: 'name',
        header: 'STAFF NAME',
        cell: (props) => {
            const data = props?.row?.original?.user;
            const { fullName, firstName, lastName, surname, otherName } = data || {};
            const name = fullName || `${firstName} ${lastName || surname} ${otherName || ''}`;
            return <p>{name}</p>;
        },
    },
    {
        accessorKey: 'ipAddress',
        header: 'IP ADDRESS',
    },
    {
        accessorKey: 'timestamp',
        header: 'TIME STAMP',
        cell: (props) => {
            const originalDate = new Date(props?.row?.original?.timestamp);
            const formattedDate = format(originalDate, 'yyyy-MM-dd, hh:mm:ss a');
            return <p>{formattedDate}</p>;
        },
    },
];

const AuditLog = () => {
    const dispatch = useDispatch();
    const { auditLogsData, isLoading } = useSelector((store) => store.ict);

    const { data: auditLogs } = auditLogsData || {};

    const [selectedRow, setSelectedRow] = useState({});
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [currentPage, setCurrentPage] = useState('1');
    const { tableSettings } = useTableContext();
    const numOfPages = tableSettings?.numOfPages;

    const handleCollapse = (row) => {
        if (row?.id === selectedRow?.id) {
            setSelectedRow(null);
        } else {
            setSelectedRow(row);
        }
    };

    useEffect(() => {
        if (selectedRow?.original?.response) {
            const prettyHtml = prettyPrintJson.toHtml(selectedRow?.original?.response);
            const element = document.getElementById('pretty');
            if (element) {
                element.innerHTML = prettyHtml;
            }
        }
    }, [selectedRow]);

    useEffect(() => {
        dispatch(getAuditLogs());
    }, [dispatch, currentPage, numOfPages]);

    const memoizedAuditLogs = useMemo(() => {
        if (debouncedSearchValue) {
            const filteredLogs = filterData(auditLogs, debouncedSearchValue);
            return filteredLogs;
        } else {
            return auditLogs;
        }
    }, [debouncedSearchValue, auditLogs]);

    return (
        <PageLayout
            pageTitle="Audit Trail"
            showTableUtils
            searchable
            searchValue={searchValue}
            onSearchChange={(e) => setSearchValue(e.target.value)}
            onSearchClose={() => setSearchValue('')}
            fields={fields}
            data={memoizedAuditLogs}
            loading={isLoading}
            isCollapsible
            isActionable
            actionType="dropdown"
            actionProp={<ArrowRight />}
            openCollapseId={selectedRow?.id}
            handleAction={(row) => handleCollapse(row)}
            eventData={<pre id="pretty" className="json-container" style={{ fontSize: '1.4rem' }}></pre>}
            onCopyClick={() => {
                navigator.clipboard.writeText(JSON.stringify(selectedRow?.original?.response));
                toast.success('Copied!');
            }}
            isPaginated={true}
            paginationComponent={
                <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                    <PaginationStatus
                        currentPage={currentPage}
                        itemsPerPage={tableSettings?.numOfPages}
                        totalItems={memoizedAuditLogs?.length}
                    />
                    <Pagination
                        pageRangeDisplayed={3}
                        totalPages={memoizedAuditLogs?.length / tableSettings?.numOfPages}
                        onPageChange={(nextPage) => {
                            setCurrentPage(nextPage);
                        }}
                        currentPage={currentPage}
                    />
                </FlexRowSpaceBetween>
            }
            collapseTitle="Event Record"
            showActionTypeOnHeader={false}
        />
    );
};

export default AuditLog;
