import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as EditIcon } from '../../assets/icons/edit3.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings-icon.svg';
import EditOrganizationUnitForm from '../../components/forms/edit-organization-unit';
import ManageOrganizationUnitStatus from '../../components/forms/manage-org-unit-status';
import ListEmptyContent from '../../components/list-empty';
import { getStatusStyles, StatusContainer } from '../../components/shared';
import { CenteredContainer } from '../../containers/ScreenContainers';
// import useDebounce from '../../hooks/useDebounce';
import PageLayout from '../../layout/page-layout/PageLayout';
import { getAllOrganisationUnits } from '../../redux/organisation-unit/actions';

const fields = [
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Organization Unit',
        accessorKey: 'unitName',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Description',
        accessorKey: 'description',
    },
    {
        cell: (props) => <p>{props?.getValue().length}</p>,
        header: 'Number of Staff',
        accessorKey: 'staffs',
    },
    {
        header: 'Status',
        accessorKey: 'unitStatus',
        cell: (props) => (
            <StatusContainer style={getStatusStyles(props.getValue()?.toLowerCase())}>
                <span>{STATUS_MAP[props.getValue()]}</span>
            </StatusContainer>
        ),
    },
];

const STATUS_MAP = {
    Custom: 'Enabled by default',
    Active: 'Enabled',
    Inactive: 'Disabled',
};

const OrganizationUnit = ({ withActions }) => {
    const dispatch = useDispatch();

    const { isLoading, orgUnits: organisationUnits } = useSelector((state) => state.organisationUnits);

    const [openDropdown, setOpenDropdown] = useState(false);

    const [showEditForm, setShowEditForm] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    // const debouncedSearchValue = useDebounce(searchValue, 700);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [openModal, setOpenModal] = useState('');

    // const unitQuery = useMemo(() => {
    //     let queryParams = {};
    //     if (debouncedSearchValue) {
    //         queryParams.unitName = debouncedSearchValue;
    //     }
    //     return queryParams;
    // }, [debouncedSearchValue]);

    const onEditSuccess = () => {
        setSelectedUnit(null);
        setOpenDropdown(false);
        dispatch(getAllOrganisationUnits());
    };

    useEffect(() => {
        dispatch(getAllOrganisationUnits());
    }, [dispatch]);

    return (
        <>
            <PageLayout
                pageTitle="Organization Unit"
                onActionBtnClick={() => setOpenDropdown(!openDropdown)}
                loading={isLoading}
                data={organisationUnits || []}
                fields={fields}
                isActionable
                actionType={withActions ? 'radio' : null}
                onSelectedItemsChange={(items) => setSelectedUnit(items[0])}
                actionOpen={openDropdown}
                actionClose={() => setOpenDropdown(false)}
                actionItems={
                    withActions
                        ? [
                              {
                                  icon: <EditIcon />,
                                  name: 'Edit Organization Unit',
                                  disabled: !selectedUnit,
                                  click: () => {
                                      setShowEditForm(true);
                                  },
                              },
                              {
                                  icon: <SettingsIcon />,
                                  name: 'Manage Unit Status',
                                  disabled: !selectedUnit || selectedUnit?.unitStatus === 'Custom',
                                  click: () => {
                                      setOpenModal('status');
                                  },
                              },
                          ]
                        : null
                }
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Organisation units "></ListEmptyContent>
                    </CenteredContainer>
                }
            />

            <EditOrganizationUnitForm
                details={selectedUnit}
                onDone={onEditSuccess}
                show={showEditForm}
                setShow={setShowEditForm}
            />

            <ManageOrganizationUnitStatus
                item={selectedUnit}
                onDone={onEditSuccess}
                show={openModal === 'status'}
                setShow={setOpenModal}
            />
        </>
    );
};

export default OrganizationUnit;
