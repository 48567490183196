export const ORGANIZATION_ROLES = [
    { name: 'Organization Owner', value: 'owner' },
    { name: 'Organization Access Manager', value: 'access-manager' },
    { name: 'Organization Administrator', value: 'administrator' },
    { name: 'Organization Member', value: 'member' },
    { name: 'Organization View-Only', value: 'view-only' },
];

export const ORGANIZATION_PERMISSIONS = [
    {
        title: 'Organisation Access Manager',
        description: 'Provides administrative access to the organisation, including the ability to manage staff.',
    },
    {
        title: 'Organisation Administrator',
        description:
            'Provides administrative access to the organisation, including the ability to manage organisational functions, staff, and full access to all organization-level features.',
    },
    {
        title: 'Organisation Member',
        description:
            'Best suited for staff who do not require organisation-level access. Provides access only to the campus(es) the staff belongs to, without any organisation-level access.',
    },
    {
        title: 'Organisation View-Only',
        description: 'Provides view-only access to everything in the organisation, including all campuses within it',
    },
];

export const getOrgRoleTypeName = (value) => {
    if (!value) return '';

    const role = ORGANIZATION_ROLES.find((item) => item.value === value);
    return role.name || '';
};
