import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getAllAgents } from '../../../redux/bursary/actions';

const AgentProfile = () => {
    const dispatch = useDispatch();
    const { allAgents, isLoading } = useSelector((store) => store.bursary);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const fields = [
        {
            cell: (props) => (
                <Link to={`${props.row.original._id}`} state={{ agent: props.row.original }}>
                    {props?.getValue()}
                </Link>
            ),
            header: 'Company Name',
            accessorKey: 'companyName',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Company Email',
            accessorKey: 'companyEmail',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: "No of Student's Referred",
            accessorKey: 'noOfStudents',
        },
    ];

    useEffect(() => {
        dispatch(getAllAgents());
    }, [dispatch]);

    return (
        <div>
            <PageLayout
                pageTitle="Agents Profile"
                showTableUtils
                searchable
                searchValue={debouncedSearchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={allAgents || []}
                fields={fields}
                loading={isLoading}
            />
        </div>
    );
};

export default AgentProfile;
