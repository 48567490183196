import React, { useEffect, useMemo, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../../assets/icons/people.svg';
import { LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../components/go-back';
import Select from '../../../../components/inputs/new-select';
import TextInput from '../../../../components/inputs/text-input-with-formik';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../containers/ScreenContainers';
import { editDiscount, getAllFees } from '../../../../redux/bursary/actions';

import { ValidationSchema } from './validationSchema';

const Container = tw.div`
    bg-white
    h-auto
    px-[3.2rem]
    py-[3.6rem]
    rounded-[16px]
    mt-10
    max-w-[1000px]
    mb-[100px]
`;

const TYPE_OF_FEE = [
    {
        name: 'Tuition Fee',
        value: 'Tuition-Fee',
    },
    {
        name: 'Acceptance Fee',
        value: 'Acceptance-Fee',
    },
    {
        name: 'Medical Fee',
        value: 'Medical-Fee',
    },
    {
        name: 'Accommodation Fee',
        value: 'Accommodation-Fee',
    },
    {
        name: 'Other Fee',
        value: 'Other-Fee',
    },
    {
        name: 'Agent Fee',
        value: 'Agent-Fee',
    },
];

const EditDiscount = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { selectedDiscount } = state || {};

    const { sessions } = useSelector((store) => store.admission);
    const { isLoading, allFees } = useSelector((store) => store.bursary);

    const [applicableFees, setApplicableFees] = useState([]);
    const [session, setSession] = useState(selectedDiscount?.session || '');
    const [discountType, setDiscountType] = useState(selectedDiscount?.discountType || '');

    const previousFees = useMemo(
        () => applicableFees?.filter((fee) => selectedDiscount.discountApplicableTo.includes(fee._id)),
        [applicableFees, selectedDiscount],
    );

    useEffect(() => {
        const fees = allFees
            // ?.filter(
            //     (item) =>
            //         item?.feeType === discountType ||
            //         (selectedDiscount?.feeType && item?.session === session) ||
            //         selectedDiscount?.session,
            // )
            ?.filter((item) => item?.feeType === discountType && item?.session === session)
            ?.flatMap((item) =>
                item?.fees?.flatMap((fee) =>
                    fee?.allFees?.map((f) => ({
                        _id: fee?._id,
                        name: f?.feeName,
                    })),
                ),
            );

        setApplicableFees(fees || []);
    }, [session, discountType, allFees, selectedDiscount]);

    const query = useMemo(() => {
        let queryParams = {};

        if (session) {
            queryParams.session = session;
        }

        return queryParams;
    }, [session]);

    useEffect(() => {
        dispatch(getAllFees(query));
    }, [dispatch, query]);
    return (
        <>
            <GoBack title="Discounts" subTitle=" Edit Discount" />

            <Container>
                <FlexCentredRow className="gap-[7.15px] mb-[3.8rem]">
                    <PeopleIcon />
                    <Text size="1.6rem" weight="600">
                        Edit Discount
                    </Text>
                </FlexCentredRow>

                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        session: selectedDiscount?.session || '',
                        discountName: selectedDiscount?.discountName || '',
                        discountType: selectedDiscount?.discountType || '',
                        discountApplicableTo: previousFees || [],
                        discountPercentage: selectedDiscount?.discountPercentage || '',
                        discountDescription: selectedDiscount?.discountDescription || '',
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const payload = {
                            ...values,
                            discountApplicableTo: values.discountApplicableTo?.map((fee) => fee?._id),
                        };

                        const res = await dispatch(editDiscount(payload, selectedDiscount?._id));

                        if (res) navigate(-1);
                    }}
                >
                    {({ handleChange, errors, setFieldValue, values }) => (
                        <Form>
                            <div className="gap-x-[3.6rem] gap-y-[2rem] grid-cols-2 grid">
                                <Select
                                    name="session"
                                    objProp="admissionYear"
                                    data={sessions}
                                    placeholder="Choose Session"
                                    label="Session"
                                    onChange={(selected) => {
                                        setFieldValue('session', selected[0].admissionYear);
                                        setSession(selected[0].admissionYear);
                                    }}
                                    error={errors.session}
                                    passedSelectedItems={values?.session}
                                />
                                <TextInput
                                    name="discountName"
                                    onChange={handleChange}
                                    label="Discount Name"
                                    type="text"
                                    placeholder="Input Discount Name "
                                    value={values?.discountName}
                                    errors={errors}
                                />
                                <Select
                                    name="discountType"
                                    placeholder="Select Discount Type"
                                    objProp="name"
                                    data={TYPE_OF_FEE}
                                    label="Discount Type"
                                    onChange={(selected) => {
                                        setFieldValue('discountApplicableTo', []);
                                        setFieldValue('discountType', selected[0].value);
                                        setDiscountType(selected[0].value);
                                    }}
                                    error={errors.discountType}
                                    passedSelectedItems={values?.discountType}
                                />
                                <Select
                                    name="discountApplicableTo"
                                    objProp="name"
                                    multiSelect={true}
                                    label="Applicable Fee(s)"
                                    placeholder="Select Applicable Fee(s)"
                                    data={applicableFees}
                                    useComponentState={false}
                                    passedSelectedItems={
                                        values.discountApplicableTo ? values.discountApplicableTo : null
                                    }
                                    onChange={(selected) => {
                                        setFieldValue('discountApplicableTo', selected);
                                    }}
                                    error={errors.discountApplicableTo}
                                />
                                <TextInput
                                    name="discountPercentage"
                                    label="Percentage Off (%)"
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Input percentage (e.g 10)"
                                    errors={errors}
                                    value={values?.discountPercentage}
                                />
                                <TextInput
                                    name="discountDescription"
                                    label="discountDescription (Optional)"
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Input discountDescription"
                                    errors={errors}
                                    value={values?.discountDescription}
                                />
                            </div>

                            <div className="flex items-center gap-[1rem] mt-[2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)} className="ml-auto">
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} disabled={isLoading} type="submit">
                                    Update Discount
                                </LoadingButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default EditDiscount;
