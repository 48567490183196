import React, { useState, useEffect } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as Plus } from '../../../../assets/icons/circularplus-black.svg';
import { ReactComponent as RemoveIcon } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import { GoBack } from '../../../../components/go-back';
import ConfirmActionDialogueWithInput from '../../../../components/popups/confirm-action-with-input';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import {  getPaymentGroupsById, removeStaffToPaymentGroup } from '../../../../redux/bursary/actions';
import { capitalizeFirstLetter } from '../../../../utils';
import { storeUnit } from '../../../../utils/originUnit';

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;
const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-[2.4rem]
    rounded-lg
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
`;


const GroupDetails = ({ unitName }) => {
    const dispatch = useDispatch();
    const navigate= useNavigate();
    const {
        state: { id, groupName, groupDetails },
    } = useLocation();
    const {staffIds: staff = []} = groupDetails;


    const { isLoading } = useSelector((state) => state.bursary);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [openModal, setOpenModal] = useState(false);
    const [selected, setSelected] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(null);

    const [inputValue, setInputValue] = useState('');
    const [filteredAcademics, setFilteredAcademics] = useState([]);
    const [filteredNonAcademics, setFilteredNonAcademics] = useState([]);

    useEffect(() => {
        if (id) {
            dispatch(getPaymentGroupsById(id));
        }
    }, [id, toggleRefetch, dispatch]);

    useEffect(() => {
        // Filter staffIds based on the search input and `staffType`
        const filteredAcademicsData = staff?.filter(
            (staff) =>
                staff?.staffType === 'Teaching' &&
                (staff?.staffName?.includes(debouncedSearchValue) ||
                    staff?.staffNumber?.includes(debouncedSearchValue))
        );
        setFilteredAcademics(filteredAcademicsData);

        const filteredNonAcademicsData = staff?.filter(
            (staff) =>
                staff?.staffType === 'Non-Teaching' &&
                (staff?.staffName?.includes(debouncedSearchValue) ||
                    staff?.staffNumber?.includes(debouncedSearchValue))
        );
        setFilteredNonAcademics(filteredNonAcademicsData);
    }, [debouncedSearchValue, staff]);

    
   const handleRemove = async () => {
         const staffIdToRemove = selected?._id
         const payload = {
                             staffIds: [staffIdToRemove],
                             staffType: selected?.staffType,
         }
         const res = await dispatch(removeStaffToPaymentGroup(payload, id));
         if (res) {
             setFilteredAcademics((prev) =>
                 prev.filter((staff) => staff._id !== staffIdToRemove)
             );
             setFilteredNonAcademics((prev) =>
                 prev.filter((staff) => staff._id !== staffIdToRemove)
             );
             setToggleRefetch((prevState) => !prevState);
             setOpenModal(false);
         }
     };
 

    const handleCloseModal = (modal) => {
        if (openModal === modal) {
            setOpenModal(null);
        } else {
            setOpenModal(modal);
        }
    };

    const fields = [
        {
            cell: ({ row }) => {
                const { firstName, otherName, surname } = row?.original || {};
                return (
                    <p>
                        {firstName} {otherName || ''} {surname}
                    </p>
                );
            },
            header: 'Staff Name',
        },

        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Staff Number',
            accessorKey: 'staffNumber',
        },
        { 
            cell: ({ row }) => {
            const { faculty, college } = row?.original || {};
            return <p>{faculty || college || ''}</p>;
        },
            header: 'College',
        },
        {
            cell: ({ row }) => {
                const { subProgram, department } = row?.original || {};
                return <p>{subProgram || department || ''}</p>;
            },
            header: 'Department',
        },
    ];

      // Function to format the date
    const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Extracting components of the date and time
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // Convert to 12-hour format
    const time = `${hours}:${minutes}:${seconds} ${amPm}`;

    return `${year}-${month}-${day}, ${time}`;
};

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return (
        <>
            <div>
                <GoBack title={`Payment Group`} 
                subTitle={groupDetails?.groupName}
                ></GoBack>
                <FlexRowSpaceBetween className="mt-[4.5rem]">
                    <PageTitle align="left">{groupName}</PageTitle>
                    <FlexCentredRow className="gap-x-4">
                        
                        <RelativeContainer>
                            <Button bgColor="#6366F1" color="#fff" onClick={() => handleCloseModal('actions')}>
                                Actions
                                <Dropdown className="ml-[13.15px]" />
                            </Button>
                            <ActionsPopup
                                open={openModal === 'actions'}
                                close={() => setOpenModal(null)}
                                items={[
                                    {
                                        icon: <Plus />,
                                        name: 'Add Staff',
                                        click: () =>
                                            navigate(`/bursary/payroll/payment-group/${id}/group-only/add-staff`, {state: { groupDetails}}),
                                    },

                               
                                    {
                                        icon: <RemoveIcon />,
                                        name: 'Remove Staff',
                                        disabled: !selected,
                                        click: () => {
                                            setOpenModal('remove');
                                        },
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
                <Details className="mt-[3rem]">
                    <FlexCentredCol>
                        <PageTitle align="left" size="1.9rem" lineheight="2.8rem">
                            Overview
                        </PageTitle>
                    </FlexCentredCol>

                    <BasicContent>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Group Name</GreyText>
                                <DarkText>{groupDetails?.groupName}</DarkText>
                            </FlexCol>
                            <FlexCol>
                                <GreyText>Created At</GreyText>
                                <DarkText>{formatDate(groupDetails?.createdAt)}</DarkText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Description</GreyText>
                                <DarkText>{groupDetails?.description}</DarkText>
                            </FlexCol>
                            <FlexCol>
                                <GreyText>Created By</GreyText>
                                <DarkText>{groupDetails?.createdBy}</DarkText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Group Type</GreyText>
                                <DarkText>{groupDetails?.groupType}</DarkText>
                            </FlexCol>
                        </RowContainer>
                        <RowContainer className="">
                            <FlexCol>
                                <GreyText>Staff Type</GreyText>
                                <DarkText>{capitalizeFirstLetter(groupDetails?.staffType)}</DarkText>
                            </FlexCol>
                        </RowContainer>
                    </BasicContent>
                </Details>

                <CTabs>
                <CNav variant="tabs" className="flex items-center mt-[2.4rem] gap-[2.4rem]">
                    <CNavItem>
                        <CNavLink className="text-[1.4rem] font-medium">Academics</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink className="text-[1.4rem] font-medium">Non-academics</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane><PageLayout
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    searchValue={searchValue}
                    onSearchClose={() => setSearchValue('')}
                    showTableUtils
                    showFilter
                    data={filteredAcademics || []}
                    fields={fields}
                    loading={isLoading}
                    width={'100%'}
                    isActionable
                    actionType="radio"
                    onSelectedItemsChange={(items) => setSelected(items[0])}
                    openFilterPop={openModal === 'options-popup-main'}
                    closeFilterPop={() => setOpenModal(null)}
                  
                />
                   
                     </CTabPane>
                    <CTabPane>
                    <PageLayout
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    searchValue={searchValue}
                    onSearchClose={() => setSearchValue('')}
                    showTableUtils
                    showFilter
                    data={filteredNonAcademics || []}
                    fields={fields}
                    loading={isLoading}
                    width={'100%'}
                    isActionable
                    actionType="radio"
                    onSelectedItemsChange={(items) => setSelected(items[0])}
                    openFilterPop={openModal === 'options-popup-main'}
                    closeFilterPop={() => setOpenModal(null)}
                  
                    />
                    </CTabPane>
                </CTabContent>
            </CTabs>

                
            </div>  
            
          
            <ConfirmActionDialogueWithInput
                show={openModal === 'remove'}
                close={() => setOpenModal('')}
                title="Remove Staff"
                subtitle="The selected staff will be removed permanently."
                subtitle2="To confirm, enter remove in the text field."
                confirmAction={() => handleRemove()}
                btn2Text="Yes, Remove"
                placeholder="Input remove"
                setInputValue={setInputValue}
                value={inputValue}
                isLoading={isLoading}
                btnDisabled={inputValue !== 'remove'}
                list
                listName={`${selected?.firstName} ${selected?.otherName || ''} ${selected?.surname}`} 
                listNumber={`${selected?.staffNumber}`}
                bgColor="#EF4444"
            />

          
        </>
    );
};
export default GroupDetails;
