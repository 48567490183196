import React, { useState } from 'react';

import { TextInput } from '@belrald_hq/belrald-ui';
import { CModal } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close-grey.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { publishMatricNumber } from '../../../../redux/admissions/actions';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 45.8rem;
        max-height: 60rem;
        margin: auto;
    }
`;

const ItemsContainer = tw.ul`
    overflow-y-auto
    border-b
    pb-2
    mt-5
`;

const CONFIRM_TEXT = 'publish';

const PublishMatricNumber = ({ show, close, students, onFinish }) => {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.admission);

    const [confirmTextValue, setConfirmTextValue] = useState('');

    const onClose = () => {
        setConfirmTextValue('');
        close();
    };

    const handleSubmit = async () => {
        const payload = {
            students: students?.map((item) => item._id),
        };

        const res = await dispatch(publishMatricNumber(payload));

        if (res) {
            onClose();
            onFinish?.();
        }
    };

    return (
        <div>
            <StyledModal show={show} onClose={onClose} centered closeOnBackdrop={true} backdrop={true} color="info">
                <FlexRowSpaceBetween>
                    <Text weight="600" size="1.9rem" align="left">
                        Publish Matric Number
                    </Text>
                    <CloseIcon className="hover:cursor-pointer" onClick={onClose} />
                </FlexRowSpaceBetween>
                <Text align="start" size="1.6rem" color="#374151" top="1rem" lineHeight="2.4rem" bottom="2.4rem">
                    Are you sure you want to publish? The students will receive a mail and this will be their matric
                    number throughout.
                </Text>

                <ItemsContainer>
                    {students?.map((item, index) => (
                        <FlexRowSpaceBetween key={index}>
                            <Text align="left">{`${item?.fullName}`}</Text>
                            <Text>{`${item?.matricNumber}`}</Text>
                        </FlexRowSpaceBetween>
                    ))}
                </ItemsContainer>
                <hr />
                <Text color="#6B7280" size="1.2rem" align="left">
                    {students?.length} name(s) selected
                </Text>

                <Text align="left" weight="400" size="1.6rem" bottom="1rem">
                    To confirm, enter <strong>publish</strong> in the text field
                </Text>
                <TextInput
                    type="text"
                    placeholder="Enter publish"
                    onChange={(e) => setConfirmTextValue(e.target.value)}
                    value={confirmTextValue}
                    required
                />
                <FlexRowEnd className="gap-x-[16px] mt-[1rem]">
                    <Button type="button" onClick={onClose} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                    <LoadingButton
                        disabled={confirmTextValue.toLowerCase() !== CONFIRM_TEXT || isLoading}
                        loading={isLoading}
                        onClick={handleSubmit}
                    >
                        Publish
                    </LoadingButton>
                </FlexRowEnd>
            </StyledModal>
        </div>
    );
};

export default PublishMatricNumber;
