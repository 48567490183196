import React, { useState, useEffect } from 'react';

import { Table } from '@belrald_hq/belrald-ui';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../assets/icons/arrow-down2.svg';
import { ReactComponent as MarkIcon } from '../../assets/icons/circular-mark-white-sm.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-sm.svg';
import { ActionsPopup } from '../../components';
import { Button, LoadingButton } from '../../components/buttons';
import SearchInput from '../../components/inputs/search-input';
import ListEmptyContent from '../../components/list-empty';
import { StatusContainer, getStatusStyles } from '../../components/shared';
import { PageTitle, Text } from '../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../containers/ScreenContainers';
import { getActiveSession, getAdmissionSessions } from '../../redux/admissions/actions';
import { getDepartments } from '../../redux/departments/actions';
import { approveExeat, getAllExeatRequest, rejectExeat } from '../../redux/exeat/actions';

import ExeatDetails from './exeatDetails';

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const Exeat = () => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [exeatId, setExeatId] = useState('');
    const [showDetails, setShowDetails] = useState(false);

    const { sessions, activeSession } = useSelector((state) => state.admission);
    const { departments } = useSelector((state) => state.department);
    const { isLoading, exeat } = useSelector((store) => store.exeat);

    const [openDropdown, setOpenDropdown] = useState(null);
    const [session, setSession] = useState(activeSession || '');
    const [level, setLevel] = useState('');
    const [program, setProgram] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);

    const handleApprove = async () => {
        setExeatId(selectedItem?._id);
        const res = await dispatch(approveExeat(exeatId));
        if (res) {
            toast.success('exeat request approved successfully');
        }
    };

    const handleReject = async () => {
        setExeatId(selectedItem?._id);
        const res = await dispatch(rejectExeat(exeatId));
        if (res) {
            toast.success('exeat request rejected successfully');
        }
    };

    const actionItemSessions = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenDropdown(null);
        },
    }));
    const actionItemProgram = departments?.map((item) => ({
        name: item.department,
        click: () => {
            setProgram(item.department);
            setOpenDropdown(null);
        },
    }));

    const actionItemLevel = ['100', '200', '300', '400', '500'].map((item) => ({
        name: item,
        click: () => {
            setLevel(item);
            setOpenDropdown(null);
        },
    }));

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const fields = [
        {
            header: 'Names ',
            accessorKey: 'name',
            cell: ({ row }) => (
                <span>
                    {row.original?.studentId?.lastName || ''} {row.original?.studentId?.firstName || ''}{' '}
                    {row.original?.studentId?.middleName || ''}
                </span>
            ),
        },
        {
            header: 'Matric Number',
            accessorKey: 'matricNumber',
            cell: ({ row }) => <p>{row.original.studentId.matricNumber || ''}</p>,
        },
        {
            header: 'Department ',
            accessorKey: 'program',
            cell: ({ row }) => <p>{row.original.studentId.program || ''}</p>,
        },
        {
            header: 'subject',
            accessorKey: 'subject',
        },
        {
            header: 'Status',
            accessorKey: 'status',
            cell: (props) => (
                <StatusContainer style={getStatusStyles(props.getValue()?.toLowerCase())}>
                    <span>{props.getValue()}</span>
                </StatusContainer>
            ),
        },
        {
            accessorKey: 'action2',
            header: '',
            cell: ({ row }) => (
                <div
                    onClick={() => {
                        setExeatId(row.original._id);
                        setShowDetails(true);
                    }}
                    className="border-[0.5px] border-[#E5E7EB] rounded shadow-[0_1px_2px_0px_rgba(6,25,56,0.05)] p-[8px]  "
                >
                    <Text size="1.4rem" lineHeight="2.4rem" weight="500" color="#6366F1">
                        View Details
                    </Text>
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (session) {
            dispatch(getAllExeatRequest(session, level, program));
        }
    }, [session, level, program, dispatch]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
        dispatch(getActiveSession());
        dispatch(getDepartments());
    }, [dispatch]);

    return (
        <>
            <FlexRowSpaceBetween>
                <PageTitle>Exeat</PageTitle>
            </FlexRowSpaceBetween>

            <div className="mt-[3.2rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FlexRowSpaceBetween>
                    <FilterContainer>
                        <FlexCentredRow>
                            <FlexCentredRow className="mr-[2.065rem] gap-4">
                                <RelativeContainer>
                                    <Button
                                        color="#6B7280"
                                        border="1px solid #9CA3AF"
                                        onClick={() => handleOpenDropdown('sessions')}
                                    >
                                        {session || 'Session'}
                                        <Dropdown />
                                    </Button>
                                    <ActionsPopup
                                        open={openDropdown === 'sessions'}
                                        close={() => setOpenDropdown(null)}
                                        items={actionItemSessions}
                                    />
                                </RelativeContainer>
                                <RelativeContainer>
                                    <Button
                                        color="#6B7280"
                                        border="1px solid #9CA3AF"
                                        onClick={() => handleOpenDropdown('semester')}
                                    >
                                        {`${level} Level` || 'Level'}
                                        <Dropdown />
                                    </Button>
                                    <ActionsPopup
                                        open={openDropdown === 'semester'}
                                        close={() => setOpenDropdown(null)}
                                        items={actionItemLevel}
                                    />
                                </RelativeContainer>
                            </FlexCentredRow>
                            <SearchInput
                                width="21.5rem"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                            />
                            <RelativeContainer>
                                <Button
                                    color="#6B7280"
                                    border="1px solid #9CA3AF"
                                    onClick={() => handleOpenDropdown('program')}
                                >
                                    {`${program}` || 'Department'}
                                    <Dropdown />
                                </Button>
                                <ActionsPopup
                                    open={openDropdown === 'program'}
                                    close={() => setOpenDropdown(null)}
                                    items={actionItemProgram}
                                />
                            </RelativeContainer>
                        </FlexCentredRow>
                    </FilterContainer>
                    <FlexCentredRow className="gap-4 mr-4">
                        <LoadingButton
                            onClick={() => handleApprove()}
                            className="shadow-[0px_1px_2px_0px_rgba(6,25,56,0.05)] rounded"
                            color="#FFFFFF"
                            bgColor="#6366F1"
                            isLoading={isLoading}
                        >
                            <MarkIcon className="mr-2" />
                            Approve
                        </LoadingButton>
                        <LoadingButton
                            loading={isLoading}
                            onClick={() => handleReject()}
                            className="shadow-[0px_1px_2px_0px_rgba(6,25,56,0.05)] rounded"
                            color="#FFFFFF"
                            bgColor="#EF4444"
                        >
                            <CloseIcon className="mr-2" />
                            Reject
                        </LoadingButton>
                    </FlexCentredRow>
                </FlexRowSpaceBetween>
                <Table
                    data={exeat || []}
                    columns={fields}
                    isActionable
                    actionType="radio"
                    onSelectedItemsChange={(items) => setSelectedItem(items[0])}
                    noItemView={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent isInTable title="No Exeat Yet" subtitle="Create New Exeat" />
                        </CenteredContainer>
                    }
                    loading={isLoading}
                    width={'100%'}
                />
            </div>

            <ExeatDetails exeatId={exeatId} show={showDetails} onClose={() => setShowDetails(false)} />
        </>
    );
};

export default Exeat;
