import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { Form, Formik, FieldArray } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ArrowRight } from '../../../../assets/icons/ChevronRight.svg';
import { ReactComponent as PlusCircle } from '../../../../assets/icons/circlePlus.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/delete.svg';
import { ReactComponent as PeopleIcon } from '../../../../assets/icons/people.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../components/go-back';
import DatePicker from '../../../../components/inputs/date-picker';
import Select from '../../../../components/inputs/new-select';
import TextInput from '../../../../components/inputs/text-input-with-formik';
import { Text } from '../../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowCentered,
    FlexRowSpaceBetween,
} from '../../../../containers/ScreenContainers';
import { getAllActivePaymentGateways, modifyDebtor } from '../../../../redux/bursary/actions';
import { getStudents } from '../../../../redux/students/actions';
import { range } from '../../../../utils';
import { installmentOpt, paymentScheduleData } from '../../../../utils/bursaryData';
import currencyFormatter from '../../../../utils/formatCurrency';

import { ValidationSchema } from './validationSchema';

const Container = tw.div`
    bg-white
    h-auto
    px-[3.2rem]
    py-[3.6rem]
    rounded-[16px]
    mt-10
    max-w-[1000px]
    mb-[100px]
`;

const SPAN_OPTIONS = [
    { name: 'First Semester', value: 'first-semester' },
    { name: 'Second Semester', value: 'second-semester' },
    { name: 'Both Semesters', value: 'both-semesters' },
];

const INSTALMENT_PLAN_OPTIONS = [
    { name: 'Percentage Based (Institution sets a ratio percentage)', value: 'percent' },
    { name: 'Student’s Choice (Students determines the amount to pay in each instalment)', value: 'student' },
];

const TYPE_OF_FEE = [
    {
        name: 'Tuition Fee',
        value: 'Tuition-Fee',
    },
];

const calculateTotalBreakdown = (feeBreakdown) => {
    let totalAmount = 0;
    feeBreakdown.forEach((fee) => {
        totalAmount += parseFloat(fee.amount) || 0;
    });
    return totalAmount;
};

const EditDebtor = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: debtId } = useParams();

    const { sessions } = useSelector((store) => store.admission);
    const { isLoading } = useSelector((store) => store.bursary);
    const { state: debtor } = useLocation();
    const defaultValues = { ...debtor, ...debtor?.installmentDetails };

    const [activePayments, setActivePayments] = useState([]);
    const [showBreakdown, setShowBreakdown] = useState(false);

    const [paymentConfig, setPaymentConfig] = useState('');
    const [activeBusiness, setActiveBusiness] = useState([]);

    const isSubmitDisabled = (feeBreakDownObj, totalOwed) => {
        const totalBreakdown = calculateTotalBreakdown(feeBreakDownObj);

        if (isLoading || (totalBreakdown > 0 && totalBreakdown !== totalOwed)) return true;
        return false;
    };

    useEffect(() => {
        const getActiveGateways = async () => {
            const data = await dispatch(getAllActivePaymentGateways());
            setActivePayments(data);
        };

        getActiveGateways();
    }, [dispatch]);

    useEffect(() => {
        dispatch(getStudents());
    }, [dispatch]);

    useEffect(() => {
        if (paymentConfig) {
            const activeBusinesses = activePayments?.filter((item) => item?.provider === paymentConfig);
            setActiveBusiness(activeBusinesses?.[0]?.keys);
        }
    }, [paymentConfig, activePayments]);

    console.log('debt', defaultValues);

    return (
        <>
            <GoBack title="Debt Management" subTitle="Edit Debtor" />

            <Container>
                <FlexCentredRow className="gap-[7.15px] mb-[3.8rem]">
                    <PeopleIcon />
                    <Text size="1.6rem" weight="600">
                        Edit Debtor
                    </Text>
                </FlexCentredRow>

                <Formik
                    initialValues={
                        defaultValues || {
                            search: '',
                            session: '',
                            //feeName
                            feeType: '',
                            //description
                            amountOwed: '',
                            span: '',
                            paymentConfig: '',
                            deadline: '',
                            debtSchedule: '',
                            numberOfInstallments: '',
                            ratios: [],
                            deadlines: [],
                            studentDecides: '',
                            feeBreakdown: [
                                // {
                                //     amount: '',
                                //     description: '',
                                // },
                            ],
                            businessName: '',
                        }
                    }
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        let deadlines = [],
                            ratios = [];
                        let deadline = [];

                        const { deadline1, deadline2, deadline3, deadline4, deadline5, ...otherValues } = values;

                        if (values.debtSchedule === 'Installment' && deadline1) {
                            const dates = [deadline1, deadline2, deadline3, deadline4, deadline5];

                            deadlines = dates.filter((item) => item).map((item) => format(item, 'yyyy-MM-dd'));
                        } else {
                            deadlines = values.deadlines;
                        }

                        if (values.debtSchedule === 'Installment' && values.studentDecides === false) {
                            if (values.ratios?.length > 1) {
                                values.ratios = [values?.ratios?.join(':').toString()];
                            }

                            ratios = values.ratios?.[0].split(':').map((item) => Number(item));
                        }

                        console.log('val', values?.deadline);
                        if (values.debtSchedule === 'Installment' && values?.deadline) {
                            deadline = '';
                        }

                        const payload = {
                            ...otherValues,
                            deadline,
                            deadlines,
                            ratios,
                        };
                        const res = await dispatch(modifyDebtor(payload, debtId, debtor?._id));
                        if (res) navigate(-1);
                    }}
                >
                    {({ handleChange, errors, values, setFieldValue, touched }) => (
                        <Form>
                            <div className="gap-x-[3.6rem] gap-y-[2rem] grid-cols-2 grid">
                                <TextInput
                                    name="description"
                                    label="Description (Optional)"
                                    onChange={handleChange}
                                    type="text"
                                    placeholder="Input description"
                                    errors={errors}
                                    value={values?.description}
                                />

                                <Select
                                    name="session"
                                    objProp="admissionYear"
                                    data={sessions}
                                    placeholder="Choose Session"
                                    label="Session"
                                    onChange={(selected) => {
                                        setFieldValue('session', selected[0].admissionYear);
                                    }}
                                    error={errors.session}
                                    passedSelectedItems={values?.session}
                                />

                                <Select
                                    name="span"
                                    objProp="name"
                                    label="Span of Fee"
                                    placeholder="Choose Span"
                                    data={SPAN_OPTIONS}
                                    onChange={(selected) => {
                                        setFieldValue('span', selected[0].value);
                                    }}
                                    error={errors.span}
                                    passedSelectedItems={values?.span}
                                />

                                <Select
                                    name="feeType"
                                    placeholder="Choose Fee Type"
                                    objProp="name"
                                    data={TYPE_OF_FEE}
                                    label="Type of Fee"
                                    onChange={(selected) => {
                                        setFieldValue('feeType', selected[0].value);
                                    }}
                                    error={errors.feeType}
                                    passedSelectedItems={values?.feeType}
                                    disabled
                                />

                                <TextInput
                                    name="amountOwed"
                                    type="number"
                                    label="Amount Owed (₦)"
                                    placeholder="Input Amount"
                                    onChange={handleChange}
                                    errors={errors}
                                    value={values?.amountOwed}
                                />

                                <Select
                                    name="debtSchedule"
                                    objProp="name"
                                    placeholder="Select a Payment Schedule"
                                    label="Payment Schedule"
                                    data={paymentScheduleData}
                                    onChange={(selected) => {
                                        setFieldValue('debtSchedule', selected[0].name);
                                        setFieldValue('deadline', '');
                                        // setFieldValue('numberOfInstallments', '');
                                        // setFieldValue('studentDecides', false);
                                        // setFieldValue('ratios', '');
                                    }}
                                    error={errors.debtSchedule}
                                    passedSelectedItems={values?.debtSchedule}
                                />

                                {values?.debtSchedule === 'One-Off' && (
                                    <DatePicker
                                        label="Deadline (Optional)"
                                        name="deadline"
                                        touched={touched}
                                        minDate={new Date()}
                                        // disabled
                                    />
                                )}

                                {values?.debtSchedule === 'Installment' && (
                                    <>
                                        <Select
                                            name="numberOfInstallments"
                                            objProp="name"
                                            label="Number of Instalments"
                                            placeholder="Select number of instalments"
                                            data={installmentOpt?.map((item) => ({ name: item }))}
                                            onChange={(selected) => {
                                                setFieldValue('numberOfInstallments', Number(selected[0].name));
                                            }}
                                            error={errors.numberOfInstallments}
                                            passedSelectedItems={values?.numberOfInstallments}
                                        />

                                        <Select
                                            name="studentDecides"
                                            objProp="name"
                                            label="Instalment Plan"
                                            placeholder="Select instalment plan"
                                            data={INSTALMENT_PLAN_OPTIONS}
                                            onChange={(selected) => {
                                                if (selected[0].value === 'student') {
                                                    setFieldValue('studentDecides', true);
                                                } else {
                                                    setFieldValue('studentDecides', false);
                                                }
                                            }}
                                            error={errors.studentDecides}
                                            passedSelectedItems={
                                                values?.studentDecides === true
                                                    ? INSTALMENT_PLAN_OPTIONS[1]
                                                    : INSTALMENT_PLAN_OPTIONS[0]
                                            }
                                        />
                                    </>
                                )}

                                {values?.debtSchedule === 'Installment' && !values?.studentDecides && (
                                    <TextInput
                                        name="ratios"
                                        type="text"
                                        label="Instalment Percentage"
                                        placeholder="Input instalment percentage e.g 50:50, 40:30:30"
                                        onChange={(e) => {
                                            setFieldValue('ratios', [e.target.value]);
                                        }}
                                        errors={errors}
                                        value={values?.ratios?.join(':')}
                                    />
                                )}
                                {values?.debtSchedule === 'Installment' && values?.numberOfInstallments && (
                                    <>
                                        {range(values?.numberOfInstallments)?.map((num) => {
                                            const deadlineValue = values?.deadlines?.[num - 1]
                                                ? new Date(values?.deadlines?.[num - 1])
                                                : null;

                                            return (
                                                <DatePicker
                                                    key={num}
                                                    label={`Deadline ${num} (Optional)`}
                                                    name={`deadline${num}`}
                                                    touched={touched}
                                                    initialValue={deadlineValue}
                                                    minDate={
                                                        num !== 1
                                                            ? new Date(values?.[`deadline${num - 1}`])
                                                            : new Date()
                                                    }
                                                    // disabled
                                                />
                                            );
                                        })}
                                    </>
                                )}

                                <Select
                                    name="paymentConfig"
                                    objProp="provider"
                                    label="Payment Gateway"
                                    placeholder="Choose a Payment Gateway"
                                    data={activePayments}
                                    onChange={(selected) => {
                                        setFieldValue('paymentConfig', selected[0]?.provider);
                                        setPaymentConfig(selected[0].provider);
                                    }}
                                    error={errors.paymentConfig}
                                    passedSelectedItems={values?.paymentConfig}
                                />

                                {values?.paymentConfig && (
                                    <FlexCentredCol>
                                        <Select
                                            name="businessName"
                                            objProp="businessName"
                                            label="Business Name"
                                            placeholder="Choose a Business Name"
                                            data={activeBusiness?.filter((item) => item?.active)}
                                            onChange={(selected) => {
                                                setFieldValue('businessName', selected[0]?.businessName);
                                            }}
                                            error={errors.paymentConfig}
                                            passedSelectedItems={values?.businessName}
                                        />
                                    </FlexCentredCol>
                                )}
                            </div>

                            <div className="">
                                <FlexCentredRow
                                    className="pt-[3.2rem] gap-[1rem] max-w-max cursor-pointer"
                                    onClick={() => setShowBreakdown(!showBreakdown)}
                                >
                                    <Text size="1.6rem" weight="600" align="left">
                                        Fee Breakdown
                                    </Text>
                                    <Text size="1.6rem" weight="400" align="left">
                                        (optional)
                                    </Text>

                                    <ArrowRight className={`${showBreakdown ? 'rotate-90' : ''}`} />
                                </FlexCentredRow>

                                {showBreakdown ? (
                                    <FlexCentredCol className="gap-[5.6rem]">
                                        <FieldArray
                                            name="feeBreakdown"
                                            render={({ insert, remove, push }) => (
                                                <React.Fragment>
                                                    <div className="flex flex-col gap-[1rem]">
                                                        {values.feeBreakdown?.map((fee, index) => (
                                                            <div
                                                                key={index}
                                                                className="grid grid-cols-[1fr_1fr_50px] gap-x-[1.6rem] mt-6"
                                                            >
                                                                <TextInput
                                                                    name={`feeBreakdown.${index}.description`}
                                                                    label="Description"
                                                                    onChange={handleChange}
                                                                    type="text"
                                                                    value={fee.description}
                                                                    placeholder="Input Description"
                                                                    errors={errors}
                                                                />
                                                                <TextInput
                                                                    name={`feeBreakdown.${index}.amount`}
                                                                    label="Amount"
                                                                    onChange={handleChange}
                                                                    type="number"
                                                                    value={fee.amount}
                                                                    placeholder="Input Amount"
                                                                    errors={errors}
                                                                />

                                                                <Delete
                                                                    onClick={() => remove(index)}
                                                                    className="cursor-pointer self-end mb-3"
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <FlexRowSpaceBetween>
                                                        <Button
                                                            className="flex items-center gap-[8px]"
                                                            border="1px solid #2563EB"
                                                            type="button"
                                                            color="#2563EB"
                                                            onClick={() => push({ description: '', amount: '' })}
                                                        >
                                                            <PlusCircle fill="#2563EB" />
                                                            Add Fee Breakdown
                                                        </Button>
                                                        <FlexRowCentered className="gap-[2.4rem]">
                                                            <Text align="left" size="1.4rem" weight="500">
                                                                Amount Owed :{' '}
                                                                {currencyFormatter.format(values.amountOwed || 0)}
                                                            </Text>

                                                            <Text align="left" size="1.4rem" weight="500">
                                                                Total Fee Breakdown :{' '}
                                                                {currencyFormatter.format(
                                                                    calculateTotalBreakdown(values.feeBreakdown) || 0,
                                                                )}
                                                            </Text>
                                                        </FlexRowCentered>
                                                    </FlexRowSpaceBetween>
                                                </React.Fragment>
                                            )}
                                        />
                                    </FlexCentredCol>
                                ) : null}
                            </div>

                            <div className="flex items-center gap-[1rem] mt-[2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)} className="ml-auto">
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={isLoading}
                                    disabled={isSubmitDisabled(values.feeBreakdown, values.amountOwed)}
                                    type="submit"
                                >
                                    Update Debtor
                                </LoadingButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default EditDebtor;
