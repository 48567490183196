import React, { useEffect, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as CircularPlus } from '../../../../assets/icons/circularplus.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as Edit } from '../../../../assets/icons/edit3.svg';
import { ConfirmActionDialogue } from '../../../../components';
import { Button } from '../../../../components/buttons';
import EditProgramme from '../../../../components/forms/edit-programme';
import ListEmptyContent from '../../../../components/list-empty';
import { CenteredContainer } from '../../../../containers/ScreenContainers';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { deleteThirdDegree } from '../../../../redux/academic-structure/actions';
import { getCollegeThirdLevel } from '../../../../redux/college/actions';
import { getFacultyThirdLevel } from '../../../../redux/faculty/actions';
import { SAVE_CURRENT_PROGRAMME } from '../../../../redux/programmes/slice';

const fields = [
    {
        header: 'Name',
        accessorKey: 'subprogram',
        cell: (props) => (
            <Link style={{ color: '#2563EB' }} to={`/faculties/department/programme/students`}>
                {props.getValue()}
            </Link>
        ),
    },
    {
        header: 'Students',
        accessorKey: 'students',
        cell: (props) => <p>{props?.getValue()?.length || 0}</p>,
    },
];

const DepartmentProgrammes = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentStructure } = useSelector((state) => state.academicStructure);
    const { isLoading, departmentProgrammes, currentProgramme } = useSelector((state) => state.programmes);
    const { currentDepartment } = useSelector((state) => state.department);
    const { currentFaculty, isLoading: facultyLoading } = useSelector((state) => state.faculty);
    const { isLoading: collegeLoading } = useSelector((state) => state.college);

    const { academicStructureId, _id: facultyId, splitAcademicStructureThree } = currentFaculty;

    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [progName, setProgName] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedProg, setSelectedProg] = useState(null);
    const [refetch, setRefetch] = useState(false);

    const handleEditFinish = () => {
        setRefetch((prev) => !prev);
        setSelectedProg(null);
    };

    useEffect(() => {
        if (facultyId) {
            const currStructName = currentStructure?.name?.toLowerCase() || '';

            if (currStructName === 'college') {
                dispatch(getCollegeThirdLevel(academicStructureId, facultyId));
            }
            if (currStructName === 'faculty') {
                dispatch(getFacultyThirdLevel(academicStructureId, facultyId));
            }
        }
    }, [dispatch, currentStructure, refetch, academicStructureId, facultyId]);

    const handleDelete = async () => {
        setLoading(true);
        setRefetch(false);
        if (currentProgramme.subprogram !== progName) {
            toast.error(`${splitAcademicStructureThree} Name is wrong`);
        } else {
            const res = await dispatch(deleteThirdDegree(selectedProg?.academicStructureId, selectedProg?._id));
            if (res) {
                toast.success(`${splitAcademicStructureThree} deleted successfully`);
                setShowDelete(false);
                setRefetch(true);
            }
        }
        setLoading(false);
    };

    return (
        <>
            <PageLayout
                secondaryButtonText={`Add ${splitAcademicStructureThree}`}
                onSecondaryButtonClick={() => navigate('add-new')}
                loading={isLoading || facultyLoading || collegeLoading}
                data={departmentProgrammes || []}
                fields={fields}
                isActionable
                actionType="radio"
                onSelectedItemsChange={(items) => setSelectedProg(items[0])}
                onActionBtnClick={() => {
                    setDropdownOpen(!dropdownOpen);
                }}
                actionOpen={dropdownOpen}
                actionClose={() => setDropdownOpen(false)}
                actionItems={[
                    {
                        icon: <Edit />,
                        name: `Edit ${splitAcademicStructureThree}`,
                        click: () => {
                            setShowEdit(true);
                            setDropdownOpen(false);
                        },
                        disabled: !selectedProg,
                    },
                    {
                        icon: <Delete />,
                        name: `Delete ${splitAcademicStructureThree}`,
                        click: () => {
                            setShowDelete(true);
                            setDropdownOpen(false);
                        },
                        disabled: !selectedProg,
                    },
                ]}
                pageTitle={currentDepartment?.departmentName}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent
                            isInTable
                            title={`No ${splitAcademicStructureThree}(s) Yet`}
                            subtitle={`Add new ${splitAcademicStructureThree}(s)`}
                        >
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Button bgColor="#6366F1" color="#fff" onClick={() => navigate('add-new')}>
                                    <CircularPlus className="mr-[1.15rem]" />
                                    Add New {splitAcademicStructureThree}
                                </Button>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
                onRowClick={(item) => {
                    const selectedItem = departmentProgrammes?.find((prog) => prog?._id === item?._id);
                    dispatch(SAVE_CURRENT_PROGRAMME(selectedItem));
                }}
            />

            <EditProgramme
                show={showEdit}
                onClose={() => setShowEdit(false)}
                selectedProg={selectedProg}
                onFinish={() => handleEditFinish()}
            />
            <ConfirmActionDialogue
                show={showDelete}
                close={() => setShowDelete(false)}
                title={`Delete ${splitAcademicStructureThree}?`}
                subtitle={`This ${splitAcademicStructureThree} will be deleted.`}
                borderLine
                bodyText={`To confirm, enter the name of the ${splitAcademicStructureThree} in the text field.`}
                input
                placeholder="Input Name"
                value={progName}
                onChange={(e) => setProgName(e.target.value)}
                btn2Text="Yes, Delete"
                confirmAction={handleDelete}
                isLoading={loading}
            />
        </>
    );
};
export default DepartmentProgrammes;
