import {
    AccessManager,
    AddCampus,
    CampusManagement,
    EditCampus,
    EditOrganizationStaff,
    InviteOrganizationStaff,
} from '../../views';
import OrganizationSettings from '../../views/campus-management/organization-settings';
import OrganizationDelete from '../../views/campus-management/organization-settings/organization-delete';
import OrganizationProfile from '../../views/campus-management/organization-settings/organization-profile';
import EditOrgProfile from '../../views/campus-management/organization-settings/organization-profile/edit-profile';

export const campusRoutes = [
    {
        path: '/campus-management/campuses',
        element: <CampusManagement />,
    },
    {
        path: '/campus-management/campuses/add-campus',
        element: <AddCampus />,
    },
    {
        path: '/campus-management/campuses/edit-campus/:id',
        element: <EditCampus />,
    },
    {
        path: '/campus-management/access',
        element: <AccessManager />,
    },
    {
        path: '/campus-management/access/add-staff',
        element: <InviteOrganizationStaff />,
    },
    {
        path: '/campus-management/access/edit-staff/:id',
        element: <EditOrganizationStaff />,
    },
    {
        path: '/campus-management/settings',
        element: <OrganizationSettings />,
    },

    {
        path: '/campus-management/settings/profile',
        element: <OrganizationProfile />,
    },
    {
        path: '/campus-management/settings/profile/edit',
        element: <EditOrgProfile />,
    },
    {
        path: '/campus-management/settings/account-deletion',
        element: <OrganizationDelete />,
    },
];
