import React, { useEffect, useMemo, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../assets/icons/delete2.svg';
// import { ReactComponent as EditIcon } from '../../assets/icons/edit3.svg';
import ListEmptyContent from '../../components/list-empty';
import ConfirmActionDialogueWithInput from '../../components/popups/confirm-action-with-input';
import { CountBadge } from '../../components/shared';
import { Text } from '../../containers/MesssageContainers';
import { CenteredContainer } from '../../containers/ScreenContainers';
import useDebounce from '../../hooks/useDebounce';
import PageLayout from '../../layout/page-layout/PageLayout';
import { getAdministratorsInOrganization /*removeStaffAsAdmin*/ } from '../../redux/administrators/actions';
import { removeAllRolesFromStaff } from '../../redux/roles/actions';

const fields = [
    {
        header: 'Name',
        accessorKey: 'name',
        cell: ({ row }) => (
            <p>
                {row.original.firstName} {row.original.otherName || ''} {row.original.surname}
            </p>
        ),
    },
    {
        header: 'Organization Unit',
        accessorKey: 'unit',
        cell: ({ row }) => (
            <div className="flex items-center gap-[4px]">
                <Text align="left" color="#6b728">
                    {row.original.organizationUnit[0] || '-'}
                </Text>
                {row.original.organizationUnit?.length > 1 ? (
                    <CountBadge>+{row.original.organizationUnit?.length - 1}</CountBadge>
                ) : null}
            </div>
        ),
    },
    {
        header: 'Designation',
        accessorKey: 'designation',
    },
    {
        header: 'Role',
        accessorKey: 'role',
        cell: ({ row }) => (
            <div className="flex items-center gap-[4px]">
                <Text align="left" color="#6b728">
                    {row.original?.staffRole[0]?.role || '-'}
                </Text>
                {row.original.staffRole?.length > 1 ? (
                    <CountBadge>+{row.original.staffRole?.length - 1}</CountBadge>
                ) : null}
            </div>
        ),
    },
    {
        header: 'Staff ID',
        accessorKey: 'staffNumber',
    },
    {
        header: 'Email',
        accessorKey: 'staffEmail',
    },
];

const CONFIRM_TEXT = 'remove';

const OrganizationAdmins = ({ withCreate }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isLoading, allAdmins } = useSelector((state) => state.administrators);
    const { isLoading: altLoading } = useSelector((state) => state.roles);

    const [openDropdown, setOpenDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openModal, setOpenModal] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [toggleRefetch, setToggleRefetch] = useState(false);

    const adminsToShow = useMemo(
        //only show admins that have a role other than platform administrator
        () =>
            allAdmins?.filter((staff) => {
                return staff?.staffRole?.some((role) => role?.role !== 'Platform Administrator');
            }),
        [allAdmins],
    );

    const confirmRemoveAction = async () => {
        if (inputValue !== CONFIRM_TEXT) return toast.error(`Input ${CONFIRM_TEXT}`);

        const res = await dispatch(removeAllRolesFromStaff(selectedItem._id));
        if (res) {
            setOpenModal('');
            setToggleRefetch((prevState) => !prevState);
        }
    };

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.role = debouncedSearchValue;
        }
        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        dispatch(getAdministratorsInOrganization(query));
    }, [dispatch, query, toggleRefetch]);

    return (
        <>
            <PageLayout
                pageTitle="Unit Administrators"
                secondaryButtonText={withCreate ? 'Add Administrators' : ''}
                onSecondaryButtonClick={() => navigate('add')}
                loading={isLoading}
                data={adminsToShow || []}
                fields={fields}
                isActionable
                actionType="radio"
                onSelectedItemsChange={(items) => setSelectedItem(items[0])}
                onActionBtnClick={() => setOpenDropdown(!openDropdown)}
                actionOpen={openDropdown}
                actionClose={() => setOpenDropdown(false)}
                actionItems={[
                    // {
                    //     icon: <EditIcon />,
                    //     name: 'Edit Administrator',
                    //     click: () => {
                    //         setOpenDropdown(false);
                    //     },
                    // },
                    {
                        icon: <DeleteIcon />,
                        name: 'Remove Administrator',
                        disabled: !selectedItem,
                        click: () => {
                            setOpenModal('confirm-remove');
                            setOpenDropdown(false);
                        },
                    },
                ]}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No admins "></ListEmptyContent>
                    </CenteredContainer>
                }
            />

            <ConfirmActionDialogueWithInput
                show={openModal === 'confirm-remove'}
                isLoading={isLoading || altLoading}
                placeholder={`Input ${CONFIRM_TEXT}`}
                title="Remove Unit Administrator?"
                subtitle="The administrator's access to this organization unit will be terminated."
                subtitle2={`To confirm Removal, enter '${CONFIRM_TEXT}' in the text field.`}
                close={() => setOpenModal('')}
                confirmAction={confirmRemoveAction}
                btn2Text="Yes, Remove"
                setInputValue={setInputValue}
                value={inputValue}
                btnDisabled={!inputValue}
            />
        </>
    );
};

export default OrganizationAdmins;
