import React, { useEffect } from 'react';

import { storeUnit } from '../../../utils/originUnit';

const Home = ({ unitName }) => {
    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return <div>Home</div>;
};

export default Home;
