import React from 'react';

import Avatar from 'react-avatar';
import tw, { styled } from 'twin.macro';

import { ReactComponent as People } from '../../../../../assets/icons/people2.svg';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../../../../utils';

import StudentOverview from './studentOverview';

const MainContainer = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-2
    grid-flow-row
    gap-6
    mt-8
    mb-[6.4rem]
`;

const BasicContent = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-4
    mt-8
`;

const Content = tw.div`
    grid
    grid-cols-1
    lg:grid-cols-2
    mt-8
`;
const RowContainer = tw.div`
    grid
    grid-rows-2
    gap-8
`;

const FlexCol = tw.div`
    gap-6
`;

const Details = tw.div`
    lg:col-span-2
    col-span-1
    bg-white
    p-6
    rounded-lg
`;
const CardDetails = tw.div`
    col-span-1
    bg-white
    p-6
    rounded-lg
`;

const DetailsCardTitleText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
`;

const DetailsCardSubText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
    line-height: 2.4rem;
    text-align: ${({ align }) => align || 'left'};
`;

const Overview = ({ details, studentDet, academicStruct, currentUnit }) => {
    const { bioData, basicData, uploads, academicData } = details || {};
    const { secondaryInfo, primaryInfo, resultInfo, utmeInfo, tertiaryInfo } = academicData || {};

    const firstLevel = academicStruct?.split('-')?.[0];
    const secondLevel = academicStruct?.split('-')?.[1];

    const firstDegree = studentDet[firstLevel];
    const secondDegree = studentDet[secondLevel];

    const leftRow = [
        {
            title: 'Full Name',
            subtitle: `${basicData?.lastName} ${basicData?.firstName} ${basicData?.middleName || ''}`,
        },
        {
            title: 'Sex',
            subtitle: bioData?.updateInfo?.gender,
        },
        {
            title: 'Date of Birth',
            subtitle: bioData?.updateInfo?.dateOfBirth?.slice(0, 10),
        },
    ];
    const centerRow = [
        {
            title: 'Matric Number',
            subtitle: basicData?.matricNumber,
        },
        {
            title: 'Admitted Session',
            subtitle: basicData?.admissionSession?.slice(0, 10),
        },
        {
            title: capitalizeFirstLetter(firstLevel),
            subtitle: capitalizeFirstLetter(firstDegree),
        },
    ];
    const rightRow = [
        {
            title: capitalizeFirstLetter(secondLevel),
            subtitle: capitalizeFirstLetter(secondDegree),
        },

        {
            title: 'Admission Mode',
            subtitle: basicData?.admissionType,
        },
        {
            title: 'Current Session',
            subtitle: new Date(details?.basicData?.admissionYear).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            }),
        },
    ];
    const primaryLeft = [
        {
            title: 'Primary School Attended',
            subtitle: academicData?.primaryInfo?.[0]?.primarySchool,
        },

        {
            title: 'Qualification Obtained',
            subtitle: academicData?.primaryInfo?.[0]?.qualificationObtain,
        },
    ];
    const primaryRight = [
        {
            title: 'Duration',
            subtitle: `${primaryInfo?.[0]?.from} - ${primaryInfo?.[0]?.to} `,
        },
    ];
    const secondaryLeft = [
        {
            title: 'Primary School Attended',
            subtitle: secondaryInfo?.[0]?.secondarySchool,
        },

        {
            title: 'Qualification Obtained',
            subtitle: secondaryInfo?.[0]?.qualificationObtain,
        },
    ];
    const secondaryRight = [
        {
            title: 'Duration',
            subtitle: `${secondaryInfo?.[0]?.from} - ${secondaryInfo?.[0]?.to} `,
        },
    ];
    const tertiaryLeft = [
        {
            title: 'Primary School Attended',
            subtitle: tertiaryInfo?.[0]?.tertiarySchool,
        },

        {
            title: 'Qualification Obtained',
            subtitle: tertiaryInfo?.[0]?.qualificationObtain,
        },
    ];
    const tertiaryRight = [
        {
            title: 'Duration',
            subtitle: `${tertiaryInfo?.[0]?.from} - ${tertiaryInfo?.[0]?.to} `,
        },
    ];

    return (
        <div>
            {currentUnit === 'Admissions' && (
                <>
                    <Details className="mt-[3rem]">
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Students Details
                            </PageTitle>
                        </FlexCentredRow>

                        <BasicContent>
                            {uploads?.passport?.[0]?.url ? (
                                <div>
                                    <img
                                        alt="profile"
                                        src={uploads?.passport?.[0]?.url || ''}
                                        className="h-[18rem] w-[15rem]"
                                    />
                                </div>
                            ) : (
                                <Avatar
                                    name={`${basicData?.lastName} ${basicData?.firstName}`}
                                    size="150"
                                    textSizeRatio={1.75}
                                    className="rounded-full"
                                />
                            )}
                            <RowContainer className="lg:text-left text-center">
                                {leftRow?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText>{item.title}</DetailsCardTitleText>
                                        <DetailsCardSubText>{item.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-left text-center">
                                {centerRow?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="left">{item.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="left">{item.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                            <RowContainer className="lg:text-right text-center">
                                {rightRow?.map((item, index) => (
                                    <FlexCol key={index}>
                                        <DetailsCardTitleText align="right">{item.title}</DetailsCardTitleText>
                                        <DetailsCardSubText align="right">{item.subtitle}</DetailsCardSubText>
                                    </FlexCol>
                                ))}
                            </RowContainer>
                        </BasicContent>
                    </Details>
                    <MainContainer>
                        <CardDetails>
                            <FlexCentredRow>
                                <People fill="#3B82F6" className="mr-[1.719rem]" />
                                <PageTitle size="1.9rem" lineheight="2.8rem">
                                    Primary School Information
                                </PageTitle>
                            </FlexCentredRow>
                            <Content>
                                <RowContainer className="lg:text-left text-center">
                                    {primaryLeft?.map((item, index) => (
                                        <FlexCol key={index}>
                                            <DetailsCardTitleText>{item.title}</DetailsCardTitleText>
                                            <DetailsCardSubText>{item.subtitle}</DetailsCardSubText>
                                        </FlexCol>
                                    ))}
                                </RowContainer>
                                <RowContainer className="lg:text-right text-center">
                                    {primaryRight?.map((item, index) => (
                                        <FlexCol key={index}>
                                            <DetailsCardTitleText align="right">{item.title}</DetailsCardTitleText>
                                            <DetailsCardSubText align="right">{item.subtitle}</DetailsCardSubText>
                                        </FlexCol>
                                    ))}
                                </RowContainer>
                            </Content>
                        </CardDetails>
                        <CardDetails>
                            <FlexCentredRow>
                                <People fill="#3B82F6" className="mr-[1.719rem]" />
                                <PageTitle size="1.9rem" lineheight="2.8rem">
                                    Secondary School Information
                                </PageTitle>
                            </FlexCentredRow>
                            <Content>
                                <RowContainer className="lg:text-left text-center">
                                    {secondaryLeft?.map((item, index) => (
                                        <FlexCol key={index}>
                                            <DetailsCardTitleText>{item.title}</DetailsCardTitleText>
                                            <DetailsCardSubText>{item.subtitle}</DetailsCardSubText>
                                        </FlexCol>
                                    ))}
                                </RowContainer>
                                <RowContainer className="lg:text-right text-center">
                                    {secondaryRight?.map((item, index) => (
                                        <FlexCol key={index}>
                                            <DetailsCardTitleText align="right">{item.title}</DetailsCardTitleText>
                                            <DetailsCardSubText align="right">{item.subtitle}</DetailsCardSubText>
                                        </FlexCol>
                                    ))}
                                </RowContainer>
                            </Content>
                        </CardDetails>

                        <CardDetails>
                            <FlexCentredRow>
                                <People fill="#3B82F6" className="mr-[1.719rem]" />
                                <PageTitle size="1.9rem" lineheight="2.8rem">
                                    Result Selection With Dates
                                </PageTitle>
                            </FlexCentredRow>
                            {resultInfo?.map((info) => (
                                <>
                                    {info?._id && (
                                        <>
                                            <div>
                                                {info?.examType && (
                                                    <Content>
                                                        <RowContainer className="lg:text-left text-center mb-[2rem]">
                                                            <FlexCol>
                                                                <DetailsCardTitleText>Sitting</DetailsCardTitleText>
                                                                <DetailsCardSubText>First</DetailsCardSubText>
                                                            </FlexCol>
                                                            <FlexCol>
                                                                <DetailsCardTitleText>Exam Number</DetailsCardTitleText>
                                                                <DetailsCardSubText>
                                                                    {info?.examNumber}
                                                                </DetailsCardSubText>
                                                            </FlexCol>
                                                        </RowContainer>
                                                        <RowContainer className="lg:text-right text-center mb-[2rem]">
                                                            <FlexCol>
                                                                <DetailsCardTitleText align="right">
                                                                    Exam Body
                                                                </DetailsCardTitleText>
                                                                <DetailsCardSubText align="right">
                                                                    {info?.examType}
                                                                </DetailsCardSubText>
                                                            </FlexCol>
                                                            <FlexCol>
                                                                <DetailsCardTitleText align="right">
                                                                    Exam Year
                                                                </DetailsCardTitleText>
                                                                <DetailsCardSubText align="right">
                                                                    {info?.examYear?.slice(0, 10)}
                                                                </DetailsCardSubText>
                                                            </FlexCol>
                                                        </RowContainer>

                                                        <RowContainer className="lg:text-left text-center">
                                                            {info?.results
                                                                ?.slice(0, Math.ceil(info?.results?.length / 2))
                                                                .map((item, index) => (
                                                                    <FlexCol key={index}>
                                                                        <DetailsCardTitleText>
                                                                            {item?.subject}
                                                                        </DetailsCardTitleText>

                                                                        <DetailsCardSubText>
                                                                            {item?.grade}
                                                                        </DetailsCardSubText>
                                                                    </FlexCol>
                                                                ))}
                                                        </RowContainer>
                                                        <RowContainer className="lg:text-right text-center">
                                                            {info?.results
                                                                ?.slice(Math.ceil(info?.results?.length / 2))
                                                                .map((item, index) => (
                                                                    <FlexCol key={index}>
                                                                        <DetailsCardTitleText align="right">
                                                                            {item.subject}
                                                                        </DetailsCardTitleText>

                                                                        <DetailsCardSubText align="right">
                                                                            {item.grade}
                                                                        </DetailsCardSubText>
                                                                    </FlexCol>
                                                                ))}
                                                        </RowContainer>
                                                    </Content>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </>
                            ))}
                        </CardDetails>
                        <FlexCol>
                            <CardDetails className="mb-6">
                                <FlexCentredRow>
                                    <People fill="#3B82F6" className="mr-[1.719rem]" />
                                    <PageTitle size="1.9rem" lineheight="2.8rem">
                                        UTME Information
                                    </PageTitle>
                                </FlexCentredRow>
                                {utmeInfo?.map((utme) => (
                                    <>
                                        {utme?._id && (
                                            <>
                                                <Content>
                                                    <RowContainer className="lg:text-left text-center">
                                                        <FlexCol>
                                                            <DetailsCardTitleText>Exam Number</DetailsCardTitleText>
                                                            <DetailsCardSubText>{utme?.utmeNumber}</DetailsCardSubText>
                                                        </FlexCol>
                                                    </RowContainer>
                                                    <RowContainer className="lg:text-right text-center">
                                                        <FlexCol>
                                                            <DetailsCardTitleText align="right">
                                                                Exam Year
                                                            </DetailsCardTitleText>
                                                            <DetailsCardSubText align="right">
                                                                {utme?.utmeYear?.slice(0, 10)}
                                                            </DetailsCardSubText>
                                                        </FlexCol>
                                                    </RowContainer>

                                                    <RowContainer className="lg:text-left text-center">
                                                        {utme?.utmeResults
                                                            ?.slice(0, Math.ceil(utme?.utmeResults?.length / 2))
                                                            .map((item, index) => (
                                                                <FlexCol key={index}>
                                                                    <DetailsCardTitleText>
                                                                        {item?.subject}
                                                                    </DetailsCardTitleText>

                                                                    <DetailsCardSubText>
                                                                        {item?.score}
                                                                    </DetailsCardSubText>
                                                                </FlexCol>
                                                            ))}
                                                    </RowContainer>
                                                    <RowContainer className="lg:text-right text-center">
                                                        {utme?.utmeResults
                                                            ?.slice(Math.ceil(utme?.utmeResults?.length / 2))
                                                            .map((item, index) => (
                                                                <FlexCol key={index}>
                                                                    <DetailsCardTitleText align="right">
                                                                        {item?.subject}
                                                                    </DetailsCardTitleText>

                                                                    <DetailsCardSubText align="right">
                                                                        {item?.score}
                                                                    </DetailsCardSubText>
                                                                </FlexCol>
                                                            ))}
                                                    </RowContainer>
                                                </Content>
                                            </>
                                        )}
                                    </>
                                ))}
                            </CardDetails>
                            <CardDetails>
                                <FlexCentredRow>
                                    <People fill="#3B82F6" className="mr-[1.719rem]" />
                                    <PageTitle size="1.9rem" lineheight="2.8rem">
                                        Tertiary School Information
                                    </PageTitle>
                                </FlexCentredRow>
                                <Content>
                                    <RowContainer className="lg:text-left text-center">
                                        {tertiaryLeft?.map((item, index) => (
                                            <FlexCol key={index}>
                                                <DetailsCardTitleText>{item.title}</DetailsCardTitleText>
                                                <DetailsCardSubText>{item.subtitle}</DetailsCardSubText>
                                            </FlexCol>
                                        ))}
                                    </RowContainer>
                                    <RowContainer className="lg:text-right text-center">
                                        {tertiaryRight?.map((item, index) => (
                                            <FlexCol key={index}>
                                                <DetailsCardTitleText align="right">{item.title}</DetailsCardTitleText>
                                                <DetailsCardSubText align="right">{item.subtitle}</DetailsCardSubText>
                                            </FlexCol>
                                        ))}
                                    </RowContainer>
                                </Content>
                            </CardDetails>
                        </FlexCol>
                    </MainContainer>
                </>
            )}
            {currentUnit === 'Students Affairs' && (
                <>
                    <StudentOverview />
                </>
            )}
        </div>
    );
};

export default Overview;
