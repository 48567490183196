import React, { useEffect, useMemo, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as RemoveIcon } from '../../../../../../assets/icons/close.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/edit-black.svg';
import { ConfirmActionDialogue } from '../../../../../../components';
import { Button, LoadingButton } from '../../../../../../components/buttons';
import AddCourseToCurriculum from '../../../../../../components/forms/add-course-curriculum';
import { CancelButton } from '../../../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../../../components/go-back';
import Select from '../../../../../../components/inputs/new-select';
import RadioInput from '../../../../../../components/inputs/radio';
import TableWithUtils from '../../../../../../components/table-with-utils';
import { PageTitle, Text } from '../../../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexDiv, FlexRowEnd } from '../../../../../../containers/ScreenContainers';
import { getAdmissionSessions } from '../../../../../../redux/admissions/actions';
import { getAllCourses } from '../../../../../../redux/courses/actions';
import {
    // approveCurriculum,
    getCurriculumByLastDegreeId,
    removeCourseFromCurriculum,
    saveCurriculum,
    updateCourseClass,
} from '../../../../../../redux/curriculum/actions';

const FilterContainer = tw(FlexDiv)`
  p-[16px]
  gap-4
`;

const Card = tw.div`
    bg-white
    mb-2
    rounded-t-lg
`;

const CreateCurriculum = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { level } = useParams();

    const { isLoading } = useSelector((state) => state.curriculum);
    const { sessions: admissionsSessions, activeSession, activeSemester } = useSelector((state) => state.admission);
    const { currentDepartment } = useSelector((state) => state.department);
    const { isLoading: altLoading, allCourses } = useSelector((state) => state.courses);
    const { currentProgramme } = useSelector((state) => state.programmes);

    const { _id: programId } = currentProgramme || {};
    const { _id: departmentId, academicStructure = '', academicStructureId } = currentDepartment || {};
    const splitStruct = academicStructure.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const [session, setSession] = useState(state?.session || activeSession || '');
    const [semester, setSemester] = useState(state?.semester || activeSemester || 'First Semester');
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [courseToEdit, setCourseToEdit] = useState(null);
    const [openModal, setOpenModal] = useState('');
    const [curriculumData, setCurriculumData] = useState(null);
    const {
        _id: curriculumId,
        courses: curriculumCourses,
        isCompulsoryCOC,
        isElectiveCOC,
        isRequiredCOC,
    } = curriculumData || {};

    const [isCompulsory, setIsCompulsory] = useState(isCompulsoryCOC);
    const [isElective, setIsElective] = useState(isElectiveCOC);
    const [isRequired, setIsRequired] = useState(isRequiredCOC);

    const filteredCourses = useMemo(() => {
        // don't include courses already added to curriculum
        const idsToExclude = new Set(curriculumCourses?.map((item) => item?.courseId));
        return allCourses?.filter((item) => !idsToExclude.has(item?._id));
    }, [allCourses, curriculumCourses]);

    const compulsoryCurriculumCourses = useMemo(
        () =>
            curriculumCourses?.filter((item) => item.courseClass === 'Compulsory' && item.courseSemester === semester),
        [curriculumCourses, semester],
    );
    const requiredCurriculumCourses = useMemo(
        () => curriculumCourses?.filter((item) => item.courseClass === 'Required' && item.courseSemester === semester),
        [curriculumCourses, semester],
    );
    const electiveCurriculumCourses = useMemo(
        () => curriculumCourses?.filter((item) => item.courseClass === 'Elective' && item.courseSemester === semester),
        [curriculumCourses, semester],
    );

    // const [checkedState, setCheckedState] = useState({
    //     required: false,
    //     compulsory: false,
    //     elective: false,
    // });

    // const handleRadioChange = (event) => {
    //     const { name, checked } = event.target;
    //     setCheckedState((prevState) => ({
    //         ...prevState,
    //         [name]: checked,
    //     }));
    // };

    const fields = [
        {
            header: 'Course Code',
            accessorKey: 'courseCode',
        },
        {
            header: 'Title',
            accessorKey: 'courseTitle',
        },
        {
            header: 'Unit',
            accessorKey: 'courseUnit',
        },
        {
            header: 'Pass Mark (%)',
            accessorKey: 'minimumPassScore',
        },
        {
            header: 'Edit',
            accessorKey: 'edit',
            cell: ({ row }) => <EditIcon className="cursor-pointer" onClick={() => onEditClick(row.original)} />,
        },
        {
            header: 'Remove',
            accessorKey: 'remove',
            cell: ({ row }) => (
                <RemoveIcon className="cursor-pointer mt-2" onClick={() => onRemoveClick(row.original)} />
            ),
        },
    ];

    const handleAdd = () => {
        if (!session) return toast.error('You need to select a session!');
        if (!semester) return toast.error('You need to select a semester!');
        if (!selectedCourse) return toast.error('You need to select a course!');

        setOpenModal('add-course');
    };

    const handleRemove = async () => {
        const data = await dispatch(removeCourseFromCurriculum(curriculumId, courseToEdit._id));
        if (data) {
            setCurriculumData(data);
            onEditCourseFinish();
        }
    };

    const handleSave = async () => {
        // const res = await dispatch(approveCurriculum(curriculumId));
        const res = await dispatch(saveCurriculum(curriculumId));
        if (res) {
            toast.success('Curriculum saved successfully!');
            navigate(-1);
        }
    };

    const getCurriculum = async () => {
        if (!session) return toast.error('You need to select a session!');
        if (!semester) return toast.error('You need to select a semester!');
        const data = await dispatch(getCurriculumByLastDegreeId(academicStructureId, lastDegreeId, session, level));
        if (data) {
            const curriculumDat = data.find((item) => item.semester?.toLowerCase() === semester?.toLowerCase());

            return setCurriculumData(curriculumDat);
        }
        setCurriculumData({});
    };

    const onAddCourseFinish = () => {
        getCurriculum();
        setSelectedCourse(null);
    };

    const onEditCourseFinish = () => {
        getCurriculum();
        setOpenModal('');
        setCourseToEdit(null);
    };

    const onEditClick = (item) => {
        setCourseToEdit(item);
        setOpenModal('edit-course');
    };

    const onRemoveClick = (item) => {
        setCourseToEdit(item);
        setOpenModal('remove-course');
    };

    useEffect(() => {
        if (semester) {
            //needs to be 'first' or 'second'
            const semesterParam = semester?.split(' ')?.[0].toLowerCase();
            dispatch(getAllCourses(semesterParam));
        }
    }, [dispatch, semester]);

    useEffect(() => {
        if (lastDegreeId && session && semester) {
            getCurriculum();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, lastDegreeId, session, semester]);

    useEffect(() => {
        if (!curriculumId) return;
        const toggleCarryOver = async () => {
            const payload = {
                compulsory: isCompulsory,
                required: isRequired,
                elective: isElective,
            };

            const res = await dispatch(updateCourseClass(academicStructureId, lastDegreeId, curriculumId, payload));
            if (res) {
                getCurriculum();
            }
        };
        toggleCarryOver();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCompulsory, isElective, isRequired, lastDegreeId, academicStructureId]);

    useEffect(() => {
        dispatch(getAdmissionSessions());
    }, [dispatch]);

    return (
        <>
            <div className="max-w-[130rem] pb-20">
                <GoBack
                    title={`Curriculum / ${level} Level`}
                    subtitle={`${state?.edit ? 'Update' : 'Create'} Curriculum `}
                />

                <PageTitle size="1.9rem" top="2.8rem" bottom="3.2rem" align="left">
                    {state?.edit ? 'Update' : 'Create'} Curriculum
                </PageTitle>

                <Card className="rounded-b-lg py-6">
                    <Text as="h4" weight="600" size="1.6rem" align="left" bottom="1.5rem" left="1.5rem">
                        Select Session/Semester
                    </Text>
                    <div className="grid grid-cols-2 gap-8 pt-7 border-t px-6">
                        <Select
                            name="session"
                            objProp="admissionYear"
                            placeholder="Select Session"
                            data={admissionsSessions}
                            passedSelectedItems={session ? { admissionYear: session } : null}
                            label="Select Session"
                            onChange={(selected) => {
                                setSession(selected[0].admissionYear);
                            }}
                            error={''}
                        />
                        <Select
                            name="semester"
                            objProp="name"
                            placeholder="Select Semester"
                            passedSelectedItems={semester ? [{ name: semester }] : null}
                            data={['First Semester', 'Second Semester'].map((item) => ({ name: item }))}
                            label="Select Semester"
                            onChange={(selected) => {
                                setSemester(selected[0].name);
                                setSelectedCourse(null);
                            }}
                            error={''}
                        />
                    </div>
                </Card>
                <Card className="p-6">
                    <Text as="h4" weight="600" size="1.6rem" align="left">
                        Select Course
                    </Text>
                    <div className="grid grid-cols-7 items-center gap-4">
                        <Select
                            searchable
                            containerClass="col-span-6"
                            name="course"
                            objProp="title"
                            placeholder="Select Course"
                            searchPlaceholder="Input course title"
                            data={filteredCourses?.map((item) => ({
                                ...item,
                                title: `${item.courseCode} - ${item.courseTitle}`,
                            }))}
                            isLoading={altLoading}
                            onChange={(selected) => {
                                setSelectedCourse(selected[0]);
                            }}
                            useComponentState={false}
                            passedSelectedItems={selectedCourse ? [selectedCourse] : null}
                            error={''}
                        />
                        <Button bgColor="#6366F1" color="white" onClick={handleAdd}>
                            Add
                        </Button>
                    </div>
                </Card>
                <div className="mt-2 shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                    <FilterContainer>
                        <Text as="h4" weight="600" size="1.6rem">
                            Compulsory Course(s)
                        </Text>
                    </FilterContainer>
                    <TableWithUtils
                        fields={fields}
                        data={compulsoryCurriculumCourses || []}
                        loading={isLoading}
                        isActionable
                    />
                    <FlexCentredRow className="pl-5 gap-10">
                        <Text>Can a compulsory course be carried over?</Text>
                        <div className="flex gap-10">
                            <RadioInput
                                name="compulsory"
                                label="Yes"
                                checked={isCompulsoryCOC}
                                onChange={() => setIsCompulsory(true)}
                            />
                            <RadioInput
                                name="compulsory"
                                label="No"
                                checked={!isCompulsoryCOC}
                                onChange={() => setIsCompulsory(false)}
                            />
                        </div>
                    </FlexCentredRow>
                </div>
                <div className="mt-2 shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                    <FilterContainer>
                        <Text as="h4" weight="600" size="1.6rem">
                            Required Course(s)
                        </Text>
                    </FilterContainer>
                    <TableWithUtils
                        fields={fields}
                        data={requiredCurriculumCourses || []}
                        loading={isLoading}
                        isActionable
                    />

                    <FlexCentredRow className="pl-5 gap-10">
                        <Text>Can a required course be carried over?</Text>
                        <div className="flex gap-10">
                            <RadioInput
                                name="required"
                                label="Yes"
                                checked={isRequiredCOC}
                                onChange={() => setIsRequired(true)}
                            />
                            <RadioInput
                                name="required"
                                label="No"
                                checked={!isRequiredCOC}
                                onChange={() => setIsRequired(false)}
                            />
                        </div>
                    </FlexCentredRow>
                </div>
                <div className="mt-2 shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                    <FilterContainer>
                        <Text as="h4" weight="600" size="1.6rem">
                            Elective Course(s)
                        </Text>
                    </FilterContainer>
                    <TableWithUtils
                        fields={fields}
                        data={electiveCurriculumCourses || []}
                        loading={isLoading}
                        isActionable
                    />

                    <FlexCentredRow className="pl-5 gap-10">
                        <Text>Can an elective course be carried over?</Text>
                        <div className="flex gap-10">
                            <RadioInput
                                name="elective"
                                label="Yes"
                                checked={isElectiveCOC}
                                onChange={() => setIsElective(true)}
                            />
                            <RadioInput
                                name="elective"
                                label="No"
                                checked={!isElectiveCOC}
                                onChange={() => setIsElective(false)}
                            />
                        </div>
                    </FlexCentredRow>
                </div>

                <FlexRowEnd className="mt-20 gap-4">
                    <CancelButton type="button" onClick={() => navigate(-1)}>
                        Cancel
                    </CancelButton>
                    <LoadingButton loading={isLoading} disabled={!curriculumId || isLoading} onClick={handleSave}>
                        {/* {edit ? 'Edit' : 'Save'} Curriculum */}
                        Save Curriculum
                    </LoadingButton>
                </FlexRowEnd>
            </div>

            <AddCourseToCurriculum
                show={openModal === 'add-course'}
                setShow={setOpenModal}
                course={selectedCourse}
                onFinish={onAddCourseFinish}
                level={level}
                session={session}
                semester={semester}
                lastDegreeId={lastDegreeId}
            />

            <AddCourseToCurriculum
                actionType="edit"
                show={openModal === 'edit-course'}
                setShow={setOpenModal}
                course={courseToEdit}
                onFinish={onEditCourseFinish}
                level={level}
                session={session}
                semester={semester}
                lastDegreeId={lastDegreeId}
                curriculumId={curriculumId}
            />

            <ConfirmActionDialogue
                show={openModal === 'remove-course'}
                close={() => setOpenModal('')}
                title={`Remove ${courseToEdit?.courseCode}?`}
                subtitle="This course will be removed."
                confirmAction={handleRemove}
                btn2Text="Yes, Remove"
                isLoading={isLoading}
            />
        </>
    );
};

export default CreateCurriculum;
