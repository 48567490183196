import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Dropdown } from '../../../../assets/icons/dropdown.svg';
import { ActionsPopup } from '../../../../components';
import { Button } from '../../../../components/buttons';
import { PageTitle } from '../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, RelativeContainer } from '../../../../containers/ScreenContainers';
import { getAllSent, getAllSentMemo, getSentLetterComms } from '../../../../redux/staff-arena/actions';

import OutboxAll from './tabs/SentAll';
import SentLetter from './tabs/SentLetter';
import SentMemo from './tabs/SentMemo';
import SentNotice from './tabs/SentNotice';

const Outbox = ({ unitName }) => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const [sentOfficeMemo, setSentOfficeMemo] = useState([]);
    const [sentLetter, setSentLetter] = useState([]);
    const [allSent, setAllSent] = useState([]);

    const ComposeList = [
        {
            name: 'Letters',
            click: () => {
                navigate('/staff-arena/office/letter');
                setDropdownOpen(false);
            },
        },

        {
            name: ' Memo ',
            click: () => {
                navigate('/staff-arena/office/memo');
                setDropdownOpen(false);
            },
        },
        {
            name: 'Notices ',
            click: () => {
                // navigate('/staff-arena/office/notice');
                setDropdownOpen(false);
            },
        },
    ];

    useEffect(() => {
        const getSentOfficeMemo = async () => {
            const res = await dispatch(getAllSentMemo());
            setSentOfficeMemo(res);
        };

        const getSentOfficeLetter = async () => {
            const res = await dispatch(getSentLetterComms());
            setSentLetter(res);
        };

        const getAllComms = async () => {
            const res = await dispatch(getAllSent());
            setAllSent(res);
        };

        getSentOfficeMemo();
        getSentOfficeLetter();
        getAllComms();
    }, [dispatch]);

    // const handleActionClick = (route) => {
    //     navigate(route);
    //     setDropdownOpen(false);
    // };

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[3.2rem]">
                <PageTitle>Office</PageTitle>

                <RelativeContainer>
                    <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                        Compose
                        <Dropdown />
                    </Button>
                    <ActionsPopup open={dropdownOpen} close={() => setDropdownOpen(false)} items={ComposeList} />
                </RelativeContainer>
            </FlexRowSpaceBetween>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink>All</CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink>Letter</CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink>Memo</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Notice</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <OutboxAll allSent={allSent} />
                    </CTabPane>
                    <CTabPane>
                        <SentLetter sentLetter={sentLetter} />
                    </CTabPane>
                    <CTabPane>
                        <SentMemo sentMemo={sentOfficeMemo} />
                    </CTabPane>
                    <CTabPane>
                        <SentNotice />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default Outbox;
