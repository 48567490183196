import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import { GET_ORGANISATION_UNITS, GET_ACTIVE_ORGANISATION_UNITS, LOADING, STOP_LOADING } from './slice';

export const getActiveOrganisationUnits = (unitQuery) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...unitQuery });

        const response = await axiosInstance.get(
            `${currentAPI}organizationUnit/get_all_custom_unit/:campusId?${queries}`,
        );

        if (response.status === 200) {
            dispatch(GET_ACTIVE_ORGANISATION_UNITS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllOrganisationUnits = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}organizationUnit/:campusId/:unitId/get_all_organization_unit`,
        );

        if (response.status === 200) {
            dispatch(GET_ORGANISATION_UNITS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editOrganisationUnit = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}organizationUnit/:campusId/:unitId/edit_organization_unit/${id}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Unit details updated successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const toggleOrganisationUnitStatus = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}organizationUnit/:campusId/:unitId/toggle_organization_unit/${id}`,
        );
        if (response.status === 200) {
            toast.success('Status updated successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getOrganisationUnitDetails = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}organizationUnit/:campusId/:unitId/get_unit_by_id/${id}`,
        );
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
