import React from 'react';

import { CModal } from '@coreui/react';
import { styled } from 'twin.macro';

import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { Button } from '../../buttons';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;

const Result = ({ children, show, close, title, isLoading }) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexCentredRow className="gap-[1.215rem]">
                <Text as="h3" size="1.9rem" weight="600">
                    {title}
                </Text>
            </FlexCentredRow>

            {children}
            <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                <Button onClick={close} disabled={isLoading} border="0.5px solid #D1D5DB">
                    Close
                </Button>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default Result;
