import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    probationSettings: {},
    academicStandingSettings: {},
    withdrawalListSettings: {},
    cumulativeUnitSettings: {},
    degreeSettings: {},
    gradeSettings: {},
    matricSettings: {},
    minimumPromotionCGPA: {},
    weightedGradePointSettings: {},
    matricNumberFormats: [],
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_PROBATION_SETTINGS: (state, action) => {
            state.probationSettings = action.payload;
        },
        GET_ACADEMIC_STANDING_SETTINGS: (state, action) => {
            state.academicStandingSettings = action.payload;
        },
        GET_WITHDRAWAL_LIST_SETTINGS: (state, action) => {
            state.withdrawalListSettings = action.payload;
        },
        GET_CUMULATIVE_UNITS_SETTINGS: (state, action) => {
            state.cumulativeUnitSettings = action.payload;
        },
        GET_DEGREE_SETTINGS: (state, action) => {
            state.degreeSettings = action.payload;
        },
        GET_GRADE_SETTINGS: (state, action) => {
            state.gradeSettings = action.payload;
        },
        GET_MATRIC_SETTINGS: (state, action) => {
            state.matricSettings = action.payload;
        },
        GET_MATRIC_NUMBER_FORMATS: (state, action) => {
            state.matricNumberFormats = action.payload;
        },
        GET_MINIMUM_PROMOTION_CGPA: (state, action) => {
            state.minimumPromotionCGPA = action.payload;
        },
        GET_WEIGHTED_GRADE_POINT_SETTINGS: (state, action) => {
            state.weightedGradePointSettings = action.payload;
        },
    },
});

export const {
    STOP_LOADING,
    LOADING,
    GET_PROBATION_SETTINGS,
    GET_WITHDRAWAL_LIST_SETTINGS,
    GET_ACADEMIC_STANDING_SETTINGS,
    GET_CUMULATIVE_UNITS_SETTINGS,
    GET_DEGREE_SETTINGS,
    GET_GRADE_SETTINGS,
    GET_MATRIC_SETTINGS,
    GET_MINIMUM_PROMOTION_CGPA,
    GET_WEIGHTED_GRADE_POINT_SETTINGS,
    GET_MATRIC_NUMBER_FORMATS,
} = settingsSlice.actions;

export default settingsSlice.reducer;
