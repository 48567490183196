import React from 'react';

import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import { ReactComponent as People } from '../../../assets/icons/people2.svg';
import { Button } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexCentredRow } from '../../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../../utils';

const MainContainer = tw.div`
    grid
    grid-cols-2
    gap-8
    mt-8
    mb-[6.4rem]
`;

const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
`;

const Personal = ({ details, bio }) => {
    const navigate = useNavigate();
    const firstDegreeStaff = details?.academicStructure?.split('-')[0];
    const secondDegreeStaff = details?.academicStructure?.split('-')[1];
    const firstDegree = details[firstDegreeStaff];
    const secondDegree = details[secondDegreeStaff];

    const leftRow = [
        {
            title: 'Name',
            subtitle: `${details?.surname} ${details?.firstName} ${details?.otherName || ''}`,
        },
        {
            title: 'Personal Email',
            subtitle: details?.staffEmail,
        },
        {
            title: 'Staff Id',
            subtitle: details?.staffNumber || '',
        },
        {
            title: 'Phone Number',
            subtitle: details?.phoneNumber,
        },
    ];
    const rightRow = [
        {
            title: 'Gender',
            subtitle: bio?.personalInfo?.gender,
        },
        {
            title: 'Date of Birth',
            subtitle: bio?.personalInfo?.dateOfBirth?.slice(0, 10),
        },
        {
            title: 'Marital Status ',
            subtitle: bio?.personalInfo?.maritalStatus,
        },
    ];
    const otherLeftRow = [
        {
            title: 'Nationality',
            subtitle: bio?.personalInfo?.nationality,
        },
        {
            title: 'Home Town',
            subtitle: bio?.personalInfo?.homeTown,
        },
        {
            title: 'Place of Birth',
            subtitle: bio?.personalInfo?.placeOfBirth,
        },
        {
            title: 'Religion',
            subtitle: bio?.personalInfo?.religion,
        },
    ];
    const otherRightRow = [
        {
            title: 'State of Origin',
            subtitle: bio?.personalInfo?.stateOfOrigin,
        },
        {
            title: 'LGA',
            subtitle: bio?.personalInfo?.localGovernment,
        },
        {
            title: 'Permanent Home Address ',
            subtitle: bio?.personalInfo?.permanentAddress,
        },
    ];
    const nextLeftRow = [
        {
            title: 'Name',
            subtitle: bio?.personalInfo?.nextOfKinName,
        },

        {
            title: 'Phone Number',
            subtitle: bio?.personalInfo?.nextOfKinPhoneNumber,
        },
    ];
    const nextRightRow = [
        {
            title: 'Address',
            subtitle: bio?.personalInfo?.nextOfKinAddress,
        },
        {
            title: 'Relationship',
            subtitle: bio?.personalInfo?.relationshipWithNextOfKin,
        },
    ];
    const deptLeftRow = [
        {
            title: capitalizeFirstLetter(secondDegreeStaff) || 'Department',
            subtitle: secondDegree || bio?.personalInfo?.department,
        },
    ];
    const deptRightRow = [
        {
            title: capitalizeFirstLetter(firstDegreeStaff) || 'Faculty',
            subtitle: firstDegree || bio?.personalInfo?.faculty,
        },
    ];

    return (
        <div>
            <Details className="mt-[3rem]">
                <FlexRowSpaceBetween>
                    <Text>Bio-Data</Text>
                    <Button
                        border="1px solid #E5E7EB"
                        bgColor="#FFFFFF"
                        color="#6366F1"
                        onClick={() => navigate('/staff-arena/profile/edit', { state: { tab: 0 } })}
                    >
                        <Edit fill="#6366F1" />
                        Edit
                    </Button>
                </FlexRowSpaceBetween>
            </Details>
            <MainContainer>
                <Details>
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Personal Information
                            </PageTitle>
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        <RowContainer className="">
                            {leftRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="">
                            {rightRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>
                <Details>
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Other Information
                            </PageTitle>
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        <RowContainer className="">
                            {otherLeftRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="">
                            {otherRightRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>
                <Details>
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Next of Kin
                            </PageTitle>
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        <RowContainer className="">
                            {nextLeftRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="">
                            {nextRightRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>
                <Details>
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Academic Information
                            </PageTitle>
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        <RowContainer className="">
                            {deptLeftRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="">
                            {deptRightRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>
                <Details>
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Referee's Information
                            </PageTitle>
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>

                    {bio?.reference?.map((refer, _id) => (
                        <BasicContent key={_id}>
                            <RowContainer className="">
                                <FlexCol>
                                    <GreyText>Name</GreyText>
                                    <DarkText>{refer.referee}</DarkText>
                                </FlexCol>
                                <FlexCol>
                                    <GreyText>phone Number</GreyText>
                                    <DarkText>{refer.refereePhoneNumber}</DarkText>
                                </FlexCol>
                            </RowContainer>
                            <RowContainer className="">
                                <FlexCol>
                                    <GreyText>Address</GreyText>
                                    <DarkText>{refer.refereeAddress}</DarkText>
                                </FlexCol>
                                <FlexCol>
                                    <GreyText>Occupation</GreyText>
                                    <DarkText>{refer.occupation}</DarkText>
                                </FlexCol>
                            </RowContainer>
                        </BasicContent>
                    ))}
                </Details>
            </MainContainer>
        </div>
    );
};

export default Personal;
