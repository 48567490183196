import React, { useState, useEffect } from 'react';

import { Link, useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../assets/icons/arrow-back-blue.svg';
import { ReactComponent as Arrow } from '../assets/icons/arrow-down-blue.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import menuIcon from '../assets/icons/menu1.svg';
import { Text } from '../containers/MesssageContainers';
import { FlexCentredRow } from '../containers/ScreenContainers';
import { useWindowSize } from '../hooks/useWindowSize';

const NavItem = tw.li`
    font-medium
    text-[1.4rem]
    mt-[5px]
    leading-6
    flex
    items-center
    lg:gap-x-[1.115rem]
    gap-x-2
    cursor-pointer
    px-[1.315rem]
    pt-[13.98px]
    pb-[14.15px]
`;

const LinkItem = tw(NavItem)`
    hover:text-[#6366F1]
`;

const InnerSidebar = ({ links, navLinks, backLink, showBackArrow }) => {
    const location = useLocation();

    const [width] = useWindowSize();

    const open =
        links.includes(location.pathname.split('/').slice(0, 5).join('/')) ||
        links.includes(location.pathname.split('/').slice(0, 4).join('/')) ||
        links.includes(location.pathname.split('/').slice(0, 3).join('/')) ||
        links?.includes(location?.pathname.split('/').slice(0, 2).join('/')) ||
        links.includes(location.pathname);

    const [openDropdown, setOpenDropdown] = useState(false);
    const [selected, setSelected] = useState(null);
    const [isOpen, setIsOpen] = useState(true);

    const handleClick = (link) => {
        if (!isOpen) setIsOpen(true);

        if (selected === link.title) {
            setOpenDropdown(false);
            setSelected(null);
        } else {
            setSelected(link.title);
            setOpenDropdown(true);
        }
        sessionStorage.removeItem('activeTab');
    };

    const isSelected = (link) => {
        if (location.pathname === link || (location.pathname.startsWith(link) && link !== '/')) return true;
        return false;
    };

    const handleSidebarClick = () => {
        if (isOpen) return;
        setIsOpen(true);
    };

    useEffect(() => {
        width && width <= 1300 && setIsOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    const selectedClass = (link) => {
        return isSelected(link) && isOpen ? 'text-[#6366F1]' : 'text-[#4B5563]';
    };

    useEffect(() => {
        if (!isOpen) {
            setOpenDropdown(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    if (!open) return null;

    return (
        <aside
            onClick={handleSidebarClick}
            className={`${
                isOpen ? 'w-[29rem] px-[2.4rem]' : 'min-w-[60px] px-[1.5rem] cursor-pointer'
            } relative pt-[4rem] duration-300 h-full pb-[5rem] bg-white overflow-y-scroll`}
        >
            {isOpen ? (
                <CloseIcon
                    className="absolute right-[24px] top-[20px] cursor-pointer"
                    onClick={() => setIsOpen(false)}
                />
            ) : (
                <div>
                    <img
                        className="absolute left-[13px] top-[20px] cursor-pointer"
                        src={menuIcon}
                        alt="menu"
                        onClick={() => setIsOpen(!isOpen)}
                    />
                </div>
            )}
            {showBackArrow && isOpen && (
                <div className={`mb-6 pb-[1.6rem] border-b border-[#6366F1]`}>
                    <Link to={backLink}>
                        <FlexCentredRow>
                            <BackArrow className="mr-[1.215rem]" />
                            <Text color="#6366F1">Back</Text>
                        </FlexCentredRow>
                    </Link>
                </div>
            )}
            {isOpen && (
                <ul>
                    {navLinks.map((link) => (
                        <div key={link.title}>
                            {link?.subMenu?.length > 0 ? (
                                <>
                                    <NavItem
                                        onClick={() => handleClick(link)}
                                        className={`${!isOpen && 'justify-center'}`}
                                    >
                                        {isOpen && <p className={`w-full mb-0`}>{link.title} </p>}
                                        {isOpen && (
                                            <Arrow
                                                className={openDropdown && selected === link.title ? '' : '-rotate-90'}
                                            />
                                        )}
                                    </NavItem>
                                    {openDropdown && selected === link.title && (
                                        <ul>
                                            {link?.subMenu?.map((item) => (
                                                <Link to={item.to} key={link.to}>
                                                    <LinkItem
                                                        className={`${
                                                            !isOpen && 'justify-center'
                                                        } text-left  ml-4 ${selectedClass(item.to)}`}
                                                    >
                                                        <p className={`${!isOpen && 'hidden'} w-full mb-0`}>
                                                            {item.title}
                                                        </p>
                                                    </LinkItem>
                                                </Link>
                                            ))}
                                        </ul>
                                    )}
                                </>
                            ) : (
                                <Link to={link.to} onClick={() => sessionStorage.removeItem('activeTab')}>
                                    <LinkItem className={`${!isOpen && 'justify-center'}  ${selectedClass(link.to)}`}>
                                        <p className={`${!isOpen && 'hidden'} w-full mb-0`}>{link.title}</p>
                                    </LinkItem>
                                </Link>
                            )}
                        </div>
                    ))}
                </ul>
            )}
        </aside>
    );
};

export default InnerSidebar;
