import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../../../assets/icons/peopleIcon.svg';
import { LoadingButton } from '../../../../../components/buttons';
import { CancelButton } from '../../../../../components/forms/sharedStyles';
import { GoBack } from '../../../../../components/go-back';
import TextInput from '../../../../../components/inputs/text-input-with-formik';
import { Loader } from '../../../../../components/loader';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { getCumulativeUnits, setCumulativeUnits } from '../../../../../redux/settings/actions';
import { generateLevels } from '../../../../../utils';

// import { ValidationSchema } from './ValidationSchema';

const SemesterHeader = tw.label`
    font-semibold
    text-[1.9rem]
    text-[#000000]
    mb-[4rem]
`;

const Label = tw.label`
    font-semibold
    text-[1.4rem]
    text-[#000000]
`;

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[10.3rem]
    justify-end
`;

const MainContainer = tw.div`
    bg-white
    px-[3rem]
    py-[3rem]
    max-w-[100.3rem]
`;

const InputsContainer = tw.div`
    gap-[0.8rem]
    flex
    flex-col
`;

const GroupContainer = tw.div`
    flex
    flex-wrap
    gap-y-10
    gap-x-20
`;

const CumulativeCreditUnits = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentDepartment } = useSelector((state) => state.department);
    const { isLoading, cumulativeUnitSettings } = useSelector((state) => state.settings);
    const { currentProgramme } = useSelector((state) => state.programmes);

    const { _id: departmentId, studyDuration, academicStructureId, academicStructure } = currentDepartment || {};
    const { _id: programId } = currentProgramme || {};

    const splitStruct = academicStructure.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const [altLoading, setAltLoading] = useState(false);
    const levels = generateLevels(studyDuration);

    const formikInitialData = {};
    levels?.forEach((num) => {
        formikInitialData[num] = {
            minimumUnit: 0,
            maximumUnit: 0,
        };
    });

    useEffect(() => {
        dispatch(getCumulativeUnits(academicStructureId, lastDegreeId));
    }, [dispatch, lastDegreeId, academicStructureId]);

    if (isLoading) return <Loader />;

    return (
        <>
            <GoBack title="Settings" subtitle="Cumulative Credit Units" />

            <PageTitle weight="600" size="2.3rem" lineHeight="3.4rem" top="3.6rem" bottom="3.7rem" align="left">
                Cumulative Credit Units
            </PageTitle>
            <MainContainer>
                <div className="flex items-center gap-[1.6rem] mb-5">
                    <PeopleIcon />
                    <Text size="1.6rem" weight="600">
                        Set Cumulative Credit Units
                    </Text>
                </div>

                <Formik
                    initialValues={{
                        firstSemester: cumulativeUnitSettings?.firstSemester || {
                            ...formikInitialData,
                        },
                        secondSemester: cumulativeUnitSettings?.secondSemester || {
                            ...formikInitialData,
                        },
                    }}
                    // validationSchema={ValidationSchema}
                    onSubmit={async (values) => {
                        setAltLoading(true);

                        const res = await dispatch(
                            setCumulativeUnits(academicStructureId, lastDegreeId, values, false),
                        );

                        if (res) {
                            toast.success('Setting saved successfully!');
                            navigate(-1);
                        }
                        setAltLoading(false);
                    }}
                >
                    {({ errors, handleChange, values }) => (
                        <Form>
                            <SemesterHeader>First Semester</SemesterHeader>
                            <GroupContainer>
                                <InputsContainer>
                                    <Label className="mb-[2rem] text-[1.4rem]">Choose Minimum Cumulative Unit</Label>
                                    {levels?.map((level, idx) => (
                                        <div key={`${level}-${idx}`}>
                                            <Label>{level} Level</Label>
                                            <TextInput
                                                name={`firstSemester.${level}.minimumUnit`}
                                                type="number"
                                                errors={errors}
                                                onChange={handleChange}
                                                value={values?.firstSemester?.[level]?.minimumUnit}
                                                width="31rem"
                                            />
                                        </div>
                                    ))}
                                </InputsContainer>

                                <InputsContainer>
                                    <Label className="mb-[2rem] text-[1.4rem]">Choose Maximum Cumulative Unit</Label>
                                    {levels?.map((level, idx) => (
                                        <div key={`${level}-${idx}`}>
                                            <Label>{level} Level</Label>
                                            <TextInput
                                                name={`firstSemester.${level}.maximumUnit`}
                                                type="number"
                                                errors={errors}
                                                onChange={handleChange}
                                                value={values?.firstSemester?.[level]?.maximumUnit}
                                                width="31rem"
                                            />
                                        </div>
                                    ))}
                                </InputsContainer>
                            </GroupContainer>

                            <SemesterHeader className="mt-[5rem]">Second Semester</SemesterHeader>
                            <GroupContainer>
                                <InputsContainer>
                                    <Label className="mb-[2rem] text-[1.4rem]">Choose Minimum Cumulative Unit</Label>
                                    {levels?.map((level, idx) => (
                                        <div key={`${level}-${idx}`}>
                                            <Label>{level} Level</Label>
                                            <TextInput
                                                name={`secondSemester.${level}.minimumUnit`}
                                                type="number"
                                                errors={errors}
                                                onChange={handleChange}
                                                value={values?.secondSemester?.[level]?.minimumUnit}
                                                width="31rem"
                                            />
                                        </div>
                                    ))}
                                </InputsContainer>

                                <InputsContainer>
                                    <Label className="mb-[2rem] text-[1.4rem]">Choose Maximum Cumulative Unit</Label>
                                    {levels?.map((level, idx) => (
                                        <div key={`${level}-${idx}`}>
                                            <Label>{level} Level</Label>
                                            <TextInput
                                                name={`secondSemester.${level}.maximumUnit`}
                                                type="number"
                                                errors={errors}
                                                onChange={handleChange}
                                                value={values?.secondSemester?.[level]?.maximumUnit}
                                                width="31rem"
                                            />
                                        </div>
                                    ))}
                                </InputsContainer>
                            </GroupContainer>
                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={altLoading}
                                    bgColor="#6366F1"
                                    color="#fff"
                                    disabled={isLoading || altLoading}
                                    type="submit"
                                >
                                    Save
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </>
    );
};

export default CumulativeCreditUnits;
