import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { GET_ALL_SPECIAL_LETTER, GET_SPECIAL_LETTER, LOADING, STOP_LOADING } from './slice';

export const addSpecialLetter = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}special-letter/:unitId?unitName=Registry`, payload);
        if (response.status === 201) {
            const successMessage = response?.data?.message || 'Special Letter Created Successfully!.';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllSpecialLetter = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}special-letter/:campusId/:unitId/special-letters/all?unitName=Registry`,
        );
        if (response.status === 200) {
            dispatch(GET_ALL_SPECIAL_LETTER(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getSpecialLetter = (id, unitName) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}special-letter/:campusId/:unitId/${id}?unitName=Registry`,
        );
        if (response.status === 200) {
            dispatch(GET_SPECIAL_LETTER(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editSpecialLetter = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}special-letter/:campusId/:unitId/${id}?unitName=Registry`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteSpecialLetter = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(
            `${currentAPI}special-letter/:campusId/:unitId/${id}?unitName=Registry`,
        );
        const successMessage = response?.data?.message || 'Special Letter Deleted Successfully!.';
        if (response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const publishSpecialLetter = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}special-letter/:campusId/:unitId/publish/${id}?unitName=Registry`,
        );
        const successMessage = response?.data?.message || 'Special Letter Published Successfully!.';
        if (response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
