import React, { useEffect, useRef, useState } from 'react';

import { Country, State } from 'country-state-city';
import { FieldArray, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ReactComponent as PlusCircle } from '../../../assets/icons/circularplus-blue.svg';
import { ReactComponent as Delete } from '../../../assets/icons/delete.svg';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { updateAdminInfo } from '../../../redux/campus-management/actions';
import { Button, LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input';
import FormikTextInput from '../../inputs/text-input-with-formik';
import { CancelButton } from '../sharedStyles';

// import { ValidationSchema } from './ValidationSchema';

const Container = tw.div`
max-w-[75.4rem]
mt-[3.6rem]
`;

const Details = tw.div`
flex
flex-row
justify-between
border
border-dashed
border-[#D1D5DB]
rounded-[8px]
items-center
px-[.5rem]
py-[.5rem]
gap-[.3rem]
`;
const Label = tw.label`
    text-[#1F2937]
    font-medium
    text-[1.4rem]
`;
const AddButton = tw.div`
bg-white
w-full
h-[4rem]
flex
justify-center
items-center
gap-[.5rem]
border-[1px]
border-[#3B82F6]
rounded-[4px]
bg-[#FFFFFF]
shadow-[0px 1px 2px rgba(6, 25, 56, 0.05)]
py-[.8rem]
px-[1.6rem]
cursor-pointer
`;

const EditOrgProfileForm = () => {
    const navigate = useNavigate();
    const hiddenFileInput = useRef([]);
    const [states, setStates] = useState([]);
    const [countries, setCountries] = useState([]);
    const [countryCode, setCountryCode] = useState('NG');
    const [country, setCountry] = useState('');
    const dispatch = useDispatch();
    const { organizationProfile, isLoading } = useSelector((store) => store.campus);
    const [logoName, setLogoName] = useState(organizationProfile?.logo?.fileName);
    const [fileName, setFileName] = useState([]);

    useEffect(() => {
        setFileName(
            organizationProfile?.organizationImages?.map((images) => {
                return images.fileName;
            }),
        );
    }, [organizationProfile]);

    useEffect(() => {
        setCountries(Country.getAllCountries());
    }, []);

    useEffect(() => {
        if (!country) return;
        const selectedCountry = countries.find((data) => data.name === country);
        setCountryCode(selectedCountry?.isoCode);
    }, [country, countries]);

    useEffect(() => {
        setStates(State.getStatesOfCountry(countryCode));
    }, [countryCode]);

    const handleClick = (index) => {
        hiddenFileInput.current[index].click();
    };

    return (
        <div>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[0.8rem]">
                <BackArrow />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Organization Profile
                </PageTitle>
                <Text size="1.6rem">/</Text>
                <Text size="1.6rem">Edit</Text>
            </FlexCentredRow>
            <Container>
                <Text align="left" size="2.3rem" weight="600" bottom="3.2rem">
                    Organization Profile
                </Text>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        institutionCountry: organizationProfile?.institutionCountry || '',
                        organizationAbbreviation: organizationProfile?.organizationAbbreviation || '',
                        institutionAddress: organizationProfile?.institutionAddress || '',
                        logo: organizationProfile?.logo?.url || '',
                        info: organizationProfile?.info || {
                            state: '',
                            website: '',
                            about: '',
                            pob: '',
                            missionStatement: '',
                            visionStatement: '',
                        },
                        phoneNumber: organizationProfile?.phoneNumber || [''],
                        organizationImages: organizationProfile?.organizationImages || [
                            {
                                url: '',
                            },
                        ],
                    }}
                    // validationSchema={ValidationSchema}
                    onSubmit={async (values) => {
                        const formData = new FormData();

                        for (const key in values.info) {
                            if (values.info[key] !== undefined) {
                                formData.append(`info[${key}]`, values.info[key]);
                            }
                        }

                        formData.append('logo', values.logo);
                        formData.append('institutionCountry', values.institutionCountry);
                        formData.append('organizationAbbreviation', values.organizationAbbreviation);
                        formData.append('institutionAddress', values.institutionAddress);

                        values.phoneNumber?.map((phone, i) => {
                            return formData.append(`phoneNumber[${i}]`, phone);
                        });

                        values.organizationImages?.map((image, i) => {
                            return formData.append(`organizationImages`, image.url);
                        });

                        const headers = {
                            'Content-Type': 'multi-part/form-data',
                        };
                        const res = await dispatch(updateAdminInfo(formData, headers));
                        if (res) {
                            navigate(-1);
                        }
                    }}
                >
                    {({ handleChange, values, errors, setFieldValue }) => (
                        <Form>
                            <div className="flex flex-col gap-[3.2rem] max-w-[75.4rem] p-[2.4rem] bg-white rounded-[0.8rem]">
                                <TextInput
                                    label="Organization Name"
                                    type="text"
                                    disabled
                                    placeholder="Organization Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={organizationProfile?.institutionName}
                                />
                                <FormikTextInput
                                    label="Organization Abbreviation"
                                    type="text"
                                    name="organizationAbbreviation"
                                    placeholder="Input Organization Abbreviation e.g BLD"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.organizationAbbreviation}
                                />

                                <div>
                                    <Label>Organization Logo</Label>
                                    <Details>
                                        <Button
                                            type="button"
                                            onClick={() => handleClick(0)}
                                            className="px-[1.6rem] py-[0.8rem] border border-[#E5E7EB] shadow-[0_1px_2px_rgba(6,25,56,0.05)] "
                                        >
                                            Upload Files
                                        </Button>
                                        <Text size="1.6rem">{logoName}</Text>
                                        <input
                                            name="logo"
                                            type="file"
                                            style={{ display: 'none' }}
                                            ref={(el) => (hiddenFileInput.current[0] = el)}
                                            onChange={({ target: { files } }) => {
                                                if (files) {
                                                    setFieldValue('logo', files[0]);
                                                    setLogoName(files[0]?.name);
                                                }
                                            }}
                                        />
                                    </Details>
                                </div>
                                <FormikTextInput
                                    label="Organization Full Address"
                                    name="institutionAddress"
                                    type="text"
                                    placeholder="Input Organization Address"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.institutionAddress}
                                />
                                <Select
                                    name="institutionCountry"
                                    objProp="name"
                                    label="Country"
                                    data={[{ name: 'Nigeria' }]}
                                    placeholder="Input Organization Country"
                                    onChange={(selected) => {
                                        setFieldValue('institutionCountry', selected[0].name);
                                        setCountry(selected[0].name);
                                    }}
                                    error={errors.institutionCountry}
                                    passedSelectedItems={values.institutionCountry}
                                />
                                <Select
                                    name="info.state"
                                    label="State"
                                    objProp="name"
                                    data={states?.map((item) => ({ name: item.name }))}
                                    placeholder="State of Origin"
                                    onChange={(selected) => {
                                        setFieldValue('info.state', selected[0].name);
                                    }}
                                    error={errors.info?.state}
                                    passedSelectedItems={values.info?.state}
                                />
                                <FieldArray
                                    name="phoneNumber"
                                    render={({ insert, remove, push }) => (
                                        <div className="flex flex-column gap-[1rem]">
                                            {values.phoneNumber?.map((phone, index) => (
                                                <div key={index} className="flex items-center gap-[2rem]">
                                                    <FormikTextInput
                                                        name={`phoneNumber.${index}`}
                                                        label="Phone Number(s)"
                                                        type="number"
                                                        placeholder="Input Phone Number"
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        value={phone}
                                                    />
                                                    {index > 0 ? (
                                                        <Delete
                                                            className="cursor-pointer"
                                                            onClick={() => remove(index)}
                                                        />
                                                    ) : null}
                                                </div>
                                            ))}
                                            <FlexCentredRow className="w-[100%] md:w-[20rem] mt-[3.2rem]">
                                                <AddButton onClick={() => push('')}>
                                                    <PlusCircle />
                                                    <Text
                                                        weight="500"
                                                        size="1.4rem"
                                                        color="#3B82F6"
                                                        style={{ width: '100%' }}
                                                    >
                                                        Add Another Number
                                                    </Text>
                                                </AddButton>
                                            </FlexCentredRow>
                                        </div>
                                    )}
                                />
                                <TextInput
                                    label="Email Address"
                                    type="text"
                                    disabled
                                    placeholder="Input email address"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={organizationProfile?.organizationEmail}
                                />
                                <FormikTextInput
                                    label="P.O Box"
                                    name="info.pob"
                                    type="text"
                                    placeholder="Input P.O Box"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.info?.pob}
                                />
                                <FormikTextInput
                                    label="Website"
                                    name="info.website"
                                    type="text"
                                    placeholder="Input Website"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.info?.website}
                                />
                                <FormikTextInput
                                    label="About Us"
                                    as="textarea"
                                    height="132px"
                                    className="py-[1rem]"
                                    name="info.about"
                                    type="text"
                                    placeholder="Write about your university"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.info?.about}
                                />
                                <FormikTextInput
                                    label="Vision Statement"
                                    as="textarea"
                                    height="132px"
                                    className="py-[1rem]"
                                    name="info.visionStatement"
                                    type="text"
                                    placeholder="Write about your university"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.info?.visionStatement}
                                />
                                <FormikTextInput
                                    label="Mission Statement"
                                    as="textarea"
                                    height="132px"
                                    className="py-[1rem]"
                                    name="info.missionStatement"
                                    type="text"
                                    placeholder="Write about your university"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.info?.missionStatement}
                                />
                                <div className="">
                                    <Label>Organization Images</Label>
                                    <FieldArray
                                        name="organizationImages"
                                        render={({ insert, remove, push }) => (
                                            <div className="flex flex-column gap-[1rem]">
                                                {values?.organizationImages?.map((image, index) => (
                                                    <div key={index}>
                                                        <Details>
                                                            <div className="flex gap-[2rem] items-center">
                                                                <Button
                                                                    type="button"
                                                                    onClick={() => handleClick(1)}
                                                                    className="px-[1.6rem] py-[0.8rem] border border-[#E5E7EB] shadow-[0_1px_2px_rgba(6,25,56,0.05)] "
                                                                >
                                                                    Upload Files
                                                                </Button>
                                                                <Text size="1.6rem">{fileName?.[index]}</Text>
                                                                <input
                                                                    type="file"
                                                                    style={{ display: 'none' }}
                                                                    ref={(el) => (hiddenFileInput.current[1] = el)}
                                                                    onChange={({ target: { files } }) => {
                                                                        if (files) {
                                                                            setFieldValue(
                                                                                `organizationImages.${index}.url`,
                                                                                files[0],
                                                                            );
                                                                            const newFiles = [...fileName];
                                                                            newFiles[index] = files[0]?.name;
                                                                            setFileName(newFiles);
                                                                            // setFileName(files[0].name);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <Button
                                                                type="button"
                                                                onClick={() => {
                                                                    setFieldValue(
                                                                        `organizationImages.${index}.url`,
                                                                        null,
                                                                    );
                                                                    setFileName((prevData) => {
                                                                        return prevData.filter(
                                                                            (item, i) => i !== index,
                                                                        );
                                                                    });
                                                                    if (index > 0) {
                                                                        remove(index);
                                                                    }
                                                                }}
                                                                bgColor={fileName?.[index] ? '#6366F1' : '#F3F4F6'}
                                                                disabled={!fileName?.[index]}
                                                                color="#D1D5DB"
                                                            >
                                                                Remove
                                                            </Button>
                                                        </Details>
                                                    </div>
                                                ))}
                                                <FlexCentredRow className="w-[100%] md:w-[20rem] mt-[3.2rem]">
                                                    <AddButton
                                                        onClick={() =>
                                                            push({
                                                                url: '',
                                                            })
                                                        }
                                                    >
                                                        <PlusCircle />
                                                        <Text
                                                            weight="500"
                                                            size="1.4rem"
                                                            color="#3B82F6"
                                                            style={{ width: '100%' }}
                                                        >
                                                            Add Another Image
                                                        </Text>
                                                    </AddButton>
                                                </FlexCentredRow>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            <FlexRowEnd className="gap-[2rem] my-[3.2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={isLoading}
                                    disabled={isLoading}
                                    bgColor="#6366F1"
                                    color="#fff"
                                    type="submit"
                                >
                                    Save Changes
                                </LoadingButton>
                            </FlexRowEnd>
                        </Form>
                    )}
                </Formik>
            </Container>
        </div>
    );
};

export default EditOrgProfileForm;
