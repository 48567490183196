import React from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { FlexCentredRow } from '../../../containers/ScreenContainers';
import { createFacultyCourse, editCourse } from '../../../redux/courses/actions';
import { capitalizeFirstLetter, splitLettersAndNumbers } from '../../../utils';
import { LoadingButton } from '../../buttons';
import { CancelButton } from '../../forms/sharedStyles';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input-with-formik';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#1F2937]
  mb-[0]
`;

const CreateNewFacultyCourse = ({ action }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { currentFaculty } = useSelector((state) => state.faculty);
    const { isLoading } = useSelector((state) => state.courses);

    const { _id: facultyId, academicStructureId } = currentFaculty;

    return (
        <>
            <Formik
                initialValues={{
                    courseCode: state?.course?.courseCode || '',
                    courseTitle: state?.course?.courseTitle || '',
                    courseUnit: state?.course?.courseUnit || '',
                    courseSemester: state?.course?.courseSemester || '',
                    courseLevel: state?.course?.courseLevel || '',
                    minimumPassScore: state?.course?.minimumPassScore || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values, actions) => {
                    let res;
                    const { courseCode, courseTitle, ...otherValues } = values;

                    const payload = {
                        ...otherValues,
                        courseCode: splitLettersAndNumbers(courseCode.trim().toUpperCase()),
                        courseTitle: capitalizeFirstLetter(courseTitle),
                    };
                    if (action.toLowerCase() === 'add') {
                        res = await dispatch(createFacultyCourse(academicStructureId, facultyId, payload));
                    } else {
                        res = await dispatch(editCourse(state?.course?._id, payload));
                    }

                    if (res) {
                        toast.success(`Course was ${action.toLowerCase()}ed successfully`);
                        actions.resetForm();
                        navigate(-1);
                    }
                }}
            >
                {({ errors, handleChange, values, setFieldValue }) => (
                    <Form className="max-w-[100.5rem]">
                        <section className=" bg-white rounded-lg p-[2.4rem] pb-[5rem]">
                            <FlexCentredRow className="mb-[2.4rem] mt-[1.2rem] gap-[0.715rem]">
                                <PeopleIcon />
                                <Title>{action} General Course</Title>
                            </FlexCentredRow>
                            <div className="gap-y-[2.4rem] gap-x-[3.2rem] grid grid-cols-2">
                                <TextInput
                                    name="courseTitle"
                                    label="Course Title"
                                    type="text"
                                    placeholder="Course Title"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.courseTitle}
                                />
                                <TextInput
                                    name="courseCode"
                                    label="Course Code e.g. GNS 101"
                                    placeholder="Course Code e.g. GNS 101"
                                    type="text"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.courseCode}
                                />

                                <Select
                                    name="courseLevel"
                                    objProp="name"
                                    label="Course Level"
                                    data={[1, 2, 3, 4, 5, 6, 7].map((item) => ({ name: `${item}00` }))}
                                    placeholder="Level"
                                    onChange={(selected) => {
                                        setFieldValue('courseLevel', selected[0].name);
                                    }}
                                    passedSelectedItems={values.courseLevel ? { name: `${values.courseLevel}` } : null}
                                    error={errors.courseLevel}
                                />

                                <TextInput
                                    name="courseUnit"
                                    label="Course Unit"
                                    type="number"
                                    placeholder="Course Unit"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.courseUnit}
                                />

                                <Select
                                    name="courseSemester"
                                    objProp="name"
                                    label="Semester"
                                    data={[
                                        { name: 'First', value: '1' },
                                        { name: 'Second', value: '2' },
                                    ]}
                                    placeholder="Course Semester"
                                    onChange={(selected) => {
                                        setFieldValue('courseSemester', selected[0].value);
                                    }}
                                    passedSelectedItems={
                                        values.courseSemester ? { name: `${values.courseSemester}` } : null
                                    }
                                    error={errors.courseSemester}
                                />
                                <TextInput
                                    name="minimumPassScore"
                                    label="Minimum Pass Mark (%)"
                                    placeholder="Minimum Pass Mark (%)"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.minimumPassScore}
                                />
                            </div>
                        </section>
                        <FlexContainer>
                            <CancelButton type="button" onClick={() => navigate(-1)}>
                                Cancel
                            </CancelButton>
                            <LoadingButton disabled={isLoading} loading={isLoading} type="submit">
                                {action}
                            </LoadingButton>
                        </FlexContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
};
export default CreateNewFacultyCourse;
