import React from 'react';

import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import { ReactComponent as ExternalLink } from '../../../assets/icons/external-Link.svg';
import { Button } from '../../../components/buttons';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { handlePdfPreview } from '../../../utils';

const RowContainer = tw.div`
    mt-8
    grid
    gap-8
`;

const FlexCol = tw.div`
    gap-6
`;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
    mb-[1.5rem]
`;

const TitleText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
    font-weight: 500;
`;

const SubText = styled(TitleText)`
    color: #374151;
    line-height: 2.4rem;
`;

const SubText1 = styled(SubText)`
    color: #6366f1;
    display: inline-block;
`;

const Publications = ({ pub }) => {
    const navigate = useNavigate();

    return (
        <div>
            <Details className="mt-[3rem]">
                <FlexRowSpaceBetween>
                    <PageTitle size="1.9rem" lineheight="2.8rem">
                        Publication
                    </PageTitle>
                    <Button
                        border="1px solid #E5E7EB"
                        bgColor="#FFFFFF"
                        color="#6366F1"
                        onClick={() => navigate('/staff-arena/profile/edit', { state: { tab: 2 } })}
                    >
                        <Edit fill="#6366F1" />
                        Edit
                    </Button>
                </FlexRowSpaceBetween>
            </Details>
            <Details>
                {pub?.map((publication, _id) => (
                    <RowContainer className="lg:text-left text-center">
                        <FlexCol>
                            <TitleText>Publisher</TitleText>
                            <SubText>
                                {publication?.publisher}
                                {publication?.publicationLink && (
                                    <a href={publication?.publicationLink} target="_blank" rel="noopener noreferrer">
                                        <SubText1>{`(${publication?.publicationLink})`}</SubText1>
                                    </a>
                                )}
                            </SubText>
                        </FlexCol>
                        <FlexCol>
                            <TitleText>Publication Type</TitleText>
                            <SubText>{publication?.publicationType}</SubText>
                        </FlexCol>
                        <FlexCol>
                            <TitleText>Publication Title</TitleText>
                            <SubText>{publication?.publicationTitle}</SubText>
                        </FlexCol>
                        <FlexCol>
                            <TitleText>Publication Authors</TitleText>
                            <SubText>{publication?.author}</SubText>
                        </FlexCol>
                        <FlexCol>
                            <TitleText>Year Published</TitleText>
                            <SubText>{publication?.year?.slice(0, 4)}</SubText>
                        </FlexCol>
                        <div className="flex justify-start">
                            {publication?.publication && (
                                <Button
                                    border="1px solid #e5e7eb"
                                    onClick={() => handlePdfPreview(publication?.publication?.url)}
                                >
                                    View Publication
                                    <ExternalLink />
                                </Button>
                            )}
                        </div>

                        <hr />
                    </RowContainer>
                ))}
            </Details>
        </div>
    );
};

export default Publications;
