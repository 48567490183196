import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import ListEmptyContent from '../../../components/list-empty';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getFacultyStaff } from '../../../redux/faculty/actions';

const FacultyPersonnels = () => {
    const dispatch = useDispatch();

    const { isLoading, currentFaculty, staff } = useSelector((state) => state.faculty);

    const { splitAcademicStructureTwo: structTwo } = currentFaculty;

    const [searchValue, setSearchValue] = useState('');

    const fields = [
        {
            header: 'Full Name',
            accessorKey: 'fullName',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: structTwo,
            accessorKey: 'department',
            cell: ({ row }) => <span>{row.original.program || row.original?.faculty || row.original?.department}</span>,
        },
        {
            header: 'Employment Type',
            accessorKey: 'staffType',
        },
        {
            header: 'Email Address',
            accessorKey: 'email',
        },
        {
            header: 'Employment Date',
            accessorKey: 'yearOfEmployment',
            cell: (props) => <p>{props.getValue() ? format(new Date(props.getValue()), 'PP') : ''}</p>,
        },
    ];

    useEffect(() => {
        const { _id: facultyId, academicStructureId } = currentFaculty || {};

        if (facultyId) {
            dispatch(getFacultyStaff(academicStructureId, facultyId));
        }
    }, [currentFaculty, dispatch]);

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                fields={fields}
                data={staff}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Staff "></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
            />
        </>
    );
};

export default FacultyPersonnels;
