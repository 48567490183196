import React, { useEffect, useState } from 'react';

import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-black.svg';
import { Button } from '../../../../components/buttons';
import AddStep from '../../../../components/forms/add-step';
import EditStep from '../../../../components/forms/edit-step';
import { GoBack } from '../../../../components/go-back';
import ListEmptyContent from '../../../../components/list-empty';
import ConfirmActionDialogueWithInput from '../../../../components/popups/confirm-action-with-input';
import { CenteredContainer, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { useTableContext } from '../../../../context/tableContext';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { deletePaymentSubGroup, getPaymentSubGroups } from '../../../../redux/bursary/actions';
import { capitalizeFirstLetter } from '../../../../utils';



const PaymentSubGroup = () => {
    const dispatch = useDispatch();
    const {
        state: { groupDetails },
    } = useLocation();
    const {_id:id, groupName}= groupDetails
    const { isLoading, subPaymentGroups } = useSelector((state) => state.bursary);

    const [searchValue, setSearchValue] = useState('');

    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [openModal, setOpenModal] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [toggleRefetch, setToggleRefetch] = useState(null);
    const [inputValue, setInputValue] = useState(null);

    const { data: allSubPaymentGroups, pagination } = subPaymentGroups || {};
    const { total, totalPages } = pagination || {};

    const [currentPage, setCurrentPage] = useState('1');
    const { tableSettings } = useTableContext();
    const numOfPages = tableSettings?.numOfPages;

    const fields = [
        {
        header: 'Sub-group Name',
        accessorKey: 'stepName',
        cell: (props) => {
            const { _id, stepName, staffType, createdAt, createdBy } = props.row.original;
            return (
                <Link
                    to={`/bursary/payroll/payment-group/${id}/group-subgroups/${_id}`}
                    state={{
                        SubGroupDetails: props.row.original,
                        stepName,
                        staffType,                      
                        createdAt,
                        createdBy,
                        _id,
                        groupName,
                        groupDetails,
                    }}
                    style={{ color: '#2563EB', cursor: 'pointer' }}
                >
                    {capitalizeFirstLetter(props?.getValue())} 
                </Link>
            );
        },
    },
     
        {
            header: 'Staff Type',
            accessorKey: 'staffType',
           
        },
       
        {
            header: 'Created By',
            accessorKey: 'createdBy',
           
        },  {
            header: 'Created At',
            accessorKey: 'createdAt', 
            cell: ({ row }) => {
                const dateTimeString = row.original?.createdAt;
                const [datePart, timePart] = dateTimeString.split('T');
                const formattedDateTime = `${datePart} ${timePart.split('.')[0]}`;

                return <p>{formattedDateTime}</p>;
            },
        },
    ];

   
    const handleDelete = async () => {
        if (inputValue.toLowerCase() !== 'delete') {
            toast.error('Input delete');
        } else {
            const res = await dispatch(deletePaymentSubGroup(selectedItem?._id));
            if (res) {
                toast.success('Step deleted successfully!');
                setToggleRefetch((prevState) => !prevState);
                setOpenModal(false);
            }
        }
    };
    useEffect(() => {
        const query = {
            search: debouncedSearchValue,
            page: currentPage,
            limit: numOfPages,
        };
        dispatch(getPaymentSubGroups(query, id ));
    }, [dispatch,toggleRefetch, debouncedSearchValue, currentPage, numOfPages, id]);
    

    return (
        <>
            <GoBack title={`Payment Group`} 
                subTitle={groupDetails?.groupName}
            ></GoBack>
            <PageLayout
                pageTitle={groupDetails?.groupName}
                secondaryButtonText="Add Step"
                data={allSubPaymentGroups || []}
                fields={fields}
                loading={isLoading}
                isActionable
                actionType="radio"
                onSelectedItemsChange={(items) => setSelectedItem(items[0])}
                onSecondaryButtonClick={() => setOpenModal('add')}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <EditIcon />,
                        name: 'Edit Step',
                        disabled: !selectedItem,
                        click: () => setOpenModal('edit')
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Step',
                        disabled: !selectedItem,
                        click: () => setOpenModal('delete'),
                    },
                ]}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                searchValue={searchValue}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                showFilter
                width={'100%'}
                paginationComponent={
                    <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                        <PaginationStatus
                            currentPage={currentPage}
                            itemsPerPage={tableSettings?.numOfPages}
                            totalItems={total}
                        />
                        <Pagination
                            pageRangeDisplayed={3}
                            totalPages={totalPages}
                            onPageChange={(nextPage) => {
                                setCurrentPage(nextPage);
                            }}
                            currentPage={currentPage}
                        />
                    </FlexRowSpaceBetween>
                }
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Step Found" subtitle="Add Step">
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Button
                                    onClick={() => setOpenModal('add')}
                                    bgColor="#10B981"
                                    color="#fff"
                                >
                                    Add Step
                                </Button>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
                openFilterPop={openModal === 'options-popup-main'}
                closeFilterPop={() => setOpenModal(null)}
            />

            
            <ConfirmActionDialogueWithInput
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete Step?"
                subtitle={`All Information in this Step would be deleted, empty the step to continue. Do you still want to delete this Step?`}
                subtitle2="To confirm, enter delete in the text field.."
                confirmAction={() => handleDelete()}
                btn2Text="Yes, Delete"
                setInputValue={setInputValue}
                value={inputValue}
                isLoading={isLoading}
                btnDisabled={inputValue !== 'delete'}
                placeholder="Input delete"
            />
            <AddStep 
                show={openModal === 'add'}
                onClose={() => setOpenModal('')}
                id={id} 
                onFinish={() => {
                    setToggleRefetch((prev) => !prev);
                }}
             />
           <EditStep 
                show={openModal === 'edit'}
                onClose={() => setOpenModal('')}
                subGroupId={selectedItem?._id}
                onFinish={() => {
                    setToggleRefetch((prev) => !prev);
                }}
             />
        </>
    );
};

export default PaymentSubGroup;
