import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import EditFeeForm from '../../../../components/forms/edit-fee';
// import { Loader } from '../../../../components/loader';
import { getFeeById } from '../../../../redux/bursary/actions';

const EditFee = () => {
    const dispatch = useDispatch();
    const { fee } = useSelector((store) => store.bursary);
    const { id } = useParams();

    useEffect(() => {
        dispatch(getFeeById(id));
    }, [dispatch, id]);

    return (
        <div>
            <EditFeeForm feeDetails={fee} feeId={id} />
        </div>
    );
};

export default EditFee;
