import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-blue.svg';
import { AllFilter } from '../../../components/filter-select';
import FilterPopup from '../../../components/filter-select/filter-popup';
import Checkbox from '../../../components/inputs/checkbox';
import ListEmptyContent from '../../../components/list-empty';
import { CenteredContainer, RelativeContainer } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getStaffArenaSession, getStaffHandbook } from '../../../redux/staff-arena/actions';
import { capitalizeFirstLetter } from '../../../utils';
import { storeUnit } from '../../../utils/originUnit';

const fields = [
    {
        accessorKey: 'action',
        cell: (props) => <p>{props?.getValue()}</p>,
        header: <Checkbox />,
        size: 5,
    },
    {
        header: 'Name',
        accessorKey: 'title',
        cell: (props) => (
            <span style={{ color: '#2563EB' }} className="cursor-pointer">
                {capitalizeFirstLetter(props.getValue())}
            </span>
        ),
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Type',
        accessorKey: 'selectType',
    },
    {
        header: 'Last Updated',
        accessorKey: 'updatedAt',
        cell: (props) => {
            const dateTimeString = props.getValue();
            const [datePart, timePart] = dateTimeString.split('T');
            const formattedDateTime = `${datePart} ${timePart.split('.')[0]}`;

            return <td>{formattedDateTime}</td>;
        },
    },
    {
        header: 'File Size',
        accessorKey: 'handbookUpload.size',
        cell: (props) => <span>{props.getValue()}</span>,
    },
];

const FilterTag = tw.div`
    flex
    px-[8px]
    py-[4px]
    h-[25px]
    min-w-max
    bg-[#EEF2FF]
    text-[#4F46E5]
    text-[12px]
    font-semibold
`;

const StyledTag = styled(FilterTag)`
    border-top-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-bottom-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-top-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
    border-bottom-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
`;

const STATUS_FILTER_OPTIONS = ['Published', 'Unpublished'];
const TYPE_FILTER_OPTIONS = ['Students', 'Academic', 'Non-Academic', 'All Staff'];
const FILTER_OPTIONS = ['Type', 'Title', 'Status'];

const StaffHandbook = ({ unitName }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    const { isLoading, sessions } = useSelector((state) => state.staffArena);

    const [handbooks, setHandbooks] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [openDropdown, setOpenDropdown] = useState('');
    // const [selectedItem, setSelectedItem] = useState(null);
    const [openModal, setOpenModal] = useState(null);

    const [session, setSession] = useState(state?.session || sessions?.[0]?.admissionYear || '');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const [filterCategories, setFilterCategories] = useState([]);
    const [currentFilterCategory, setCurrentFilterCategory] = useState('');
    const [titleFilter, setTitleFilter] = useState('');
    const [selectTypeFilter, setSelectTypeFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');

    const sessionItems = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const titleList = handbooks?.map((item) => item?.title);

    const generateFilterOptions = (data = [], setStateAction, closeAction, objKey) => {
        const filterOptions = data?.map((option) => {
            let item;

            if (typeof option === 'object') {
                item = option[objKey];
            } else {
                item = option;
            }

            return {
                name: item,
                click: () => {
                    setStateAction(item);
                    closeAction(null);
                },
            };
        });

        return filterOptions;
    };

    const handleReset = () => {
        setFilterCategories([]);
        setCurrentFilterCategory('');
        setStatusFilter('');
        setSearchValue('');
        setTitleFilter('');
        setSelectTypeFilter('');
    };

    const FILTER_POPUP_OPTIONS = {
        Title: generateFilterOptions(titleList, setTitleFilter, setOpenModal),
        Type: generateFilterOptions(TYPE_FILTER_OPTIONS, setSelectTypeFilter, setOpenModal),
        Status: generateFilterOptions(STATUS_FILTER_OPTIONS, setStatusFilter, setOpenModal),
    };

    const FILTER_VALUES_LOOKUP = {
        Title: {
            value: titleFilter,
            stateAction: setTitleFilter,
        },
        Type: {
            value: selectTypeFilter,
            stateAction: setSelectTypeFilter,
        },

        Status: {
            value: statusFilter,
            stateAction: setStatusFilter,
        },
    };

    const handleSelectCategories = (category) => {
        const isSelected = filterCategories.includes(category);
        if (isSelected) return;
        setFilterCategories((prevState) => [...prevState, category]);
    };

    const handleRemoveFilterCategory = (category, stateAction) => {
        const newItems = filterCategories.filter((item) => item !== category);
        setFilterCategories(newItems);
        if (newItems.length === 0) setCurrentFilterCategory('');
        stateAction('');
    };

    const query = useMemo(() => {
        let queryParams = {};

        if (selectTypeFilter) {
            queryParams.selectType = selectTypeFilter;
        }

        if (titleFilter) {
            queryParams.title = titleFilter;
        }
        if (debouncedSearchValue) {
            queryParams.title = debouncedSearchValue;
        }

        if (statusFilter) {
            queryParams.status = statusFilter;
        }

        return queryParams;
    }, [selectTypeFilter, titleFilter, statusFilter, debouncedSearchValue]);

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    useEffect(() => {
        dispatch(getStaffArenaSession());
    }, [dispatch]);

    useEffect(() => {
        if (!session) return;

        const getHandbooks = async () => {
            const data = await dispatch(getStaffHandbook(session, query));
            if (data) return setHandbooks(data);
            setHandbooks([]);
        };

        getHandbooks();
    }, [dispatch, session, query]);

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return (
        <PageLayout
            pageTitle="Handbook"
            searchable
            data={handbooks || []}
            fields={fields}
            loading={isLoading}
            isActionable
            // actionType="radio"
            // onSelectedItemsChange={(items) => setSelectedItem(items[0])}
            onSessionFilterClick={() => handleOpenDropdown('sessions')}
            sessionPlaceHolder={session}
            openSessionFilter={openDropdown === 'sessions'}
            closeSessionFilter={() => setOpenDropdown(null)}
            sessionItems={sessionItems}
            onSearchChange={(e) => setSearchValue(e.target.value)}
            searchValue={searchValue}
            onSearchClose={() => setSearchValue('')}
            showTableUtils
            showFilter
            width={'100%'}
            noItemView={
                <CenteredContainer className="mt-[5rem]">
                    <ListEmptyContent isInTable title="No Handbook ">
                        <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col"></CenteredContainer>
                    </ListEmptyContent>
                </CenteredContainer>
            }
            onRowClick={(item) => {
                const selectedItem = handbooks?.find((handbook) => handbook?._id === item?._id);
                navigate(`${item?._id}`, {
                    state: {
                        session,
                        details: selectedItem,
                    },
                });
            }}
            filterItems={FILTER_OPTIONS.map((item) => ({
                name: item,
                click: () => {
                    handleSelectCategories(item);
                    setCurrentFilterCategory(item);
                    setOpenModal('options-popup-main');
                },
            }))}
            handleFilterReset={handleReset}
            showClear={currentFilterCategory}
            openFilterPop={openModal === 'options-popup-main'}
            isFilterPopLoading={isLoading}
            closeFilterPop={() => setOpenModal(null)}
            filterPopItems={FILTER_POPUP_OPTIONS[currentFilterCategory]}
            filterCategories={
                <div>
                    {filterCategories?.length > 0 && (
                        <div className="flex flex-wrap items-center gap-4 p-[18px] border-t">
                            {filterCategories.map((category) => (
                                <div accessorKey={category} className="flex gap-[1px]">
                                    <StyledTag withLeftRadius>{category}</StyledTag>

                                    {FILTER_VALUES_LOOKUP[category].value && (
                                        <StyledTag withRightRadius>
                                            {FILTER_VALUES_LOOKUP[category].value}
                                            <CloseIcon
                                                className="ml-[4px] hover:cursor-pointer"
                                                onClick={() =>
                                                    handleRemoveFilterCategory(
                                                        category,
                                                        FILTER_VALUES_LOOKUP[category].stateAction,
                                                    )
                                                }
                                            />
                                        </StyledTag>
                                    )}
                                </div>
                            ))}

                            <RelativeContainer>
                                <AllFilter
                                    useSecondaryBtn
                                    showClear={currentFilterCategory}
                                    items={FILTER_OPTIONS.map((item) => ({
                                        name: item,
                                        click: () => {
                                            handleSelectCategories(item);
                                            setCurrentFilterCategory(item);
                                            setOpenModal('options-popup-sub');
                                        },
                                    }))}
                                    handleReset={handleReset}
                                />
                                <FilterPopup
                                    open={openModal === 'options-popup-sub'}
                                    isLoading={isLoading}
                                    close={() => setOpenModal(null)}
                                    items={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                                />
                            </RelativeContainer>
                        </div>
                    )}
                </div>
            }
        />
    );
};

export default StaffHandbook;
