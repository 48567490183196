import React, { useState } from 'react';

import { CModal } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as Warning } from '../../../assets/icons/warning.svg';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexDiv, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button } from '../../buttons';
import DeleteError from '../delete-error';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 3.2rem;
        width: 48.8rem;
        background-color: white;
    }
`;

const Input = tw.input`
    border-[1px]
    border-[#6366F1]
    p-3
    rounded
    outline-none
    focus:ring-[#6366F1]
    focus:ring-2
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
`;
//     absolute
//     top-[2.1rem]
//     right-[3.73rem]
// `;

const DeleteCourse = ({
    show,
    close,
    title,
    subtitle,
    subtitle2,
    btnText,
    deleteError,
    onDelete,
    placeholder,
    value,
    onChange,
}) => {
    const navigate = useNavigate();
    const [openSuccess, setShowOpenSuccess] = useState(false);

    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween className="mb-[1.6rem]">
                <FlexCentredRow className=" items-center  gap-[1.215rem]">
                    <Warning fill="#EF4444" />
                    <PageTitle size="1.9rem" lineHeight="2.8rem" weight="600">
                        Delete {title}?
                    </PageTitle>
                </FlexCentredRow>
                <CloseIcon onClick={close} />
            </FlexRowSpaceBetween>

            <Text
                align="left"
                bottom="5rem"
                size="1.6rem"
                lineHeight="2.4rem"
                className="border-b border-[#D1D5DB] pb-[5rem] "
            >
                {subtitle || 'This course will be deleted.'}
            </Text>
            <Text align="left" bottom="2.4rem" size="1.6rem" lineHeight="2.4rem">
                {subtitle2 || 'To confirm, enter the title of the course in the text field.'}
            </Text>
            <Input type="text" placeholder={placeholder || 'Input Course Title'} value={value} onChange={onChange} />
            <div className="flow-root">
                <FlexDiv className="float-right gap-[1.6rem]">
                    <Button bgColor="#FFF" onClick={close} className="py-2 px-4 mt-12 cursor-pointer border-[#D1D5DB]">
                        Cancel
                    </Button>
                    <Button
                        bgColor="#EF4444"
                        color="#FFF"
                        onClick={onDelete ? onDelete : () => setShowOpenSuccess(true)}
                        className="py-2 px-4 mt-12 cursor-pointer"
                    >
                        {btnText || 'Yes, Remove'}
                    </Button>
                </FlexDiv>
            </div>
            {deleteError && (
                <DeleteError
                    show={openSuccess}
                    btnAction={() => navigate('/faculties/department/courses')}
                    title="Sorry!"
                    subtitle="You can't delete a course that has contents, activities, students and lecturers. Kindly empty the course and then retry."
                    btnText="Close"
                />
            )}
        </StyledModal>
    );
};

export default DeleteCourse;
