import React, { useEffect, useMemo, useState } from 'react';

import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ChevronGrey } from '../../../../../../assets/icons/arrow-down2.svg';
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/circular-mark-sm-black.svg';
// import { ReactComponent as DeleteIcon } from '../../../../../../assets/icons/delete-red.svg';
import { ReactComponent as ImportIcon } from '../../../../../../assets/icons/download-grey.svg';
import { ActionsPopup } from '../../../../../../components';
import { Button } from '../../../../../../components/buttons';
import DeleteCurriculum from '../../../../../../components/forms/delete-curriculum';
import ImportCurriculum from '../../../../../../components/forms/import-curriculum';
import { Text } from '../../../../../../containers/MesssageContainers';
import {
    CenteredContainer,
    FlexColumn,
    FlexDiv,
    RelativeContainer,
} from '../../../../../../containers/ScreenContainers';
import PageLayout from '../../../../../../layout/page-layout/PageLayout';
import { getCurriculumByLastDegreeId, publishCurriculum } from '../../../../../../redux/curriculum/actions';

const fields = [
    {
        header: 'Title',
        accessorKey: 'courseTitle',
    },
    {
        header: 'Course Code',
        accessorKey: 'courseCode',
    },
    {
        header: 'Level',
        accessorKey: 'courseLevel',
    },
    {
        header: 'Semester',
        accessorKey: 'courseSemester',
    },
    {
        header: 'Unit',
        accessorKey: 'courseUnit',
    },
    {
        header: 'Status',
        accessorKey: 'courseClass',
    },
];

const FilterContainer = tw(FlexDiv)`
  p-[16px]
  gap-4
`;

const SEMESTER_PARAM = {
    '1st Semester': 'First Semester',
    'First Semester': 'First Semester',
    '2nd Semester': 'Second Semester',
    'Second Semester': 'Second Semester',
};

const CurriculumDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: level } = useParams();
    const { state } = useLocation();

    const { isLoading } = useSelector((state) => state.curriculum);
    const { sessions: admissionsSessions, activeSession, activeSemester } = useSelector((state) => state.admission);
    const { currentDepartment } = useSelector((state) => state.department);
    const { currentProgramme } = useSelector((state) => state.programmes);

    const { _id: programId } = currentProgramme || {};
    const { _id: departmentId, academicStructure = '', academicStructureId } = currentDepartment || {};
    const splitStruct = academicStructure.split('-');
    const lastDegreeId = splitStruct[2] ? programId : departmentId;

    const [session, setSession] = useState(state?.session || activeSession || '');
    const [semester, setSemester] = useState(activeSemester || 'First Semester');
    const [openModal, setOpenModal] = useState(null);
    const [showImportForm, setShowImportForm] = useState(false);
    const [curriculumData, setCurriculumData] = useState(null);
    const [toggleRefetch, setToggleRefetch] = useState(false);

    const { _id: curriculumId, courses: curriculumCourses = [] } = curriculumData || {};

    const filteredCourses = useMemo(
        () => curriculumCourses?.filter((item) => item?.courseSemester === SEMESTER_PARAM[semester]),
        [curriculumCourses, semester],
    );

    // const [itemsPerPage, setItemsPerPage] = useState(10);
    // const totalPages = filteredCourses?.length > 0 ? Math.ceil(filteredCourses?.length / itemsPerPage) : 1;
    // const [currentPage, setCurrentPage] = useState(1);

    const handleOpenModal = (action) => {
        if (action === openModal) {
            return setOpenModal(null);
        }
        setOpenModal(action);
    };

    const handlePublish = async () => {
        if (!curriculumId) return toast.error('Please select a curriculum');
        const res = await dispatch(publishCurriculum(curriculumId));
        if (res) {
            toast.success('Curriculum published successfully!');
        }
    };

    const nextPageState = { edit: curriculumCourses?.length, session, semester };

    useEffect(() => {
        if (lastDegreeId && session) {
            const getData = async () => {
                const data = await dispatch(
                    getCurriculumByLastDegreeId(academicStructureId, lastDegreeId, session, level),
                );

                if (data) {
                    const curriculumDat = data.find((item) => item.semester?.toLowerCase() === semester?.toLowerCase());

                    return setCurriculumData(curriculumDat);
                }
                setCurriculumData(null);
            };

            getData();
        }
    }, [dispatch, lastDegreeId, session, level, academicStructureId, semester, toggleRefetch]);

    return (
        <div className="min-h-[100vh] pb-20">
            <PageLayout
                pageTitle={`${level} Level`}
                backTitle="Curriculum"
                backSubtitle={`${level} Level`}
                data={filteredCourses || []}
                fields={fields}
                loading={isLoading}
                secondaryButtonText={`${curriculumCourses?.length ? 'Update' : 'Create'} Curriculum`}
                onSecondaryButtonClick={() => navigate(`create/${level}`, { state: nextPageState })}
                onActionBtnClick={() => handleOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <ImportIcon />,
                        name: 'Import Curriculum',
                        click: () => {
                            setShowImportForm(true);
                            setOpenModal(null);
                        },
                    },
                    {
                        icon: <PlusIcon />,
                        name: 'Publish Curriculum',
                        click: () => {
                            handlePublish();
                            setOpenModal(null);
                        },
                    },
                    // {
                    //     icon: <DeleteIcon />,
                    //     name: 'Delete Curriculum',
                    //     click: () => {
                    //         setOpenModal('delete');
                    //     },
                    // },
                ]}
                showTableUtils
                tableUtilsChildren={
                    <FilterContainer>
                        <RelativeContainer>
                            <Button
                                bgColor="#FFF"
                                color="#6B7280"
                                border="0.5px solid #9CA3AF"
                                onClick={() => handleOpenModal('session')}
                            >
                                {session || 'Select Session'}
                                <ChevronGrey />
                            </Button>
                            <div className="absolute left-[200px] top-0 ">
                                <div className="relative">
                                    <ActionsPopup
                                        open={openModal === 'session'}
                                        close={() => setOpenModal(null)}
                                        items={admissionsSessions?.map((item) => ({
                                            name: item.admissionYear,
                                            click: () => {
                                                setSession(item.admissionYear);
                                                setOpenModal(null);
                                            },
                                        }))}
                                    />
                                </div>
                            </div>
                        </RelativeContainer>
                        <RelativeContainer>
                            <Button
                                bgColor="#FFF"
                                color="#6B7280"
                                border="0.5px solid #9CA3AF"
                                onClick={() => handleOpenModal('semester')}
                            >
                                {semester || 'Select Semester'}
                                <ChevronGrey />
                            </Button>
                            <ActionsPopup
                                open={openModal === 'semester'}
                                close={() => setOpenModal(null)}
                                items={[
                                    {
                                        name: 'First Semester',
                                        click: () => {
                                            setSemester('First Semester');
                                            setOpenModal(null);
                                        },
                                    },
                                    {
                                        name: 'Second Semester',
                                        click: () => {
                                            setSemester('Second Semester');
                                            setOpenModal(null);
                                        },
                                    },
                                ]}
                            />
                        </RelativeContainer>
                    </FilterContainer>
                }
                noItemView={
                    <CenteredContainer className="py-10">
                        <FlexColumn>
                            <Text color="#172B4D" weight="500" size="2rem">
                                You have not created any curriculum
                            </Text>
                            <Link to={`create/${level}`} state={nextPageState}>
                                <Text color="#97A0AF" size="1.6rem">
                                    Create Curriculum
                                </Text>
                            </Link>
                        </FlexColumn>
                    </CenteredContainer>
                }
            />

            <ImportCurriculum
                show={showImportForm}
                setShow={setShowImportForm}
                level={level}
                onFinish={() => setToggleRefetch((prevState) => !prevState)}
            />

            <DeleteCurriculum
                show={openModal === 'delete'}
                setShow={setOpenModal}
                level={level}
                onFinish={() => setToggleRefetch((prevState) => !prevState)}
            />
        </div>
    );
};

export default CurriculumDetails;
