import React from 'react';

import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-grey.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 45.8rem;
        max-height: 60rem;
        margin: auto;
    }
`;

const ItemsContainer = tw.ul`
    overflow-y-auto
    pb-2
    mb-5
    // flex
    // gap-4
`;

const Input = tw.input`
    border-[1px]
    border-[#E5E7EB]
    pl-3
    rounded
    outline-none
    focus:ring-[#6366F1]
    focus:ring-2
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
    w-full
    h-[4rem]
`;

const DetachFeeModal = ({
    show,
    close,
    subtitle,
    title,
    nameField = 'fullName',
    idField = 'id',
    value,
    placeholder,
    bodyText,
    input,
    onChange,
    type,
    list,
    onSend,
    isLoading,
    disabled,
}) => {
    return (
        <StyledModal show={show} onClose={close} centered closeOnBackdrop={true} backdrop={true} color="info">
            <FlexRowSpaceBetween>
                <Text as="h3" size="1.9rem" weight="600">
                    {title || ''}
                </Text>
                <CloseIcon className="hover:cursor-pointer" onClick={close} />
            </FlexRowSpaceBetween>
            <Text align="start" size="1.6rem" color="#374151" top="1.6rem" lineHeight="2.4rem" bottom="1rem">
                {subtitle}
            </Text>
            <Text align="start" size="1.6rem" weight="600" top="1.6rem" lineHeight="2.4rem" bottom="1rem">
                Name of Fee to Detach
            </Text>
            <ItemsContainer>
                {list?.map((item, index) => (
                    <Text align="left" key={item?.[idField] || index}>{`${item?.[nameField]}`}</Text>
                ))}
            </ItemsContainer>
            {bodyText}
            {input && <Input type={type || 'text'} placeholder={placeholder} value={value} onChange={onChange} />}
            <FlexRowEnd className="gap-x-[16px] mt-5">
                <Button type="button" onClick={close} border="0.5px solid #D1D5DB">
                    Cancel
                </Button>
                <LoadingButton disabled={disabled} loading={isLoading} onClick={onSend}>
                    Send
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default DetachFeeModal;
