import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as Circularplus } from '../../../../assets/icons/circularplus.svg';
import { Button } from '../../../../components/buttons';
import ListEmptyContent from '../../../../components/list-empty';
import { CountBadge, getStatusStyles, StatusContainer } from '../../../../components/shared';
import { Text } from '../../../../containers/MesssageContainers';
import { CenteredContainer } from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { getAcademicStaff } from '../../../../redux/staff/actions';
import { getDegreeInfo } from '../../../../utils';

const AcademicStaff = ({ handleSelect }) => {
    const dispatch = useDispatch();

    const { isLoading, academicStaff: staff } = useSelector((state) => state.staff);
    const { firstDegreeOptionsStr, secondDegreeOptionsStr } = useSelector((state) => state.academicStructure);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.searchTerm = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    const fields = [
        {
            header: 'Full Name',
            accessorKey: 'fullName',
            cell: (props) => (
                <Link style={{ color: '#2563EB' }} to={props.row.original._id}>
                    {props.getValue()}
                </Link>
            ),
        },
        {
            header: firstDegreeOptionsStr || '',
            accessorKey: 'firstDegree',
            cell: ({ row }) => {
                const { firstDegree } = getDegreeInfo(row.original, 'academicStructure');

                return <p>{firstDegree}</p>;
            },
        },
        {
            header: secondDegreeOptionsStr || '',
            accessorKey: 'secondDegree',
            cell: ({ row }) => {
                const { secondDegree } = getDegreeInfo(row.original, 'academicStructure');

                return <p>{secondDegree}</p>;
            },
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Designation',
            accessorKey: 'designation',
        },
        {
            header: 'Unit',
            accessorKey: 'unitName',
            cell: ({ row }) => {
                const rowItem = row.original;
                const itemObj = rowItem?.unitName?.length ? rowItem.unitName : rowItem?.organizationUnit;

                return (
                    <div className="flex items-center gap-[4px]">
                        <Text color="#6b728">{itemObj[0] || '-'}</Text>
                        {itemObj?.length > 1 ? <CountBadge>+{itemObj?.length - 1}</CountBadge> : null}
                    </div>
                );
            },
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Email',
            accessorKey: 'staffEmail',
        },
        {
            header: 'Admin Status',
            accessorKey: 'staffStatus',
            cell: (props) => (
                <StatusContainer style={getStatusStyles(props.getValue().toLowerCase())}>
                    <span>{props.getValue()}</span>
                </StatusContainer>
            ),
        },
        {
            header: 'Lecturers Status',
            accessorKey: 'lecturerStatus',
            cell: (props) => (
                <StatusContainer style={getStatusStyles(props.getValue().toLowerCase())}>
                    <span>{props.getValue() || ''}</span>
                </StatusContainer>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getAcademicStaff(query));
        // dispatch(getStaffInUnit());
    }, [dispatch, query]);

    return (
        <>
            <PageLayout
                noMargin
                showTableUtils
                isActionable
                actionType="checkbox"
                onSelectedItemsChange={(items) => handleSelect(items)}
                searchable
                loading={isLoading}
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={staff || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Staff " subtitle="Create Staff">
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Link to="add-staff">
                                    <Button bgColor="#10B981" color="#fff">
                                        <Circularplus className="mr-[1.15rem]" />
                                        Create New
                                    </Button>
                                </Link>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
            />
        </>
    );
};

export default AcademicStaff;
