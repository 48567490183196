import React, { useEffect, useState, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ListEmptyContent from '../../../../components/list-empty';
import { CenteredContainer } from '../../../../containers/ScreenContainers';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { getStudentsInSecondDegree } from '../../../../redux/departments/actions';

const DepartmentStudents = () => {
    const dispatch = useDispatch();
    const { isLoading, students, currentDepartment } = useSelector((state) => state.department);
    const { currentFaculty } = useSelector((state) => state.faculty);

    const { _id: facultyId, academicStructureId, splitAcademicStructureThree: structThree } = currentFaculty;
    const { _id: departmentId } = currentDepartment || {};

    const [searchValue, setSearchValue] = useState('');

    const acceptedStudents = useMemo(() => students?.filter((student) => student?.status === 'Accepted'), [students]);

    const fields = [
        {
            header: 'Full Name',
            accessorKey: 'name',
            cell: ({ row }) => (
                <Link
                    style={{ color: '#2563EB' }}
                    to={`/faculties/department/students/${row.original?._id}`}
                    state={{
                        studentId: row.original?._id,
                    }}
                >
                    {row.original?.lastName || ''} {row.original?.firstName || ''} {row.original?.middleName || ''}
                </Link>
            ),
        },
        ...(structThree
            ? [
                  {
                      cell: ({ row }) => <td>{row.original?.[structThree?.toLowerCase()]}</td>,
                      header: structThree,
                      accessorKey: 'program',
                  },
              ]
            : []),
        {
            header: 'Level',
            accessorKey: 'level',
        },
        {
            header: 'Admission Year',
            accessorKey: 'admissionSession',
        },
    ];

    useEffect(() => {
        if (departmentId) {
            dispatch(getStudentsInSecondDegree(academicStructureId, facultyId, departmentId));
        }
    }, [dispatch, departmentId, academicStructureId, facultyId]);

    return (
        <>
            <PageLayout
                pageTitle={currentDepartment?.departmentName}
                showTableUtils
                loading={isLoading}
                isActionable
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={acceptedStudents || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Students "></ListEmptyContent>
                    </CenteredContainer>
                }
            />
        </>
    );
};

export default DepartmentStudents;
