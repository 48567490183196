import React, { useEffect, useState } from 'react';

import { Form, Formik, FieldArray } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down-ash.svg';
import { ReactComponent as BackArrow } from '../../../assets/icons/back-arrow.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/ChevronRight.svg';
import { ReactComponent as Choose } from '../../../assets/icons/choose.svg';
import { ReactComponent as PlusCircle } from '../../../assets/icons/circlePlus.svg';
import { ReactComponent as Delete } from '../../../assets/icons/delete.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { CancelButton } from '../../../components/forms/sharedStyles';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexColumn,
    FlexRowCentered,
    FlexRowSpaceBetween,
} from '../../../containers/ScreenContainers';
import { useFeeFormReducer } from '../../../hooks/useFeeFormReducer';
import { getSavedAcademicStructure } from '../../../redux/academic-structure/actions';
import { editFee, getAllActivePaymentGateways, searchStudents } from '../../../redux/bursary/actions';
import { getCollegeSecondLevel, getColleges } from '../../../redux/college/actions';
import { getFaculties, getFacultySecondLevel } from '../../../redux/faculty/actions';
import { capitalizeEachWord } from '../../../utils';
import {
    Span,
    Target,
    installmentOpt,
    installmentPlan,
    paymentScheduleData,
    typeOfFee,
} from '../../../utils/bursaryData';
import { Button, LoadingButton } from '../../buttons';
import Checkbox from '../../inputs/checkbox';
import DatePicker from '../../inputs/date-picker';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input-with-formik';

const Container = tw.div`
    bg-white
    h-auto
    max-w-[1000px]
    px-[3.2rem]
    py-[3.6rem]
    rounded-[16px]
`;

const initialState = {
    feeType: '',
    singleFaculty: [],
    data: [],
    departmentId: [],
    targets: [],
    search: [],
    selectedSearch: [],
    isSearchOpen: false,
    searchValue: '',
    directStudents: [],
    selectedFaculty: [],
    receivingAccount: '',
    targetOptions: Target,
    paymentScheduleOption: paymentScheduleData,
    spanOptions: Span,
    facId: [],
    facName: [],
    selectedFacultyId: [],
};
const FilterLevel = ['Direct-Student', 'Institution Wide', 'Acceptance-Fee'];

const EditFeeForm = ({ feeId }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state, dispatch: dispatchFormAction } = useFeeFormReducer(initialState);
    const { faculties, isLoading: facLoading } = useSelector((store) => store.faculty);
    const { isLoading: colLoading } = useSelector((store) => store.college);
    const { sessions } = useSelector((store) => store.admission);
    const { isLoading, currentFee } = useSelector((store) => store.bursary);
    const { savedAcademicStructure } = useSelector((state) => state.academicStructure);
    const academicUnits = Object.keys(savedAcademicStructure || {}).map((item) => ({ name: capitalizeEachWord(item) }));
    const filteredFaculties = faculties?.filter(
        (item) =>
            item?.facultyData?.length !== 0 ||
            item?.departmentData?.length !== 0 ||
            item?.subProgramData?.length !== 0 ||
            item?.schoolData?.length !== 0,
    );
    const {
        feeType,
        data: academicData,
        departmentId,
        searchValue,
        targetOptions,
        paymentScheduleOption,
        spanOptions,
        facId,
        facName,
        targets,
    } = state;

    const [activePayments, setActivePayments] = useState([]);
    const [selectedAcadUnit, setSelectedAcadUnit] = useState(null);
    const [selectedSpan, setSelectedSpan] = useState(null);
    const [selectedFaculty, setSelectedFaculty] = useState(null);
    const [showBreakdown, setShowBreakdown] = useState(false);
    const [levelList, setLevelList] = useState([100, 200, 300, 400, 500, 600, 700]);
    const [paymentConfig, setPaymentConfig] = useState('');
    const [activeBusiness, setActiveBusiness] = useState([]);

    useEffect(() => {
        if (paymentConfig) {
            const activeBusinesses = activePayments?.filter((item) => item?.provider === paymentConfig);
            setActiveBusiness(activeBusinesses?.[0]?.keys);
        }
    }, [paymentConfig, activePayments]);

    const calculateTotalAmount = (feeBreakdown) => {
        let totalAmount = 0;
        feeBreakdown.forEach((fee) => {
            totalAmount += parseFloat(fee.amount) || 0;
        });
        return totalAmount;
    };

    const updateField = (field, value) => {
        dispatchFormAction({ type: 'SET_FIELD', field, value });
    };

    const getDirectStudents = async (val) => {
        const res = await dispatch(searchStudents(val));
        updateField('directStudents', res);
    };

    useEffect(() => {
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    useEffect(() => {
        if (selectedAcadUnit) {
            const currStructName = selectedAcadUnit?.name?.toLowerCase() || '';
            if (currStructName === 'college') {
                dispatch(getColleges());
            }
            if (currStructName === 'faculty') {
                dispatch(getFaculties());
            }
        }
    }, [dispatch, selectedAcadUnit]);

    useEffect(() => {
        if (feeType === 'Acceptance-Fee') {
            updateField('targetOptions', [targetOptions.find((item) => item.name === 'Newly Admitted Students')]);
            updateField('paymentScheduleOption', [paymentScheduleOption.find((item) => item.name === 'One-Off')]);
            updateField('spanOptions', [spanOptions.find((item) => item.name === 'Session')]);
        } else if (feeType === 'Direct-Student') {
            updateField('targetOptions', [targetOptions.find((item) => item?.name === 'Matric Number')]);
            // } else if (feeType === 'Medical-Fee' || feeType === 'Accommodation-Fee' || feeType === 'Sport-Fee') {
            //     updateField('targetOptions', [targetOptions.find((item) => item.name === 'Institution Wide')]);
        } else if (feeType === 'Tuition-Fee') {
            updateField(
                'targetOptions',
                targetOptions?.filter((item) => item?.name !== 'Matric Number'),
            );
        } else {
            updateField('targetOptions', Target);
            updateField('paymentScheduleOption', paymentScheduleData);
            updateField('spanOptions', Span);
        }
        //eslint-disable-next-line
    }, [feeType]);

    useEffect(() => {
        if (searchValue?.length > 0) {
            getDirectStudents(searchValue);
        } else {
            updateField('directStudents', null);
        }
        //eslint-disable-next-line
    }, [searchValue]);

    const getSecondLevelData = async (structureId, firstLevelId, levelTwo, firstLevelName) => {
        if (selectedFaculty) {
            const currStructName = selectedAcadUnit?.name?.toLowerCase() || '';
            const params = { academicStructureId: structureId, firstDegreeId: firstLevelId, structTwo: levelTwo };

            if (currStructName === 'college') {
                const data = await dispatch(getCollegeSecondLevel(params));
                return { firstLevelId, firstLevelName, data };
            } else if (currStructName === 'faculty') {
                const data = await dispatch(getFacultySecondLevel(params));
                return { firstLevelId, firstLevelName, data };
            }
        }
    };

    const handleFacultyChange = (e, depts) => {
        const isAllDepartmentChecked = depts.data.every((department) => departmentId?.includes(department?._id));
        const facultyId = depts?.id;

        const departmentIds = [];

        if (!isAllDepartmentChecked) {
            depts.data?.forEach((department) => {
                departmentIds.push(department._id);
            });
            updateField('departmentId', departmentIds);
            const targetIndex = targets?.findIndex((target) => target.targetId === facultyId);

            if (targetIndex >= 0) {
                updateField('targets', [...targets.splice(0, targetIndex)]);
            } else {
                updateField('targets', [...targets, { targetId: facultyId, lastDegreeIds: [...departmentIds] }]);
            }
        } else {
            updateField('departmentId', []);
            const targetIndex = targets?.findIndex((target) => target.targetId === facultyId);
            if (targetIndex >= 0) {
                updateField('targets', [...targets.splice(0, targetIndex)]);
            } else {
                updateField('targets', targets);
            }
            updateField('targets', []);
        }
    };

    const handleDeptChange = (e, facultyId, academic, department_Id) => {
        let isChecked = departmentId?.includes(department_Id);

        if (!isChecked) {
            updateField('departmentId', [...departmentId, department_Id]);
            const targetIndex = targets.findIndex((target) => target.targetId === facultyId);

            if (targetIndex >= 0) {
                updateField('targets', [
                    ...targets.slice(0, targetIndex),
                    {
                        targetId: facultyId,
                        lastDegreeIds: [...targets[targetIndex].lastDegreeIds, department_Id],
                    },
                    ...targets.slice(targetIndex + 1),
                ]);
            } else {
                updateField('targets', [...targets, { targetId: facultyId, lastDegreeIds: [department_Id] }]);
            }
        } else {
            updateField(
                'departmentId',
                departmentId?.filter((department) => department !== department_Id),
            );

            const targetIndex = targets.findIndex((target) => target.targetId === facultyId);
            if (targetIndex >= 0) {
                const updatedTargets = [...targets];
                const updatedDepartments = [...updatedTargets[targetIndex].lastDegreeIds];

                const departmentIndex = updatedDepartments.indexOf(department_Id);

                if (departmentIndex >= 0) {
                    updatedDepartments.splice(departmentIndex, 1);

                    if (updatedDepartments.length === 0) {
                        updatedTargets.splice(targetIndex, 1);
                    } else {
                        updatedTargets[targetIndex] = {
                            targetId: facultyId,
                            lastDegreeIds: updatedDepartments,
                        };
                    }

                    updateField('targets', updatedTargets);
                } else {
                    updateField('targets', targets);
                }
            } else {
                updateField('targets', targets);
            }
        }
    };

    function getUniqueSortedLevels(departments) {
        const levelSet = new Set();

        departments?.forEach((department) => {
            department?.levels?.forEach((level) => levelSet.add(level));
        });

        const levelArray = Array.from(levelSet)?.sort((a, b) => a - b);
        return levelArray;
    }

    useEffect(() => {
        if (facId?.length === 0) return;
        const data = async () => {
            const response = await Promise.all(
                facId?.map((faculty, index) =>
                    getSecondLevelData(
                        faculty.academicStructureId,
                        faculty._id,
                        faculty.academicStructure.split('-')[1],
                        faculty.facultyName,
                    ),
                ),
            );
            const transformedResponse = response?.flatMap(({ firstLevelId, firstLevelName, data }) => {
                return { id: firstLevelId, name: firstLevelName, data };
            });

            const departments = transformedResponse[0]?.data;

            updateField('data', transformedResponse);

            const levels = getUniqueSortedLevels(departments);
            setLevelList(levels);
        };
        data();
        //eslint-disable-next-line
    }, [facId, facName]);

    useEffect(() => {
        const getActiveGateways = async () => {
            const data = await dispatch(getAllActivePaymentGateways());
            setActivePayments(data);
        };

        getActiveGateways();
    }, [dispatch]);

    // const validateRatios = (value, requiredLength) => {
    //     const ratioPattern = /^(\d+)(:\d+)*$/;
    //     if (!ratioPattern.test(value)) {
    //         setFieldValidation('Ratio must folloe this format 50:50, 40:30:30');
    //         return false;
    //     }

    //     const ratios = value.split(':').map(Number);
    //     if (ratios?.length !== requiredLength) {
    //         setFieldValidation('Ratio length must be equal to number of installments');
    //         return false;
    //     }
    //     const sum = ratios.reduce((acc, curr) => acc + curr, 0);
    //     if (sum !== 100) {
    //         setFieldValidation('Ratio must equal 100');
    //     } else {
    //         return sum === 100;
    //     }
    // };

    const currentFeeObjOne = currentFee?.fee;
    const currentFeeObjTwo = currentFee?.fee?.allFees?.[0];
    const installmentDetails = currentFeeObjTwo?.installmentDetails;

    const newCurrFee = { ...currentFeeObjOne, ...currentFeeObjTwo, ...installmentDetails };

    return (
        <>
            <FlexCentredRow onClick={() => navigate(-1)} className="gap-[0.5rem] hover:cursor-pointer mb-[3.3rem]">
                <BackArrow className="mr-[8px]" />

                <PageTitle as="span" color="#6366F1" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Bursary
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem" className="mx-[1.4rem]">
                    /
                </PageTitle>
                <PageTitle as="span" size="1.6rem" weight="400" lineHeight="2.8rem">
                    Edit Fee
                </PageTitle>
            </FlexCentredRow>

            <Container>
                <FlexCentredRow className="gap-[7.15px] mb-[3.8rem]">
                    <PeopleIcon />
                    <Text size="1.6rem" weight="600">
                        Edit Fee
                    </Text>
                </FlexCentredRow>
                <Formik
                    initialValues={
                        newCurrFee || {
                            session: '',
                            feeType: '',
                            amountPayable: '',
                            paymentSchedule: '',
                            span: '',
                            feeName: '',
                            description: '',
                            deadline: '',
                            firstDeadline: '',
                            secondDeadline: '',
                            thirdDeadline: '',
                            finalDeadline: '',
                            level: [],
                            target: '',
                            targets: [],
                            search: [],
                            paymentConfig: '',
                            spanTarget: '',
                            feeBreakdown: [
                                // {
                                //     amount: '',
                                //     description: '',
                                // },
                            ],
                            // paymentGateway: '',
                            studentDecides: newCurrFee?.studentDecides || false,
                            numberOfInstallments: newCurrFee?.numberOfInstallments || '',
                            ratios: newCurrFee?.ratios || [],
                            deadlines: [],
                            structureId: [],
                            startDate: '',
                            endDate: '',
                            businessName: '',
                        }
                    }
                    // validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        if (
                            values?.target === 'Academic-Structure' &&
                            (values?.targets?.length === 0 || targets?.length === 0)
                        )
                            return toast.error(
                                `Please select minimum of one under ${
                                    selectedFaculty?.facultyName || 'target'
                                } academic structure`,
                            );

                        if (values.feeType === 'Direct-Student' || values?.feeType === 'Accommodation-Fee')
                            delete values.level;

                        let deadlineArray = [];

                        if (values?.deadline) {
                            values.deadline = new Date(values?.deadline).toISOString().slice(0, 10);
                            values?.numberOfInstallments && deadlineArray.push(values?.deadline);
                        }
                        if (values.firstDeadline) {
                            values.firstDeadline = new Date(values.firstDeadline).toISOString().slice(0, 10);
                            deadlineArray.push(values?.firstDeadline);
                        }

                        if (values.secondDeadline) {
                            values.secondDeadline = new Date(values.secondDeadline).toISOString().slice(0, 10);
                            deadlineArray.push(values?.firstDeadline);
                        }

                        if (values.thirdDeadline) {
                            values.thirdDeadline = new Date(values.thirdDeadline).toISOString().slice(0, 10);
                            deadlineArray.push(values.thirdDeadline);
                        }

                        if (values.finalDeadline) {
                            values.finalDeadline = new Date(values.finalDeadline).toISOString().slice(0, 10);
                            deadlineArray.push(values.finalDeadline);
                        }

                        values?.numberOfInstallments && delete values?.deadline;

                        delete values.firstDeadline;
                        delete values.secondDeadline;
                        delete values.thirdDeadline;
                        delete values.finalDeadline;

                        if (values?.startDate) {
                            values.startDate = new Date(values.startDate).toISOString().slice(0, 10);
                            values.endDate = new Date(values.endDate).toISOString().slice(0, 10);
                        }

                        const monthlySpan = {
                            startDate: values?.startDate,
                            endDate: values?.endDate,
                        };

                        delete values?.startDate;
                        delete values?.endDate;

                        delete values?.allFees;
                        delete values?.installmentDetails;
                        const splittedRatio = typeof values?.ratios === 'string' ? values?.ratios?.split(':') : null;
                        const payload = {
                            ...values,
                            deadlines: deadlineArray?.length > 0 ? deadlineArray : values?.deadlines,
                            studentDecides:
                                values?.studentDecides === 'percent'
                                    ? false
                                    : values?.studentDecides === 'student'
                                    ? true
                                    : values?.studentDecides,
                            search: values?.search?.map((student) => student?.matricNumber),
                            monthlySpan,
                            ratios: splittedRatio ? splittedRatio?.map(Number) : values?.ratios,
                        };
                        const res = await dispatch(editFee(feeId, payload));
                        if (res) {
                            window.location.reload();
                            navigate(-1);
                        }
                    }}
                >
                    {({ handleChange, errors, values, setFieldValue, touched, setFieldError, setErrors }) => (
                        <Form>
                            <div className="gap-x-[3.6rem] gap-y-[2rem] grid-cols-2 grid">
                                <FlexCentredCol>
                                    <Select
                                        name="session"
                                        objProp="admissionYear"
                                        data={sessions}
                                        placeholder="Choose Session"
                                        label="Session"
                                        onChange={(selected) => {
                                            setFieldValue('session', selected[0].admissionYear);
                                        }}
                                        error={errors.session}
                                        passedSelectedItems={newCurrFee?.session}
                                        disabled
                                    />
                                </FlexCentredCol>
                                <FlexCentredCol>
                                    <TextInput
                                        name="feeName"
                                        onChange={handleChange}
                                        label="Name of Fee"
                                        type="text"
                                        placeholder="Tuition Fee for Faculty of Law"
                                        errors={errors}
                                        value={values?.feeName}
                                    />
                                </FlexCentredCol>
                                <FlexCentredCol>
                                    <Select
                                        name="feeType"
                                        placeholder="Choose Fee"
                                        objProp="name"
                                        data={typeOfFee}
                                        label="Type of Fee"
                                        onChange={(selected) => {
                                            setFieldValue('feeType', selected[0].value);
                                            updateField('feeType', selected[0].value);
                                        }}
                                        error={errors.feeType}
                                        passedSelectedItems={newCurrFee?.feeType}
                                        disabled
                                    />
                                </FlexCentredCol>
                                <FlexCentredCol>
                                    <TextInput
                                        name="description"
                                        label="Description"
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="Input Description"
                                        errors={errors}
                                        value={values?.description}
                                    />
                                </FlexCentredCol>
                                {/* {(values.target === 'MatricNumber' || values.feeType === 'Direct-Student') && (
                                    <FlexCentredCol>
                                        <Label>Search For Students</Label>
                                        <TagInputContainer
                                            className="relative"
                                            //onClick={() => dispatchFormAction({ type: 'TOGGLE_OPEN' })}
                                        >
                                            {search?.map((matric) => (
                                                <TagItems key={matric}>
                                                    <Text color="#FFF">{matric} </Text>
                                                </TagItems>
                                            ))}

                                            <NewInput
                                                value={searchValue}
                                                placeholder="Type Matric Number"
                                                onKeyDown={(e) => {
                                                    dispatchFormAction({ type: 'TOGGLE_OPEN' });
                                                }}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    updateField('searchValue', value);
                                                }}
                                            />
                                        </TagInputContainer>
                                        {isSearchOpen && (
                                            <div className="sticky z-[5] mt-[1rem] w-max-[51.5rem]">
                                                {!directStudentList?.length > 0 ? (
                                                    <Text size="1.5rem" weight="500">
                                                        No student found!
                                                    </Text>
                                                ) : (
                                                    <ListWithCheckBox
                                                        list={directStudentList}
                                                        setSelectedItems={setSelectedSearch}
                                                        selectedItems={selectedSearch}
                                                        onChange={handleSearchCheck}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </FlexCentredCol>
                                )} */}
                                <FlexCentredCol>
                                    <TextInput
                                        name="amountPayable"
                                        type="number"
                                        label="Amount Payable"
                                        placeholder="Input Amount"
                                        onChange={handleChange}
                                        errors={errors}
                                        value={values?.amountPayable}
                                    />
                                </FlexCentredCol>
                                <FlexCentredCol>
                                    <Select
                                        name="span"
                                        objProp="name"
                                        label="Span of Fee"
                                        placeholder="Choose Span"
                                        data={spanOptions}
                                        onChange={(selected) => {
                                            setSelectedSpan(selected[0].name);
                                            setFieldValue('span', selected[0].name);
                                        }}
                                        error={errors.span}
                                        passedSelectedItems={newCurrFee?.span}
                                    />
                                </FlexCentredCol>
                                <FlexCentredCol>
                                    <Select
                                        name="paymentSchedule"
                                        objProp="name"
                                        placeholder="Select a Payment Schedule"
                                        label="Payment Schedule"
                                        data={paymentScheduleOption}
                                        onChange={(selected) => {
                                            setFieldValue('paymentSchedule', selected[0].name);
                                            if (selected[0].name === 'One-Off') {
                                                setFieldValue('numberOfInstallments', null);
                                            }
                                        }}
                                        error={errors.paymentSchedule}
                                        passedSelectedItems={newCurrFee?.paymentSchedule}
                                    />
                                </FlexCentredCol>
                                {values?.paymentSchedule === 'Installment' && (
                                    <FlexCentredCol>
                                        <Select
                                            name="studentDecides"
                                            objProp="name"
                                            label="Installment Plan"
                                            placeholder="Choose installment plan"
                                            data={installmentPlan}
                                            onChange={(selected) => {
                                                setFieldValue('studentDecides', selected[0].value);
                                            }}
                                            error={errors.studentDecides}
                                            passedSelectedItems={
                                                newCurrFee?.studentDecides === true
                                                    ? installmentPlan?.[1].name
                                                    : installmentPlan?.[0].name
                                            }
                                        />
                                    </FlexCentredCol>
                                )}
                                {values?.paymentSchedule === 'Installment' && (
                                    <FlexCentredCol>
                                        <Select
                                            name="numberOfInstallments"
                                            objProp="name"
                                            label="Number of Installments"
                                            placeholder="Pick the installment option"
                                            data={installmentOpt.map((item) => ({ name: item }))}
                                            onChange={(selected) => {
                                                setFieldValue('numberOfInstallments', parseInt(selected[0].name));
                                            }}
                                            error={errors.numberOfInstallments}
                                            passedSelectedItems={newCurrFee?.numberOfInstallments}
                                        />
                                    </FlexCentredCol>
                                )}
                                {values?.numberOfInstallments &&
                                    (values?.studentDecides === 'percent' || values?.studentDecides === false) && (
                                        <FlexCentredCol>
                                            <TextInput
                                                name="ratios"
                                                type="text"
                                                label="Installment Percentage"
                                                placeholder="Input installment percentage (e.g 50:50, 40:30:30)"
                                                onChange={handleChange}
                                                errors={errors}
                                                value={
                                                    typeof values?.ratios === 'object'
                                                        ? values?.ratios?.join(':')
                                                        : values?.ratios
                                                }
                                            />
                                        </FlexCentredCol>
                                    )}
                                {values.numberOfInstallments && (
                                    <>
                                        {values.numberOfInstallments >= '1' && (
                                            <FlexCentredCol>
                                                <DatePicker
                                                    label="Deadline 1 (optional)"
                                                    name="deadline"
                                                    touched={touched}
                                                    minDate={new Date()}
                                                    initialValue={
                                                        newCurrFee?.deadlines?.length > 0
                                                            ? new Date(newCurrFee?.deadlines?.[0])
                                                            : null
                                                    }
                                                />
                                            </FlexCentredCol>
                                        )}
                                        {values.numberOfInstallments >= '2' && (
                                            <FlexCentredCol>
                                                <DatePicker
                                                    label="Deadline 2 (optional)"
                                                    name="firstDeadline"
                                                    touched={touched}
                                                    minDate={new Date(values?.deadline)}
                                                    initialValue={
                                                        newCurrFee?.deadlines?.length > 1
                                                            ? new Date(newCurrFee?.deadlines?.[1])
                                                            : null
                                                    }
                                                />
                                            </FlexCentredCol>
                                        )}
                                        {values.numberOfInstallments >= '3' && (
                                            <FlexCentredCol>
                                                <DatePicker
                                                    label="Deadline 3 (optional)"
                                                    name="secondDeadline"
                                                    touched={touched}
                                                    minDate={new Date(values?.firstDeadline)}
                                                    initialValue={
                                                        newCurrFee?.deadlines?.length > 2
                                                            ? new Date(newCurrFee?.deadlines?.[2])
                                                            : null
                                                    }
                                                />
                                            </FlexCentredCol>
                                        )}
                                        {values.numberOfInstallments >= '4' && (
                                            <FlexCentredCol>
                                                <DatePicker
                                                    label="Deadline 4 (optional)"
                                                    name="thirdDeadline"
                                                    touched={touched}
                                                    minDate={new Date(values?.secondDeadline)}
                                                    initialValue={
                                                        newCurrFee?.deadlines?.length > 3
                                                            ? new Date(newCurrFee?.deadlines?.[3])
                                                            : null
                                                    }
                                                />
                                            </FlexCentredCol>
                                        )}
                                        {values.numberOfInstallments >= '5' && (
                                            <FlexCentredCol>
                                                <DatePicker
                                                    label="Deadline 5 (optional)"
                                                    name="finalDeadline"
                                                    touched={touched}
                                                    minDate={new Date(values?.thirdDeadline)}
                                                    initialValue={
                                                        newCurrFee?.deadlines?.length > 4
                                                            ? new Date(newCurrFee?.deadlines?.[4])
                                                            : null
                                                    }
                                                />
                                            </FlexCentredCol>
                                        )}
                                    </>
                                )}
                                {values?.paymentSchedule === 'One-Off' && values?.span !== 'Monthly' && (
                                    <FlexCentredCol>
                                        <DatePicker
                                            label="Deadline"
                                            name="deadline"
                                            touched={touched}
                                            minDate={new Date(values?.deadline)}
                                            initialValue={newCurrFee?.deadline ? new Date(newCurrFee?.deadline) : null}
                                        />
                                    </FlexCentredCol>
                                )}
                                {selectedSpan === 'Semester' && (
                                    <FlexCentredCol>
                                        <Select
                                            name="spanTarget"
                                            objProp="name"
                                            label="Semester"
                                            placeholder="Choose Semester"
                                            data={[
                                                { name: 'First Semester', value: 'first-semester' },
                                                { name: 'Second Semester', value: 'second-semester' },
                                            ]}
                                            onChange={(selected) => {
                                                setFieldValue('spanTarget', selected[0]?.value);
                                            }}
                                        />
                                    </FlexCentredCol>
                                )}
                                <FlexCentredCol>
                                    <Select
                                        name="paymentConfig"
                                        objProp="provider"
                                        label="Payment Gateway"
                                        placeholder="Choose a Payment Gateway"
                                        data={activePayments}
                                        onChange={(selected) => {
                                            setFieldValue('paymentConfig', selected[0]?.provider);
                                            setPaymentConfig(selected[0].provider);
                                        }}
                                        error={errors.paymentConfig}
                                        passedSelectedItems={newCurrFee?.paymentConfig}
                                        // disabled
                                    />
                                </FlexCentredCol>
                                <FlexCentredCol>
                                    <Select
                                        name="target"
                                        objProp="name"
                                        placeholder="Choose Target"
                                        label="Target"
                                        data={targetOptions}
                                        onChange={(selected) => {
                                            setFieldValue('target', selected[0].value);
                                        }}
                                        error={errors.target}
                                        passedSelectedItems={newCurrFee?.target}
                                    />
                                </FlexCentredCol>
                                {values?.paymentConfig && (
                                    <FlexCentredCol>
                                        <Select
                                            name="businessName"
                                            objProp="businessName"
                                            label="Business Name"
                                            placeholder="Choose a Business Name"
                                            data={activeBusiness?.filter((item) => item?.active)}
                                            onChange={(selected) => {
                                                setFieldValue('businessName', selected[0]?.businessName);
                                            }}
                                            error={errors.paymentConfig}
                                            passedSelectedItems={newCurrFee?.businessName}
                                        />
                                    </FlexCentredCol>
                                )}
                                {values?.target === 'Academic-Structure' && (
                                    <FlexCentredCol>
                                        <Select
                                            objProp="name"
                                            placeholder="Choose Structure"
                                            label="Academic Structure"
                                            data={academicUnits}
                                            onChange={(selected) => {
                                                setSelectedAcadUnit(selected[0]);
                                            }}
                                            error={errors.target}
                                            passedSelectedItems={newCurrFee?.structureType?.split('-')[0]}
                                        />
                                    </FlexCentredCol>
                                )}
                                {selectedAcadUnit ? (
                                    <FlexCentredCol>
                                        <Select
                                            objProp="facultyName"
                                            data={filteredFaculties}
                                            label={`Choose ${selectedAcadUnit?.name}`}
                                            placeholder={selectedAcadUnit?.name}
                                            isLoading={facLoading || colLoading}
                                            onChange={(selected) => {
                                                setSelectedFaculty(selected[0]);
                                                updateField('facId', selected);
                                                updateField(
                                                    'facName',
                                                    selected?.map((item) => item?.facultyName),
                                                );
                                            }}
                                        />
                                    </FlexCentredCol>
                                ) : null}
                                {(FilterLevel.includes(values.feeType) || FilterLevel.includes(values.target)) &&
                                selectedFaculty ? (
                                    ''
                                ) : (
                                    <FlexCentredCol>
                                        <Select
                                            name="level"
                                            objProp="name"
                                            placeholder="Level"
                                            label="Level"
                                            multiSelect
                                            data={levelList?.map((item) => ({ name: item }))}
                                            onChange={(selected) => {
                                                setFieldValue(
                                                    'level',
                                                    selected?.map((item) => item?.name),
                                                );
                                            }}
                                            passedSelectedItems={newCurrFee?.level}
                                        />
                                    </FlexCentredCol>
                                )}
                                {values.target === 'Academic-Structure' ? (
                                    <div className="flex gap-[2rem] flex-col items-start">
                                        <FlexCentredRow className="gap-[0.815rem] mt-[5rem]">
                                            <Choose />
                                            <PageTitle size="1.9rem" lineHeight="2.8rem">
                                                Faculty/College/Department
                                            </PageTitle>
                                        </FlexCentredRow>
                                        {isLoading && (
                                            <Text align="left" size="1rem" weight="500">
                                                Loading...
                                            </Text>
                                        )}
                                        {academicData?.map((depts) => (
                                            <FlexCentredCol className="gap-[1rem] mb-[2rem]" key={depts.id}>
                                                <FlexCentredRow>
                                                    <Checkbox
                                                        value={depts}
                                                        checked={depts.data.every((department) =>
                                                            departmentId?.includes(department?._id),
                                                        )}
                                                        data-faculty-id={depts.id}
                                                        onChange={(e) => handleFacultyChange(e, depts)}
                                                        label={depts.name}
                                                        labelClassName="text-[1.6rem] font-semibold"
                                                    />
                                                </FlexCentredRow>
                                                <FlexCentredRow className="ml-[4rem] gap-[1rem] flex-wrap">
                                                    {depts?.data?.map((dept) => (
                                                        <div
                                                            key={dept?._id}
                                                            className="relative flex items-start justify-start w-max h-min-[4rem] flex-wrap"
                                                        >
                                                            <Checkbox
                                                                value={dept?.departmentName || dept?.subprogram}
                                                                data-faculty-id={depts?.id}
                                                                data-academic-structure={dept?.academicStructure}
                                                                data-department-id={dept?._id}
                                                                // data-middle-id={dept?.}
                                                                checked={departmentId?.includes(dept?._id)}
                                                                onChange={(e) =>
                                                                    handleDeptChange(
                                                                        e,
                                                                        depts?.id,
                                                                        dept?.academicStructure,
                                                                        dept?._id,
                                                                    )
                                                                }
                                                            />

                                                            <label
                                                                className="text-[1.4rem] font-normal"
                                                                htmlFor={dept?.departmentName || dept?.subprogram}
                                                            >
                                                                {dept?.subprogram
                                                                    ? dept?.subprogram
                                                                    : dept?.department || dept?.departmentName}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </FlexCentredRow>
                                            </FlexCentredCol>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                            <div className="">
                                <FlexCentredRow
                                    className="pt-[3.2rem] gap-[1rem]"
                                    onClick={() => setShowBreakdown(!showBreakdown)}
                                >
                                    <Text size="1.6rem" weight="600" align="left">
                                        Fee Breakdown
                                    </Text>
                                    <Text size="1.6rem" weight="400" align="left">
                                        (optional)
                                    </Text>
                                    <div className="">{showBreakdown ? <ArrowDown /> : <ArrowUp />}</div>
                                </FlexCentredRow>
                                {showBreakdown ? (
                                    <FlexCentredCol className="mt-[2rem] gap-[5.6rem]">
                                        <FieldArray
                                            name="feeBreakdown"
                                            render={({ insert, remove, push }) => (
                                                <React.Fragment>
                                                    <div className="flex flex-col gap-[1rem]">
                                                        {values.feeBreakdown?.map((fee, index) => (
                                                            <>
                                                                {index >= 0 ? (
                                                                    <FlexRowCentered key={index} className="gap-[1rem]">
                                                                        <TextInput
                                                                            name={`feeBreakdown.${index}.description`}
                                                                            label="Description"
                                                                            onChange={handleChange}
                                                                            type="text"
                                                                            placeholder="Enter Fee Description"
                                                                            errors={errors}
                                                                        />
                                                                        <TextInput
                                                                            name={`feeBreakdown.${index}.amount`}
                                                                            label="Amount"
                                                                            onChange={handleChange}
                                                                            type="text"
                                                                            placeholder="Enter Fee Amount"
                                                                            errors={errors}
                                                                        />
                                                                        <FlexCentredRow className="w-[100%] md:w-[14.9rem]">
                                                                            <Button onClick={() => remove(index)}>
                                                                                <Delete />
                                                                            </Button>
                                                                        </FlexCentredRow>
                                                                    </FlexRowCentered>
                                                                ) : (
                                                                    <FlexColumn>
                                                                        <Text align="left" size="1.4rem" weight="500">
                                                                            No fee breakdown has been added to this fee
                                                                        </Text>
                                                                    </FlexColumn>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>

                                                    <FlexRowSpaceBetween>
                                                        <Button
                                                            className="flex items-center gap-[8px]"
                                                            border="1px solid #2563EB"
                                                            type="button"
                                                            color="#2563EB"
                                                            onClick={() => push({ description: '', amount: '' })}
                                                        >
                                                            <PlusCircle fill="#2563EB" />
                                                            Add Fee Breakdown
                                                        </Button>
                                                        <FlexRowCentered className="gap-[2.4rem]">
                                                            <Text align="left" size="1.2rem" weight="500">
                                                                Amount Payable : {values.amountPayable || 0}
                                                            </Text>

                                                            <Text align="left" size="1.2rem" weight="500">
                                                                Total Fee Breakdown :{' '}
                                                                {calculateTotalAmount(values.feeBreakdown)}
                                                            </Text>
                                                        </FlexRowCentered>
                                                    </FlexRowSpaceBetween>
                                                </React.Fragment>
                                            )}
                                        />
                                    </FlexCentredCol>
                                ) : null}
                            </div>
                            <div className="flex items-center gap-[1rem] mt-[2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)} className="ml-auto">
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={isLoading}
                                    disabled={
                                        isLoading ||
                                        (values.amountPayable > 0 &&
                                            values?.feeBreakdown?.length > 0 &&
                                            values.amountPayable !== calculateTotalAmount(values.feeBreakdown))
                                    }
                                    bgColor="#6366F1"
                                    color="#fff"
                                    type="submit"
                                >
                                    Edit Fee
                                </LoadingButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    );
};

export default EditFeeForm;
