import React, { useState, useEffect, useMemo } from 'react';

import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Mark } from '../../assets/icons/circular-mark-sm-black.svg';
import { ReactComponent as Plus } from '../../assets/icons/circularplus-black.svg';
import { ReactComponent as CircularPlus } from '../../assets/icons/circularplus.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../assets/icons/dropdown.svg';
import { ActionsPopup } from '../../components';
import { Button } from '../../components/buttons';
import ListEmptyContent from '../../components/list-empty';
import ConfirmActionDialogueWithInput from '../../components/popups/confirm-action-with-input';
import { CountBadge } from '../../components/shared';
import { Text } from '../../containers/MesssageContainers';
import { CenteredContainer, FlexCentredRow, RelativeContainer } from '../../containers/ScreenContainers';
import useDebounce from '../../hooks/useDebounce';
import PageLayout from '../../layout/page-layout/PageLayout';
import { getRolesInUnit, removeAllRolesFromStaff } from '../../redux/roles/actions';
import { getStaffInUnit } from '../../redux/staff/actions';
import { storeUnit } from '../../utils/originUnit';

const fields = [
    {
        header: 'Name',
        accessorKey: 'name',
        cell: ({ row }) => (
            <p>
                {row.original.firstName} {row.original.otherName || ''} {row.original.surname}
            </p>
        ),
    },
    {
        header: 'Staff Number',
        accessorKey: 'staffNumber',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Role',
        accessorKey: 'role',
        cell: (props) => (
            <div className="flex items-center gap-[4px]">
                <Text align="left" color="#6b728">
                    {props.row.original?.staffRole[0]?.role || '-'}
                </Text>
                {props.row.original.staffRole?.length > 1 ? (
                    <CountBadge>+{props.row.original.staffRole?.length - 1}</CountBadge>
                ) : null}
            </div>
        ),
    },
    {
        header: 'Email Address',
        accessorKey: 'staffEmail',
    },
];

const CONFIRM_TEXT = 'remove';

const Administrator = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.roles);
    const { isLoading: altLoading } = useSelector((state) => state.staff);

    const [admins, setAdmins] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [roleQuery, setRoleQuery] = useState('');
    const [roles, setRoles] = useState([]);
    const [toggleRefetch, setToggleRefetch] = useState(false);

    const adminsWithRoles = useMemo(
        //only show admins that have a role
        () => admins?.filter((staff) => staff?.staffRole?.length),
        [admins],
    );

    const rolesActionItems = roles?.map((role) => {
        return {
            name: role?.role,
            click: () => {
                setRoleQuery(role?.role);
                setDropdownOpen(null);
            },
        };
    });

    const confirmAction = async () => {
        if (CONFIRM_TEXT !== inputValue) return toast.error('Confirmation text does not match');

        const res = await dispatch(removeAllRolesFromStaff(selectedAdmin?._id));
        if (res) {
            setToggleRefetch((prevState) => !prevState);
            setShowConfirm(false);
        }
    };

    const query = useMemo(() => {
        let queryParams = {};
        if (debouncedSearchValue) {
            queryParams.staffSearch = debouncedSearchValue;
        }
        if (roleQuery) {
            queryParams.role = roleQuery;
        }
        return queryParams;
    }, [debouncedSearchValue, roleQuery]);

    useEffect(() => {
        if (!unitName) return;

        const getRoles = async () => {
            const data = await dispatch(getRolesInUnit(unitName));
            data && setRoles(data);
        };
        storeUnit(unitName);
        getRoles();
    }, [unitName, dispatch]);

    useEffect(() => {
        if (!unitName) return;
        const getDetails = async () => {
            const data = await dispatch(getStaffInUnit({ query }));
            data && setAdmins(data);
        };

        getDetails();
    }, [unitName, dispatch, query, toggleRefetch]);

    return (
        <>
            <div>
                <PageLayout
                    noMargin
                    searchable
                    loading={isLoading || altLoading}
                    data={adminsWithRoles}
                    fields={fields}
                    searchValue={searchValue}
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    onSearchClose={() => setSearchValue('')}
                    isActionable
                    actionType="radio"
                    onSelectedItemsChange={(items) => setSelectedAdmin(items[0])}
                    showFilter
                    showTableUtils
                    tableUtilsChildren={
                        <FlexCentredRow className="gap-[10px]">
                            <Button border="1px solid #e5e7eb" onClick={() => navigate('add-administrator')}>
                                <Plus />
                                Add Administrator
                            </Button>

                            <RelativeContainer>
                                <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen('actions')}>
                                    Actions
                                    <Dropdown />
                                </Button>
                                <ActionsPopup
                                    open={dropdownOpen === 'actions'}
                                    close={() => setDropdownOpen(false)}
                                    items={[
                                        {
                                            icon: <Mark />,
                                            name: 'Edit Administrator',
                                            disabled: !selectedAdmin,
                                            click: () => {
                                                navigate('edit-administrator', { state: { selectedAdmin } });
                                            },
                                        },
                                        {
                                            icon: <Delete />,
                                            name: 'Remove Administrator',
                                            disabled: !selectedAdmin,
                                            click: () => {
                                                setShowConfirm(true);
                                                setDropdownOpen(false);
                                            },
                                        },
                                    ]}
                                />
                            </RelativeContainer>
                        </FlexCentredRow>
                    }
                    // openFilter={dropdownOpen === 'roles'}
                    // closeFilter={() => setDropdownOpen(null)}
                    filterItems={rolesActionItems}
                    noItemView={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent isInTable title="No Administrator " subtitle="Add an Administrator">
                                <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                    <Button
                                        onClick={() => navigate('add-administrator')}
                                        bgColor="#10B981"
                                        color="#fff"
                                    >
                                        <CircularPlus className="mr-[1.15rem]" />
                                        Add Administrator
                                    </Button>
                                </CenteredContainer>
                            </ListEmptyContent>
                        </CenteredContainer>
                    }
                />
            </div>

            <ConfirmActionDialogueWithInput
                show={showConfirm}
                title="Remove Administrator?"
                subtitle="The administrator's access to this organization unit will be terminated."
                subtitle2={`To confirm removal, enter '${CONFIRM_TEXT}' in the text field.`}
                placeholder={`Input ${CONFIRM_TEXT}`}
                close={() => setShowConfirm(false)}
                confirmAction={confirmAction}
                btn2Text="Yes, Remove"
                setInputValue={setInputValue}
                value={inputValue}
                isLoading={isLoading}
                btnDisabled={inputValue !== CONFIRM_TEXT}
            />
        </>
    );
};

export default Administrator;
