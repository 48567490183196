import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit3.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/send-green.svg';
import { ConfirmActionDialogue } from '../../../components';
import RadioInput from '../../../components/inputs/radio';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getAllBulletins, publishCommunication } from '../../../redux/communications/actions';
import { capitalizeEachWord } from '../../../utils';
import { All, Bulletin, InternalMemo, Mail, Notice } from '../components';

const ComposeCommunications = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((store) => store.communications);
    const { currentUnit } = useSelector((store) => store.organisationUnits);

    const url = window.location.href;
    const [activeTab, setActiveTab] = useState('all');
    const [openModal, setOpenModal] = useState(null);
    const [value, setValue] = useState('');
    const [selectedItem, setSelectedItem] = useState('');
    const [allCommunications, setAllCommunications] = useState({
        all: [],
        bulletins: [],
        internalMemo: [],
        notice: [],
        mail: [],
    });
    const selectedComms = activeTab === 'all' ? selectedItem?.type : activeTab;
    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
        const getAllCommunications = async () => {
            const res = await dispatch(getAllBulletins(''));
            setAllCommunications((prev) => ({ ...prev, all: res }));
        };
        const getInternalCommunications = async () => {
            const res = await dispatch(getAllBulletins('Internal memo'));
            setAllCommunications((prev) => ({ ...prev, internalMemo: res }));
        };
        const getBulletinCommunications = async () => {
            const res = await dispatch(getAllBulletins('Bulletin'));
            setAllCommunications((prev) => ({ ...prev, bulletins: res }));
        };
        const getMailCommunications = async () => {
            const res = await dispatch(getAllBulletins('Mail'));
            setAllCommunications((prev) => ({ ...prev, mail: res }));
        };
        const getNoticeCommunications = async () => {
            const res = await dispatch(getAllBulletins('Notice'));
            setAllCommunications((prev) => ({ ...prev, notice: res }));
        };

        getAllCommunications();
        getInternalCommunications();
        getBulletinCommunications();
        getMailCommunications();
        getNoticeCommunications();
    }, [dispatch, refetch]);

    const TableHeaders = [
        {
            header: <RadioInput />,
            size: 5,
            accessorKey: 'action',
            cell: (props) => <p>{props.getValue()}</p>,
        },
        {
            header: 'Subject',
            accessorKey: 'subject',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Ref No.',
            accessorKey: 'ref',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Session',
            accessorKey: 'session',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        {
            header: 'Time Stamp',
            accessorKey: 'time',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
        ...(activeTab === 'all'
            ? [
                  {
                      header: 'Type',
                      accessorKey: 'type',
                      cell: (props) => <p>{props?.getValue()}</p>,
                  },
              ]
            : []),
        {
            header: 'Publish Status',
            accessorKey: 'commsStatus',
            cell: (props) => <p>{props?.getValue()}</p>,
        },
    ];

    const handleSelect = (item) => {
        setSelectedItem(item);
    };

    const handlePublish = async () => {
        if (value !== 'publish') return toast.error("Enter the word 'publish' to publish communications");
        const res = await dispatch(publishCommunication(selectedItem?._id, selectedItem?.type));
        if (res) {
            setSelectedItem(null);
            setOpenModal(null);
            setValue('');
            setRefetch((prev) => !prev);
        }
    };

    return (
        <div>
            <PageLayout
                secondaryButtonText="Compose"
                onSecondaryButtonClick={() => navigate('create')}
                secondaryButtonIcon={<EditIcon />}
                pageTitle="Compose"
                onActionBtnClick={() => {
                    setOpenModal('actions');
                }}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <SendIcon />,
                        name: `Publish ${capitalizeEachWord(selectedComms)}`,
                        click: () => {
                            setOpenModal('publish');
                        },
                    },
                    {
                        icon: <EditIcon />,
                        name: `Edit ${capitalizeEachWord(selectedComms)}`,
                        // click: () => {
                        //     setShowConfrimActionDialogue(true);
                        //     setDropdownOpen(false);
                        // },
                    },
                    { icon: <DeleteIcon />, name: `Delete ${capitalizeEachWord(selectedComms)}` },
                ]}
            />
            <div className="mt-[3.2rem]">
                <CTabs>
                    <CNav variant="tabs">
                        <CNavItem onClick={() => setActiveTab('all')}>
                            <CNavLink>
                                All{' '}
                                <span
                                    className={`${
                                        activeTab === 'all'
                                            ? 'bg-[#FEE2E2] text-[#EF4444]'
                                            : 'bg-[#E5E7EB] text-[#1F2937]'
                                    } px-[6px] py-[2px] rounded-[1000px] font-semibold text-[1.2rem]`}
                                >
                                    {allCommunications?.all?.length > 10
                                        ? `${allCommunications?.all?.length}+`
                                        : allCommunications?.all?.length}
                                </span>
                            </CNavLink>
                        </CNavItem>
                        {currentUnit?.title?.toLowerCase() === 'registry' && (
                            <CNavItem onClick={() => setActiveTab('bulletin')}>
                                <CNavLink>
                                    Bulletin{' '}
                                    <span
                                        className={`${
                                            activeTab === 'bulletin'
                                                ? 'bg-[#FEE2E2] text-[#EF4444]'
                                                : 'bg-[#E5E7EB] text-[#1F2937]'
                                        } px-[6px] py-[2px] rounded-[1000px] font-semibold text-[1.2rem]`}
                                    >
                                        {allCommunications?.bulletins?.length > 10
                                            ? `${allCommunications?.bulletins?.length}+`
                                            : allCommunications?.bulletins?.length}
                                    </span>
                                </CNavLink>
                            </CNavItem>
                        )}

                        <CNavItem onClick={() => setActiveTab('Internal memo')}>
                            <CNavLink>
                                Internal Memo{' '}
                                <span
                                    className={`${
                                        activeTab === 'Internal memo'
                                            ? 'bg-[#FEE2E2] text-[#EF4444]'
                                            : 'bg-[#E5E7EB] text-[#1F2937]'
                                    } px-[6px] py-[2px] rounded-[1000px] font-semibold text-[1.2rem]`}
                                >
                                    {allCommunications?.internalMemo?.length > 10
                                        ? `${allCommunications?.internalMemo?.length}+`
                                        : allCommunications?.internalMemo?.length}
                                </span>
                            </CNavLink>
                        </CNavItem>
                        <CNavItem onClick={() => setActiveTab('notice')}>
                            <CNavLink>
                                Notice{' '}
                                <span
                                    className={`${
                                        activeTab === 'notice'
                                            ? 'bg-[#FEE2E2] text-[#EF4444]'
                                            : 'bg-[#E5E7EB] text-[#1F2937]'
                                    } px-[6px] py-[2px] rounded-[1000px] font-semibold text-[1.2rem]`}
                                >
                                    {allCommunications?.notice?.length > 10
                                        ? `${allCommunications?.notice?.length}+`
                                        : allCommunications?.notice?.length}
                                </span>
                            </CNavLink>
                        </CNavItem>
                        {!url.includes('staff-arena') && (
                            <CNavItem onClick={() => setActiveTab('mail')}>
                                <CNavLink>
                                    Mail{' '}
                                    <span
                                        className={`${
                                            activeTab === 'mail'
                                                ? 'bg-[#FEE2E2] text-[#EF4444]'
                                                : 'bg-[#E5E7EB] text-[#1F2937]'
                                        } px-[6px] py-[2px] rounded-[1000px] font-semibold text-[1.2rem]`}
                                    >
                                        {allCommunications?.mail?.length > 10
                                            ? `${allCommunications?.mail?.length}+`
                                            : allCommunications?.mail?.length}
                                    </span>
                                </CNavLink>
                            </CNavItem>
                        )}
                    </CNav>
                    <CTabContent>
                        <CTabPane>
                            <All
                                tableData={allCommunications?.all}
                                tableHeader={TableHeaders}
                                selectedItem={selectedItem}
                                handleSelect={handleSelect}
                            />
                        </CTabPane>
                        <CTabPane>
                            <Bulletin
                                tableData={allCommunications?.bulletins}
                                tableHeader={TableHeaders}
                                selectedItem={selectedItem}
                                handleSelect={handleSelect}
                            />
                        </CTabPane>
                        <CTabPane>
                            <InternalMemo
                                tableData={allCommunications?.internalMemo}
                                tableHeader={TableHeaders}
                                selectedItem={selectedItem}
                                handleSelect={handleSelect}
                            />
                        </CTabPane>
                        <CTabPane>
                            <Notice
                                tableData={allCommunications?.notice}
                                tableHeader={TableHeaders}
                                selectedItem={selectedItem}
                                handleSelect={handleSelect}
                            />
                        </CTabPane>
                        {!url.includes('staff-arena') && (
                            <CTabPane>
                                <Mail tableData={allCommunications?.mail} tableHeader={TableHeaders} />
                            </CTabPane>
                        )}
                    </CTabContent>
                </CTabs>
                <ConfirmActionDialogue
                    show={openModal === 'publish'}
                    close={() => setOpenModal(null)}
                    svgIcon={<SendIcon />}
                    title={`Publish ${capitalizeEachWord(activeTab)}`}
                    subtitle={`This ${activeTab.toLocaleLowerCase()} will be published and sent to the distribution list(s).`}
                    bodyText={`To confirm, enter publish in the text field.`}
                    noStyle
                    input
                    placeholder="Publish"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    btn2Text="Yes, Publish"
                    bgColor="#10B981"
                    isLoading={isLoading}
                    confirmAction={handlePublish}
                    btnDisabled={value !== 'publish'}
                />
            </div>
        </div>
    );
};

export default ComposeCommunications;
