import React, { useEffect } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as HeaderIcon } from '../../../assets/icons/circularplus-blue.svg';
import { editBedSpace, getBedspaceById } from '../../../redux/hostel/action';
import { LoadingButton } from '../../buttons';
import TextInput from '../../inputs/text-input-with-formik';
import FormContainer from '../form-container';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end

`;

const EditBedspace = ({ show, setShow, setToogleRefetch, item }) => {
    const dispatch = useDispatch();
    const onClose = () => {
        setShow(false);
    };

    const { isLoading, bedspaceDetails } = useSelector((store) => store.hostel);

    useEffect(() => {
        if (item?._id) {
            dispatch(getBedspaceById(item?._id));
        }
    }, [dispatch, item?._id]);

    return (
        <>
            <FormContainer headerIcon={<HeaderIcon />} close={onClose} show={show} title={'Edit Bedspaces'}>
                <Formik
                    enableReinitialize
                    initialValues={{
                        bedSpaceName: bedspaceDetails?.bedSpaceName,
                    }}
                    validationSchema={ValidationSchema}
                    onSubmit={async (values, actions) => {
                        const res = await dispatch(
                            editBedSpace(values, item?._id, item?.roomId, item?.blockId, item?.hostelId),
                        );
                        if (res) {
                            actions.resetForm();
                            onClose();
                            setToogleRefetch(true);
                            toast.success('Bedspaces was updated successfully!');
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <div className="gap-10 flex flex-col">
                                <TextInput
                                    name="bedSpaceName"
                                    type="text"
                                    label="Bedspace Name"
                                    placeholder="Input Bedspace Name"
                                    value={values.bedSpaceName}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                                {/* 
                                <Select
                                    name="bedSpaceStatus"
                                    objProp="bedSpaceStatus"
                                    label="Bedspace Status"
                                    data={status.map((item) => ({ bedSpaceStatus: item }))}
                                    placeholder="Select Bedspace Status"
                                    onChange={(selected) => {
                                        setFieldValue('bedSpaceStatus', String(selected[0].bedSpaceStatus));
                                    }}
                                    error={errors.bedSpaceStatus}
                                    passedSelectedItems={values?.bedSpaceStatus ? values?.bedSpaceStatus : null}
                                /> */}
                            </div>

                            <FlexContainer>
                                <CancelButton type="button" onClick={onClose}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isLoading} disabled={isLoading} type="submit">
                                    Update Bedspace Name
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </FormContainer>
        </>
    );
};

export default EditBedspace;
