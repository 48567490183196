import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Download } from '../../../assets/icons/download-new.svg';
import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';
import { ReactComponent as OpenEye } from '../../../assets/icons/external-Link.svg';
import { ReactComponent as ThreeDots } from '../../../assets/icons/three-dots.svg';
import { ReactComponent as UploadedFile } from '../../../assets/icons/uploaded-file.svg';
import { ActionsPopup } from '../../../components';
import { Button } from '../../../components/buttons';
import { Text } from '../../../containers/MesssageContainers';
import {
    FlexCentredCol,
    FlexCentredRow,
    FlexRowSpaceBetween,
    RelativeContainer,
} from '../../../containers/ScreenContainers';
import { handleDownload, handleImagePreview } from '../../../utils';
// import { getStaffArenaDetails } from '../../../redux/staff-arena/actions';

const GridContainer = tw.div`
    grid
    grid-cols-2
    mt-[2.8rem]
    gap-x-[2.8rem]
`;

const UploadContainer = tw.div`
    mb-[2.8rem]
`;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
    mb-[1.5rem]
    mt-[3rem]
`;
const items = [
    {
        title: 'Recent Passport',
        objKey: 'passport',
    },
    {
        title: 'Letter of Appointment',
        objKey: 'appointment',
    },
    {
        title: 'Certificate of Origin*',
        objKey: 'origin',
    },
    {
        title: 'First Degree Certificate',
        objKey: 'firstDegree',
    },
    {
        title: 'Attestation Letter*',
        objKey: 'attestation',
    },
    {
        title: 'Second Degree Certificate',
        objKey: 'secondDegree',
    },
    {
        title: "O'Level Result*",
        objKey: 'olevel',
    },
    {
        title: 'Medical Certificate*',
        objKey: 'medical',
    },
];

const UploadsDetails = ({ uploads }) => {
    const navigate = useNavigate();
    const [openDropdown, setOpenDropdown] = useState(null);

    return (
        <>
            <Details>
                <FlexRowSpaceBetween>
                    <Text>Experience</Text>
                    <Button
                        border="1px solid #E5E7EB"
                        bgColor="#FFFFFF"
                        color="#6366F1"
                        onClick={() => navigate('/staff-arena/profile/edit', { state: { tab: 3 } })}
                    >
                        <Edit fill="#6366F1" />
                        Edit
                    </Button>
                </FlexRowSpaceBetween>
            </Details>
            <GridContainer>
                {items.map((item) => (
                    <UploadContainer>
                        <FlexCentredRow className="bg-white py-10 pl-10 text-center border-b-2">
                            <Text weight="600" size="1.6rem" lineHeight="2.8rem">
                                {item.title}
                            </Text>
                        </FlexCentredRow>

                        {uploads?.[item.objKey]?.map((itemObj, index) => (
                            <FlexRowSpaceBetween key={index} className="bg-white p-10 items-center">
                                <FlexCentredRow className="cursor-pointer w-full">
                                    <UploadedFile className="mr-[2.308rem]" />
                                    <FlexCentredCol>
                                        <Text weight="500" size="1.6rem" lineHeight="2.8rem" align="left">
                                            {itemObj?.fileName?.length > 10
                                                ? itemObj?.fileName.substring(0, 10) + '...'
                                                : itemObj?.fileName}
                                        </Text>
                                        <Text
                                            weight="500"
                                            align="left"
                                            color="#6B7280"
                                            size="1.4rem"
                                            lineHeight="2.4rem"
                                        >
                                            Size - {itemObj?.size}kb
                                        </Text>
                                    </FlexCentredCol>
                                </FlexCentredRow>
                                <RelativeContainer>
                                    <ThreeDots onClick={() => setOpenDropdown(itemObj)} />
                                    <ActionsPopup
                                        open={openDropdown === itemObj}
                                        close={() => setOpenDropdown(null)}
                                        items={[
                                            {
                                                icon: <Download fill="#1F2937" />,
                                                name: 'Download',
                                                click: () => {
                                                    const downloadUrl = itemObj?.url;
                                                    const fileName = itemObj?.fileName;

                                                    if (downloadUrl && fileName) {
                                                        handleDownload(downloadUrl, fileName);
                                                    }
                                                    setOpenDropdown(false);
                                                },
                                            },

                                            {
                                                icon: <OpenEye fill="#1F2937" />,
                                                name: 'Preview',
                                                click: () => {
                                                    handleImagePreview(itemObj?.url);
                                                    setOpenDropdown(false);
                                                },
                                            },
                                        ]}
                                    />
                                </RelativeContainer>
                            </FlexRowSpaceBetween>
                        ))}
                    </UploadContainer>
                ))}
            </GridContainer>
        </>
    );
};

export default UploadsDetails;
