import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PlusIcon } from '../../../assets/icons/circlePlus.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../containers/ScreenContainers';
import { configurePaymentGateway, getPaymentGatewayById, modifyPaymentGateway } from '../../../redux/bursary/actions';
import { GET_PAYMENT_GATEWAY } from '../../../redux/bursary/slice';
import { LoadingButton } from '../../buttons';
import Select from '../../inputs/new-select';
import ToggleSwitch from '../../inputs/switch';
import FormikTextInput from '../../inputs/text-input-with-formik';
import { CancelButton } from '../sharedStyles';

import { validationSchema } from './ValidationSchema';

const FlexContainer = tw.div`
flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;

const PaymentGateway = [
    { name: 'Paystack' },
    { name: 'Monnify' },
    { name: 'Flutterwave' },
    // { name: 'Remita', src: Remita, enabled: false },
];

const PaymentGatewayForm = ({ action }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { paymentGateway, isLoading } = useSelector((store) => store.bursary);

    const [active, setActive] = useState(false);
    const { state: gateway } = useLocation();
    const { _id: gatewayId } = gateway || {};

    useEffect(() => {
        if (paymentGateway !== null && action === 'edit') {
            setActive(paymentGateway?.active);
        } else {
            setActive(false);
        }
    }, [action, paymentGateway]);

    useEffect(() => {
        if (action === 'edit') {
            dispatch(getPaymentGatewayById(gatewayId));
        } else {
            dispatch(GET_PAYMENT_GATEWAY(null));
        }
    }, [dispatch, action, gatewayId]);

    return (
        <div>
            <div className="w-[800px]">
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        provider: paymentGateway?.provider || '',
                        businessName: paymentGateway?.businessName || '',
                        publicKey: paymentGateway?.publicKey || '',
                        secretKey: paymentGateway?.secretKey || '',
                        encryptKey: paymentGateway?.encryptKey || '',
                        contractCode: paymentGateway?.contractCode || '',
                        active: paymentGateway?.active || '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, actions) => {
                        values.active = active;

                        if (action === 'add') {
                            const res = await dispatch(configurePaymentGateway(values));
                            if (res) {
                                actions.resetForm();
                                navigate(-1);
                            }
                        } else {
                            const res = await dispatch(modifyPaymentGateway(values, gateway?._id));
                            if (res) {
                                actions.resetForm();
                                navigate(-1);
                            }
                        }
                    }}
                >
                    {({ values, errors, handleChange, setFieldValue, isSubmitting }) => (
                        <Form>
                            <div className="bg-white p-[3rem] rounded-[1.6rem]">
                                <FlexCentredRow className="gap-3 pb-[3rem]">
                                    {action === 'add' ? <PlusIcon fill="#6366F1" /> : <EditIcon />}
                                    <Text align="left" size="1.6rem" weight="600">
                                        {action === 'add' ? 'Add Gateway' : 'Edit Gateway'}
                                    </Text>
                                </FlexCentredRow>
                                <div className="gap-5 flex flex-col">
                                    <Select
                                        label="Provider"
                                        name="provider"
                                        objProp="name"
                                        data={PaymentGateway}
                                        placeholder="Provider"
                                        onChange={(selected) => {
                                            setFieldValue('provider', selected?.[0].name?.toLowerCase());
                                        }}
                                        passedSelectedItems={gateway?.provider}
                                    />
                                    {isLoading && action === 'edit' ? (
                                        <></>
                                    ) : (
                                        <>
                                            {values?.provider && (
                                                <>
                                                    <FormikTextInput
                                                        label="Business Name"
                                                        name="businessName"
                                                        type="text"
                                                        placeholder="Input Business Name"
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        value={values?.businessName}
                                                    />
                                                    <FormikTextInput
                                                        label="Live Public Key"
                                                        name="publicKey"
                                                        type="password"
                                                        placeholder="Input Public Key"
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        value={values?.publicKey}
                                                    />
                                                    <FormikTextInput
                                                        label="Live Secret Key"
                                                        name="secretKey"
                                                        type="password"
                                                        placeholder="Input Secret Key"
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        value={values?.secretKey}
                                                    />
                                                    {values?.provider?.toLowerCase() !== 'paystack' &&
                                                        values?.provider?.toLowerCase() !== 'remita' && (
                                                            <FormikTextInput
                                                                label={`Live ${
                                                                    values?.provider?.toLowerCase() === 'monnify'
                                                                        ? 'Contract Code'
                                                                        : values?.provider?.toLowerCase() ===
                                                                              'flutterwave' && 'Encrypt Key'
                                                                }`}
                                                                name={
                                                                    values?.provider?.toLowerCase() === 'monnify'
                                                                        ? 'contractCode'
                                                                        : 'encryptKey'
                                                                }
                                                                type="password"
                                                                placeholder={`Input ${
                                                                    values?.provider?.toLowerCase() === 'monnify'
                                                                        ? 'Contract Code'
                                                                        : values?.provider?.toLowerCase() ===
                                                                              'flutterwave' && 'Encrypt Key'
                                                                }`}
                                                                errors={errors}
                                                                onChange={(e) => {
                                                                    if (values?.provider?.toLowerCase() === 'monnify') {
                                                                        setFieldValue('contractCode', e.target.value);
                                                                    } else {
                                                                        setFieldValue('encryptKey', e.target.value);
                                                                    }
                                                                }}
                                                                value={
                                                                    values?.provider?.toLowerCase() === 'Monnify'
                                                                        ? values?.contractCode
                                                                        : values?.encryptKey
                                                                }
                                                            />
                                                        )}

                                                    <FlexCentredRow className="gap-4">
                                                        <Text>Enable Gateway</Text>

                                                        <ToggleSwitch
                                                            name="active"
                                                            checked={active}
                                                            setChecked={setActive}
                                                        />
                                                    </FlexCentredRow>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton loading={isSubmitting} type="submit">
                                    {action === 'add' ? 'Add Gateway' : 'Update Gateway'}
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default PaymentGatewayForm;
