import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { Button } from '../../../components/buttons';
import CreateCategory from '../../../components/forms/create-category';
import ListEmptyContent from '../../../components/list-empty';
import SuccessPopup from '../../../components/popups/success';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getAppointmentCatgories } from '../../../redux/appointments/actions';

const fields = [
    {
        header: 'Appointments',
        accessorKey: 'appointments',
        cell: ({ row }) => (
            <Link to={`/personnel/appointments/${row.original.id}`} style={{ color: '#2563EB' }}>
                {row.original.appointment}
            </Link>
        ),
    },
];

const Appointment = () => {
    const dispatch = useDispatch();

    const { category, isLoading } = useSelector((store) => store.appointment);

    const [showCreateCategory, setShowCreateCategory] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [refetch, setRefetch] = useState(false);

    const Data = category?.map((app) => {
        return {
            id: app._id,
            appointment: app.appointmentName,
        };
    });

    useEffect(() => {
        const getAllAppointments = async () => {
            await dispatch(getAppointmentCatgories());
        };

        getAllAppointments();
    }, [dispatch, refetch]);

    return (
        <>
            <PageLayout
                pageTitle="Appointments"
                onSecondaryButtonClick={() => setShowCreateCategory(true)}
                secondaryButtonText="Create Category"
                loading={isLoading}
                data={Data || []}
                fields={fields}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Appoinments " subtitle="Add Appoinments">
                            <CenteredContainer className="gap-x-4 lg:flex-row sm:gap-y-4 sm:flex-col">
                                <Button onClick={() => setShowCreateCategory(true)} bgColor="#10B981" color="#fff">
                                    <Circularplus className="mr-[1.15rem]" />
                                    Create Category
                                </Button>
                            </CenteredContainer>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
            />
            <CreateCategory show={showCreateCategory} setShow={setShowCreateCategory} setOpenSuccess={setOpenSuccess} />

            <SuccessPopup
                show={openSuccess}
                onClose={() => {
                    setOpenSuccess(false);
                    setRefetch((prev) => !prev);
                }}
                title="Category Added!"
                subtitle="A new category has been added"
                btnText="Close"
            />
        </>
    );
};

export default Appointment;
