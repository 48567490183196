import React, { useEffect, useState } from 'react';

import { Pagination, PaginationStatus } from '@belrald_hq/belrald-ui';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as CircularPlus } from '../../../assets/icons/circularplus-black.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
import ConfirmActionDialogueWithInput from '../../../components/popups/confirm-action-with-input';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { useTableContext } from '../../../context/tableContext';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { deleteHostel, getAllHostel } from '../../../redux/hostel/action';

const HostelManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, allHostelsData } = useSelector((state) => state.hostel);

    const [toggleRefetch, setToggleRefetch] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const [openModal, setOpenModal] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const { data: allHostels, pagination } = allHostelsData || {};
    const { totalHostels, totalPages } = pagination || {};

    const [currentPage, setCurrentPage] = useState('1');
    const { tableSettings } = useTableContext();
    const numOfPages = tableSettings?.numOfPages;

    const fields = [
        {
            cell: (props) => (
                <Link
                    to={`/student-affairs/accomodation-management/${props.row.original._id}`}
                    state={{
                        hostelName: props.row.original.hostelName,
                        _id: props.row.original._id,
                        hostelLogo: props.row.original.hostelLogo,
                        hostelPartitionName: props.row.original.hostelPartitionName,
                        gender: props.row.original.gender,
                    }}
                    style={{ color: '#2563EB', cursor: 'pointer' }}
                >
                    {props?.getValue()}
                </Link>
            ),
            header: 'Hostel Name',
            accessorKey: 'hostelName',
        },

        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'Gender',
            accessorKey: 'gender',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'No of Blocks',
            accessorKey: 'totalBlocks',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'No of Rooms',
            accessorKey: 'totalRooms',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'No of Bedspaces',
            accessorKey: 'totalBedspaces',
        },
    ];

    const handleDelete = async () => {
        if (selectedItem?.hostelName !== inputValue) return toast.error('Confirmation text does not match');

        const check = selectedItem?.hostelName !== inputValue;
        if (!check) {
            const res = await dispatch(deleteHostel(selectedItem?._id));
            if (res) {
                toast.success('Hostel deleted successfully!');
                setToggleRefetch((prevState) => !prevState);
                setOpenModal(false);
            }
        }
    };

    useEffect(() => {
        const query = {
            search: debouncedSearchValue,
            page: currentPage,
            limit: numOfPages,
        };
        dispatch(getAllHostel(query));
    }, [dispatch, debouncedSearchValue, toggleRefetch, currentPage, numOfPages]);

    return (
        <>
            <PageLayout
                pageTitle="Accomodation Management"
                secondaryButtonText="Add New hostel"
                secondaryButtonIcon={<CircularPlus />}
                actionType="radio"
                onSelectedItemsChange={(items) => setSelectedItem(items[0])}
                onSecondaryButtonClick={() => navigate('/student-affairs/accomodation-management/create-hostel')}
                onActionBtnClick={() => setOpenModal('actions')}
                actionOpen={openModal === 'actions'}
                actionClose={() => setOpenModal(null)}
                actionItems={[
                    {
                        icon: <EditIcon />,
                        name: 'Edit Hostel',
                        disabled: !selectedItem,
                        click: () =>
                            navigate('/student-affairs/accomodation-management/edit-hostel', {
                                state: { selectedHostel: selectedItem || {} },
                            }),
                    },
                    {
                        icon: <DeleteIcon />,
                        name: 'Delete Hostel',
                        disabled: !selectedItem,
                        click: () => setOpenModal('delete'),
                    },
                ]}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                searchValue={searchValue}
                onSearchClose={() => setSearchValue('')}
                showTableUtils
                showFilter
                data={allHostels || []}
                fields={fields}
                loading={isLoading}
                width={'100%'}
                isActionable
                openFilterPop={openModal === 'options-popup-main'}
                closeFilterPop={() => setOpenModal(null)}
                isPaginated={true}
                paginationComponent={
                    <FlexRowSpaceBetween className="pt-[1.6rem] px-[1.6rem]">
                        <PaginationStatus
                            currentPage={currentPage}
                            itemsPerPage={tableSettings?.numOfPages}
                            totalItems={totalHostels}
                        />
                        <Pagination
                            pageRangeDisplayed={3}
                            totalPages={totalPages}
                            onPageChange={(nextPage) => {
                                setCurrentPage(nextPage);
                            }}
                            currentPage={currentPage}
                        />
                    </FlexRowSpaceBetween>
                }
            />

            <ConfirmActionDialogueWithInput
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete hostel?"
                subtitle="This hostel will be completely deleted along with the data attached to it."
                subtitle2="To confirm deletion, enter the name of the Hostel in the text field."
                confirmAction={() => handleDelete()}
                btn2Text="Yes, Delete"
                setInputValue={setInputValue}
                value={inputValue}
                isLoading={isLoading}
                btnDisabled={inputValue !== selectedItem?.hostelName}
                placeholder="Input name of hostel"
            />
        </>
    );
};
export default HostelManagement;
