import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import {
    LOADING,
    STOP_LOADING,
    GET_FACULTY_PROGRAMMES,
    GET_DEPARTMENT_PROGRAMMES,
    GET_PROGRAMME_STUDENTS,
} from './slice';

export const createProgramme =
    (payload, useLoader = true) =>
    async (dispatch, getState) => {
        if (useLoader) dispatch(LOADING());
        try {
            const response = await axiosInstance.post(
                `${currentAPI}program/:campusId/:unitId/create_program/`,
                payload,
            );
            if (response.status === 200 || response.status === 201) {
                return true;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
            toast.error(errorMessage);
        } finally {
            dispatch(STOP_LOADING());
        }
    };

export const getFacultyProgrammes = (facultyId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}program/:campusId/:unitId/get_program_by_faculty/${facultyId}`,
        );
        if (response.status === 200) {
            dispatch(GET_FACULTY_PROGRAMMES(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getDepartmentProgrammes = (departmentId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}program/:campusId/:unitId/get_program_by_department/${departmentId}`,
        );
        if (response.status === 200) {
            dispatch(GET_DEPARTMENT_PROGRAMMES(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An errorr occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getProgrammeStudents = (structureId, thirdDegreeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}students/:campusId/:unitId/get_all_students_last_degree/${structureId}/${thirdDegreeId}`,
        );
        if (response.status === 200) {
            dispatch(GET_PROGRAMME_STUDENTS(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editProgramme = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}program/:campusId/:unitId/edit_program/${id}`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editThirdDegree = (structureId, thirdDegreeId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}structure/:campusId/:unitId/edit_third_degree/${structureId}/${thirdDegreeId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteProgramme = (id) => async (dispatch, getState) => {
    try {
        const response = await axiosInstance.delete(`${currentAPI}program/:campusId/:unitId/delete_program/${id}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    }
};
