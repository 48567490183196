import React from 'react';

import PageLayout from '../../../layout/page-layout/PageLayout';

import { getOrgRoleTypeName } from './utils';

const fields = [
    {
        header: 'Staff Name',
        accessorKey: 'staffName',
        cell: ({ row }) => (
            <p>{row?.original?.surname + ' ' + row?.original?.firstName + ' ' + row?.original?.otherName}</p>
        ),
    },
    {
        header: 'Staff Number',
        accessorKey: 'staffNumber',
    },
    {
        header: 'Staff Email',
        accessorKey: 'staffEmail',
    },
    {
        header: 'Number of Campuses',
        accessorKey: 'campuses',
        cell: (props) => <span>{props.row?.original?.campusId?.length}</span>,
    },
    {
        header: 'Organization Role',
        accessorKey: 'organizationRoleType',
        cell: (props) => <span>{getOrgRoleTypeName(props.getValue())}</span>,
    },
    {
        header: 'Archived by',
        accessorKey: 'addedBy',
        cell: (props) => <span>{props.row?.original?.creationInfo?.createdBy}</span>,
    },
    {
        header: 'Archived at',
        accessorKey: 'createdAt',
        cell: (props) => (
            <p>
                {props.getValue()
                    ? `${new Date(props.getValue()).toLocaleDateString()}, ${new Date(
                          props.getValue(),
                      ).toLocaleTimeString()}`
                    : ''}
            </p>
        ),
    },
];

const ArchivedStaff = ({ organizationStaffs, setSelectedItem, setSearchValue, isLoading, searchValue }) => {
    return (
        <div>
            <PageLayout
                noMargin
                fields={fields}
                data={organizationStaffs || []}
                onSelectedItemsChange={(items) => setSelectedItem(items[0])}
                showTableUtils
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                isActionable
                actionType="radio"
                loading={isLoading}
            />
        </div>
    );
};

export default ArchivedStaff;
