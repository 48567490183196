import React, { useEffect, useReducer } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { LoadingButton } from '../../../../components/buttons';
import { CancelButton } from '../../../../components/forms/sharedStyles';
import MultiSelect from '../../../../components/inputs/multi-select';
import TextInput from '../../../../components/inputs/text-input';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexColumn } from '../../../../containers/ScreenContainers';
import { distributionListReducer, initialState } from '../../../../reducers/distributionReducer';
import { getCollegeSecondLevel, getCollegeThirdLevel } from '../../../../redux/college/actions';
import { addDistributionList, getAllColleges, getAllFaculties } from '../../../../redux/distribution-list/actions';
import { getFacultySecondLevel, getFacultyThirdLevel } from '../../../../redux/faculty/actions';
import { capitalizeEachWord } from '../../../../utils';

const Container = tw.div`
mt-[2.4rem]
shadow-lg
rounded-t-lg
bg-[white]
pt-[2.4rem]
px-[2.4rem]
pb-[3.7rem]
`;
const FlexContainer = tw.div`
    flex
    p-[2.4rem]
    bg-white
    items-center
    gap-[2.4rem]
   mt-[0.1rem]
    justify-end
`;
const LeftContainer = tw.div`
w-[70.8rem]
bg-white
p-[4rem]
rounded-[8px]
mt-[2.1rem]
`;
const RightContainer = tw.div`
w-[35%]
`;
const MainContainer = tw.div`
flex
flex-row
gap-[2rem]
`;
const TagItems = tw.div`
bg-[#6366F1]
flex
justify-center
items-center
text-[1.2rem]
text-white
rounded
px-2
py-1
gap-4
w-max
h-[2.5rem]
`;

const AddDistributionList = ({ title }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const unitName = sessionStorage.getItem('unitName');

    const { savedAcademicStructure } = useSelector((store) => store.academicStructure);
    const { allFaculties, allColleges } = useSelector((store) => store.distributionList);
    const { filteredActiveOrgUnits } = useSelector((store) => store.organisationUnits);
    const { isLoading: collegeLoading } = useSelector((store) => store.college);
    const { isLoading: facultyLoading, currentFaculty } = useSelector((store) => store.faculty);
    const { currentDepartment } = useSelector((store) => store.department);
    const location = useLocation();

    const current_location = location?.pathname?.includes('department')
        ? currentDepartment
        : location?.pathname?.includes('faculties')
        ? currentFaculty
        : { unitName };

    const organizationAcademicStructures = Object.keys(savedAcademicStructure || {}).map((item) => ({
        name: capitalizeEachWord(item),
    }));

    const orgUnitsToShow = filteredActiveOrgUnits?.map((item) => {
        return {
            name: item?.unitName,
            ...item,
        };
    });

    const [state, setState] = useReducer(distributionListReducer, initialState);

    const {
        selectedStudentFirstDegree,
        selectedTeachingFirstDegree,
        selectedAcademicFirstDegree,
        selectedNonteachingFirstDegree,
        selectedStudentSecondDegree,
        selectedAcademicSecondDegree,
        selectedTeachingSecondDegree,
        selectedNonTeachingSecondDegree,
        selectedStudentLevels,
        selectedNonTeachingLevels,
        selectedTeachingStaffLevels,
        selectedAcademicLevels,
        studentSelection,
        studentsPayload,
        studentSecondDegree,
        nonTeachingSecondDegree,
        teachingSecondDegree,
        academicSecondDegree,
        nonAcademicUnitPayload,
        academicUnitSelection,
        academicUnitPayload,
        distributionListName,
        distributionListDesc,
        studentSecondLevelData,
        teachingSecondLevelData,
        nonTeachingSecondLevelData,
        academicSecondLevelData,
        selectedTeachingStructure,
        selectedStudentStructure,
        selectedNonTeachingStructure,
        selectedAcademicStructure,
        studentAcademicLevels,
        nonTeachingAcademicLevels,
        teachingAcademicLevels,
        studentThirdLevelData,
        nonTeachingThirdLevelData,
        teachingThirdLevelData,
        academicUnitLevels,
        academicThirdLevelData,
        teachingStaffSelection,
        teachingStaffPayload,
        nonTeachingStaffSelection,
        nonTeachingStaffPayload,
        academicThirdDegree,
        teachingThirdDegree,
        nonTeachingThirdDegree,
        studentThirdDegree,
        nonAcademicUnitSelection,
        nonTeachingStaffUnitsSelection,
    } = state;

    const fetchSecondLevelData = async (firstDegree, setSelectedStructure, setSecondLevelData) => {
        const academicStructure = firstDegree?.academicStructure;
        const structure = academicStructure?.split('-');
        setState({ type: 'SET_VALUE', field: setSelectedStructure, value: structure?.length });
        const secondLevel = structure?.[0];
        const params = {
            academicStructureId: firstDegree?.academicStructureId,
            firstDegreeId: firstDegree?._id,
            structTwo: structure?.[1],
        };
        if (secondLevel === 'college') {
            const res = await dispatch(getCollegeSecondLevel(params));
            setState({ type: 'SET_VALUE', field: setSecondLevelData, value: res });
            // setState({ type: 'SET_VALUE', field: setSelectedStructure, value: '' });
        } else if (secondLevel === 'faculty') {
            const res = await dispatch(getFacultySecondLevel(params));
            setState({ type: 'SET_VALUE', field: setSecondLevelData, value: res });
            // setState({ type: 'SET_VALUE', field: setSelectedStructure, value: '' });
        } else {
            return;
        }
    };

    const fetchStudentThirdLevelData = async (firstDegree, setThirdLevelData) => {
        const academicStructure = firstDegree?.academicStructure;
        const structure = academicStructure?.split('-');

        let secondLevel;
        secondLevel = structure?.[0];

        const academicStructureId = firstDegree?.academicStructureId;
        const firstDegreeId = firstDegree?._id;

        if (secondLevel === 'college') {
            const res = await dispatch(getCollegeThirdLevel(academicStructureId, firstDegreeId));
            setState({ type: 'SET_VALUE', field: setThirdLevelData, value: res });
        } else if (secondLevel === 'faculty') {
            const res = await dispatch(getFacultyThirdLevel(academicStructureId, firstDegreeId));
            setState({ type: 'SET_VALUE', field: setThirdLevelData, value: res });
        } else {
            return;
        }
    };

    useEffect(() => {
        if (selectedStudentStructure !== 3) return;
        fetchStudentThirdLevelData(selectedStudentFirstDegree, 'studentThirdLevelData');
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStudentFirstDegree, selectedStudentStructure]);

    useEffect(() => {
        if (selectedAcademicStructure !== 3) return;
        fetchStudentThirdLevelData(selectedAcademicFirstDegree, 'academicThirdLevelData');
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcademicFirstDegree, selectedAcademicStructure]);

    useEffect(() => {
        if (selectedNonTeachingStructure !== 3) return;
        fetchStudentThirdLevelData(selectedNonteachingFirstDegree, 'nonTeachingThirdLevelData');
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNonteachingFirstDegree, selectedNonTeachingStructure]);

    useEffect(() => {
        if (selectedTeachingStructure !== 3) return;
        fetchStudentThirdLevelData(selectedTeachingFirstDegree, 'teachingThirdLevelData');
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeachingFirstDegree, selectedTeachingStructure]);

    useEffect(() => {
        if (!selectedStudentFirstDegree) return;
        fetchSecondLevelData(selectedStudentFirstDegree, 'selectedStudentStructure', 'studentSecondLevelData');
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStudentFirstDegree]);

    useEffect(() => {
        if (!selectedNonteachingFirstDegree) return;
        fetchSecondLevelData(
            selectedNonteachingFirstDegree,
            'selectedNonTeachingStructure',
            'nonTeachingSecondLevelData',
        );
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedNonteachingFirstDegree]);

    useEffect(() => {
        if (!selectedTeachingFirstDegree) return;
        fetchSecondLevelData(selectedTeachingFirstDegree, 'selectedTeachingStructure', 'teachingSecondLevelData');
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeachingFirstDegree]);

    useEffect(() => {
        if (!selectedAcademicFirstDegree) return;
        fetchSecondLevelData(selectedAcademicFirstDegree, 'selectedAcademicStructure', 'academicSecondLevelData');
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcademicFirstDegree]);

    const handleSelectFirstDegree = (selected, setSelectedFirstDegree, setSelectedStructure) => {
        if (selected?.length > 0) {
            const firstDegree = selected?.[0];
            const struct = firstDegree?.academicStructure;
            const structLength = struct?.split('-');
            setState({ type: 'SET_VALUE', field: setSelectedStructure, value: structLength?.length });
            setState({ type: 'SET_VALUE', field: setSelectedFirstDegree, value: firstDegree });
        }
    };

    const updateOrCreateSecondDegreeKey = (key, value, setSelectedSecondDegree) => {
        setState({
            type: 'UPDATE_OR_CREATE_SECOND_DEGREE_KEY',
            field: setSelectedSecondDegree,
            key,
            value,
        });
    };

    const handleInputChange = (e, field) => {
        setState({ type: 'SET_VALUE', field, value: e.target.value });
    };

    const fetchDataForItem = (item) => {
        if (item?.toLowerCase() === 'faculty') {
            dispatch(getAllFaculties());
        } else if (item?.toLowerCase() === 'college') {
            dispatch(getAllColleges());
        } else {
            return;
        }
        // Add more conditions as needed
    };

    useEffect(() => {
        organizationAcademicStructures.forEach((item) => fetchDataForItem(item?.name));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelection = (field, value) => {
        setState({
            type: 'ADD_TO_SELECTION',
            field: field,
            value: value,
        });
    };

    useEffect(() => {
        const nonTeachingStaffPayload = nonTeachingStaffSelection?.map((student) => {
            return {
                structureId: student?.structureId,
                collegeId: student?.firstDegreeId,
                firstdegreeIds: student?.firstDegreeId,
                middleId: student.middleId ? student?.middleId : null,
                subProgramIds: student?.subProgramIds,
                // level: student?.levelId?.map((item) => item),
            };
        });
        setState({ type: 'SET_VALUE', field: 'nonTeachingStaffPayload', value: nonTeachingStaffPayload });
    }, [nonTeachingStaffSelection]);

    useEffect(() => {
        const teachingStaffPayload = teachingStaffSelection?.map((student) => {
            return {
                structureId: student?.structureId,
                collegeId: student?.firstDegreeId,
                firstdegreeIds: student?.firstDegreeId,
                middleId: student.middleId ? student?.middleId : null,
                subProgramIds: student?.subProgramIds,
                // level: student?.levelId?.map((item) => item),
            };
        });
        setState({ type: 'SET_VALUE', field: 'teachingStaffPayload', value: teachingStaffPayload });
    }, [teachingStaffSelection]);

    useEffect(() => {
        const studentsPayload = studentSelection?.map((student) => {
            return {
                structureId: student?.structureId,
                collegeId: student?.firstDegreeId,
                firstdegreeIds: student?.firstDegreeId,
                middleId: student.middleId ? student?.middleId : null,
                subProgramIds: student?.subProgramIds,
                level: student?.levelId?.filter((level) => level !== 'Select All')?.map((item) => item),
            };
        });
        setState({ type: 'SET_VALUE', field: 'studentsPayload', value: studentsPayload });
    }, [studentSelection]);

    useEffect(() => {
        const academicPayload = academicUnitSelection?.map((academic) => {
            return {
                structureId: academic?.structureId,
                collegeId: academic?.firstDegreeId,
                firstdegreeIds: academic?.firstDegreeId,
                middleId: academic?.middleId ? academic?.middleId : null,
                subProgramIds: academic?.subProgramIds,
                // level: academic?.levelId?.map((item) => item),
            };
        });
        setState({ type: 'SET_VALUE', field: 'academicUnitPayload', value: academicPayload });
    }, [academicUnitSelection]);

    const removeNullValues = (obj) => {
        return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== null));
    };

    const createDistributionList = async () => {
        const payload = {
            distributionName: distributionListName,
            unitName: current_location?.unitName || current_location?.departmentName || current_location?.facultyName,
            description: distributionListDesc,
            students: studentsPayload?.length > 0 ? studentsPayload : null,
            academicUnits: academicUnitPayload?.length > 0 ? academicUnitPayload : null,
            nonAcademicUnits:
                nonAcademicUnitPayload?.length > 0
                    ? {
                          units: nonAcademicUnitPayload?.map((item) => item?._id),
                      }
                    : null,
            teachingStaffs: teachingStaffPayload?.length > 0 ? teachingStaffPayload : null,
            nonteachingStaffs: nonTeachingStaffPayload?.length > 0 ? nonTeachingStaffPayload : null,
        };
        const formattedPayload = removeNullValues(payload);
        const res = await dispatch(addDistributionList(formattedPayload));
        if (res) {
            navigate(-1);
        }
    };

    const handleRemoveSelection = (i) => {
        setState({ type: 'REMOVE_FROM_SELECTION', field: 'studentSelection', index: i });
    };

    const handleRemoveOrgSelection = (i) => {
        setState({ type: 'REMOVE_FROM_SELECTION', field: 'nonAcademicUnitSelection', index: i });
    };

    const handleRemoveAcadSelection = (i) => {
        setState({ type: 'REMOVE_FROM_SELECTION', field: 'academicUnitSelection', index: i });
    };

    const handleRemoveTeachSelection = (i) => {
        setState({ type: 'REMOVE_FROM_SELECTION', field: 'teachingStaffSelection', index: i });
    };

    const handleRemoveNonTeachSelection = (i) => {
        setState({ type: 'REMOVE_FROM_SELECTION', field: 'nonTeachingStaffSelection', index: i });
    };

    const handleRemoveNonOrgSelection = (i) => {
        setState({ type: 'REMOVE_FROM_SELECTION', field: 'nonTeachingStaffUnitsSelection', index: i });
    };

    console.log('acaU', academicUnitSelection);

    console.log('acaId', academicSecondDegree);

    return (
        <div className="overflow-y-auto h-full scrollbar-hide">
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer">
                <BackArrow className="mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    {title}
                </PageTitle>
                <Text left="1rem" size="1.6rem">
                    / Distribution List
                </Text>
                <Text left="1rem" size="1.6rem">
                    / Add Distribution List
                </Text>
            </FlexCentredRow>

            <PageTitle align="left" top="2rem">
                Add Distribution List
            </PageTitle>
            <MainContainer>
                <LeftContainer>
                    <div className="w-[100%] flex flex-col gap-[2.4rem]">
                        <div className="">
                            <Text align="left" size="1.6rem" lineHeight="2.4rem" weight="600">
                                Choose a category or sub-category
                            </Text>
                            <hr className="my-[2.4rem]" />
                        </div>
                        <TextInput
                            name="name"
                            label="Name"
                            placeholder="Input Distribution Name"
                            onChange={(e) => handleInputChange(e, 'distributionListName')}
                            labelClass="font-semibold text-[1.6rem] leading-[2.8rem] text-[#1F2937]"
                            errors="name"
                        />
                        <TextInput
                            type="text"
                            as="textarea"
                            name="description"
                            label="Description (optional)"
                            placeholder="Input Description"
                            className="py-[1.5rem]"
                            onChange={(e) => handleInputChange(e, 'distributionListDesc')}
                            height="8rem"
                            labelClass="font-semibold text-[1.6rem] flex items-start justify-start leading-[2.8rem] text-[#1F2937]"
                            errors="description"
                        />
                        <Text align="left" size="1.6rem" weight="600" top="2rem">
                            Students
                        </Text>

                        <div className="ml-[4.5rem] flex flex-col gap-[1.8rem]">
                            {organizationAcademicStructures?.map((item) => (
                                <FlexCentredRow key={item?.name} className="gap-[1.8rem]">
                                    <label className="font-semibold text-[14px]">{item?.name}</label>
                                    <MultiSelect
                                        searchable
                                        containerClass="w-[25rem] relative"
                                        objProp="facultyName"
                                        placeholder={`Choose ${item?.name}`}
                                        onChange={(selected) => {
                                            handleSelectFirstDegree(
                                                selected,
                                                'selectedStudentFirstDegree',
                                                'selectedStudentStructure',
                                            );
                                        }}
                                        data={item?.name === 'College' ? allColleges : allFaculties}
                                        secondLevelList={studentSecondLevelData?.map((item) => ({
                                            id: item?._id,
                                            name: item?.departmentName,
                                            levels: item?.levels?.map((level) => ({
                                                id: level,
                                                name: `${level} Level`,
                                            })),
                                        }))}
                                        onButtonClick={() => {
                                            handleSelection('studentSelection', {
                                                structureId: selectedStudentFirstDegree?.academicStructureId,
                                                firstDegree: selectedStudentFirstDegree?.facultyName,
                                                secondDegree: studentSecondDegree?.name,
                                                level: selectedStudentLevels?.map((item) => item?.name),
                                                middleId:
                                                    selectedStudentStructure === 3 ? studentSecondDegree?.id : null,
                                                subProgramIds: selectedStudentSecondDegree,
                                                firstDegreeId: selectedStudentFirstDegree?._id,
                                                levelId: selectedStudentLevels?.map((item) => item?.id),
                                                thirdDegree: studentThirdDegree,
                                            });
                                            setState({
                                                type: 'SET_VALUE',
                                                field: 'selectedStudentLevels',
                                                value: [],
                                            });
                                        }}
                                        setSelectedAcademicLevels={(levels) =>
                                            setState({
                                                type: 'SET_VALUE',
                                                field: 'selectedStudentLevels',
                                                value: levels,
                                            })
                                        }
                                        selectedLevels={selectedStudentLevels}
                                        selectedAcademicLevels={selectedStudentLevels}
                                        formType="student"
                                        structure={selectedStudentStructure}
                                        secondLevelLoading={collegeLoading || facultyLoading}
                                        onSecondLevelChange={(item) => {
                                            const newLevels = item?.levels?.sort((a, b) => a.id - b.id);
                                            newLevels?.unshift({ id: 'Select All', name: 'Select All' });
                                            setState({
                                                type: 'SET_VALUE',
                                                field: 'studentAcademicLevels',
                                                value: newLevels,
                                            });
                                            setState({
                                                type: 'SET_VALUE',
                                                field: 'studentSecondDegree',
                                                value: item,
                                            });
                                            updateOrCreateSecondDegreeKey(
                                                selectedStudentFirstDegree?._id,
                                                item?.id,
                                                'selectedStudentSecondDegree',
                                            );
                                        }}
                                        academicLevels={studentAcademicLevels}
                                        thirdLevelList={studentThirdLevelData?.map((item) => ({
                                            id: item?._id,
                                            name: item?.departmentName ?? item?.department ?? item?.subprogram,
                                        }))}
                                        onThirdLevelChange={(selected) => {
                                            setState({
                                                type: 'SET_VALUE',
                                                field: 'studentThirdDegree',
                                                value: selected,
                                            });
                                        }}
                                        showValues={false}
                                    />
                                </FlexCentredRow>
                            ))}
                        </div>
                        <FlexColumn className="gap-[3rem]">
                            <Text align="left" size="1.6rem" weight="600" top="2rem">
                                Organization Units
                            </Text>
                            <FlexCentredRow className="gap-[1.8rem] ml-[4.5rem]">
                                <label className="font-normal text-[14px]">Non Academic Units</label>
                                <MultiSelect
                                    searchable
                                    containerClass="w-[25rem]"
                                    objProp="name"
                                    multiSelect
                                    placeholder="Choose Units"
                                    onChange={(selected) => {
                                        setState({
                                            type: 'SET_VALUE',
                                            field: 'nonAcademicUnitPayload',
                                            value: selected,
                                        });
                                    }}
                                    onButtonClick={() => {
                                        const filteredNonAcadUnits = nonAcademicUnitPayload?.filter(
                                            (item) => item?.id !== 'Select All',
                                        );
                                        handleSelection('nonAcademicUnitSelection', filteredNonAcadUnits);
                                    }}
                                    data={[{ name: 'Select All', id: 'Select All' }, ...orgUnitsToShow]}
                                    singleSelect
                                    showValues={false}
                                />
                            </FlexCentredRow>
                            <div className="ml-[4.5rem]">
                                <label className="font-normal text-[14px]">Academic Units</label>
                                <FlexColumn className="gap-[1.8rem]">
                                    {organizationAcademicStructures?.map((item) => (
                                        <FlexCentredRow key={item?.name} className="gap-[1.8rem] ml-[3rem]">
                                            <label className="font-semibold text-[14px]">{item?.name}</label>
                                            <MultiSelect
                                                searchable
                                                containerClass="w-[25rem] relative"
                                                objProp="facultyName"
                                                placeholder={`Choose ${item?.name}`}
                                                onChange={(selected) =>
                                                    handleSelectFirstDegree(
                                                        selected,
                                                        'selectedAcademicFirstDegree',
                                                        'selectedAcademicStructure',
                                                    )
                                                }
                                                data={item?.name === 'College' ? allColleges : allFaculties}
                                                secondLevelList={academicSecondLevelData?.map((item) => ({
                                                    id: item?._id,
                                                    name: item?.departmentName,
                                                    levels: item?.levels?.map((level) => ({
                                                        id: level,
                                                        name: `${level} Level`,
                                                    })),
                                                }))}
                                                selectedThirdLevel={academicThirdDegree}
                                                onButtonClick={() => {
                                                    handleSelection('academicUnitSelection', {
                                                        structureId: selectedAcademicFirstDegree?.academicStructureId,
                                                        firstDegree: selectedAcademicFirstDegree?.facultyName,
                                                        secondDegree: academicSecondDegree,
                                                        level: selectedStudentLevels?.map((item) => item?.name),
                                                        subProgramIds: selectedAcademicSecondDegree,
                                                        firstDegreeId: selectedAcademicFirstDegree?._id,
                                                        levelId: selectedAcademicLevels?.map((item) => item?.id),
                                                        middleId:
                                                            selectedAcademicStructure === 3
                                                                ? academicSecondDegree?.[0]?.id
                                                                : null,
                                                        thirdDegree: academicThirdDegree,
                                                    });
                                                    setState({
                                                        type: 'SET_VALUE',
                                                        field: 'academicThirdDegree',
                                                        value: [],
                                                    });
                                                }}
                                                setSelectedAcademicLevels={(levels) =>
                                                    setState({
                                                        type: 'SET_VALUE',
                                                        field: 'selectedAcademicLevels',
                                                        value: levels,
                                                    })
                                                }
                                                showLevels={false}
                                                selectedAcademicLevels={selectedAcademicLevels}
                                                structure={selectedAcademicStructure}
                                                secondLevelLoading={collegeLoading || facultyLoading}
                                                onSecondLevelChange={(item) => {
                                                    const newLevels = item?.levels?.sort((a, b) => a.id - b.id);
                                                    newLevels?.unshift({ id: 'Select All', name: 'Select All' });
                                                    setState({
                                                        type: 'SET_VALUE',
                                                        field: 'academicUnitLevels',
                                                        value: newLevels,
                                                    });
                                                    setState({
                                                        type: 'SET_VALUE',
                                                        field: 'academicSecondDegree',
                                                        value: [item],
                                                    });
                                                    updateOrCreateSecondDegreeKey(
                                                        selectedAcademicFirstDegree?._id,
                                                        item?.id,
                                                        'selectedAcademicSecondDegree',
                                                    );
                                                }}
                                                selectedSecondLevel={academicSecondDegree}
                                                academicLevels={academicUnitLevels}
                                                thirdLevelList={academicThirdLevelData?.map((item) => ({
                                                    id: item?._id,
                                                    name: item?.departmentName ?? item?.department ?? item?.subprogram,
                                                }))}
                                                onThirdLevelChange={(selected) => {
                                                    const isSelected = academicThirdDegree?.find(
                                                        (item) => item?.id === selected?.id,
                                                    );
                                                    let selectedArray = [];
                                                    if (isSelected) {
                                                        const filteredArr = selectedArray?.filter(
                                                            (item) => item?.id !== selected?.id,
                                                        );
                                                        selectedArray = filteredArr;
                                                    } else {
                                                        selectedArray.push(...academicThirdDegree, selected);
                                                    }

                                                    console.log('selA', selectedArray);
                                                    setState({
                                                        type: 'SET_VALUE',
                                                        field: 'academicThirdDegree',
                                                        value: selectedArray,
                                                    });
                                                }}
                                                showValues={false}
                                            />
                                        </FlexCentredRow>
                                    ))}
                                </FlexColumn>
                            </div>
                        </FlexColumn>
                        <FlexColumn className="gap-[3rem]">
                            <Text align="left" size="1.6rem" weight="600" top="2rem">
                                Teaching Staff
                            </Text>
                            <FlexColumn className="gap-[1.8rem]">
                                {organizationAcademicStructures?.map((item) => (
                                    <FlexCentredRow key={item?.name} className="gap-[1.8rem] ml-[3rem]">
                                        <label className="font-semibold text-[14px]">{item?.name}</label>
                                        <MultiSelect
                                            showLevels={false}
                                            searchable
                                            containerClass="w-[25rem] relative"
                                            objProp="facultyName"
                                            placeholder={`Choose ${item?.name}`}
                                            onChange={(selected) =>
                                                handleSelectFirstDegree(
                                                    selected,
                                                    'selectedTeachingFirstDegree',
                                                    'selectedTeachingStructure',
                                                )
                                            }
                                            data={item?.name === 'College' ? allColleges : allFaculties}
                                            secondLevelList={teachingSecondLevelData?.map((item) => ({
                                                id: item?._id,
                                                name: item?.departmentName,
                                                levels: item?.levels?.map((level) => ({
                                                    id: level,
                                                    name: `${level} Level`,
                                                })),
                                            }))}
                                            selectedSecondLevel={teachingSecondDegree}
                                            selectedThirdLevel={teachingThirdDegree}
                                            onButtonClick={() => {
                                                handleSelection('teachingStaffSelection', {
                                                    structureId: selectedTeachingFirstDegree?.academicStructureId,
                                                    firstDegree: selectedTeachingFirstDegree?.facultyName,
                                                    secondDegree: teachingSecondDegree,
                                                    level: selectedTeachingStaffLevels?.map((item) => item?.name),
                                                    subProgramIds: selectedTeachingSecondDegree,
                                                    firstDegreeId: selectedTeachingFirstDegree?._id,
                                                    levelId: selectedTeachingStaffLevels?.map((item) => item?.id),
                                                    middleId:
                                                        selectedTeachingStructure === 3
                                                            ? teachingSecondDegree?.id
                                                            : null,
                                                    thirdDegree: teachingThirdDegree,
                                                });
                                                setState({
                                                    type: 'SET_VALUE',
                                                    field: 'teachingThirdDegree',
                                                    value: [],
                                                });
                                            }}
                                            setSelectedAcademicLevels={(levels) =>
                                                setState({
                                                    type: 'SET_VALUE',
                                                    field: 'selectedTeachingStaffLevels',
                                                    value: levels,
                                                })
                                            }
                                            selectedAcademicLevels={selectedTeachingStaffLevels}
                                            structure={selectedTeachingStructure}
                                            secondLevelLoading={collegeLoading || facultyLoading}
                                            onSecondLevelChange={(item) => {
                                                const newLevels = item?.levels?.sort((a, b) => a.id - b.id);
                                                newLevels?.unshift({ id: 'Select All', name: 'Select All' });
                                                setState({
                                                    type: 'SET_VALUE',
                                                    field: 'teachingAcademicLevels',
                                                    value: newLevels,
                                                });
                                                setState({
                                                    type: 'SET_VALUE',
                                                    field: 'teachingSecondDegree',
                                                    value: [item],
                                                });
                                                updateOrCreateSecondDegreeKey(
                                                    selectedTeachingFirstDegree?._id,
                                                    item?.id,
                                                    'selectedTeachingSecondDegree',
                                                );
                                            }}
                                            academicLevels={teachingAcademicLevels}
                                            thirdLevelList={teachingThirdLevelData?.map((item) => ({
                                                id: item?._id,
                                                name: item?.departmentName ?? item?.department ?? item?.subprogram,
                                            }))}
                                            onThirdLevelChange={(selected) => {
                                                const isSelected = teachingThirdDegree?.find(
                                                    (item) => item?.id === selected?.id,
                                                );
                                                setState({
                                                    type: 'SET_VALUE',
                                                    field: 'teachingThirdDegree',
                                                    value: isSelected
                                                        ? teachingThirdDegree?.filter(
                                                              (item) => item?.id !== selected?.id,
                                                          )
                                                        : [selected],
                                                });
                                            }}
                                            showValues={false}
                                        />
                                    </FlexCentredRow>
                                ))}
                            </FlexColumn>
                        </FlexColumn>
                        <FlexColumn className="gap-[3rem]">
                            <Text align="left" size="1.6rem" weight="600" top="2rem">
                                Non-teaching Staff
                            </Text>
                            <label className="font-normal text-[14px]">Academic Units</label>
                            <FlexColumn className="gap-[1.8rem]">
                                {organizationAcademicStructures?.map((item) => (
                                    <FlexCentredRow key={item?.name} className="gap-[1.8rem] ml-[3rem]">
                                        <label className="font-semibold text-[14px]">{item?.name}</label>
                                        <MultiSelect
                                            showLevels={false}
                                            searchable
                                            containerClass="w-[25rem] relative"
                                            objProp="facultyName"
                                            placeholder={`Choose ${item?.name}`}
                                            onChange={(selected) => {
                                                handleSelectFirstDegree(
                                                    selected,
                                                    'selectedNonteachingFirstDegree',
                                                    'selectedNonTeachingStructure',
                                                );
                                            }}
                                            data={item?.name === 'College' ? allColleges : allFaculties}
                                            secondLevelList={nonTeachingSecondLevelData?.map((item) => ({
                                                id: item?._id,
                                                name: item?.departmentName,
                                                levels: item?.levels?.map((level) => ({
                                                    id: level,
                                                    name: `${level} Level`,
                                                })),
                                            }))}
                                            selectedSecondLevel={nonTeachingSecondDegree}
                                            selectedThirdLevel={nonTeachingThirdDegree}
                                            onButtonClick={() =>
                                                handleSelection('nonTeachingStaffSelection', {
                                                    structureId: selectedNonteachingFirstDegree?.academicStructureId,
                                                    firstDegree: selectedNonteachingFirstDegree?.facultyName,
                                                    secondDegree: nonTeachingSecondDegree,
                                                    level: selectedNonTeachingLevels?.map((item) => item?.name),
                                                    subProgramIds: selectedNonTeachingSecondDegree,
                                                    firstDegreeId: selectedNonteachingFirstDegree?._id,
                                                    levelId: selectedNonTeachingLevels?.map((item) => item?.id),
                                                    middleId:
                                                        selectedNonTeachingStructure === 3
                                                            ? nonTeachingSecondDegree?.id
                                                            : null,
                                                    thirdDegree: nonTeachingThirdDegree,
                                                })
                                            }
                                            setSelectedAcademicLevels={(levels) =>
                                                setState({
                                                    type: 'SET_VALUE',
                                                    field: 'selectedNonTeachingLevels',
                                                    value: levels,
                                                })
                                            }
                                            selectedAcademicLevels={selectedNonTeachingLevels}
                                            structure={selectedNonTeachingStructure}
                                            secondLevelLoading={collegeLoading || facultyLoading}
                                            onSecondLevelChange={(item) => {
                                                const newLevels = item?.levels?.sort((a, b) => a.id - b.id);
                                                newLevels?.unshift({ id: 'Select All', name: 'Select All' });
                                                setState({
                                                    type: 'SET_VALUE',
                                                    field: 'nonTeachingAcademicLevels',
                                                    value: newLevels,
                                                });
                                                setState({
                                                    type: 'SET_VALUE',
                                                    field: 'nonTeachingSecondDegree',
                                                    value: [item],
                                                });
                                                updateOrCreateSecondDegreeKey(
                                                    selectedNonteachingFirstDegree?._id,
                                                    item?.id,
                                                    'selectedNonTeachingSecondDegree',
                                                );
                                            }}
                                            academicLevels={nonTeachingAcademicLevels}
                                            thirdLevelList={nonTeachingThirdLevelData?.map((item) => ({
                                                id: item?._id,
                                                name: item?.departmentName ?? item?.department ?? item?.subprogram,
                                            }))}
                                            onThirdLevelChange={(selected) => {
                                                const isSelected = nonTeachingThirdDegree?.find(
                                                    (item) => item?.id === selected?.id,
                                                );
                                                setState({
                                                    type: 'SET_VALUE',
                                                    field: 'nonTeachingThirdDegree',
                                                    value: isSelected
                                                        ? nonTeachingThirdDegree?.filter(
                                                              (item) => item?.id !== selected?.id,
                                                          )
                                                        : [selected],
                                                });
                                            }}
                                            showValues={false}
                                        />
                                    </FlexCentredRow>
                                ))}
                            </FlexColumn>
                            <FlexCentredRow className="gap-[1.8rem]">
                                <label className="font-normal text-[14px]">Non Academic Units</label>
                                <MultiSelect
                                    searchable
                                    containerClass="w-[25rem]"
                                    objProp="unitName"
                                    multiSelect
                                    placeholder="Choose Units"
                                    onChange={(selected) =>
                                        setState({
                                            type: 'SET_VALUE',
                                            field: 'nonTeachingStaffUnitsPayload',
                                            value: selected,
                                        })
                                    }
                                    onButtonClick={() => {
                                        const filteredNonAcadUnits = nonAcademicUnitPayload?.filter(
                                            (item) => item?.id !== 'Select All',
                                        );
                                        handleSelection('nonTeachingStaffUnitsSelection', filteredNonAcadUnits);
                                    }}
                                    data={[{ name: 'Select All', id: 'Select All' }, ...orgUnitsToShow]}
                                    singleSelect
                                    showValues={false}
                                />
                            </FlexCentredRow>
                        </FlexColumn>
                    </div>
                </LeftContainer>
                <RightContainer>
                    <Container>
                        <Text align="left" size="1.6rem" lineHeight="2.4rem" weight="600">
                            Selections
                        </Text>
                        <hr className="my-[2.4rem]" />
                        <div>
                            <Text lineHeight="2.4rem" size="1.4rem" weight="500" align="left" bottom="0.8rem">
                                Students
                            </Text>
                            <div className="border-[#D1D5DB] min-h-[10.7rem] flex flex-col gap-[1rem] border rounded mb-[4rem] py-[1.4rem] px-[1rem]">
                                {studentSelection?.map((item, i) => (
                                    <div
                                        key={item?.firstDegree}
                                        className="relative border-[1px] rounded-[4px] border-dashed border-[#6EE7B7] w-full p-[10px] flex flex-wrap gap-[1rem]"
                                    >
                                        <div className="absolute rounded-[4px] shadow-closeButton border-[0.5px] right-[1rem] p-[8px] bg-[#fff] cursor-pointer">
                                            <CloseIcon onClick={() => handleRemoveSelection(i)} className="" />
                                        </div>
                                        <TagItems>
                                            <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                {item?.firstDegree}
                                            </Text>
                                        </TagItems>

                                        <TagItems>
                                            <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                {item?.secondDegree}
                                            </Text>
                                        </TagItems>

                                        <TagItems>
                                            <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                {item?.thirdDegree?.name}
                                            </Text>
                                        </TagItems>

                                        <div className="flex gap-[.5rem] flex-wrap">
                                            {item?.level
                                                ?.filter((level) => level !== 'Select All')
                                                ?.map((level) => (
                                                    <TagItems key={level}>
                                                        <Text
                                                            weight="500"
                                                            size="1.2rem"
                                                            lineHeight="1.7rem"
                                                            color="#FFF"
                                                        >
                                                            {level}
                                                        </Text>
                                                    </TagItems>
                                                ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <Text lineHeight="2.4rem" size="1.4rem" weight="500" align="left" bottom="0.8rem">
                                Organization Units
                            </Text>
                            <div className="border-[#D1D5DB] min-h-[10.7rem] border rounded mb-[4rem] flex flex-wrap gap-[1rem] py-[1.4rem] px-[1.8rem]">
                                {nonAcademicUnitSelection?.map((item, i) => (
                                    <div
                                        key={item?._id}
                                        className="relative border-[1px] rounded-[4px] border-dashed border-[#6EE7B7] w-full p-[10px] flex flex-wrap gap-[1rem]"
                                    >
                                        <div className="">
                                            <div className="absolute rounded-[4px] shadow-closeButton border-[0.5px] right-[1rem] p-[8px] bg-[#fff] cursor-pointer">
                                                <CloseIcon onClick={() => handleRemoveOrgSelection(i)} className="" />
                                            </div>
                                            <TagItems>
                                                <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                    {item?.unitName}
                                                </Text>
                                            </TagItems>
                                        </div>
                                    </div>
                                ))}
                                {academicUnitSelection?.map((item, i) => (
                                    <div
                                        key={item?.firstDegree}
                                        className="relative border-[1px] rounded-[4px] border-dashed border-[#6EE7B7] w-full p-[10px] flex flex-wrap gap-[1rem]"
                                    >
                                        <div className="absolute rounded-[4px] shadow-closeButton border-[0.5px] right-[1rem] p-[8px] bg-[#fff] cursor-pointer">
                                            <CloseIcon onClick={() => handleRemoveAcadSelection(i)} className="" />
                                        </div>
                                        <TagItems>
                                            <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                {item?.firstDegree}
                                            </Text>
                                        </TagItems>

                                        {item?.secondDegree?.map((secDeg) => (
                                            <TagItems key={secDeg?.id}>
                                                <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                    {secDeg?.name}
                                                </Text>
                                            </TagItems>
                                        ))}

                                        {item?.thirdDegree?.map((thDeg) => (
                                            <TagItems key={thDeg?.id}>
                                                <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                    {thDeg?.name}
                                                </Text>
                                            </TagItems>
                                        ))}

                                        <div className="flex gap-[.5rem] flex-wrap">
                                            {item?.levelId?.map((level) => (
                                                <TagItems key={level}>
                                                    <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                        {level}
                                                    </Text>
                                                </TagItems>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <Text lineHeight="2.4rem" size="1.4rem" weight="500" align="left" bottom="0.8rem">
                                Teaching Staff
                            </Text>
                            <div className="border-[#D1D5DB] min-h-[10.7rem] flex flex-col gap-[1rem] border rounded mb-[4rem] py-[1.4rem] px-[1rem]">
                                {teachingStaffSelection?.map((item, i) => (
                                    <div
                                        key={item?.firstDegree}
                                        className="relative border-[1px] rounded-[4px] border-dashed border-[#6EE7B7] w-full p-[10px] flex flex-wrap gap-[1rem]"
                                    >
                                        <div className="absolute rounded-[4px] shadow-closeButton border-[0.5px] right-[1rem] p-[8px] bg-[#fff] cursor-pointer">
                                            <CloseIcon onClick={() => handleRemoveTeachSelection(i)} className="" />
                                        </div>
                                        <TagItems>
                                            <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                {item?.firstDegree}
                                            </Text>
                                        </TagItems>

                                        {item?.secondDegree?.map((secDeg) => (
                                            <TagItems key={secDeg?.id}>
                                                <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                    {secDeg?.name}
                                                </Text>
                                            </TagItems>
                                        ))}

                                        {item?.thirdDegree?.map((thDeg) => (
                                            <TagItems key={thDeg?.id}>
                                                <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                    {thDeg?.name}
                                                </Text>
                                            </TagItems>
                                        ))}

                                        <div className="flex gap-[.5rem] flex-wrap">
                                            {item?.level?.map((level) => (
                                                <TagItems key={level}>
                                                    <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                        {level}
                                                    </Text>
                                                </TagItems>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <Text lineHeight="2.4rem" size="1.4rem" weight="500" align="left" bottom="0.8rem">
                                Non-teaching Staff
                            </Text>
                            <div className="border-[#D1D5DB] min-h-[10.7rem] flex flex-col gap-[1rem] border rounded mb-[4rem] py-[1.4rem] px-[1rem]">
                                {nonTeachingStaffUnitsSelection?.map((item, i) => (
                                    <div
                                        key={item?._id}
                                        className="relative border-[1px] rounded-[4px] border-dashed border-[#6EE7B7] w-full p-[10px] flex flex-wrap gap-[1rem]"
                                    >
                                        <div className="">
                                            <div className="absolute rounded-[4px] shadow-closeButton border-[0.5px] right-[1rem] p-[8px] bg-[#fff] cursor-pointer">
                                                <CloseIcon
                                                    onClick={() => handleRemoveNonOrgSelection(i)}
                                                    className=""
                                                />
                                            </div>
                                            <TagItems>
                                                <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                    {item?.unitName}
                                                </Text>
                                            </TagItems>
                                        </div>
                                    </div>
                                ))}

                                {nonTeachingStaffSelection?.map((item, i) => (
                                    <div
                                        key={item?.firstDegree}
                                        className="relative border-[1px] rounded-[4px] border-dashed border-[#6EE7B7] w-full p-[10px] flex flex-wrap gap-[1rem]"
                                    >
                                        <div className="absolute rounded-[4px] shadow-closeButton border-[0.5px] right-[1rem] p-[8px] bg-[#fff] cursor-pointer">
                                            <CloseIcon onClick={() => handleRemoveNonTeachSelection(i)} className="" />
                                        </div>
                                        <TagItems>
                                            <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                {item?.firstDegree}
                                            </Text>
                                        </TagItems>

                                        {item?.secondDegree?.map((secDeg) => (
                                            <TagItems key={secDeg?.id}>
                                                <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                    {secDeg?.name}
                                                </Text>
                                            </TagItems>
                                        ))}

                                        {item?.thirdDegree?.map((thDeg) => (
                                            <TagItems key={thDeg?.id}>
                                                <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                    {thDeg?.name}
                                                </Text>
                                            </TagItems>
                                        ))}

                                        <div className="flex gap-[.5rem] flex-wrap">
                                            {item?.level?.map((level) => (
                                                <TagItems key={level}>
                                                    <Text weight="500" size="1.2rem" lineHeight="1.7rem" color="#FFF">
                                                        {level}
                                                    </Text>
                                                </TagItems>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Container>
                    <FlexContainer>
                        <CancelButton type="button" onClick={() => navigate(-1)}>
                            Cancel
                        </CancelButton>
                        <LoadingButton
                            disabled={!distributionListName}
                            bgColor="#6366F1"
                            onClick={createDistributionList}
                            color="#fff"
                            type="submit"
                        >
                            Add
                        </LoadingButton>
                    </FlexContainer>
                </RightContainer>
            </MainContainer>
        </div>
    );
};

export default AddDistributionList;
