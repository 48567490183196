import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import RadioInput from '../../../components/inputs/radio';
import UploadFile from '../../../components/upload-file';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { capitalizeFirstLetter, formatDate, getStatusBadge, sortData } from '../../../utils';

const Bulletin = ({ tableHeader, tableData, selectedItem, handleSelect }) => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [showUploadFile, setShowUploadFile] = useState(false);
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        const sortedData = sortData(tableData);
        setSortedData(sortedData);
    }, [tableData]);

    const BulletinData = sortedData?.map((bulletins) => {
        const formattedDate = formatDate(bulletins.createdAt);
        return {
            action: (
                <td className="cursor-pointer">
                    <RadioInput checked={selectedItem?._id === bulletins?._id} />
                </td>
            ),
            time: formattedDate,
            subject: (
                <td
                    className="cursor-pointer text-[#2563EB]"
                    onClick={() => navigate(`view/${bulletins._id}`, { state: bulletins?.type })}
                >
                    {bulletins?.title}
                </td>
            ),
            commsStatus: getStatusBadge(
                capitalizeFirstLetter(bulletins.status === 'sent' ? 'Published' : bulletins?.status),
            ),
            ...bulletins,
            // subject: <td style={{ fontWeight: '600' }}>{bulletins?.title}</td>,
        };
    });

    return (
        <>
            <PageLayout
                noMargin
                searchable
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                data={BulletinData}
                fields={tableHeader}
                // loading={isLoading}
                onRowClick={(item) => handleSelect(item)}
            />

            {showUploadFile && <UploadFile show={showUploadFile} setShow={setShowUploadFile} />}
        </>
    );
};

export default Bulletin;
