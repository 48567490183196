import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    provider: Yup.string().nullable().required('Provider is required'),
    businessName: Yup.string().nullable().required('Business Name is required'),
    publicKey: Yup.string().nullable().required('Public Key is required'),
    secretKey: Yup.string().nullable().required('Secret Key is required'),
    encryptKey: Yup.string().when('provider', {
        is: 'flutterwave',
        then: Yup.string()
            .required('Encrypt Key is required when the provider is "flutterwave"')
            .notOneOf([''], 'Encrypt Key cannot be empty'),
        otherwise: Yup.string().notRequired(),
    }),
    contractCode: Yup.string().when('provider', {
        is: 'monnify',
        then: Yup.string()
            .required('Contract Code is required when the provider is "monnify"')
            .notOneOf([''], 'Contract Code cannot be empty'),
        otherwise: Yup.string().notRequired(),
    }),
    active: Yup.boolean(),
});
