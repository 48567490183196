import React from 'react';

import Avatar from 'react-avatar';
import tw, { styled } from 'twin.macro';

import { ReactComponent as People } from '../../../../../assets/icons/people2.svg';
import { StatusContainer, getStatusStyles } from '../../../../../components/shared';
import { PageTitle, Text } from '../../../../../containers/MesssageContainers';
import { FlexRowSpaceBetween, FlexCentredRow } from '../../../../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../../../../utils';

const MainContainer = tw.div`
    grid
    gap-8
    mt-8
    mb-[6.4rem]
`;

const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const RowContainer = tw.div`
    flex
    flex-col
    gap-8
`;

const FlexCol = tw.div``;

const Details = tw.div`
    bg-white
    p-6
    rounded-lg
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: left;
`;

const DarkText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-align: left;
`;

const Overview = ({ bio, staff }) => {
    const firstDegreeStaff = staff?.academicStructure?.split('-')[0];
    const secondDegreeStaff = staff?.academicStructure?.split('-')[1];
    const firstDegree = staff?.[firstDegreeStaff];
    const secondDegree = staff?.[secondDegreeStaff];

    const leftRow = [
        {
            title: 'Full Name',
            subtitle: `${staff?.surname} ${staff?.firstName} ${staff?.otherName || ''}`,
        },
        {
            title: capitalizeFirstLetter(firstDegreeStaff),
            subtitle: firstDegree || '',
        },
        {
            title: 'Designation',
            subtitle: staff?.designation,
        },
        {
            title: 'Appointment',
            subtitle: staff?.appointmentName,
        },
    ];
    const rightRow = [
        {
            title: capitalizeFirstLetter(secondDegreeStaff),
            subtitle: secondDegree || staff?.subProgram || '',
        },
        {
            title: 'Email',
            subtitle: staff?.staffEmail,
        },
        {
            title: 'Date of Employment ',
            subtitle: staff?.yearOfEmployment?.slice(0, 10),
        },
    ];

    const otherDetailsItems = [
        {
            title: 'Employment Type',
            subtitle: staff?.staffType,
        },
        {
            title: 'Nationality',
            subtitle: bio?.personalInfo?.nationality,
        },
        {
            title: 'Residential Address',
            subtitle: bio?.personalInfo?.permanentAddress,
        },
        {
            title: 'State of Origin',
            subtitle: bio?.personalInfo?.stateOfOrigin,
        },

        {
            title: 'Personal Email Address',
            subtitle: staff?.staffEmail,
        },
        {
            title: 'LGA',
            subtitle: bio?.personalInfo?.localGovernment,
        },
        {
            title: 'Phone Number',
            subtitle: bio?.personalInfo?.phoneNumber,
        },
    ];

    return (
        <div>
            <MainContainer>
                <Details className="mt-[3rem]">
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Staff Details
                            </PageTitle>
                        </FlexCentredRow>

                        <div className="flex gap-2">
                            <FlexCol className="justify-end gap-[3.2rem]">
                                <Text>Admin</Text>
                                <StatusContainer style={getStatusStyles(staff?.staffStatus.toLowerCase())}>
                                    <span>{staff?.staffStatus}</span>
                                </StatusContainer>
                            </FlexCol>
                            {staff?.staffType === 'Teaching' && (
                                <FlexCol className="justify-end gap-[3.2rem]">
                                    <Text>Lecturers</Text>
                                    <StatusContainer style={getStatusStyles(staff?.lecturerStatus.toLowerCase())}>
                                        <span>{staff?.lecturerStatus}</span>
                                    </StatusContainer>
                                </FlexCol>
                            )}
                        </div>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        {staff?.staffType === 'Teaching' && bio?.profilePicture?.url ? (
                            <img
                                src={bio?.profilePicture?.url}
                                alt="profile"
                                className="w-[15rem] h-[15rem] col-span-1"
                            />
                        ) : staff?.staffType === 'Non-Teaching' && bio?.profilePic?.url ? (
                            <img src={bio?.profilePic?.url} alt="profile" className="w-[15rem] h-[15rem] col-span-1" />
                        ) : (
                            <Avatar
                                name={`${staff?.surname} ${staff?.firstName} ${staff?.otherName || ''}`}
                                size="150"
                                textSizeRatio={1.75}
                            />
                        )}

                        <RowContainer className="">
                            {leftRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                        <RowContainer className="">
                            {rightRow?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </RowContainer>
                    </BasicContent>
                </Details>
                <Details className="mt-[3rem]">
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People fill="#3B82F6" className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Other Details
                            </PageTitle>
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        <div className="grid grid-cols-3 gap-8">
                            {otherDetailsItems?.map((item, index) => (
                                <FlexCol key={index}>
                                    <GreyText>{item.title}</GreyText>
                                    <DarkText>{item.subtitle}</DarkText>
                                </FlexCol>
                            ))}
                        </div>
                    </BasicContent>
                </Details>
            </MainContainer>
        </div>
    );
};

export default Overview;
